import { WithChildren } from "@kea-mod/common";
import { EditBPMNModalContextProvider } from "context/EditBPMNModalContextProvider";
import { EditLabelModalContextProvider } from "context/EditLabelModalContextProvider";
import { EditUMLLifeLineContextProvider } from "context/EditUMLLifeLineContextProvider";
import { EditUMLModalContextProvider } from "context/EditUMLModalContextProvider";

interface Props extends WithChildren {}

export const StandaloneContext = (props: Props) => {
  return (
    <EditLabelModalContextProvider>
      <EditUMLModalContextProvider>
        <EditBPMNModalContextProvider>
          <EditUMLLifeLineContextProvider>{props.children}</EditUMLLifeLineContextProvider>
        </EditBPMNModalContextProvider>
      </EditUMLModalContextProvider>
    </EditLabelModalContextProvider>
  );
};
