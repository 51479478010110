import * as joint from "jointjs";

export const editLinkTool = new joint.linkTools.Button({
  markup: [
    {
      tagName: "circle",
      selector: "button",
      attributes: {
        r: 7,
        fill: "#35495d",
        cursor: "pointer",
      },
    },
    {
      tagName: "path",
      selector: "icon",
      attributes: {
        d: "M -3 -4 L 3 -4 M -3 -4 L -3 4 M -3 0 L 2 0 M -3 4 L 3 4",
        fill: "none",
        stroke: "#FFFFFF",
        "stroke-width": 2,
        "pointer-events": "none",
      },
    },
  ],
  distance: -60,
  offset: 10,
  action: function (_evt) {
    // @ts-ignore
    (this.paper as KEAPaper)?.nodeClb(this.model);
  },
});
