import { WithChildren } from "@kea-mod/common";
import { getExerciseId, getModelId } from "helper/Helper";
import { useCallback, useEffect, useState } from "react";
import { AssessmentContext } from "./AssessmentContext";

interface Props extends WithChildren {}

export const AssessmentContextProvider = (props: Props) => {
  const [exerciseId, setExerciseId] = useState<number | undefined>(undefined);
  const [modelId, setModelId] = useState<number | undefined>(undefined);

  const updateExerciseId = useCallback(() => {
    const exerciseId = getExerciseId();
    exerciseId ? setExerciseId(exerciseId) : void 0;
  }, [setExerciseId]);

  const updateModelId = useCallback(() => {
    const modelId = getModelId();
    modelId ? setModelId(modelId) : void 0;
  }, [setModelId]);

  useEffect(() => {
    updateExerciseId();
    updateModelId();
  }, [updateExerciseId, updateModelId]);

  return (
    <AssessmentContext.Provider
      value={{
        exerciseId,
        updateExerciseId,
        modelId,
        updateModelId,
      }}
    >
      {props.children}
    </AssessmentContext.Provider>
  );
};
