import * as joint from "jointjs";

export const startNewLinkTool = new joint.elementTools.Button({
  markup: [
    {
      tagName: "rect",
      attributes: {
        width: 20,
        height: 20,
        fill: "white",
        opacity: 0.9,
        cursor: "crosshair",
      },
    },
    {
      tagName: "path",
      attributes: {
        transform: "translate(10 10)",
        stroke: "black",
        fill: "none",
        d: "M -5 3 L -2 3 L -2 -3 L 2 -3 L 2 -5 L 5 -2 L 2 1 L 2 -1 L 0 -1 L 0 5 L -5 5 Z",
        cursor: "crosshair",
      },
    },
  ],
  x: "100%",
  y: "100%",
  offset: {
    x: -10,
    y: 10,
  },
  action: function (evt) {
    // @ts-ignore
    const paper = this.paper as KEAPaper;
    paper.trigger("keaelement:link", this, evt);
  },
});
