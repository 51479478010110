export const exportFile = (file: any, name = "model.kea", contentType = "application/json;charset=utf-8;") => {
  const blob = new Blob([decodeURIComponent(encodeURI(file))], {
    type: contentType,
  });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = blobUrl;
  link.download = name;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export const getIsExportEnabled = (): boolean => {
  const isExportEnabled = getURLParam("exportEnabled");
  if (!isExportEnabled) return false;
  return convertToBoolean(isExportEnabled);
};

const convertToBoolean = (s: string): boolean => {
  switch (s.toLowerCase().trim()) {
    case "true":
      return true;
    case "false":
    case null:
    default:
      return false;
  }
};

export const getModelId = (): string | null => {
  return getURLParam("modelId");
};

export const getExerciseId = (): string | null => {
  return getURLParam("exerciseId");
};

export const getSubmissionId = (): string | null => {
  return getURLParam("submissionId");
};

export const getParentModel = (): string | null => {
  return getURLParam("parentModel");
};

export const getParentId = (): number | null => {
  const parentId = getURLParam("parentId");
  if (!parentId) return null;
  return parseInt(parentId);
};

export const getModeltype = (): string | null => {
  return getURLParam("type");
};

export const getSubmissionExerciseId = (): string | null => {
  return getURLParam("submissionExerciseId");
};

const getURLParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const setIntervalLimited = (callback: any, interval: number, repeats: number) => {
  for (let i = 0; i < repeats; i++) {
    setTimeout(callback, i * interval);
  }
};
