import {
  KEAGraph,
  KEALink,
  KEAUMLClass,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const class1 = new KEAUMLClass();
class1.position(20, 10);
class1.setClassIdentifier("Class");
class1.setClassName("");

const class2 = new KEAUMLClass();
class2.position(20, 190);
class2.setClassIdentifier("Interface");
class2.setClassName("");
const class3 = new KEAUMLClass();
class3.position(20, 370);
class3.setClassIdentifier("Enumeration");
class3.setClassName("");

const link1 = new KEALink();
link1.source({ x: 20, y: 560 });
link1.target({ x: 200, y: 560 });
link1.setDasharray(LinkType.SOLID);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.NONE);

const link2 = new KEALink();
link2.source({ x: 20, y: 600 });
link2.target({ x: 200, y: 600 });
link2.setDasharray(LinkType.SOLID);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const link3 = new KEALink();
link3.source({ x: 20, y: 640 });
link3.target({ x: 200, y: 640 });
link3.setDasharray(LinkType.SOLID);
link3.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.ARROW);
link3.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const link4 = new KEALink();
link4.source({ x: 20, y: 680 });
link4.target({ x: 200, y: 680 });
link4.setDasharray(LinkType.SOLID);
link4.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link4.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_EMPTY);

const link5 = new KEALink();
link5.source({ x: 20, y: 720 });
link5.target({ x: 200, y: 720 });
link5.setDasharray(LinkType.SOLID);
link5.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link5.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.DIAMOND_EMPTY);

let defaultLink = link1.clone();

export const getUMLClassConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [class1, class2, class3, link1, link2, link3, link4, link5];
};

export const getUMLClassMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLClassConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.6,
    () => {
      return defaultLink;
    },
    conf,
  );
};
