import {
  KEABPMNSwimlane,
  KEAGraph,
  KEALink,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const swimlane1 = new KEABPMNSwimlane().position(10, 10).setNumberOfLanes(1);
const swimlane2 = new KEABPMNSwimlane().position(10, 170).setNumberOfLanes(2);
const swimlane3 = new KEABPMNSwimlane().position(10, 480).setNumberOfLanes(3);

let defaultLink = new KEALink();
defaultLink.setDasharray(LinkType.SOLID);
defaultLink.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
defaultLink.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

export const getSwimlaneConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [swimlane1, swimlane2, swimlane3];
};

export const getSwimlaneMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getSwimlaneConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.4,
    () => {
      return defaultLink;
    },
    conf,
  );
};
