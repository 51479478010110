import { forwardRef } from "react";
import { LicenseModalContextProvider } from "../../../../context/LicenseModalContextProvider";
import { FooterView } from "./FooterView";

interface Props {}

export const FooterContainer = forwardRef<HTMLDivElement>((_props: Props, ref) => {
  return (
    <LicenseModalContextProvider>
      <FooterView footerRef={ref} />
    </LicenseModalContextProvider>
  );
});
