import { KEABPMNSwimlane } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: KEABPMNSwimlane;
}

export const EditBPMNModal = ({ setisActive, isActive, node }: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const [label, setLabel] = useState<string>("");
  const [numberOfLanes, setNumberOfLanes] = useState<number>(-1);
  const [hasPoolLabel, setHasPoolLabel] = useState<Array<boolean>>([]);
  const [poolLabels, setPoolLabels] = useState<Array<string>>([]);

  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (node) {
      setLabel(node.getLabel());
      setNumberOfLanes(node.getNumberOfLanes());
      setHasPoolLabel(node.getHasPoolLabel());
      setPoolLabels(node.getPoolLabels());
    }
  }, [node]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLabel(event.target.value);
  };

  const handleHasPoolChange = (_e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    let temp = [...hasPoolLabel];
    temp[index] = !temp[index];
    setHasPoolLabel(temp);
  };

  const handlePoolChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    let temp = [...poolLabels];
    temp[index] = e.target.value;
    setPoolLabels(temp);
  };

  const setIsActiveWrapper = useCallback((): void => {
    setisActive(false);
  }, [setisActive]);

  const assignLabel = useCallback((): void => {
    if (node) {
      node.setLabel(label);
      node.setNumberOfLanes(numberOfLanes);
      node.setHasPoolLabel(hasPoolLabel);
      poolLabels.forEach((label, index) => {
        node.setPoolLabel(index, label);
      });
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setisActive(true);
  }, [node, keaGraphContext, setisActive, label, numberOfLanes, hasPoolLabel, poolLabels]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignLabel();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignLabel, isActive, setIsActiveWrapper]);

  const addLane = () => {
    if (!node) return;
    (node as KEABPMNSwimlane).setNumberOfLanes(numberOfLanes + 1);
    setNumberOfLanes(numberOfLanes + 1);
    setHasPoolLabel([...hasPoolLabel, false]);
    setPoolLabels([...poolLabels, ""]);
    node.setLabel(label);
    node.setNumberOfLanes(numberOfLanes);
    node.setHasPoolLabel(hasPoolLabel);
    poolLabels.forEach((label, index) => {
      node.setPoolLabel(index, label);
    });
  };
  const removeLane = () => {
    if (!node) return;
    if ((node as KEABPMNSwimlane).get("numberOfPoolLanes") < 2) return;
    (node as KEABPMNSwimlane).removeLastLane();
    setNumberOfLanes(numberOfLanes - 1);
    const newHasPoolLabel = [...hasPoolLabel];
    const newPoolLabel = [...poolLabels];
    newHasPoolLabel.pop();
    poolLabels.pop();
    setHasPoolLabel([...newHasPoolLabel]);
    setPoolLabels([...newPoolLabel]);
    node.setLabel(label);
    node.setNumberOfLanes(numberOfLanes);
    node.setHasPoolLabel(hasPoolLabel);
    poolLabels.forEach((label, index) => {
      node.setPoolLabel(index, label);
    });
  };
  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("bpmn_modal_editswimlane") as string}
      body={
        <>
          <div className="field">
            <label className="label">{t("bpmn_modal_editswimlane_name")}</label>
            <div className="control">
              <input
                ref={inputElement}
                placeholder={t("bpmn_modal_editswimlane_name") as string}
                className="input"
                type="text"
                value={label}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="divider">{t("bpmn_modal_editswimlane_laneconfiguration")}</div>

          {hasPoolLabel.map((_item, index) => {
            return (
              <div key={index}>
                <div className="field has-addons">
                  <div className="control pr-3">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={hasPoolLabel[index]}
                        onChange={(e) => handleHasPoolChange(e, index)}
                      />
                      {t("bpmn_modal_editswimlane_laneconfiguration_hasheader")}
                    </label>
                  </div>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder={t("bpmn_modal_editswimlane_laneconfiguration_header") as string}
                      disabled={!hasPoolLabel[index]}
                      value={poolLabels[index]}
                      onChange={(e) => handlePoolChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button className="button is-rounded is-light is-success" onClick={addLane}>
            {t("bpmn_swimLane_add")}
          </button>
          <button className="button is-rounded is-light is-danger" onClick={removeLane} disabled={numberOfLanes < 2}>
            {t("bpmn_swimLane_remove")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignLabel();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
