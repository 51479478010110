import { KEAElement } from "@kea-mod/jointjs";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useUserSettingsContext } from "context/UserSettingsContext";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  LOCAL_STORAGE_LABEL_COLOR_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT,
  LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT,
  LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT,
} from "shared/UserLocalStorage";
import { CustomModalCard } from "./CustomModalCard";
import { GridType } from "shared/misc/GridType";

interface Props {
  setIsActive: (isActive: boolean) => void;
  isActive: boolean;
}

const getGridOptions = (drawGrid: any) => {
  if (drawGrid.name === "doubleMesh") {
    return {
      gridType: "doubleMesh",
      gridColor: drawGrid.args[0].color,
      gridThickness: drawGrid.args[0].thickness,
      grid2Color: drawGrid.args[1].color,
      grid2Thickness: drawGrid.args[1].thickness,
      scaleFactor: drawGrid.args[1].scaleFactor,
    };
  }
  return {
    gridType: drawGrid.name,
    gridColor: drawGrid.args[0].color,
    gridThickness: drawGrid.args[0].thickness,
    grid2Color: undefined,
    grid2Thickness: undefined,
    scaleFactor: undefined,
  };
};

const getDrawGrid = (
  gridType: GridType,
  gridThickness: number,
  gridColor: string,
  grid2Thickness: number | undefined,
  grid2Color: string | undefined,
  scaleFactor: number | undefined,
) => {
  if (gridType === GridType.NONE) {
    return {
      name: gridType,
      args: [
        {
          color: gridColor,
          thickness: gridThickness,
        },
      ],
    };
  }
  if (gridType === GridType.DOUBLE_MESH) {
    return {
      name: "doubleMesh",
      args: [
        {
          color: gridColor,
          thickness: gridThickness,
        },
        {
          color: grid2Color,
          scaleFactor: scaleFactor,
          thickness: grid2Thickness,
        },
      ],
    };
  }
  return {
    name: gridType,
    args: [
      {
        color: gridColor,
        thickness: gridThickness,
      },
    ],
  };
};

export const UserSettingsModal = (props: Props) => {
  const { t } = useTranslation();

  const userSettings = useUserSettingsContext();
  const keagraphcontext = useKEAGraphContext();

  const assignSettings = useCallback((): void => {
    keagraphcontext.templateGraph.getElements().forEach((el) => {
      (el as KEAElement)
        .setPrimaryColor(userSettings.primaryColor)
        .setSecondaryColor(userSettings.secondaryColor)
        .setLabelColor(userSettings.labelColor);
    });
    keagraphcontext.modelingGraph.getElements().forEach((el) => {
      (el as KEAElement)
        .setPrimaryColor(userSettings.primaryColor)
        .setSecondaryColor(userSettings.secondaryColor)
        .setLabelColor(userSettings.labelColor);
    });
    keagraphcontext.modelingPaper.setGridOptions(userSettings.grid, userSettings.gridSize, userSettings.gridBackground);
  }, [keagraphcontext, userSettings]);

  const resetColorToDefault = (): void => {
    userSettings.setPrimaryColor(LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT);
    userSettings.setSecondaryColor(LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT);
    userSettings.setLabelColor(LOCAL_STORAGE_LABEL_COLOR_DEFAULT);
  };

  const resetTaskWindowToDefault = (): void => {
    userSettings.setTaskCardPositionX(LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT);
    userSettings.setTaskCardPositionY(LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT);
    userSettings.setTaskCardWidth(LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT);
    userSettings.setTaskCardHeight(LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT);
  };

  const resetGridToDefault = (): void => {
    userSettings.setGridSize(LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT);
    userSettings.setGridBackground(LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT);
    userSettings.setGrid(LOCAL_STORAGE_PAPER_GRID_DEFAULT);
  };

  const resetModelWindowToDefault = useCallback((): void => {
    userSettings.setSecondaryGraphPositionX(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X_DEFAULT);
    userSettings.setSecondaryGraphPositionY(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT);
    userSettings.setSecondaryGraphWidth(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT);
    userSettings.setSecondaryGraphHeight(LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT);
  }, [userSettings]);

  const abortEditing = useCallback((): void => {
    props.setIsActive(false);
  }, [props]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && props.isActive) {
        assignSettings();
        props.setIsActive(false);
      }
      if (event.key === "Escape" && props.isActive) {
        abortEditing();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [abortEditing, props.isActive, props.setIsActive, assignSettings, props]);

  const saveSettings = (gridOptions: any): void => {
    userSettings.setGrid(
      getDrawGrid(
        gridOptions.gridType,
        gridOptions.gridThickness,
        gridOptions.gridColor,
        gridOptions.grid2Thickness,
        gridOptions.grid2Color,
        gridOptions.scaleFactor,
      ),
    );
  };

  const setGridType = (gridType: GridType) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    if (gridType === gridOptions.gridType) return;
    if (gridType === GridType.DOUBLE_MESH) {
      gridOptions.grid2Color = gridOptions.gridColor;
      gridOptions.grid2Thickness = gridOptions.gridThickness + 5;
      gridOptions.scaleFactor = 5;
    } else {
      gridOptions.grid2Color = undefined;
      gridOptions.grid2Thickness = undefined;
      gridOptions.scaleFactor = undefined;
    }
    gridOptions.gridType = gridType;
    saveSettings(gridOptions);
  };

  const setGridColor = (gridColor: string) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    gridOptions.gridColor = gridColor;
    saveSettings(gridOptions);
  };

  const setGridThickness = (gridThickness: number) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    gridOptions.gridThickness = gridThickness;
    saveSettings(gridOptions);
  };

  const setGrid2Color = (grid2Color: string) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    if (gridOptions.gridType !== GridType.DOUBLE_MESH) return;
    gridOptions.grid2Color = grid2Color;
    saveSettings(gridOptions);
  };

  const setGrid2Thickness = (grid2Thickness: number) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    if (gridOptions.gridType !== GridType.DOUBLE_MESH) return;
    gridOptions.grid2Thickness = grid2Thickness;
    saveSettings(gridOptions);
  };

  const setScaleFactor = (scaleFactor: number) => {
    const grid = userSettings.grid;
    const gridOptions = getGridOptions(grid);
    if (gridOptions.gridType !== GridType.DOUBLE_MESH) return;
    gridOptions.scaleFactor = scaleFactor;
    saveSettings(gridOptions);
  };

  const firstGridSettings = () => {
    if (userSettings.grid.name === GridType.MESH) return t("usersettings_backgroundGrid");
    if (userSettings.grid.name === GridType.DOUBLE_MESH) return t("usersettings_backgroundGrid") + " 1";
    return t("usersettings_backgroundDot");
  };

  return (
    <CustomModalCard
      setisActive={abortEditing}
      isActive={props.isActive}
      header={t("usersettings_header") as string}
      body={
        <>
          <div className="divider is-left is-info">{t("usersettings_colors")}</div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_primarycolor")}</label>
                <div className="control">
                  <input
                    id="primaryColor"
                    placeholder={t("usersettings_primarycolor") as string}
                    className="input"
                    type="color"
                    value={userSettings.primaryColor}
                    onChange={(e) => userSettings.setPrimaryColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_secondarycolor") as string}</label>
                <div className="control">
                  <input
                    id="secondaryColor"
                    className="input"
                    type="color"
                    value={userSettings.secondaryColor}
                    onChange={(e) => userSettings.setSecondaryColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_labelcolor")}</label>
                <div className="control">
                  <input
                    id="labelColor"
                    className="input"
                    type="color"
                    value={userSettings.labelColor}
                    onChange={(e) => userSettings.setLabelColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider is-left is-info">{t("usersettings_restore")}</div>
          <div className="columns">
            <div className="column">
              <button
                type="button"
                className="button is-fullwidth"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  resetTaskWindowToDefault();
                }}
              >
                {t("usersettings_restoreTaskWindowDefaults")}
              </button>
            </div>
            <div className="column">
              <button
                type="button"
                className="button is-fullwidth"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  resetModelWindowToDefault();
                }}
              >
                {t("usersettings_restoreModelWindowDefaults")}
              </button>
            </div>
            <div className="column">
              <button
                type="button"
                className="button is-fullwidth"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  resetColorToDefault();
                }}
              >
                {t("usersettings_restoreColorDefaults")}
              </button>
            </div>
            <div className="column">
              <button
                type="button"
                className="button is-fullwidth"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  resetGridToDefault();
                }}
              >
                {t("usersettings_background")}
              </button>
            </div>
          </div>
          <div className="divider is-left is-info">{t("usersettings_background")}</div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_backgroundSpacing")}</label>
                <input
                  type="number"
                  className="input"
                  value={userSettings.gridSize}
                  onChange={(e) => userSettings.setGridSize(parseInt(e.target.value))}
                />
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_color")}</label>
                <input
                  type="color"
                  className="input"
                  value={userSettings.gridBackground}
                  onChange={(e) => userSettings.setGridBackground(e.target.value)}
                />
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label has-text-centered">{t("usersettings_backgroundType")}</label>
                <div className="select">
                  <select value={userSettings.grid.name} onChange={(e) => setGridType(e.target.value as GridType)}>
                    <option value={GridType.NONE}>{GridType.NONE}</option>
                    <option value={GridType.DOT}>{GridType.DOT}</option>
                    <option value={GridType.FIXED_DOT}>{GridType.FIXED_DOT}</option>
                    <option value={GridType.MESH}>{GridType.MESH}</option>
                    <option value={GridType.DOUBLE_MESH}>{GridType.DOUBLE_MESH}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {userSettings.grid.name !== GridType.NONE && (
            <>
              <div className="divider is-left is-info">{firstGridSettings()}</div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label has-text-centered">{t("usersettings_backgroundThickness")}</label>
                    <input
                      type="number"
                      className="input"
                      value={userSettings.grid.args[0].thickness}
                      onChange={(e) => setGridThickness(parseInt(e.target.value))}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label has-text-centered">{t("usersettings_color")}</label>
                    <input
                      id="gridColor"
                      className="input"
                      type="color"
                      value={userSettings.grid.args[0].color}
                      onChange={(e) => setGridColor(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {userSettings.grid.name === GridType.DOUBLE_MESH && (
            <>
              <div className="divider is-left is-info">{t("usersettings_backgroundGrid")} 2</div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label has-text-centered">{t("usersettings_backgroundThickness")} 2</label>
                    <input
                      type="number"
                      className="input"
                      value={userSettings.grid.args[1].thickness || 0}
                      onChange={(e) => setGrid2Thickness(parseInt(e.target.value))}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label has-text-centered">{t("usersettings_color")} 2</label>
                    <input
                      id="gridColor"
                      className="input"
                      type="color"
                      value={userSettings.grid.args[1].color || "#333333"}
                      onChange={(e) => setGrid2Color(e.target.value)}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label has-text-centered">{t("usersettings_backgroundScaleFactor")}</label>
                    <input
                      type="number"
                      className="input"
                      value={userSettings.grid.args[1].scaleFactor || 0}
                      onChange={(e) => setScaleFactor(parseInt(e.target.value))}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              props.setIsActive(false);
            }}
          >
            {t("close")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignSettings();
              props.setIsActive(false);
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
