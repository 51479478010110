import { createContext, useContext } from "react";
import { APIManager } from "service/APIManager";
interface IAPIContext {
  apiManager: APIManager;
}

const defaultState: IAPIContext = {
  apiManager: new APIManager(),
};

export const APIContext = createContext<IAPIContext>(defaultState);
export const useAPIContext = () => useContext(APIContext);
