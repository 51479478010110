import classNames from "classnames";
import i18n from "i18n/i18n";

interface Props {}

export const ChangeLanguageFooter = (_props: Props) => {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="column has-text-centered has-text-right-tablet mr-2 pr-5">
      <span
        className={classNames({
          "is-clickable": true,
          "has-text-weight-bold": i18n.language === "de",
        })}
        onClick={() => changeLanguage("de")}
      >
        DE
      </span>
      <span className="is-unselectable">|</span>
      <span
        className={classNames({
          "is-clickable": true,
          "has-text-weight-bold": i18n.language === "en",
        })}
        onClick={() => changeLanguage("en")}
      >
        EN
      </span>
    </div>
  );
};
