import {
  KEAElement,
  KEAGraph,
  KEALink,
  keaNamespace,
  LinkLabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
} from "@kea-mod/jointjs";
import { KEABPMNSwimlane } from "@kea-mod/jointjs/components/KEABPMNSwimlane";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const swimlane1 = new KEABPMNSwimlane().position(10, 10).setNumberOfLanes(2);
const swimlane2 = new KEABPMNSwimlane().position(10, 320).setNumberOfLanes(3);

const defaultLink = new KEALink();
defaultLink.source({ x: 10, y: 340 });
defaultLink.target({ x: 290, y: 340 });
defaultLink.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
defaultLink.setDasharray(LinkType.SOLID);
defaultLink.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
defaultLink.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

export const getUMLSwimlaneConfiguration = (): Array<KEAElement | KEALink> => {
  return [swimlane1, swimlane2];
};

export const getUMLSwimlaneMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLSwimlaneConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.4,
    () => {
      return defaultLink;
    },
    conf,
  );
};
