import { useKEAGraphContext } from "context/KEAGraphContext";
import { useEffect } from "react";

type Props = {
  setisActive(): void;
  isActive: boolean;
  header: string | undefined;
  body: JSX.Element;
  footer: JSX.Element;
};

export const CustomModalCard = (props: Props): JSX.Element => {
  const keaGraphContext = useKEAGraphContext();

  useEffect(() => {
    if (props.isActive) keaGraphContext.modelingPaper.removeDeleteEvent();
    if (!props.isActive) keaGraphContext.modelingPaper.addDeleteEvent();
  }, [props.isActive, keaGraphContext.modelingPaper]);

  const handleCloseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.setisActive();
  };

  return (
    <>
      {props.isActive && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            {props.header !== undefined && (
              <header className="modal-card-head">
                <p className="modal-card-title">{props.header}</p>
                <button className="delete" aria-label="close" onClick={handleCloseClick} type="button"></button>
              </header>
            )}
            <section className="modal-card-body">{props.body}</section>
            <footer className="modal-card-foot">{props.footer}</footer>
          </div>
        </div>
      )}
    </>
  );
};
