import {
  KEABPMNSwimlane,
  KEAGraph,
  KEALink,
  keaNamespace,
  LinkType,
  MarkerPosition,
  MarkerType,
} from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const swimlane1 = new KEABPMNSwimlane().position(10, 10).setNumberOfLanes(1);
const swimlane2 = new KEABPMNSwimlane().position(10, 170).setNumberOfLanes(2);

let link1 = new KEALink();
link1.source({ x: 10, y: 500 });
link1.target({ x: 200, y: 500 });
link1.setDasharray(LinkType.SOLID);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

let link2 = new KEALink();
link2.source({ x: 10, y: 525 });
link2.target({ x: 200, y: 525 });
link2.setDasharray(LinkType.DASHED);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.CIRCLE_EMPTY);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_EMPTY);

let link3 = new KEALink();
link3.source({ x: 10, y: 550 });
link3.target({ x: 200, y: 550 });
link3.setDasharray(LinkType.DASHED);

let link4 = new KEALink();
link4.source({ x: 10, y: 575 });
link4.target({ x: 200, y: 575 });
link4.setDasharray(LinkType.DASHED);
link4.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link4.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link1.clone();

export const getGeneralConfiguration = (): Array<any> => {
  return [swimlane1, swimlane2, link1, link2, link3, link4];
};

export const getBPMNGeneralMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getGeneralConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.8,
    () => {
      return defaultLink;
    },
    conf,
  );
};
