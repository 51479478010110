import {
  KEAGraph,
  KEALink,
  keaNamespace,
  KEAUMLActivityBar,
  KEAUMLContinuation,
  KEAUMLDestruction,
  KEAUMLLifeLine,
  KEAUMLStateInvariant,
  LinkLabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
} from "@kea-mod/jointjs";
import { KEAElement } from "@kea-mod/jointjs/components/KEAElement";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const activityBar = new KEAUMLActivityBar();
activityBar.position(20, 20);

const destruction = new KEAUMLDestruction();
destruction.position(20, 200);

const lifeLine = new KEAUMLLifeLine();
lifeLine.position(20, 290);

const stateInvariant = new KEAUMLStateInvariant();
stateInvariant.position(150, 290);

const continuation = new KEAUMLContinuation();
continuation.position(15, 400);

const linkSyncMessage = new KEALink();
linkSyncMessage.source({ x: 100, y: 25 });
linkSyncMessage.target({ x: 275, y: 25 });
linkSyncMessage.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
linkSyncMessage.setDasharray(LinkType.SOLID);
linkSyncMessage.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

const linkAsyncMessage = new KEALink();
linkAsyncMessage.source({ x: 100, y: 75 });
linkAsyncMessage.target({ x: 275, y: 75 });
linkAsyncMessage.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
linkAsyncMessage.setDasharray(LinkType.SOLID);
linkAsyncMessage.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const linkResponseMessage = new KEALink();
linkResponseMessage.source({ x: 100, y: 125 });
linkResponseMessage.target({ x: 275, y: 125 });
linkResponseMessage.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
linkResponseMessage.setDasharray(LinkType.DASHED);
linkResponseMessage.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const linkLostMessage = new KEALink();
linkLostMessage.source({ x: 100, y: 175 });
linkLostMessage.target({ x: 275, y: 175 });
linkLostMessage.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
linkLostMessage.setDasharray(LinkType.SOLID);
linkLostMessage.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.CIRCLE_FILLED_ARROW);

const linkFoundMessage = new KEALink();
linkFoundMessage.source({ x: 100, y: 225 });
linkFoundMessage.target({ x: 275, y: 225 });
linkFoundMessage.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
linkFoundMessage.setDasharray(LinkType.SOLID);
linkFoundMessage.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.CIRCLE_FILLED);
linkFoundMessage.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const defaultLink = new KEALink();
defaultLink.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
defaultLink.setDasharray(LinkType.DASHED);

export const getUMLSequenceConfiguration = (): Array<KEAElement | KEALink> => {
  return [
    activityBar,
    lifeLine,
    destruction,
    stateInvariant,
    continuation,
    linkSyncMessage,
    linkAsyncMessage,
    linkResponseMessage,
    linkLostMessage,
    linkFoundMessage,
  ];
};

export const getUMLSequenceMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: false,
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLSequenceConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
