import { WithChildren } from "@kea-mod/common";
import { EditBPMNActivityModal } from "modals/EditBPMNActivityModal";
import { EditBPMNModal } from "modals/EditBPMNModal";
import { useEffect, useState } from "react";
import { EditBPMNContext } from "./EditBPMNContext";

interface Props extends WithChildren {}

export const EditBPMNModalContextProvider = (props: Props) => {
  const [swimlanenode, setswimlanenode] = useState<any>(undefined);
  const [activitynode, setactivitynode] = useState<any>(undefined);
  const [bpmnswimlanemodalvisible, setbpmnswimlanemodalvisible] = useState<boolean>(false);
  const [bpmnactivitymodalmodalvisible, setbpmnactivitymodalmodalvisible] = useState<boolean>(false);

  const isEditableBySwimlaneModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.BPMNSwimlane":
        return true;
      default:
        return false;
    }
  };

  const isEditableByActivityModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.BPMNActivity":
        return true;
      default:
        return false;
    }
  };

  const toggleSwimlaneModal = () => {
    setbpmnswimlanemodalvisible((prevState) => !prevState);
  };

  const toggleActivityModal = () => {
    setbpmnactivitymodalmodalvisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (swimlanenode) toggleSwimlaneModal();
  }, [swimlanenode]);

  useEffect(() => {
    if (activitynode) toggleActivityModal();
  }, [activitynode]);

  const setNode = (node: joint.dia.Cell) => {
    if (isEditableBySwimlaneModal(node)) setswimlanenode(node);
    if (isEditableByActivityModal(node)) setactivitynode(node);
    if (!isEditableBySwimlaneModal(node)) setswimlanenode(undefined);
    if (!isEditableByActivityModal(node)) setactivitynode(undefined);
    setbpmnswimlanemodalvisible(isEditableBySwimlaneModal(node));
    setbpmnactivitymodalmodalvisible(isEditableByActivityModal(node));
  };

  return (
    <EditBPMNContext.Provider
      value={{
        setNode,
      }}
    >
      {props.children}
      <EditBPMNModal isActive={bpmnswimlanemodalvisible} setisActive={toggleSwimlaneModal} node={swimlanenode} />
      <EditBPMNActivityModal
        isActive={bpmnactivitymodalmodalvisible}
        setisActive={toggleActivityModal}
        node={activitynode}
      />
    </EditBPMNContext.Provider>
  );
};
