import {
  KEACircle,
  KEAElement,
  KEAEllipse,
  KEAGraph,
  KEALink,
  KEAPolygon,
  KEARectangle,
  LinkConfiguration,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const circle = new KEACircle();
circle.position(75, 30);
circle.size(100, 100);

const ellipse = new KEAEllipse();
ellipse.position(250, 30);
ellipse.size(150, 100);

const rectangle = new KEARectangle();
rectangle.position(25, 175);
rectangle.size(200, 100);

const doubleBorderRectangle = new KEARectangle();
doubleBorderRectangle.position(250, 175);
doubleBorderRectangle.size(200, 100);
doubleBorderRectangle.setHasSecondBorder(true);

const polygon = new KEAPolygon();
polygon.position(75, 325);
polygon.size(100, 100);

const doubleBorderPolygon = new KEAPolygon();
doubleBorderPolygon.position(250, 325);
doubleBorderPolygon.size(100, 100);
doubleBorderPolygon.setHasSecondBorder(true);

const link = new KEALink();
link.source({ x: 25, y: 475 });
link.target({ x: 200, y: 475 });

const defaultLink = new KEALink();

export const getERConfiguration = (): Array<KEAElement | KEALink> => {
  return [circle, rectangle, doubleBorderRectangle, ellipse, polygon, doubleBorderPolygon, link];
};

export const getERMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getERConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.7,
    () => {
      return defaultLink;
    },
    conf,
  );
};
