import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEABPMNSwimlane extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.BPMNSwimlane",
      size: {
        width: 400,
        height: 300,
      },
      attrs: {
        "bpmn-swimlane-header": {
          stroke: "#020202",
          strokeWidth: 1,
          fill: "#ededed",
          width: 20,
          height: "calc(h)",
          visibility: "visible",
        },
        "bpmn-swimlane-header-text": {
          x: 0,
          y: 0,
          transform: "rotate(-90, 10, 0) translate(-200,5)",
          fontSize: 14,
          width: "100%",
          fill: "#333333",
          text: "",
          visibility: "visible",
        },
        "bpmn-swimlane-body-0": {
          stroke: "#020202",
          strokeWidth: 1,
          fill: "#fefefe",
          width: "calc(w)",
          height: 150,
        },
        "bpmn-swimlane-header-0": {
          stroke: "#020202",
          strokeWidth: 1,
          fill: "#ededed",
          width: 20,
          height: 150,
          transform: "translate(20, 0)",
          visibility: "visible",
        },
        body: {
          stroke: "none",
          fill: "none",
          width: "calc(w)",
          height: "calc(h)",
          x: "0",
          y: "0",
          pointerEvents: "invisible",
        },
        resize_border_right: {
          width: 10,
          height: "calc(h)",
          x: "calc(w)",
          y: "0",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          width: "calc(w)",
          height: 10,
          x: "0",
          y: "calc(h)",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      hasPoolLabel: [true],
      poolLabels: [""],
      numberOfPoolLanes: 1,
      laneHeight: 150,
      hasHeader: true,
      resizeHeight: true,
      resizeWidth: true,
      rotatable: true,
    });
  }
  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this.on("change:size", this.changeSizeListener, this);
    if (attributes) {
      if (attributes.hasPoolLabel) {
        this.setHasPoolLabel(attributes.hasPoolLabel);
      }
      if (attributes.poolLabels) {
        attributes.poolLabels.forEach((label: string, index: number) => {
          this.setPoolLabel(index, label);
        });
      }
    }
  }

  getEmbeddedElementsOfLane = (laneIndex: number): Array<KEAElement> => {
    const children = this.getEmbeddedCells();
    const elementY = this.attributes.position!.y;
    return children
      .filter((child) => {
        const startLane = laneIndex * this.get("laneHeight") + elementY;
        const endLane = (laneIndex + 1) * this.get("laneHeight") + elementY;
        const y = child.attributes.position.y;
        return y >= startLane && y < endLane;
      })
      .map((child) => child as KEAElement);
  };

  changeSizeListener = () => {
    const numberOfLanes = this.get("numberOfPoolLanes");
    const height = this.attributes!.size!.height / numberOfLanes;
    this.set("laneHeight", height);
    this.updateBodyAttributes();
    this.updateLabelPosition();
  };

  onChangePrimaryColor(element: KEAElement, property: string, _options: any): void {
    let rectAttributes = {};
    const numberOfLanes: number = this.get("numberOfPoolLanes");

    for (let i = 0; i <= numberOfLanes; i++) {
      rectAttributes = Object.assign(rectAttributes, {
        [`bpmn-swimlane-body-${i}`]: {
          stroke: property,
        },
      });
      rectAttributes = Object.assign(rectAttributes, {
        [`bpmn-swimlane-header-${i}`]: {
          stroke: property,
        },
      });
    }
    this.attr(rectAttributes);
    element.attr({
      "bpmn-swimlane-header": {
        stroke: property,
      },
    });
  }

  onChangeSecondaryColor(element: KEAElement, property: string, _options: any): void {
    let rectAttributes = {};
    const numberOfLanes: number = this.get("numberOfPoolLanes");

    for (let i = 0; i <= numberOfLanes; i++) {
      rectAttributes = Object.assign(rectAttributes, {
        [`bpmn-swimlane-body-${i}`]: {
          fill: property,
        },
        [`bpmn-swimlane-header-${i}`]: {
          fill: property,
        },
      });
    }
    this.attr(rectAttributes);
    element.attr({
      "bpmn-swimlane-header": {
        fill: property,
      },
    });
  }

  onChangeLabelColor(_element: KEAElement, _property: string, _options: any): void {
    let rectAttributes = {};
    const numberOfLanes: number = this.get("numberOfPoolLanes");
    for (let i = 0; i < numberOfLanes; i++) {
      rectAttributes = Object.assign(rectAttributes, {
        [`bpmn-swimlane-header-text-${i}`]: {
          fill: this.getLabelColor(),
        },
      });
    }
    this.attr(rectAttributes);
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    const template = util.breakText(property, {
      width: this.attributes!.size!.height,
      height: this.attributes!.size!.width,
    });

    this.attr({
      "bpmn-swimlane-header-text": {
        text: template.split("\n", 1)[0],
      },
    });
  }

  setHasHeader = (value: boolean): KEABPMNSwimlane => {
    this.set("hasHeader", value);
    const cssValueString = value ? "visible" : "hidden";

    this.attr({
      "bpmn-swimlane-header": {
        visibility: cssValueString,
      },
      "bpmn-swimlane-header-text": {
        visibility: cssValueString,
      },
    });

    this.trigger("change:attribute", this);
    return this;
  };

  getHasHeader = (): boolean => {
    return this.get("hasHeader");
  };

  setHasPoolLabel = (value: Array<boolean>): KEABPMNSwimlane => {
    this.set("hasPoolLabel", value);

    value.forEach((item, index) => {
      const value = item ? "visible" : "hidden";
      this.attr({
        [`bpmn-swimlane-header-${index}`]: {
          visibility: value,
        },
        [`bpmn-swimlane-header-text-${index}`]: {
          visibility: value,
        },
      });
    });

    this.trigger("change:attribute", this);
    return this;
  };

  getHasPoolLabel = (): Array<boolean> => {
    return this.get("hasPoolLabel");
  };

  setPoolLabel = (pos: number, value: string) => {
    const labels = this.get("poolLabels");
    labels[pos] = value;
    this.set("poolLabels", labels);

    const template = util.breakText(value, {
      width: 100,
      height: this.attributes!.size!.width,
    });

    this.attr({
      [`bpmn-swimlane-header-text-${pos}`]: {
        text: template.split("\n", 1)[0],
      },
    });

    this.trigger("change:attribute", this);
    return this;
  };

  getPoolLabels = () => {
    return this.get("poolLabels");
  };

  setNumberOfLanes = (value: number): KEABPMNSwimlane => {
    this.numberOfLanes(value);
    return this;
  };

  numberOfLanes = (value: number) => {
    this.set("numberOfPoolLanes", value);
    this.set("minHeight", value * 150);
    if (this.size().height < this.get("minHeight")) {
      this.resize(this.size().width, this.get("minHeight"));
    }
    const hasPoolLabel = [];
    const poolLabels = [];
    for (let i = 0; i++; i < value) {
      hasPoolLabel.push(false);
      poolLabels.push("");
    }
    this.set("hasPoolLabel", hasPoolLabel);
    this.set("poolLabels", poolLabels);
    this.resize(this.attributes!.size!.width, value * this.get("laneHeight"));
    this.updateMarkup();
    this.trigger("change:attribute", this);
    this.trigger("render-markup");
  };

  removeLastLane = (): KEABPMNSwimlane => {
    if (!this.get("numberOfPoolLanes")) return this;
    const numberOfLanes = this.get("numberOfPoolLanes");
    if (numberOfLanes < 2) return this;
    const laneHeight = this.get("laneHeight");
    const children = this.getEmbeddedCells();
    const yEl = this.attributes.position?.y;
    const yMin = (numberOfLanes - 1) * laneHeight + (yEl ? yEl : 0);
    const childrenLastLane = children.filter((child) => {
      return child.attributes.position.y >= yMin;
    });
    childrenLastLane.forEach((child) => {
      const x: number = child.attributes.position.x;
      const y: number = child.attributes.position.y - laneHeight;
      if (child.isElement()) {
        const el = child as KEAElement;
        el.position(x, y);
      }
    });
    this.numberOfLanes(numberOfLanes - 1);
    this.updateMarkup();
    return this;
  };

  getNumberOfLanes = (): number => {
    return this.get("numberOfPoolLanes");
  };

  private updateMarkup = (): void => {
    this.markup = this.getBodyMarkup();
    this.updateBodyAttributes();
    this.updateLabelPosition();
    this.trigger("render-markup");
  };

  private updateLabelPosition = (): void => {
    const numberOfLanes: number = this.get("numberOfPoolLanes");
    this.attr({
      "bpmn-swimlane-header-text": {
        x: 0,
        y: 0,
        transform: `rotate(-90, 10, 0) translate(-${numberOfLanes * this.get("laneHeight") - 20},5)`,
        fontSize: 14,
        width: "100%",
        fill: "#333333",
      },
    });
  };

  private updateBodyAttributes = (): void => {
    const recentAttributes = this.attributes.attrs || {};
    let rectAttributes = {};
    const numberOfLanes: number = this.get("numberOfPoolLanes");
    const hasPoolLabel: Array<boolean> = this.get("hasPoolLabel");
    const poolLabels: Array<string> = this.get("poolLabels");
    if (!hasPoolLabel || !poolLabels) return;
    if (!numberOfLanes) {
      rectAttributes = Object.assign(recentAttributes, {
        "bpmn-swimlane-body-0": {
          stroke: this.getPrimaryColor(),
          strokeWidth: 1,
          fill: this.getSecondaryColor(),
          width: "calc(w)",
          height: this.get("laneHeight"),
          transform: `translate(0, ${this.get("laneHeight")})`,
        },
      });
    }
    for (let i = 0; i < numberOfLanes; i++) {
      rectAttributes = Object.assign(recentAttributes, {
        [`bpmn-swimlane-body-${i}`]: {
          stroke: this.getPrimaryColor(),
          strokeWidth: 1,
          fill: this.getSecondaryColor(),
          width: "calc(w)",
          height: this.get("laneHeight"),
          transform: `translate(0, ${i * this.get("laneHeight")})`,
        },
        [`bpmn-swimlane-header-${i}`]: {
          stroke: this.getPrimaryColor(),
          strokeWidth: 1,
          fill: "white",
          width: 20,
          height: this.get("laneHeight"),
          transform: `translate(20, ${i * this.get("laneHeight")})`,
          visibility: hasPoolLabel[i] ? "visible" : "hidden",
        },
        [`bpmn-swimlane-header-text-${i}`]: {
          x: 0,
          y: 0,
          transform: `rotate(-90, 15, 0) translate(-${(i + 1) * this.get("laneHeight") - 20}, 20)`,
          fontSize: 14,
          width: "100%",
          fill: this.getLabelColor(),
          text: poolLabels[i],
          visibility: hasPoolLabel[i] ? "visible" : "hidden",
        },
      });
    }
    this.attr(rectAttributes);
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    const numberOfLanes: number = this.get("numberOfPoolLanes");
    let rectMarkup = [];
    if (!numberOfLanes) {
      rectMarkup.push({
        tagName: "rect",
        selector: "bpmn-swimlane-body-0",
      });
    }

    const hasPoolLabel = [];
    const poolLabels = [];

    for (let i = 0; i < numberOfLanes; i++) {
      hasPoolLabel.push(false);
      poolLabels.push("");

      rectMarkup.push({
        tagName: "rect",
        selector: `bpmn-swimlane-body-${i}`,
      });
      rectMarkup.push({
        tagName: "rect",
        selector: `bpmn-swimlane-header-${i}`,
      });
      rectMarkup.push({
        tagName: "text",
        selector: `bpmn-swimlane-header-text-${i}`,
      });
    }

    this.set("hasPoolLabel", hasPoolLabel);
    this.set("poolLabels", poolLabels);
    this.updateBodyAttributes();

    let markup = [
      {
        tagName: "g",
        selector: "rotatable",
        children: [
          {
            tagName: "g",
            selector: "scalable",
            children: [
              {
                tagName: "g",
                children: rectMarkup,
              },
              {
                tagName: "rect",
                selector: "bpmn-swimlane-header",
              },
              {
                tagName: "text",
                selector: "bpmn-swimlane-header-text",
              },
            ],
          },
        ],
      },
      {
        tagName: "rect",
        selector: "body",
      },
    ];

    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }

    return markup;
  };

  markup = this.getBodyMarkup();
}
