import { KEALink } from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";

export class TemplateConfiguration {
  graph: any;
  scaleFactor: number;
  linkConfiguration: LinkConfiguration;
  defaultLink: () => KEALink;
  constructor(graph: any, scaleFactor: number, defaultLink: () => KEALink, linkConfiguration: LinkConfiguration) {
    this.graph = graph;
    this.scaleFactor = scaleFactor;
    this.defaultLink = defaultLink;
    this.linkConfiguration = linkConfiguration;
  }
}
