import { util } from "jointjs";
import { getLabelMarkup, KEAElement, LabelPosition } from "./KEAElement";

export class KEAPNMarker extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.PNMarker",
      size: {
        width: 10,
        height: 10,
      },
      attrs: {
        body: {
          refCx: "50%",
          refCy: "50%",
          refR: "50%",
          strokeWidth: 2,
          stroke: "#333333",
          fill: "#333333",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "50%",
          fontSize: 14,
          fill: "#333333",
        },
      },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangeLabelPosition(_element: KEAElement, property: string, _options: any): void {
    const labelPosition: LabelPosition = LabelPosition[property as keyof typeof LabelPosition];
    const markup = getLabelMarkup(labelPosition);
    this.attr({
      label: { ...markup },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "circle",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
