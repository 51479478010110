import { KEACircle, KEAEllipse, KEAGraph, KEALink, keaNamespace, KEAPolygon, KEARectangle } from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";
import { ElementSelectionGrid } from "./_grid";

const grid = new ElementSelectionGrid(30, 30, 30, 30);
grid.addElement(new KEACircle().setResizeHeight(true).setResizeWidth(true), 1, 1);
grid.addRow();
grid.addElement(new KEARectangle().setResizeHeight(true).setResizeWidth(true), 2, 1);
grid.addElement(new KEARectangle().setHasSecondBorder(true).setResizeHeight(true).setResizeWidth(true), 2, 1);
grid.addRow();
grid.addElement(new KEAEllipse().setResizeHeight(true).setResizeWidth(true), 1.5, 1);
grid.addRow();
grid.addElement(new KEAPolygon().setHasSecondBorder(true).setResizeHeight(true).setResizeWidth(true), 1, 1);
grid.addElement(new KEAPolygon().setResizeHeight(true).setResizeWidth(true), 1, 1);
grid.addRow();
grid.addLink(new KEALink(), 3);
grid.scale(60, false);

const defaultLink = new KEALink();

export const getERMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(grid.arrange(true));
  return new TemplateConfiguration(graph.toJSON(), 0.7, () => defaultLink, conf);
};
