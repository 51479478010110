import {
  KEAEPKConnector,
  KEAEPKConnectorType,
  KEAEPKEvent,
  KEAEPKFunction,
  KEAEPKInformationObject,
  KEAEPKOrganizationalUnit,
  KEAElement,
  KEAGraph,
  KEALink,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const function1 = new KEAEPKFunction();
function1.position(10, 10);

const event1 = new KEAEPKEvent();
event1.position(240, 10);

const organizationalUnit = new KEAEPKOrganizationalUnit();
organizationalUnit.position(10, 140);

const informationObject = new KEAEPKInformationObject();
informationObject.position(240, 140);

const connector1 = new KEAEPKConnector();
connector1.position(10, 280);
connector1.setConnectorType(KEAEPKConnectorType.XOR);

const connector2 = new KEAEPKConnector();
connector2.position(70, 280);
connector2.setConnectorType(KEAEPKConnectorType.AND);

const connector3 = new KEAEPKConnector();
connector3.position(130, 280);
connector3.setConnectorType(KEAEPKConnectorType.OR);

const link1 = new KEALink();
link1.setDasharray(LinkType.DASHED);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);
link1.source({
  x: 10,
  y: 375,
});
link1.target({
  x: 200,
  y: 375,
});

const link2 = new KEALink();
link2.setDasharray(LinkType.SOLID);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.NONE);
link2.source({
  x: 10,
  y: 400,
});
link2.target({
  x: 200,
  y: 400,
});

const link3 = new KEALink();
link3.setDasharray(LinkType.SOLID);
link3.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link3.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);
link3.source({
  x: 10,
  y: 425,
});
link3.target({
  x: 200,
  y: 425,
});

const link4 = new KEALink();
link4.setDasharray(LinkType.SOLID);
link4.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.ARROW);
link4.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);
link4.source({
  x: 10,
  y: 450,
});
link4.target({
  x: 200,
  y: 450,
});

let defaultLink = link1.clone();

export const getEPKConfiguration = (): Array<KEAElement | KEALink> => {
  return [
    event1,
    function1,
    organizationalUnit,
    informationObject,
    connector1,
    connector2,
    connector3,
    link1,
    link2,
    link3,
    link4,
  ];
};

export const getEPKMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getEPKConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
