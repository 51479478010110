import { ModelingLanguage } from "@kea-mod/common";
import { KEALink } from "@kea-mod/jointjs";
import { XMLElement } from "xmlbuilder";

//TODO: Area

enum UMLVisibility {
  public = "public",
  private = "private",
  protected = "protected",
  package = "package",
}

type UMLAttribute = {
  visibility: UMLVisibility;
  attributeName: string;
  attributeType: string;
  multiplicity: string;
  defaultValue: string;
  properties: UMLAttributeProperties;
};

type UMLAttributeProperties = {
  isComposite: boolean;
  isDerived: boolean;
  isDerivedUnion: boolean;
  isReadOnly: boolean;
  isStatic: boolean;
  isUnique: boolean;
};

// @ts-ignore
type UMLOperation = {
  visibility: UMLVisibility;
  operationName: string;
  operationParameters: UMLOperationParameter[];
  returnType: string;
  hasProperties: boolean;
  isOrdered: boolean;
  isQuery: boolean;
  isUnique: boolean;
};

// @ts-ignore
type UMLOperationProperties = {
  ordered: boolean;
  readOnly: boolean;
  in: boolean;
  out: boolean;
  inout: boolean;
  redefines: boolean;
};

type UMLOperationParameter = {
  handoverDirection: HandoverDirection;
  parameterName: string;
  type: string;
  multiplicity: string;
  defaultValue: string;
  properties: OperationProperties;
};

enum HandoverDirection {
  IN = "in",
  OUT = "out",
  INOUT = "inout",
  RETURN = "return",
}

interface OperationProperties {
  ordered: boolean;
  readOnly: boolean;
  in: boolean;
  out: boolean;
  inout: boolean;
  redefines: boolean;
}

export type Graph = {
  cells: any[];
};
export class XMIConverter {
  private idRef: Array<{ id: string; name: string }>;
  private root: XMLElement;

  constructor() {
    const builder = require("xmlbuilder");
    this.root = builder
      .create("uml:Model", { encoding: "utf-8" })
      .a("xmi:version", "2.1")
      .a("xmlns:uml", "http://schema.omg.org/spec/UML/2.1")
      .a("xmlns:xmi", "http://schema.omg.org/spec/XMI/2.1")
      .a("xmi:id", "1")
      .a("name", "name")
      .a("visibility", "public");
    this.idRef = [];
  }

  public parseModel = (graph: Graph, modelingLanguage: ModelingLanguage) => {
    const builder = require("xmlbuilder");
    this.root = builder
      .create("uml:Model", { encoding: "utf-8" })
      .a("xmi:version", "2.1")
      .a("xmlns:uml", "http://schema.omg.org/spec/UML/2.1")
      .a("xmlns:xmi", "http://schema.omg.org/spec/XMI/2.1")
      .a("xmi:id", "1")
      .a("name", "name")
      .a("visibility", "public");
    this.idRef = [];

    graph.cells.forEach((node) => {
      if (node.type === "kea.UMLClass" || node.type === "kea.UMLActivity")
        this.idRef.push({ id: node.id, name: node.name[1] });
    });

    this.parseNodes(modelingLanguage, graph.cells, this.root);
    this.parseAssociations(modelingLanguage, graph.cells, this.root);

    return this.root.end({
      pretty: true,
    });
  };

  private parseAssociations = (modelingLanguage: ModelingLanguage, associations: any[], document: XMLElement) => {
    associations.forEach((ass) => {
      if (ass.type === "kea.Link") {
        if (modelingLanguage === ModelingLanguage.UML_CD) this.parseAssociation(ass, document);
      }
    });
  };

  private parseAssociation = (association: any, document: XMLElement) => {
    let link = this.createPackagedElement(association.id, "uml:Association", document, undefined, UMLVisibility.public);

    if (association.source.id !== undefined && association.target.id !== undefined) {
      this.createOwnedEnd(
        "uml:Property",
        association.id,
        association.source.id.concat("-ownedEnd"),
        "undirected",
        link,
      );
      this.createOwnedEnd(
        "uml:Property",
        association.target.id.concat("-ownedEnd"),
        association.target.id,
        "undirected",
        link,
      );
    }

    return link;
  };

  private getControlFlows = (associations: any[], modelingLanguage: ModelingLanguage): KEALink[] => {
    var result: any[] = [];
    if (modelingLanguage === ModelingLanguage.UML_AD) {
      associations.forEach((ass) => {
        if (ass.type === "kea.Link") {
          result.push(ass);
        }
      });
    }
    return result;
  };

  private parseControlFlow = (sourceXML: XMLElement, association: any) => {
    if (association.source.id !== undefined && association.target.id !== undefined) {
      this.createEdge("uml:ControlFlow", association.id, association.source.id, association.target.id, sourceXML);
    }
    return sourceXML;
  };

  private createEdge = (
    xmiType: string,
    xmiId: string,
    source: string,
    target: string,
    document: XMLElement,
  ): XMLElement => {
    return document.e("edge").a("xmi:type", xmiType).a("xmi:id", xmiId).a("source", source).a("target", target);
  };

  private createContainedNode = (xmiId: string, document: XMLElement): XMLElement => {
    return document.e("containedNode").a("xmi:id", xmiId);
  };

  private createOwnedEnd = (
    xmiType: string,
    xmiId: string,
    type: string,
    aggregation: string,
    document: XMLElement,
  ): XMLElement => {
    return document
      .e("ownedEnd")
      .a("xmi:type", xmiType)
      .a("xmi:id", xmiId)
      .a("type", type)
      .a("defaultValue", aggregation);
  };

  private parseNodes = (modelingLanguage: ModelingLanguage, nodes: any[], document: XMLElement) => {
    const controlFlows: any[] = this.getControlFlows(nodes, modelingLanguage);
    nodes.forEach((node) => {
      if (node.type === "kea.UMLClass") {
        this.parseNode(node, document);
      } else {
        this.parseActivityNodes(node, document, controlFlows, nodes);
      }
    });
  };

  private parseActivityNodes = (node: any, document: XMLElement, controlFlows: any[], allNodes: any[]): void => {
    let classNode: XMLElement;
    switch (node.type) {
      case "kea.UMLActivityNode":
        classNode = this.createPackagedElement(node.id, "uml:Activity", document, node.label);
        break;
      case "kea.UMLCircleState":
        const state = node.stateType;
        switch (state) {
          case "INITIAL":
            classNode = this.createPackagedElement(node.id, "uml:InitialNode", document, node.label);
            break;
          case "EXIT":
            classNode = this.createPackagedElement(node.id, "uml:ExitNode", document, node.label);
            break;
          case "FINAL":
            classNode = this.createPackagedElement(node.id, "uml:FinalNode", document, node.label);
            break;
          default:
            classNode = new XMLElement();
        }
        break;
      case "kea.UMLForkAndJoin":
        classNode = this.createPackagedElement(node.id, "uml:ForkNode", document, node.label);
        break;
      case "kea.Polygon":
        classNode = this.createPackagedElement(node.id, "uml:DecisionNode", document, node.label);
        break;
      case "kea.UMLSendSymbol":
        classNode = this.createPackagedElement(node.id, "uml:AnySendEvent", document, node.label);
        break;
      case "kea.UMLReceiveSymbol":
        classNode = this.createPackagedElement(node.id, "uml:AnyReceiveEvent", document, node.label);
        break;
      case "kea.BPMNSwimlane":
        this.parseSwimlane(node, document, allNodes);
        break;
    }
    controlFlows.forEach((cF) => {
      if (node.id === cF.source.id) {
        this.parseControlFlow(classNode, cF);
      }
    });
  };

  private parseSwimlane = (node: any, document: XMLElement, allNodes: any[]): void => {
    const lanes: XMLElement[] = [];
    const numberOfLanes: number = node.numberOfPoolLanes;
    const poolLabels: string[] = node.poolLabels;
    for (var i = 0; i < numberOfLanes; i++) {
      lanes.push(this.createPackagedElement(node.id + i, "uml:ActivityPartition", document, poolLabels[i]));
    }
    const embedCells: string[] = node.embeds as string[];

    if (embedCells !== undefined) {
      allNodes.forEach((element) => {
        if (embedCells.includes(element.id)) {
          const y = element.position.y - node.position.y;
          const laneheight = node.laneHeight;
          const dif = y / laneheight;
          const round = Math.floor(dif);
          switch (round) {
            case 0:
              this.createContainedNode(element.id, lanes[0]);
              break;
            case 1:
              this.createContainedNode(element.id, lanes[1]);
              break;
            case 2:
              this.createContainedNode(element.id, lanes[2]);
              break;
            case 3:
              this.createContainedNode(element.id, lanes[3]);
              break;
            case 4:
              this.createContainedNode(element.id, lanes[4]);
              break;
            case 5:
              this.createContainedNode(element.id, lanes[5]);
              break;
          }
        }
      });
    }
  };

  private parseNode = (node: any, document: XMLElement): XMLElement => {
    let classNode: XMLElement;

    if (node.identifier === "Interface") {
      classNode = this.createPackagedElement(node.id, "uml:Interface", document, node.name, UMLVisibility.public);
    } else {
      classNode = this.createPackagedElement(node.id, "uml:Class", document, node.name, UMLVisibility.public);
    }
    this.parseAttributes(node.attributes, classNode);
    this.parseOperations(node.operations, classNode);
    return classNode;
  };

  private parseOperations = (operations: any[], document: XMLElement) => {
    operations.forEach((op) => this.parseOperation(op, document));
  };

  private parseOperation = (operation: any, document: XMLElement) => {
    this.createOwnedOperation(
      "uml:Operation",
      document,
      operation.operationName,
      operation.visibility,
      operation.returnType,
      operation.operationParameters,
      false,
      false,
      false,
    );
  };

  private parseAttributes = (attributes: any[], document: XMLElement) => {
    attributes.forEach((attr) => this.parseAttribute(attr, document));
  };

  private parseAttribute = (attribute: UMLAttribute, document: XMLElement) => {
    this.createOwnedAttribute(
      "uml:Property",
      document,
      attribute.defaultValue,
      attribute.attributeName,
      attribute.attributeType,
      attribute.visibility,
      attribute.multiplicity,
    );
  };

  createOwnedAttribute = (
    xmiType: string,
    document: XMLElement,
    defaultValue?: string,
    name?: string,
    type?: string,
    visibility?: UMLVisibility,
    multiplicity?: string,
  ): XMLElement => {
    const ownedAttribute = document.e("ownedAttribute").a("xmi:type", xmiType);

    if (defaultValue) {
      ownedAttribute.a("defaultValue", defaultValue);
    }
    if (name) {
      ownedAttribute.a("name", name);
    }
    if (visibility) {
      ownedAttribute.a("visibility", visibility);
    }

    if (multiplicity) {
      if (multiplicity.indexOf("..") !== -1) {
        const s = multiplicity.split("..");
        ownedAttribute.a("lower", s[0]).a("upper", s[1]);
      } else {
        ownedAttribute.a("lower", multiplicity).a("upper", multiplicity);
      }
    }

    if (type) {
      switch (type.toLowerCase()) {
        case "boolean":
          this.createType(
            ownedAttribute,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Boolean",
          );
          break;
        case "integer":
          this.createType(
            ownedAttribute,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Integer",
          );
          break;
        case "real":
          this.createType(
            ownedAttribute,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Real",
          );
          break;
        case "string":
          this.createType(
            ownedAttribute,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#String",
          );
          break;
        default:
          const id = this.idRef.find((ele) => ele.name === type);
          if (id !== undefined) {
            ownedAttribute.a("type", id.id);
          } else {
            this.createPackagedElement(type + "type", "uml:DataType", this.root, type);
            this.idRef.push({ id: type + "type", name: type });
            ownedAttribute.a("type", type + "type");
          }
          break;
      }
    }
    return document;
  };

  private createOwnedOperation = (
    xmiType: string,
    document: XMLElement,
    name?: string,
    visibility?: UMLVisibility,
    returnType?: string,
    ownedParameter?: UMLOperationParameter[],
    isOrdered?: boolean,
    isQuery?: boolean,
    isUnique?: boolean,
  ): XMLElement => {
    const ownedOperation = document.e("ownedOperation").a("xmi:type", xmiType);

    if (name) {
      ownedOperation.a("name", name);
    }
    if (visibility) {
      ownedOperation.a("visibility", visibility);
    }
    if (isOrdered) {
      ownedOperation.a("isOrdered", isOrdered);
    }
    if (isQuery) {
      ownedOperation.a("isQuery", isQuery);
    }
    if (isUnique) {
      ownedOperation.a("isUnique", isUnique);
    }

    if (ownedParameter) {
      for (const p of ownedParameter) {
        this.createOwnedParameter(
          ownedOperation,
          p.parameterName !== "" ? p.parameterName : undefined,
          p.type !== "" ? p.type : undefined,
          p.multiplicity !== "" ? p.multiplicity : undefined,
        );
      }

      if (returnType) {
        this.createOwnedParameter(ownedOperation, undefined, returnType, undefined);
      }
    }
    return ownedOperation;
  };

  private createOwnedParameter = (
    document: XMLElement,
    name?: string,
    type?: string,
    multiplicity?: string,
  ): XMLElement => {
    const ownedParameter = document.e("ownedParameter");

    if (name) {
      ownedParameter.a("name", name);
    }

    if (multiplicity) {
      if (multiplicity.indexOf("..") !== -1) {
        const s = multiplicity.split("..");
        ownedParameter.a("lower", s[0]).a("upper", s[1]);
      } else {
        ownedParameter.a("lower", multiplicity).a("upper", multiplicity);
      }
    }

    if (type) {
      switch (type.toLowerCase()) {
        case "boolean":
          this.createType(
            ownedParameter,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Boolean",
          );
          break;
        case "integer":
          this.createType(
            ownedParameter,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Integer",
          );
          break;
        case "real":
          this.createType(
            ownedParameter,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#Real",
          );
          break;
        case "string":
          this.createType(
            ownedParameter,
            "uml:PrimitiveType",
            "http://www.omg.org/spec/UML/20161101/PrimitiveTypes.xmi#String",
          );
          break;
        default:
          const id = this.idRef.find((ele) => ele.name === type);
          if (id !== undefined) {
            ownedParameter.a("type", id.id);
          } else {
            this.createPackagedElement(type + "type", "uml:DataType", this.root, type);
            this.idRef.push({ id: type + "type", name: type });
            ownedParameter.a("type", type + "type");
          }
          break;
      }
    }

    return ownedParameter;
  };

  private createType = (document: XMLElement, xmiType: string, href: string): XMLElement => {
    return document.e("type").a("xmi:type", xmiType).a("href", href);
  };

  private createPackagedElement = (
    xmiId: string,
    xmiType: string,
    document: XMLElement,
    name?: string,
    visibility?: UMLVisibility,
  ): XMLElement => {
    const packagedElement = document.e("packagedElement").a("xmi:type", xmiType).a("xmi:id", xmiId);

    if (name) {
      packagedElement.a("name", name);
    }
    if (visibility) {
      packagedElement.a("visibility", visibility);
    }
    return packagedElement;
  };
}
