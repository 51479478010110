import { ModelingLanguage } from "@kea-mod/common";
import { KEAGraph, keaNamespace, KEAPaper, KEAPaperBuilder, PaperEventTypes } from "@kea-mod/jointjs";
import { InteractionType, KEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, Component, ContextType, createRef, RefObject } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getLanguageMarkup } from "./misc/GetLanguageMarkup";
import { ResizableCard } from "./ResizbleCard";

interface State {
  selected: ModelingLanguage;
}

interface Props extends WithTranslation {
  modelLanguage: ModelingLanguage;
  setModelLanguage: (language: ModelingLanguage) => void;
  userCanChangeModelingLanguage: boolean;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  setPositionX: (positionX: number) => void;
  setPositionY: (positionY: number) => void;
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
}

const paperOptions: joint.dia.Paper.Options = {
  width: "100%",
  height: "calc(100% - 64px)",
  interactive: false,
  gridSize: 5,
  cellViewNamespace: keaNamespace,
  background: {
    color: "rgb(245, 245, 245, .3)",
  },
};

export class SecondaryGraph extends Component<Props, State> {
  static contextType = KEAGraphContext;
  context!: ContextType<typeof KEAGraphContext>;

  secondaryGraphRef: RefObject<HTMLDivElement>;

  flyGraph: KEAGraph = new KEAGraph({}, { cellNamespace: keaNamespace });
  flyPaper: KEAPaper | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: this.props.modelLanguage,
    };
    this.secondaryGraphRef = createRef();
  }

  /**
   * Set the size of the SVG element to the (client) bounding box of the elements in the SVG + the padding.
   * The padding on the right and bottom side is the same as the padding on the left and top side.
   *
   * @private
   */
  private setSVGSizeToBoundingBox(svg: SVGSVGElement) {
    const topGroup: NodeListOf<SVGGElement> = svg.querySelectorAll("svg > g");
    const bboxes = Array.from(topGroup).map((group) => group.getBoundingClientRect());

    // bboxes.maxOfOrNull { it.width } ?: 0
    const bbwidth = bboxes.reduce((acc, bbox) => Math.max(acc, 2 * bbox.x + bbox.width), 0);
    svg.setAttribute("width", bbwidth.toString());

    // bboxes.maxOfOrNull { it.height } ?: 0
    const bbheight = bboxes.reduce((acc, bbox) => Math.max(acc, 2 * bbox.y + bbox.height), 0);
    svg.setAttribute("height", bbheight.toString());
  }

  componentDidMount = () => {
    //this is a hacky, but works. Joint elements need to be in DOM to import new graphs. Otherwise, the scaling goes wrong. There is no way to guarantee, that the virtualDOM is indeed present in the actual DOM.
    //look at https://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(() => {
      this.context.templatePaper = this.getPaper();
    }, 1000);
    setTimeout(() => {
      const configuration = getLanguageMarkup(this.props.modelLanguage);
      // Set the correct size of the svg containing the modeling elements.
      const modeling_elements_svg = document.querySelector("div#graph_element_container > svg");
      if (modeling_elements_svg !== null) this.setSVGSizeToBoundingBox(modeling_elements_svg as SVGSVGElement);

      this.context.templateGraph.fromJSON(configuration.graph);
      this.context.templatePaper.scale(configuration.scaleFactor, configuration.scaleFactor);
      this.context.modelingPaper.options.defaultLink = configuration.defaultLink;
      this.context.updateLinkConfiguration(configuration.linkConfiguration);
    }, 2000);
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (this.props.modelLanguage !== prevProps.modelLanguage) {
      const configuration = getLanguageMarkup(this.props.modelLanguage);

      this.context.templateGraph.fromJSON(configuration.graph);
      this.context.templatePaper.scale(configuration.scaleFactor, configuration.scaleFactor);
      this.context.modelingPaper.options.defaultLink = configuration.defaultLink;
      this.context.updateLinkConfiguration(configuration.linkConfiguration);
      this.setState({ selected: this.props.modelLanguage });
    }
    if (this.state.selected !== prevState.selected) {
      const configuration = getLanguageMarkup(this.state.selected);

      this.context.templateGraph.fromJSON(configuration.graph);
      this.context.templatePaper.scale(configuration.scaleFactor, configuration.scaleFactor);
      this.context.modelingPaper.options.defaultLink = configuration.defaultLink;
      this.context.updateLinkConfiguration(configuration.linkConfiguration);

      const modeling_elements_svg = document.querySelector("div#graph_element_container > svg");
      if (modeling_elements_svg !== null) this.setSVGSizeToBoundingBox(modeling_elements_svg as SVGSVGElement);
    }
  };

  getPaper = (): KEAPaper => {
    return new KEAPaperBuilder(this.secondaryGraphRef, this.context.templateGraph, paperOptions)
      .historyClb(this.historyClb)
      .interactionClb(this.interactionClb)
      .dropGraph(this.flyGraph)
      .dropPaper(this.context.modelingPaper)
      .event(PaperEventTypes.NODE_DRAG_AND_DROP)
      .build();
  };

  historyClb = () => {
    this.context.pushPresent();
  };

  interactionClb = (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | joint.dia.Link | joint.dia.Cell | joint.dia.Cell[],
  ) => {
    this.context.addUserInteraction(interactionType, interactionTime, payload);
  };

  handleModelingLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.setModelLanguage(event.target.value as ModelingLanguage);
    this.setState({
      selected: event.target.value as ModelingLanguage,
    });
  };

  getDisabledValueForSelectWithoutOptGroup = (language: ModelingLanguage): boolean => {
    return !this.props.userCanChangeModelingLanguage && this.props.modelLanguage !== language;
  };

  getDisabledValueForSelectWitBPMNOptGroup = (): boolean => {
    return (
      !this.props.userCanChangeModelingLanguage &&
      this.props.modelLanguage !== ModelingLanguage.BPMN &&
      this.props.modelLanguage !== ModelingLanguage.BPMN_SWIMLANE &&
      this.props.modelLanguage !== ModelingLanguage.BPMN_EVENT &&
      this.props.modelLanguage !== ModelingLanguage.BPMN_GATEWAY &&
      this.props.modelLanguage !== ModelingLanguage.BPMN_ACTIVITY
    );
  };

  getDisabledValueForSelectWitActivityOptGroup = (): boolean => {
    return (
      !this.props.userCanChangeModelingLanguage &&
      this.props.modelLanguage !== ModelingLanguage.UML_AD &&
      this.props.modelLanguage !== ModelingLanguage.UML_AD_SWIMLANE
    );
  };

  getDisabledValueForSelectWitSequenceOptGroup = (): boolean => {
    return (
      !this.props.userCanChangeModelingLanguage &&
      this.props.modelLanguage !== ModelingLanguage.UML_SEQ &&
      this.props.modelLanguage !== ModelingLanguage.UML_SEQD_FRAME
    );
  };

  render() {
    return (
      <ResizableCard
        positionX={this.props.positionX}
        positionY={this.props.positionY}
        width={this.props.width}
        height={this.props.height}
        minWidth={200}
        minHeight={400}
        visible={true}
        setPositionX={this.props.setPositionX}
        setPositionY={this.props.setPositionY}
        setWidth={this.props.setWidth}
        setHeight={this.props.setHeight}
        header={<></>}
        body={
          <>
            <div className="select m-0 p-0 is-fullwidth">
              <select
                value={this.state.selected}
                onChange={(ev) => {
                  this.handleModelingLanguageChange(ev);
                }}
              >
                <option
                  disabled={this.getDisabledValueForSelectWithoutOptGroup(ModelingLanguage.ER)}
                  value={ModelingLanguage.ER}
                >
                  {this.props.t("header_navbar_item_er")}
                </option>
                <option
                  disabled={this.getDisabledValueForSelectWithoutOptGroup(ModelingLanguage.EPC)}
                  value={ModelingLanguage.EPC}
                >
                  {this.props.t("header_navbar_item_epk")}
                </option>
                <option
                  disabled={this.getDisabledValueForSelectWithoutOptGroup(ModelingLanguage.PN)}
                  value={ModelingLanguage.PN}
                >
                  {this.props.t("header_navbar_item_petri")}
                </option>
                <optgroup label={this.props.t("header_navbar_item_bpmn") as string}>
                  <option disabled={this.getDisabledValueForSelectWitBPMNOptGroup()} value={ModelingLanguage.BPMN}>
                    General
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitBPMNOptGroup()}
                    value={ModelingLanguage.BPMN_SWIMLANE}
                  >
                    Swimlanes
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitBPMNOptGroup()}
                    value={ModelingLanguage.BPMN_EVENT}
                  >
                    Events
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitBPMNOptGroup()}
                    value={ModelingLanguage.BPMN_GATEWAY}
                  >
                    Gateways
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitBPMNOptGroup()}
                    value={ModelingLanguage.BPMN_ACTIVITY}
                  >
                    Activities
                  </option>
                </optgroup>
                <option
                  disabled={this.getDisabledValueForSelectWithoutOptGroup(ModelingLanguage.UML_CD)}
                  value={ModelingLanguage.UML_CD}
                >
                  {this.props.t("header_navbar_item_class")}
                </option>
                <option
                  disabled={this.getDisabledValueForSelectWithoutOptGroup(ModelingLanguage.UML_SD)}
                  value={ModelingLanguage.UML_SD}
                >
                  {this.props.t("header_navbar_item_state")}
                </option>
                <optgroup label={this.props.t("header_navbar_item_ad") as string}>
                  <option
                    disabled={this.getDisabledValueForSelectWitActivityOptGroup()}
                    value={ModelingLanguage.UML_AD}
                  >
                    General
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitActivityOptGroup()}
                    value={ModelingLanguage.UML_AD_SWIMLANE}
                  >
                    Swimlanes
                  </option>
                </optgroup>
                <optgroup label={this.props.t("header_navbar_item_seq") as string}>
                  <option
                    disabled={this.getDisabledValueForSelectWitSequenceOptGroup()}
                    value={ModelingLanguage.UML_SEQ}
                  >
                    General
                  </option>
                  <option
                    disabled={this.getDisabledValueForSelectWitSequenceOptGroup()}
                    value={ModelingLanguage.UML_SEQD_FRAME}
                  >
                    Frames
                  </option>
                </optgroup>
              </select>
            </div>
            <div id={"graph_element_container"} style={{ overflow: "auto" }} ref={this.secondaryGraphRef}></div>
          </>
        }
      />
    );
  }
}

export default withTranslation()(SecondaryGraph);
