import { createContext, useContext } from "react";
import { FeedbackMessage } from "tasks/sample_feedback";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("FeedbackContext");
};

export interface IFeedbackContext {
  feedback: { [id: string]: Array<FeedbackMessage> };
  unresolvedFeedback: Array<FeedbackMessage>;
  positionFeedback: Array<FeedbackMessage>;
  getFeedbackForNode: (node: joint.dia.Cell) => Array<FeedbackMessage>;
  setFeedback: (feedback: { [id: string]: Array<FeedbackMessage> }) => void;
  setUnresolvedFeedback: (feedback: Array<FeedbackMessage>) => void;
  setPositionFeedback: (feedback: Array<FeedbackMessage>) => void;
}

const defaultState: IFeedbackContext = {
  feedback: {},
  unresolvedFeedback: [],
  positionFeedback: [],
  getFeedbackForNode: () => {
    printOutsideContext();
    return [];
  },
  setFeedback: () => printOutsideContext(),
  setUnresolvedFeedback: () => printOutsideContext(),
  setPositionFeedback: () => printOutsideContext(),
};

export const FeedbackContext = createContext<IFeedbackContext>(defaultState);
export const useFeedbackContext = () => useContext(FeedbackContext);
