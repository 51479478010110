import { WithChildren } from "@kea-mod/common";
import { KEAUMLComplexState } from "@kea-mod/jointjs";
import { EditUMLClassModal } from "modals/EditUMLClassModal";
import { EditUMLComplexStateModal } from "modals/EditUMLComplexStateModal";
import { Component } from "react";
import { EditUMLContext } from "./EditUMLContext";

interface State {
  node: any;
  changeLabelModalVisible: boolean;
  stateNode: KEAUMLComplexState | undefined;
  stateModalVisible: boolean;
}

interface Props extends WithChildren {}

export class EditUMLModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      node: undefined,
      changeLabelModalVisible: false,
      stateNode: undefined,
      stateModalVisible: false,
    };
  }

  isEditableByUMLClassModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.UMLClass":
        return true;
      default:
        return false;
    }
  };

  isEditableByUMLStateModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.UMLComplexState":
        return true;
      default:
        return false;
    }
  };

  toggleEditLabelModal = () => {
    this.setState((prevState) => ({
      changeLabelModalVisible: !prevState.changeLabelModalVisible,
    }));
  };

  toggleStateModal = () => {
    this.setState((prevState) => ({
      stateModalVisible: !prevState.stateModalVisible,
    }));
  };

  setNode = (node: any) => {
    if (this.isEditableByUMLClassModal(node)) {
      this.setState(
        {
          node,
        },
        () => {
          this.setState((prevState) => ({
            changeLabelModalVisible: !prevState.changeLabelModalVisible,
          }));
        },
      );
    }
    if (this.isEditableByUMLStateModal(node)) {
      this.setState(
        {
          stateNode: node,
        },
        () => {
          this.setState((prevState) => ({
            stateModalVisible: !prevState.stateModalVisible,
          }));
        },
      );
    }
  };

  render() {
    return (
      <EditUMLContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}
        <EditUMLClassModal
          isActive={this.state.changeLabelModalVisible}
          setisActive={this.toggleEditLabelModal}
          node={this.state.node}
        />
        <EditUMLComplexStateModal
          isActive={this.state.stateModalVisible}
          setisActive={this.toggleStateModal}
          node={this.state.stateNode}
        />
      </EditUMLContext.Provider>
    );
  }
}
