import { dia } from "jointjs";

export class KEABPMNSwimlaneView extends dia.ElementView {
  constructor(opt?: dia.CellView.Options<dia.Element>) {
    super(opt);
    this.listenTo(this.model, "render-markup", () => {
      this.render();
    });
  }
}
