import { ModelingLanguage } from "@kea-mod/common";
import { KEAGraph } from "@kea-mod/jointjs";
import { Interaction } from "context/KEAGraphContext";
import { KEAGraphExport } from "types/KEAGraphExport";

export type ApiMethod = "POST" | "GET" | "PUT";

export const createDefaultHeaderWithToken = (token: string): Array<APIHeader> => {
  return createDefaultHeader().concat([createAccessTokenHeader(token)]);
};

export const createDefaultHeader = (): Array<APIHeader> => {
  return [ContentTypeHeader, AccessTypeHeader];
};

export const createAccessTokenHeader = (accessToken: string): APIHeader => {
  return {
    key: "x-access-token",
    value: accessToken,
  };
};

export const createAccessControlAllowCredentialsHeader = (): APIHeader => {
  return {
    key: "Access-Control-Allow-Credentials",
    value: "true",
  };
};

export type APIHeader = {
  key: string;
  value: string;
};

export const ContentTypeHeader: APIHeader = {
  key: "Content-Type",
  value: "application/json",
};

export const AccessTypeHeader: APIHeader = {
  key: "Accept",
  value: "application/json",
};

export const CacheControlTypeHeader: APIHeader = {
  key: "Cache-Control",
  value: "no-store,no-cache,must-revalidate",
};

export type ApiRequestLogin = {
  token: string;
};

export type ApiBodySendModel = {
  graphData: KEAGraphExport;
  interactionData: Interaction[];
  type: string;
  parentModel?: string;
  parentId?: number;
  name: string;
};

export type ApiBodyUpdateModel = {
  graphData: KEAGraphExport;
  interactionData: Interaction[];
  name: string;
};

export type ApiResponseLogin = {
  id: string;
  token: string;
  roles: string[];
  accessToken: string;
  createdAt: Date;
  expiresIn: number;
};

export type ApiRequestModelsPost = {
  exerciseId: number;
  type: string;
  startDate: string;
  endDate: string;
  modelId: number;
};

export type ApiResponseModelsPost = {
  success: boolean;
  timestamp: Date;
  statusCode: number;
  statusMsg: string;
  msg: string;
  data: {
    message: string;
    id: number;
  };
};

export type ApiRequestExercise = {};

// TODO add other properties
export type ApiResponseExercise = {
  success: boolean;
  timestamp: Date;
  statusCode: number;
  statusMsg: string;
  data: {
    creation: {
      model: {
        graphData: KEAGraph;
      };
      scenario: string;
    };
    language: {
      name: ModelingLanguage;
    };
    id: number;
    name: string;
    type: string;
    description: string;
  };
};

export type GraderFeedback = {
  id: number;
  content: string;
  category: string;
  positionId: string;
  positionX: number;
  positionY: number;
  valuation: string;
  gradingId: number;
  createdAt: string;
  updatedAt: string;
};

export type GraderResult = {
  id: number;
  result: string;
  resultType: string;
  logMessage: string;
  jobSuccessful: boolean;
  executionTimeMillis: number;
  assessmentId: number;
  gradingserviceId: number;
  schematreeId: number;
  createdAt: string;
  updatedAt: string;
  feedbacks: Array<GraderFeedback>;
};

export type APIResponseSubmissionExerciseAssessment = {
  success: boolean;
  statusCode: number;
  statusMsg: string;
  timestamp: string;
  data: {
    id: number;
    totalscore: number;
    maxTotalScore: string;
    comment: string | null;
    autoAssessment: boolean;
    finalResult: boolean;
    status: string;
    submissionExerciseId: number;
    schemaId: number;
    createdAt: string;
    updatedAt: string;
    gradings: Array<GraderResult>;
    feedbacksmanual: Array<GraderFeedback>;
  };
};
