import { ModelingLanguage } from "@kea-mod/common";
import { getActivityMarkup } from "tasks/bpmn_activity";
import { getEventMarkup } from "tasks/bpmn_event";
import { getGatewayMarkup } from "tasks/bpmn_gateway";
import { getBPMNGeneralMarkup } from "tasks/bpmn_general";
import { getSwimlaneMarkup } from "tasks/bpmn_swimlane";
import { getEmptyMarkup } from "tasks/empty";
import { getEPKMarkup } from "tasks/epk";
import { getERMarkup } from "tasks/er";
import { getPetriNetMarkup } from "tasks/petri_net";
import { TemplateConfiguration } from "tasks/TemplateConfiguration";
import { getUMLActivityMarkup } from "tasks/uml_activity";
import { getUMLSwimlaneMarkup } from "tasks/uml_activity_swimlanes";
import { getUMLClassMarkup } from "tasks/uml_class";
import { getUMLSequenceMarkup } from "tasks/uml_sequence";
import { getUMLSequenceFrameMarkup } from "tasks/uml_sequence_frames";
import { getUMLStateMarkup } from "tasks/uml_state";

const languageMarkupMap = {
  [ModelingLanguage.UML_CD]: getUMLClassMarkup,
  [ModelingLanguage.UML_COMPD]: getEmptyMarkup,
  [ModelingLanguage.UML_SD]: getUMLStateMarkup,
  [ModelingLanguage.UML_OD]: getEmptyMarkup,
  [ModelingLanguage.UML_SEQ]: getUMLSequenceMarkup,
  [ModelingLanguage.UML_SEQD_FRAME]: getUMLSequenceFrameMarkup,
  [ModelingLanguage.UML_AD]: getUMLActivityMarkup,
  [ModelingLanguage.UML_AD_SWIMLANE]: getUMLSwimlaneMarkup,
  [ModelingLanguage.EMPTY]: getEmptyMarkup,
  [ModelingLanguage.ER]: getERMarkup,
  [ModelingLanguage.EPC]: getEPKMarkup,
  [ModelingLanguage.PN]: getPetriNetMarkup,
  [ModelingLanguage.BPMN]: getBPMNGeneralMarkup,
  [ModelingLanguage.BPMN_EVENT]: getEventMarkup,
  [ModelingLanguage.BPMN_GATEWAY]: getGatewayMarkup,
  [ModelingLanguage.BPMN_SWIMLANE]: getSwimlaneMarkup,
  [ModelingLanguage.BPMN_ACTIVITY]: getActivityMarkup,
  [ModelingLanguage.ANY]: getEmptyMarkup,
  [ModelingLanguage.NONE]: getEmptyMarkup,
};

export const getLanguageMarkup = (language: ModelingLanguage): TemplateConfiguration => languageMarkupMap[language]();
