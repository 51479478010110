import { ChangeEvent } from "react";

import { KEALinkLabel, LinkLabelPosition } from "@kea-mod/jointjs";
import { useKEAGraphContext } from "context/KEAGraphContext";

interface Props {
  label?: KEALinkLabel;
  position: LinkLabelPosition;
  text: string;
  handleLinkLabelChange: (index: LinkLabelPosition, event: ChangeEvent<HTMLInputElement>) => void;
  addLinkLabel: (pos: LinkLabelPosition) => void;
  removeLinkLabel: (pos: LinkLabelPosition) => void;
}

export const LinkLabelComponent = ({
  label,
  position,
  text,
  handleLinkLabelChange,
  addLinkLabel,
  removeLinkLabel,
}: Props) => {
  const keaGraphContext = useKEAGraphContext();

  if (label) {
    return (
      <div className="columns">
        <div className="column">
          <div className="control">
            <input
              className="input"
              type="text"
              value={label.value}
              onChange={(e) => {
                handleLinkLabelChange(label.position, e);
              }}
            />
          </div>
        </div>
        <div className="column">
          <button
            type="button"
            disabled={!keaGraphContext.linkConfiguration.addLabelEnabled}
            className="button is-danger"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              removeLinkLabel(position);
            }}
          >
            -
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="columns">
      <div className="column">
        <button
          type="button"
          disabled={!keaGraphContext.linkConfiguration.addLabelEnabled}
          className="button is-fullscreen"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            addLinkLabel(position);
          }}
        >
          {text}
        </button>
      </div>
    </div>
  );
};
