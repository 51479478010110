import { ModelingLanguage } from "@kea-mod/common";
import { KEAUMLLifeLine } from "@kea-mod/jointjs";
import { useEditBPMNContext } from "context/EditBPMNContext";
import { useEditKEALinkContext } from "context/EditKEALinkContext";
import { useEditLabelContext } from "context/EditLabelContext";
import { useEditPositionLabelContext } from "context/EditPositionLabelModalContext";
import { useEditUMLContext } from "context/EditUMLContext";
import { useEditUMLLifeLineContext } from "context/EditUMLLifeLineContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useModelingLanguageContext } from "context/ModelLanguageContext";
import { useUserSettingsContext } from "context/UserSettingsContext";
import { dimension, useWindowSizeContext, windowPadding } from "context/WindowSizeContext";
import { useEffect, useState } from "react";
import { useCallbackRef } from "shared/misc/UseCallbackRef";
import { ContentContainer } from "../../shared/ContentContainer";
import { ContentView } from "./content/ContentView";
import { FooterContainer } from "./footer/FooterContainer";

interface Props {}

export const AppEditor = (_props: Props) => {
  const labelContext = useEditLabelContext();
  const positonLabelContext = useEditPositionLabelContext();
  const umlContext = useEditUMLContext();
  const bpmnContext = useEditBPMNContext();
  const lifeLineContext = useEditUMLLifeLineContext();
  const windowSizeContext = useWindowSizeContext();
  const modelingLanguageContext = useModelingLanguageContext();
  const keaGraphContext = useKEAGraphContext();
  const kealinkContext = useEditKEALinkContext();
  const userSettingsContext = useUserSettingsContext();

  useEffect(() => {
    modelingLanguageContext.setModelingLanguage(ModelingLanguage.ER);
    modelingLanguageContext.setUserCanChangeModelingLanguage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [footerHeight, setFooterRef] = useCallbackRef((node: any) => node?.clientHeight || 0);

  const [contentDimension, setContentDimension] = useState<dimension>({
    width: 1,
    height: 1,
  });

  useEffect(() => {
    setContentDimension({
      width: windowSizeContext.dimension.width,
      height: windowSizeContext.dimension.height - (footerHeight as unknown as number) - windowPadding,
    });
  }, [footerHeight, windowSizeContext.dimension.height, windowSizeContext.dimension.width]);

  const setNodeWrapper = (node: joint.dia.Element) => {
    umlContext.setNode(node);
    bpmnContext.setNode(node);
    lifeLineContext.setNode(node as KEAUMLLifeLine);
    labelContext.setNode(node);
    kealinkContext.setNode(node);
    positonLabelContext.setNode(node);
  };
  return (
    <>
      <ContentContainer width={contentDimension.width} height={contentDimension.height}>
        <ContentView
          width={contentDimension.width}
          height={contentDimension.height}
          setNode={setNodeWrapper}
          modelLanguage={modelingLanguageContext.modelingLanguage}
          setModelLanguage={modelingLanguageContext.setModelingLanguage}
          userCanChangeModelingLanguage={modelingLanguageContext.userCanChangeModelingLanguage}
          pushPresent={keaGraphContext.pushPresent}
          addUserInteraction={keaGraphContext.addUserInteraction}
          userSettingsContext={userSettingsContext}
          grid={userSettingsContext.grid}
          gridSize={userSettingsContext.gridSize}
          gridBackground={userSettingsContext.gridBackground}
        />
      </ContentContainer>
      <FooterContainer ref={setFooterRef} />
    </>
  );
};
