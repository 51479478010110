import {
  KEAGraph,
  KEALink,
  KEAPolygon,
  KEARectangle,
  KEAUMLCircleState,
  KEAUMLComplexState,
  KEAUMLReceiveSymbol,
  KEAUMLSendSymbol,
  KEAUMLSimpleState,
  LinkConfiguration,
  LinkLabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
  StateType,
  keaNamespace,
} from "@kea-mod/jointjs";
import { KEAElement, LabelPosition } from "@kea-mod/jointjs/components/KEAElement";

import { TemplateConfiguration } from "./TemplateConfiguration";

const initialUMLCircleState = new KEAUMLCircleState();
initialUMLCircleState.position(10, 200);
initialUMLCircleState.setStateType(StateType.INITIAL);

const exitUMLCircleState = new KEAUMLCircleState();
exitUMLCircleState.position(50, 200);
exitUMLCircleState.setStateType(StateType.EXIT);

const finalUMLCircleState = new KEAUMLCircleState();
finalUMLCircleState.position(90, 200);
finalUMLCircleState.setStateType(StateType.FINAL);

const umlSimpleState = new KEAUMLSimpleState();
umlSimpleState.position(10, 10);

const umlComplexState = new KEAUMLComplexState();
umlComplexState.position(10, 80);

const umlChoice = new KEAPolygon();
umlChoice.position(140, 200);
umlChoice.setLabelPosition(LabelPosition.BELOW);
umlChoice.size(30, 30);

const umlParallel = new KEARectangle();
umlParallel.size(10, 100);
umlParallel.position(230, 80);

const send = new KEAUMLSendSymbol();
send.position(10, 300);

const receive = new KEAUMLReceiveSymbol();
receive.position(160, 300);

const link = new KEALink();
link.source({ x: 10, y: 250 });
link.target({ x: 200, y: 250 });
link.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
link.setDasharray(LinkType.SOLID);
link.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link.clone();

export const getUMLStateConfiguration = (): Array<KEAElement | KEALink> => {
  return [
    initialUMLCircleState,
    exitUMLCircleState,
    finalUMLCircleState,
    umlSimpleState,
    umlComplexState,
    umlChoice,
    umlParallel,
    receive,
    send,
    link,
  ];
};

export const getUMLStateMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLStateConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
