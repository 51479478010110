import { util } from "jointjs";

import { KEAElement } from "./KEAElement";

export enum DatastoreType {
  DATA_OBJECT = "DATA_OBJECT",
  DATA_INPUT = "DATA_INPUT",
  DATA_OUTPUT = "DATA_OUTPUT",
}

export class BPMNDatastore extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.BPMNDatastore",
      size: {
        width: 50,
        height: 75,
      },
      attrs: {
        page: {
          stroke: "#000000",
          strokeWidth: 2,
          strokeLinejoin: "round",
          fill: "#FFFFFF",
          d: "M 0 0 L 40 0 L 50 10 L 50 75 L 0 75 Z",
        },
        crinkle: {
          stroke: "#000000",
          strokeWidth: 2,
          strokeLinejoin: "round",
          fill: "#FFFFFF",
          d: "M 40 1 L 40 10 L 49 10 Z",
        },
        icon: {
          refX: "10%",
          refY: "10%",
          strokeWidth: 2,
          d: "",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "120%",
          fontSize: 14,
          fill: "#333333",
        },
      },
      strokeLineJoin: "round",
      datastoreType: DatastoreType.DATA_INPUT,
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      page: { fill: property },
      crinkle: { fill: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      page: { stroke: property },
      crinkle: { stroke: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      page: { strokeWidth: property },
      crinkle: { strokeWidth: property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { fill: property },
    });
  }

  setDatastoreType = (type: DatastoreType): BPMNDatastore => {
    this.set("datastoreType", type);
    this.attr(BPMNDatastore.getDatastoreMarkup(type));
    this.trigger("change:attribute", this);
    return this;
  };

  getDatastoreType = (): DatastoreType => {
    return this.get("datastoreType");
  };

  setStrokeLinejoin = (join: string): BPMNDatastore => {
    this.set("strokeLinejoin", join);
    this.attr({
      page: { strokeLinejoin: join },
      crinkle: { strokeLinejoin: join },
    });
    this.trigger("change:attribute", this);
    return this;
  };

  static getDatastoreMarkup = (datastoreType: DatastoreType) => {
    switch (datastoreType) {
      case DatastoreType.DATA_OBJECT:
        return {
          icon: {
            fill: "none",
            d: "",
          },
        };

      case DatastoreType.DATA_INPUT:
        return {
          icon: {
            fill: "none",
            stroke: "black",
            d: "M 0 3 L 13 3 L 13 0 L 20 5 L 13 10 L 13 7 L 0 7 Z",
          },
        };
      case DatastoreType.DATA_OUTPUT:
      default:
        return {
          icon: {
            fill: "black",
            stroke: "black",
            d: "M 0 3 L 13 3 L 13 0 L 20 5 L 13 10 L 13 7 L 0 7 Z",
          },
        };
    }
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "path",
        selector: "page",
      },
      {
        tagName: "path",
        selector: "crinkle",
      },
      {
        tagName: "path",
        selector: "icon",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
