import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("TaskContext");
};

export interface ITaskContext {
  markdown: string;
  setMarkdown: (value: string) => void;
  showTask: boolean;
  setShowTask: (value: boolean) => void;
  description: string;
  setDescription: (value: string) => void;
}

const defaultState: ITaskContext = {
  markdown: "",
  setMarkdown: () => printOutsideContext(),
  showTask: false,
  setShowTask: () => printOutsideContext(),
  description: "",
  setDescription: () => printOutsideContext(),
};

export const TaskContext = createContext<ITaskContext>(defaultState);
export const useTaskContext = () => useContext(TaskContext);
