import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLSimpleState extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLSimpleState",
      size: {
        width: 200,
        height: 50,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          rx: 10,
          ry: 10,
          fill: "#ecf0f1",
          stroke: "#bdc3c7",
          strokeWidth: 3,
          fillOpacity: 0.6,
        },
        label: {
          ref: "body",
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "50%",
          fontSize: 14,
          text: "",
          fill: "#333333",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      minHeight: 50,
      minWidth: 150,
      resizeHeight: true,
      resizeWidth: true,
    });
  }

  onChangePrimaryColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      body: { fill: _property },
    });
  }

  onChangeSecondaryColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      body: { stroke: _property },
    });
  }

  onChangeLabelColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      label: { fill: _property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
