import * as joint from "jointjs";
import { KEALink } from "../KEALink";

export const switchMarkerEndsTool = new joint.linkTools.Button({
  markup: [
    {
      tagName: "circle",
      selector: "button",
      attributes: {
        r: 7,
        fill: "#35495d",
        cursor: "pointer",
      },
    },
    {
      tagName: "path",
      selector: "icon",
      attributes: {
        d: "M -5 0 L 5 0 L 3 -2 M 5 0 L 3 2 M -5 0 L -3 -2 M -5 0 L -3 2 Z",
        fill: "none",
        stroke: "#FFFFFF",
        "stroke-width": 2,
        "pointer-events": "none",
      },
    },
  ],
  distance: -80,
  offset: 10,
  action: function () {
    if (this.model) {
      const link = this.model as unknown as KEALink;
      const graph = link.graph;
      const source = graph.getCell(link.get("source").id);
      const target = graph.getCell(link.get("target").id);
      link.set("target", {
        id: source.id,
      });
      link.set("source", {
        id: target.id,
      });
    }
  },
});
