import { WithChildren } from "@kea-mod/common";
import { useState } from "react";
import { TaskContext } from "./TaskContext";

interface Props extends WithChildren {}

export const TaskContextProvider = (props: Props) => {
  const [markdown, setMarkdown] = useState<string>("bG9hZGluZy4u");
  const [description, setDescription] = useState<string>("bG9hZGluZy4u");
  const [showTask, setShowTask] = useState<boolean>(false);

  return (
    <TaskContext.Provider
      value={{
        markdown: markdown,
        setMarkdown: setMarkdown,
        showTask: showTask,
        setShowTask: setShowTask,
        description: description,
        setDescription: setDescription,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};
