export const LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X_DEFAULT = 20;
const LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X = "secondary_graph.position.x";

export const LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT = 20;
const LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y = "secondary_graph.position.y";

export const LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT = 300;
const LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH = "secondary_graph.width";

export const LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT = 450;
const LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT = "secondary_graph.height";

export const LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT = 340;
const LOCAL_STORAGE_TASK_CARD_POSITION_X = "task_card.position.x";

export const LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT = 20;
const LOCAL_STORAGE_TASK_CARD_POSITION_Y = "task_card.position.y";

export const LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT = 400;
const LOCAL_STORAGE_TASK_CARD_WIDTH = "task_card.width";

export const LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT = 200;
const LOCAL_STORAGE_TASK_CARD_HEIGHT = "task_card.height";

export const LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT = 20;
const LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X = "task_card.position.x";

export const LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y_DEFAULT = 20;
const LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y = "task_card.position.y";

export const LOCAL_STORAGE_FEEDBACK_CARD_WIDTH_DEFAULT = 200;
const LOCAL_STORAGE_FEEDBACK_CARD_WIDTH = "task_card.width";

export const LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT_DEFAULT = 400;
const LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT = "task_card.height";

export const LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT = "#333333";
const LOCAL_STORAGE_PRIMARY_COLOR = "primaryColor";

export const LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT = "#ffffff";
const LOCAL_STORAGE_SECONDARY_COLOR = "secondaryColor";

export const LOCAL_STORAGE_LABEL_COLOR_DEFAULT = "#333333";
const LOCAL_STORAGE_LABEL_COLOR = "labelColor";

export const LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT = 5;
const LOCAL_STORAGE_PAPER_GRID_SIZE = "gridSize";

export const LOCAL_STORAGE_PAPER_GRID_DEFAULT = {
  name: "doubleMesh",
  args: [
    {
      color: "grey",
      thickness: 0.5,
    },
    {
      color: "#131313",
      scaleFactor: 16,
      thickness: 2,
    },
  ],
};

export const LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT = "#EDF2FA";
const LOCAL_STORAGE_PAPER_GRID_BACKGROUND = "gridBackground";

const LOCAL_STORAGE_PAPER_GRID = "grid";

export const LOCAL_STORAGE_THESIS_ACCEPTED_TERMS_DEFAULT = false;
const LOCAL_STORAGE_THESIS_ACCEPT_TERMS = "thesis_accepted_terms";

export const LOCAL_STORAGE_THESIS_CONFIRM_ACCEPTED_TERMS_DEFAULT = false;
const LOCAL_STORAGE_THESIS_CONFIRM_ACCEPT_TERMS = "thesis_confirm_accepted_terms";

export class UserLocalStorage {
  constructor() {
    if (!instance) {
      instance = this;
    }
    this.checkLocalStorage();
    return instance;
  }

  checkLocalStorage = () => {
    if (localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X,
        LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X_DEFAULT.toString(),
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y,
        LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT.toString(),
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH) === null) {
      localStorage.setItem(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH, LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT,
        LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT.toString(),
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_TASK_CARD_POSITION_X) === null) {
      localStorage.setItem(LOCAL_STORAGE_TASK_CARD_POSITION_X, LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_TASK_CARD_POSITION_Y) === null) {
      localStorage.setItem(LOCAL_STORAGE_TASK_CARD_POSITION_Y, LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_TASK_CARD_WIDTH) === null) {
      localStorage.setItem(LOCAL_STORAGE_TASK_CARD_WIDTH, LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_TASK_CARD_HEIGHT) === null) {
      localStorage.setItem(LOCAL_STORAGE_TASK_CARD_HEIGHT, LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X,
        LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT.toString(),
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y,
        LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y_DEFAULT.toString(),
      );
    }
    if (localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_WIDTH) === null) {
      localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_WIDTH, LOCAL_STORAGE_FEEDBACK_CARD_WIDTH_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT) === null) {
      localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT, LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR) === null) {
      localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT);
    }
    if (localStorage.getItem(LOCAL_STORAGE_SECONDARY_COLOR) === null) {
      localStorage.setItem(LOCAL_STORAGE_SECONDARY_COLOR, LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT);
    }
    if (localStorage.getItem(LOCAL_STORAGE_LABEL_COLOR) === null) {
      localStorage.setItem(LOCAL_STORAGE_LABEL_COLOR, LOCAL_STORAGE_LABEL_COLOR_DEFAULT);
    }
    if (localStorage.getItem(LOCAL_STORAGE_PAPER_GRID_BACKGROUND) === null) {
      localStorage.setItem(LOCAL_STORAGE_PAPER_GRID_BACKGROUND, LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT);
    }
    if (localStorage.getItem(LOCAL_STORAGE_PAPER_GRID_SIZE) === null) {
      localStorage.setItem(LOCAL_STORAGE_PAPER_GRID_SIZE, LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_PAPER_GRID) === null) {
      localStorage.setItem(LOCAL_STORAGE_PAPER_GRID, JSON.stringify(LOCAL_STORAGE_PAPER_GRID_DEFAULT));
    }
    if (localStorage.getItem(LOCAL_STORAGE_THESIS_ACCEPT_TERMS) === null) {
      localStorage.setItem(LOCAL_STORAGE_THESIS_ACCEPT_TERMS, LOCAL_STORAGE_THESIS_ACCEPTED_TERMS_DEFAULT.toString());
    }
    if (localStorage.getItem(LOCAL_STORAGE_THESIS_CONFIRM_ACCEPT_TERMS) === null) {
      localStorage.setItem(
        LOCAL_STORAGE_THESIS_CONFIRM_ACCEPT_TERMS,
        LOCAL_STORAGE_THESIS_CONFIRM_ACCEPTED_TERMS_DEFAULT.toString(),
      );
    }
  };

  getGrid = (): any => {
    const value = localStorage.getItem(LOCAL_STORAGE_PAPER_GRID);
    if (value === null) return LOCAL_STORAGE_PAPER_GRID_DEFAULT;
    return JSON.parse(value);
  };

  setGrid = (value: any) => {
    localStorage.setItem(LOCAL_STORAGE_PAPER_GRID, JSON.stringify(value));
  };

  getGridBackground = (): string => {
    const value = localStorage.getItem(LOCAL_STORAGE_PAPER_GRID_BACKGROUND);
    if (value === null) return LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT;
    return value;
  };

  setGridBackground = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_PAPER_GRID_BACKGROUND, value.toString());
  };

  getGridSize = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_PAPER_GRID_SIZE);
    if (value === null) return LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT;
    return parseInt(value);
  };

  setGridSize = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_PAPER_GRID_SIZE, value.toString());
  };

  getSecondaryGraphPositionX = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X);
    if (value === null) return LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X_DEFAULT;
    return parseInt(value);
  };

  setSecondaryGraphPositionX = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_X, value.toString());
  };

  getSecondaryGraphPositionY = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y);
    if (value === null) return LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT;
    return parseInt(value);
  };

  setSecondaryGraphPositionY = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y, value.toString());
  };

  getSecondaryGraphWidth = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH);
    if (value === null) return LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT;
    return parseInt(value);
  };

  setSecondaryGraphWidth = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH, value.toString());
  };

  getSecondaryGraphHeight = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT);
    if (value === null) return LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT;
    return parseInt(value);
  };

  setSecondaryGraphHeight = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT, value.toString());
  };

  getTaskCardPositionX = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_TASK_CARD_POSITION_X);
    if (value === null) return LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT;
    return parseInt(value);
  };

  setTaskCardPositionX = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_TASK_CARD_POSITION_X, value.toString());
  };

  getTaskCardPositionY = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_TASK_CARD_POSITION_Y);
    if (value === null) return LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT;
    return parseInt(value);
  };

  setTaskCardPositionY = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_TASK_CARD_POSITION_Y, value.toString());
  };

  getTaskCardWidth = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_TASK_CARD_WIDTH);
    if (value === null) return LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT;
    return parseInt(value);
  };

  setTaskCardWidth = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_TASK_CARD_WIDTH, value.toString());
  };

  getTaskCardHeight = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_TASK_CARD_HEIGHT);
    if (value === null) return LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT;
    return parseInt(value);
  };

  setTaskCardhHeight = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_TASK_CARD_HEIGHT, value.toString());
  };

  getFeedbackCardPositionX = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X);
    if (value === null) return LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT;
    return parseInt(value);
  };

  setFeedbackCardPositionX = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X, value.toString());
  };

  getFeedbackCardPositionY = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y);
    if (value === null) return LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y_DEFAULT;
    return parseInt(value);
  };

  setFeedbackCardPositionY = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y, value.toString());
  };

  getFeedbackCardWidth = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_WIDTH);
    if (value === null) return LOCAL_STORAGE_FEEDBACK_CARD_WIDTH_DEFAULT;
    return parseInt(value);
  };

  setFeedbackCardWidth = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_WIDTH, value.toString());
  };

  getFeedbackCardHeight = (): number => {
    const value = localStorage.getItem(LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT);
    if (value === null) return LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT_DEFAULT;
    return parseInt(value);
  };

  setFeedbackCardhHeight = (value: number) => {
    localStorage.setItem(LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT, value.toString());
  };

  getPrimaryColor = (): string => {
    const value = localStorage.getItem(LOCAL_STORAGE_PRIMARY_COLOR);
    if (value === null) return LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT;
    return value;
  };

  setPrimaryColor = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_PRIMARY_COLOR, value.toString());
  };

  getSecondaryColor = (): string => {
    const value = localStorage.getItem(LOCAL_STORAGE_SECONDARY_COLOR);
    if (value === null) return LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT;
    return value;
  };

  setSecondaryColor = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_SECONDARY_COLOR, value.toString());
  };

  getLabelColor = (): string => {
    const value = localStorage.getItem(LOCAL_STORAGE_LABEL_COLOR);
    if (value === null) return LOCAL_STORAGE_LABEL_COLOR_DEFAULT;
    return value;
  };

  setLabelColor = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_LABEL_COLOR, value.toString());
  };

  getThesisAcceptedTerms = (): boolean => {
    const value = localStorage.getItem(LOCAL_STORAGE_THESIS_ACCEPT_TERMS);
    if (value === null) return LOCAL_STORAGE_THESIS_ACCEPTED_TERMS_DEFAULT;
    return JSON.parse(value);
  };

  setThesisAcceptedTerms = (value: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_THESIS_ACCEPT_TERMS, value.toString());
  };

  getThesisConfirmAcceptedTerms = (): boolean => {
    const value = localStorage.getItem(LOCAL_STORAGE_THESIS_CONFIRM_ACCEPT_TERMS);
    if (value === null) return LOCAL_STORAGE_THESIS_CONFIRM_ACCEPTED_TERMS_DEFAULT;
    return JSON.parse(value);
  };

  setThesisConfirmAcceptedTerms = (value: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_THESIS_CONFIRM_ACCEPT_TERMS, value.toString());
  };
}

let instance: UserLocalStorage;
