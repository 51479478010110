import { KEAElement, KEALink } from "@kea-mod/jointjs";

/**
 * A grid to place elements in a structured way.
 */
export class ElementSelectionGrid {
  private borderTop: number;
  private borderLeft: number;
  private rowMargin: number;
  private colMargin: number;
  private elements: Array<Array<KEAElement | KEALink>>;

  constructor(borderTop: number, borderLeft: number, rowMargin: number, colMargin: number) {
    this.borderTop = borderTop;
    this.borderLeft = borderLeft;
    this.rowMargin = rowMargin;
    this.colMargin = colMargin;
    this.elements = [[]];
  }

  /**
   * Add an KEAElement to the current row.
   */
  addElement(element: KEAElement, width: number, height: number) {
    element.size(width, height);
    this.elements[this.elements.length - 1].push(element);
  }

  addLink(link: KEALink, width: number) {
    this.elements[this.elements.length - 1].push(link);
    link.source({ x: 0, y: 0 });
    link.target({ x: width, y: 0 });
  }

  /**
   * Add a new row to the grid, if the last row is not empty.
   * Else does nothing.
   * It is not necessary to call this method before adding the first element.
   */
  addRow() {
    if (this.elements[this.elements.length - 1].length != 0) this.elements.push([]);
  }

  /**
   * Scale the grid by a factor.
   * Returns the grid itself.
   */
  scale(factor: number, scaleMargins: boolean) {
    this.elements.forEach((row) => {
      row.forEach((element) => {
        // @ts-ignore
        if (element instanceof KEAElement) element.size(element.size().width * factor, element.size().height * factor);
        else element.target({ x: (element.target().x || 0) * factor, y: 0 });
      });
    });

    if (scaleMargins) {
      this.borderTop *= factor;
      this.borderLeft *= factor;
      this.rowMargin *= factor;
      this.colMargin *= factor;
    }
    return this;
  }

  /**
   * Arrange the elements in the grid and returns them.
   */
  arrange(center: Boolean, maxColSize: number | null = null): Array<KEAElement | KEALink> {
    // get nrows and ncols
    const nrows = this.elements.length;
    const ncols = this.elements.map((row) => row.length).reduce((a, b) => (a > b ? a : b), 0);

    // get the height of each row
    // this.elements.map { row -> row.filterIsInstance<KeaElement>().maxOf { it.size.height } }
    const rowHeights = this.elements.map((row) =>
      row
        .filter((element) => element instanceof KEAElement)
        .map((element) => (element as KEAElement).size().height)
        .reduce((a, b) => (a > b ? a : b), 0),
    );

    // get the width of each column
    // (0 until ncols).map { col -> this.elements.mapNotNull { row -> row.getOrNull(col) as? KeaElement }.maxOf { it.size.width } }
    const colWidths = Array(ncols)
      .fill(0)
      .map((_, col) => {
        let colSize = this.elements
          .map((row) => row[col])
          .filter((element) => element instanceof KEAElement)
          .map((element) => (element as KEAElement).size().width)
          .reduce((a, b) => (a > b ? a : b), 0);
        return maxColSize == null ? colSize : colSize > maxColSize ? maxColSize : colSize;
      });

    let currentX = this.borderLeft;
    let currentY = this.borderTop;

    // set all element positions
    for (let i = 0; i < nrows; i++) {
      currentX = this.borderLeft;
      for (let j = 0; j < ncols; j++) {
        const element = this.elements[i][j];
        if (element == null) break;
        if (element instanceof KEAElement) {
          // does the element fit in this column?
          if (element.size().width > colWidths[j]) {
            element.position(currentX, currentY);
            currentX += element.size().width + this.colMargin;
          } else {
            // place the element centered
            if (center)
              element.position(
                currentX + (colWidths[j] - element.size().width) / 2,
                currentY + (rowHeights[i] - element.size().height) / 2,
              );
            else element.position(currentX, currentY);
            currentX += colWidths[j] + this.colMargin;
          }
        } else {
          // @ts-ignore
          let width = element.target().x - element.source().x;
          element.source({ x: currentX, y: currentY });
          element.target({ x: currentX + width, y: currentY });
          currentX += colWidths[j] + this.colMargin;
        }
      }
      currentY += rowHeights[i] + this.rowMargin;
    }

    return this.elements.flat();
  }
}
