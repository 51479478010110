import { WithChildren } from "@kea-mod/common";

interface Props extends WithChildren {
  width: number;
  height: number;
}

export const ContentContainer = (props: Props) => {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
      }}
      className="has-background-light p-0 m-0"
    >
      {props.children}
    </div>
  );
};
