import { KEAElement } from "./KEAElement";
import { util } from "jointjs";

export enum KEAEPKConnectorType {
  AND = "AND",
  OR = "OR",
  XOR = "XOR",
}

export class KEAEPKConnector extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.EPKConnector",
      size: {
        width: 50,
        height: 50,
      },
      attrs: {
        body: {
          refCx: "50%",
          refCy: "50%",
          refR: "50%",
          strokeWidth: 2,
          stroke: "#333333",
          fill: "#e2e2e2",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "50%",
          text: "∧",
          fontSize: 24,
          fill: "#333333",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      connectorType: "AND",
    });
  }

  setConnectorType = (type: KEAEPKConnectorType): KEAEPKConnector => {
    this.set("connectorType", type);
    this.attr(KEAEPKConnector.getConnectorMarkup(type));
    this.trigger("change:attribute", this);
    return this;
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "circle",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();

  static getConnectorMarkup = (connectorType: KEAEPKConnectorType) => {
    switch (connectorType) {
      case KEAEPKConnectorType.AND:
        return {
          label: { fontSize: 24, text: "∧" },
        };
      case KEAEPKConnectorType.OR:
        return {
          label: { fontSize: 24, text: "∨" },
        };

      case KEAEPKConnectorType.XOR:
      default:
        return {
          label: { fontSize: 14, text: "XOR" },
        };
    }
  };
}
