import {
  BPMNTaskType,
  KEABPMNActivity,
  KEAGraph,
  KEALink,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";
import { TemplateConfiguration } from "./TemplateConfiguration";

export type LinkConfiguration = {
  editSourceMarkerEnabled: boolean;
  editTargetMarkerEnabled: boolean;
  editPathTypeEnabled: boolean;
  sourceLabelEnabled: boolean;
  midLabelEnabled: boolean;
  targetLabelEnabled: boolean;
};

const activity1 = new KEABPMNActivity().position(10, 10).setTaskType(BPMNTaskType.NONE);

const activity2 = new KEABPMNActivity().position(175, 10).setTaskType(BPMNTaskType.SERVICE).setLabel("Service");

const activity3 = new KEABPMNActivity().position(10, 100).setTaskType(BPMNTaskType.SEND).setLabel("Send");

const activity4 = new KEABPMNActivity().position(175, 100).setTaskType(BPMNTaskType.RECEIVE).setLabel("Receive");

const activity5 = new KEABPMNActivity().position(10, 190).setTaskType(BPMNTaskType.USER).setLabel("User");

const activity6 = new KEABPMNActivity().position(175, 190).setTaskType(BPMNTaskType.MANUAL).setLabel("Manual");

const activity7 = new KEABPMNActivity()
  .position(10, 280)
  .setTaskType(BPMNTaskType.BUSINESS_RULE)
  .setLabel("Business Rule");

const activity8 = new KEABPMNActivity().position(175, 280).setTaskType(BPMNTaskType.SCRIPT).setLabel("Script");

const activity9 = new KEABPMNActivity().position(10, 370).setIsSubProcess(true).setIsLoop(true).setLabel("Loop");

const activity10 = new KEABPMNActivity()
  .position(175, 370)
  .setIsSubProcess(true)
  .setIsParallel(true)
  .setLabel("Multi-Instance");

const activity11 = new KEABPMNActivity()
  .position(10, 460)
  .setIsSubProcess(true)
  .setIsCompensation(true)
  .setLabel("Compensation");

const activity12 = new KEABPMNActivity().position(175, 460).setIsSubProcess(true).setIsAdHoc(true).setLabel("Ad-Hoc");

const activity13 = new KEABPMNActivity()
  .position(10, 550)
  .setIsSubProcess(true)
  .setIsAdHoc(true)
  .setIsCompensation(true)
  .setLabel("Compensation & Ad-Hoc");

const activity14 = new KEABPMNActivity().position(175, 550).setIsCallActivity(true);

let defaultLink = new KEALink();
defaultLink.setDasharray(LinkType.SOLID);
defaultLink.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
defaultLink.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

export const getActivityConfiguration = (): Array<any> => {
  return [
    activity1,
    activity2,
    activity3,
    activity4,
    activity5,
    activity6,
    activity7,
    activity8,
    activity9,
    activity10,
    activity11,
    activity12,
    activity13,
    activity14,
  ];
};

export const getActivityMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getActivityConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.8,
    () => {
      return defaultLink;
    },
    conf,
  );
};
