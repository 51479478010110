import { WithChildren } from "@kea-mod/common";
import { useEffect, useRef, useState } from "react";
import { Draggable } from "./Draggable";
import { ResizeBorder } from "./ResizeBorder";

interface Props extends WithChildren {
  initalX: number;
  initialY: number;
  initialWidth: number;
  initialHeight: number;
  visible: boolean;
  title: string;
}

export const ResizableAndDraggableContainer = (props: Props) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [position, setPosition] = useState<joint.dia.Point>({ x: 0, y: 0 });

  const ref = useRef(null);

  useEffect(() => {
    setPosition({
      x: props.initalX,
      y: props.initialY,
    });
    setWidth(props.initialWidth);
    setHeight(props.initialHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: props.visible ? "inline" : "none" }}>
      <div
        ref={ref}
        style={{
          position: "absolute",
          left: position.x,
          top: position.y,
          width: width,
          height: height,
          overflowY: "scroll",
        }}
        className="box p-1"
      >
        <Draggable setPosition={setPosition}>
          <div className="h1">{props.title}</div>
        </Draggable>
        {props.children}
      </div>
      <ResizeBorder
        x={position.x}
        y={position.y}
        width={width}
        height={height}
        setWidth={setWidth}
        setHeight={setHeight}
      />
    </div>
  );
};
