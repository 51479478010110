import { createContext, useContext } from "react";

export type UserSettingsContextType = {
  setIsActiveSettingsModal: (isActive: boolean) => void;
};

export const UserSettingsModalContext = createContext<UserSettingsContextType>({
  setIsActiveSettingsModal: () => console.warn("UserSettingsModalContext: no function defined."),
});
export const useUserSettingsModalContext = () => useContext(UserSettingsModalContext);
