import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("UserSettingsContext");
};

export interface IUserSettingsContext {
  gridSize: number;
  setGridSize: (value: number) => void;
  grid: any;
  setGrid: (value: any) => void;
  gridBackground: string;
  setGridBackground: (value: string) => void;
  secondaryGraphPositionX: number;
  setSecondaryGraphPositionX: (value: number) => void;
  secondaryGraphPositionY: number;
  setSecondaryGraphPositionY: (value: number) => void;
  secondaryGraphWidth: number;
  setSecondaryGraphWidth: (value: number) => void;
  secondaryGraphHeight: number;
  setSecondaryGraphHeight: (value: number) => void;
  taskCardPositionX: number;
  setTaskCardPositionX: (value: number) => void;
  taskCardPositionY: number;
  setTaskCardPositionY: (value: number) => void;
  taskCardWidth: number;
  setTaskCardWidth: (value: number) => void;
  taskCardHeight: number;
  setTaskCardHeight: (value: number) => void;
  feedbackCardPositionX: number;
  setFeedbackCardPositionX: (value: number) => void;
  feedbackCardPositionY: number;
  setFeedbackCardPositionY: (value: number) => void;
  feedbackCardWidth: number;
  setFeedbackCardWidth: (value: number) => void;
  feedbackCardHeight: number;
  setFeedbackCardHeight: (value: number) => void;
  primaryColor: string;
  setPrimaryColor: (value: string) => void;
  secondaryColor: string;
  setSecondaryColor: (value: string) => void;
  labelColor: string;
  setLabelColor: (value: string) => void;
  isActive: boolean;
  setIsActive: (value: boolean) => void;
}

const defaultState: IUserSettingsContext = {
  gridSize: -1,
  setGridSize: () => printOutsideContext(),
  grid: {},
  setGrid: () => printOutsideContext(),
  gridBackground: "",
  setGridBackground: () => printOutsideContext(),
  secondaryGraphPositionX: -1,
  setSecondaryGraphPositionX: () => printOutsideContext(),
  secondaryGraphPositionY: -1,
  setSecondaryGraphPositionY: () => printOutsideContext(),
  secondaryGraphWidth: -1,
  setSecondaryGraphWidth: () => printOutsideContext(),
  secondaryGraphHeight: -1,
  setSecondaryGraphHeight: () => printOutsideContext(),
  taskCardPositionX: -1,
  setTaskCardPositionX: () => printOutsideContext(),
  taskCardPositionY: -1,
  setTaskCardPositionY: () => printOutsideContext(),
  taskCardWidth: -1,
  setTaskCardWidth: () => printOutsideContext(),
  taskCardHeight: -1,
  setTaskCardHeight: () => printOutsideContext(),
  feedbackCardPositionX: -1,
  setFeedbackCardPositionX: () => printOutsideContext(),
  feedbackCardPositionY: -1,
  setFeedbackCardPositionY: () => printOutsideContext(),
  feedbackCardWidth: -1,
  setFeedbackCardWidth: () => printOutsideContext(),
  feedbackCardHeight: -1,
  setFeedbackCardHeight: () => printOutsideContext(),
  primaryColor: "#F0F0F0",
  setPrimaryColor: () => printOutsideContext(),
  secondaryColor: "#333333",
  setSecondaryColor: () => printOutsideContext(),
  labelColor: "#333333",
  setLabelColor: () => printOutsideContext(),
  isActive: false,
  setIsActive: () => printOutsideContext(),
};

export const UserSettingsContext = createContext<IUserSettingsContext>(defaultState);

export const useUserSettingsContext = () => useContext(UserSettingsContext);
