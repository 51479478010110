import { HTTPType, useNameModelModalContext } from "context/NameModelModalContext";

import { useAPIContext } from "context/APIContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useModelingLanguageContext } from "context/ModelLanguageContext";
import { getModelId } from "helper/Helper";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  isActive: boolean;
  toggleModal: () => void;
}

export const NameModelModal = ({ isActive, toggleModal }: Props) => {
  const modelLanguageContext = useModelingLanguageContext();
  const nameModelModalContext = useNameModelModalContext();
  const keaGraphContext = useKEAGraphContext();
  const apiContext = useAPIContext();
  const { t } = useTranslation();

  return (
    <CustomModalCard
      setisActive={toggleModal}
      isActive={isActive}
      header={t("NameModelModalHeader") as string}
      body={
        <div className="field">
          <label className="label">{t("NameModelModalInputFieldLabel")}</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("NameModelModalInputFieldPlaceholder") as string}
              value={nameModelModalContext.name}
              onChange={(e) => nameModelModalContext.setName(e.target.value)}
            />
          </div>
          <p className="help">{t("NameModelModalInputFieldHelper")}</p>
        </div>
      }
      footer={
        <>
          <button
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              toggleModal();
            }}
            type="button"
            className="button is-warning"
          >
            {t("cancel")}
          </button>

          {nameModelModalContext.type === HTTPType.POST && (
            <button
              type="button"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                nameModelModalContext.postModel(
                  keaGraphContext.modelingGraph,
                  nameModelModalContext.name,
                  keaGraphContext.getUserInteractions(),
                  apiContext.apiManager,
                  modelLanguageContext.modelingLanguage,
                );
              }}
              className="button is-primary"
            >
              {t("save")}
            </button>
          )}
          {nameModelModalContext.type === HTTPType.PUT && (
            <button
              type="button"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                nameModelModalContext.putModel(
                  keaGraphContext.modelingGraph,
                  nameModelModalContext.name,
                  keaGraphContext.getUserInteractions(),
                  apiContext.apiManager,
                  getModelId(),
                );
              }}
              className="button is-primary"
            >
              {t("overwrite")}
            </button>
          )}
        </>
      }
    />
  );
};
