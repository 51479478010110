import { getModeltype, getParentId, getParentModel } from "helper/Helper";
import { useEffect, useState } from "react";
import { HTTPType, NameModelModalContext } from "./NameModelModalContext";

import { WithChildren } from "@kea-mod/common";
import { KEAGraph } from "@kea-mod/jointjs";
import { NameModelModal } from "modals/NameModelModal";
import { APIManager } from "service/APIManager";
import { useErrorMessageContext } from "./ErrorModalContext";
import { Interaction } from "./KEAGraphContext";
import { useSuccessModalContext } from "./SuccessModalContext";

interface Props extends WithChildren {}

export const NameModelModalContextProvider = (props: Props) => {
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<HTTPType>(HTTPType.POST);
  const [nameModelModalVisible, setNameModelModalVisible] = useState<boolean>(false);

  const successModalContext = useSuccessModalContext();
  const errorModalContext = useErrorMessageContext();

  const [parentModel, setParentModel] = useState<string | null>(null);
  const [parentId, setParentId] = useState<number | null>(null);
  const [modelType, setModelType] = useState<string | null>(null);

  useEffect(() => {
    setParentModel(getParentModel());
    setParentId(getParentId());
    setModelType(getModeltype());
  }, []);

  const toggleModal = () => {
    setNameModelModalVisible(!nameModelModalVisible);
  };

  const postModel = async (model: KEAGraph, name: string, interaction: Interaction[], apiManager: APIManager) => {
    const success = await apiManager.sendModel(
      {
        graph: model.toJSON(),
      },
      interaction,
      modelType || "free",
      name,
      parentModel || undefined,
      parentId || undefined,
    );

    if (success && success.id !== -1) {
      setNameModelModalVisible(false);
      successModalContext.toggleSuccessModal();
    } else {
      setNameModelModalVisible(false);
      errorModalContext.toggleErrorModal();
    }
  };

  const putModel = async (
    model: KEAGraph,
    name: string,
    interaction: Interaction[],
    apiManager: APIManager,
    id: number | null,
  ) => {
    if (id) {
      const success = await apiManager.upateModel(
        {
          graph: model.toJSON(),
        },
        interaction,
        name,
        id,
      );

      if (success) {
        setNameModelModalVisible(false);
        successModalContext.toggleSuccessModal();
      } else {
        setNameModelModalVisible(false);
        errorModalContext.toggleErrorModal();
      }
    }
    setNameModelModalVisible(false);
    errorModalContext.toggleErrorModal();
  };

  return (
    <NameModelModalContext.Provider
      value={{
        name: name,
        setName: setName,
        type: type,
        setType: setType,
        toggleNameModelModal: setNameModelModalVisible,
        postModel: postModel,
        putModel: putModel,
      }}
    >
      {props.children}
      <NameModelModal toggleModal={toggleModal} isActive={nameModelModalVisible} />
    </NameModelModalContext.Provider>
  );
};
