import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export enum StateType {
  INITIAL = "INITIAL",
  FINAL = "FINAL",
  EXIT = "EXIT",
}

export class KEAUMLCircleState extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLCircleState",
      size: {
        width: 30,
        height: 30,
      },
      attrs: {
        body: {
          cx: "calc(0.5*w)",
          cy: "calc(0.5*h)",
          r: "calc(0.5*w)",
          stroke: "none",
          fill: "none",
        },
        outerCircle: {
          cx: "calc(0.5*w)",
          cy: "calc(0.5*h)",
          r: "calc(0.5*w)",
          strokeWidth: 1,
          stroke: "#333333",
          fill: "#333333",
          visibility: "visible",
        },
        innerCircle: {
          cx: "calc(0.5*w)",
          cy: "calc(0.5*h)",
          r: "calc(0.35*w)",
          stroke: "#333333",
          fill: "#333333",
          visibility: "hidden",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "150%",
          fontSize: 14,
          text: "",
          fill: "#333333",
        },
        exit: {
          refX: "50%",
          refY: "50%",
          d: "M -9 10 L -10 9 L -1 0 L -10 -9 L -9 -10 L 0 -1 L 9 -10 L 10 -9 L 1 0 L 10 9 L 9 10 L 0 1 L -9 10",
          stroke: "#333333",
          strokeWidth: 2,
          fill: "#333333",
          visibility: "hidden",
        },
      },
      stateType: StateType.INITIAL,
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      outerCircle: {
        stroke: property,
      },
      innerCircle: {
        fill: property,
        stroke: property,
      },
      exit: {
        fill: property,
        stroke: property,
      },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      outerCircle: {
        fill: property,
      },
    });
  }

  getStateType = (): StateType => {
    return this.get("stateType");
  };

  setStateType = (type: StateType): KEAUMLCircleState => {
    this.set("stateType", type);
    switch (type) {
      case StateType.INITIAL:
        this.attr({
          outerCircle: {
            visibility: "visible",
          },
        });
        break;
      case StateType.FINAL:
        this.attr({
          outerCircle: {
            visibility: "visible",
          },
          innerCircle: {
            visibility: "visible",
          },
        });
        break;
      case StateType.EXIT:
        this.attr({
          outerCircle: {
            visibility: "visible",
          },
          innerCircle: {
            visibility: "hidden",
          },
          exit: {
            visibility: "visible",
          },
        });
        break;
    }

    this.trigger("change:attribute", this);
    return this;
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "circle",
        selector: "body",
      },
      {
        tagName: "circle",
        selector: "outerCircle",
      },
      {
        tagName: "circle",
        selector: "innerCircle",
      },
      {
        tagName: "text",
        selector: "label",
      },
      {
        tagName: "path",
        selector: "exit",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
