import { ModelingLanguage, WithChildren } from "@kea-mod/common";
import { useState } from "react";
import { ModelingLanguageContext } from "./ModelLanguageContext";

interface Props extends WithChildren {}

export const ModelingLanguageContextProvider = (props: Props) => {
  const [modelingLanguage, setModelingLanguage] = useState<ModelingLanguage>(ModelingLanguage.NONE);
  const [userCanChangeModelingLanguage, setUserCanChangeModelingLanguage] = useState<boolean>(false);

  const setModelingLanguageWrapper = (language: ModelingLanguage) => {
    if (language === ModelingLanguage.ANY) {
      setUserCanChangeModelingLanguage(true);
      setModelingLanguage(ModelingLanguage.UML_CD);
    } else {
      setModelingLanguage(language);
    }
  };

  return (
    <ModelingLanguageContext.Provider
      value={{
        modelingLanguage,
        setModelingLanguage: setModelingLanguageWrapper,
        userCanChangeModelingLanguage,
        setUserCanChangeModelingLanguage,
      }}
    >
      {props.children}
    </ModelingLanguageContext.Provider>
  );
};
