import { useTranslation } from "react-i18next";

interface Props {}

export const AboutProjectFooter = (_props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="column has-text-centered has-text-left-tablet ml-2">
      {t("footer_about")}&nbsp;
      <a target="_blank" rel="noopener noreferrer" href={window?._env_?.REACT_APP_HOMEPAGE_URL}>
        keamod.gi.de
      </a>
    </div>
  );
};
