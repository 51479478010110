import { APIHeader, ApiMethod } from "./APITypes";

export class APIService {
  private _headers: [string, string][] = [];
  private _method: ApiMethod = "POST";

  public request<T>(body?: T): RequestInit {
    if (body) {
      return {
        method: this._method,
        headers: this._headers,
        body: JSON.stringify(body),
      };
    }
    return {
      method: this._method,
      headers: this._headers,
    };
  }

  public setHeaders = (headers: Array<APIHeader>): APIService => {
    for (const i in headers) {
      this._headers.push([headers[i].key, headers[i].value]);
    }
    return this;
  };

  public setMethod = (newMethod: ApiMethod): APIService => {
    this._method = newMethod;
    return this;
  };

  get headers(): string[][] {
    return this._headers;
  }
  public resetHeaders(): void {
    this._headers = [];
  }
}

export class RequestBody<T> {
  constructor(private _requestBody: T) {}
  get requestBody(): T {
    return this._requestBody;
  }
  set requestBody(newRequestBody: T) {
    this._requestBody = newRequestBody;
  }
}
