import { defineObjectProperty } from "@kea-mod/common";
import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export type ObjectAttribute = {
  name: string;
  value: any;
};

export class KEAUMLObject extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLObject",
      size: {
        width: 200,
        height: 160,
      },
      attrs: {
        uml_object_name_rect: {
          refWidth: "100%",
          height: 40,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#ededed",
        },
        uml_object_attrs_rect: {
          refWidth: "100%",
          refY: 40,
          height: "calc(h - 40)",
          strokeWidth: 2,
          stroke: "#000000",
          fill: "white",
        },
        uml_object_name_text: {
          ref: "uml_object_name_rect",
          refY: 0.5,
          refX: 0.5,
          textAnchor: "middle",
          yAlignment: "middle",
          fontWeight: "bold",
          fontFamily: "Arial, sans-serif",
          fill: "black",
          fontSize: 12,
          textDecoration: "underline",
          text: "",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        body: {
          width: "calc(w)",
          height: "calc(h)",
          refX: "0%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          pointerEvents: "invisible",
        },
      },
      resizeHeight: true,
      resizeWidth: true,
      minWidth: 100,
      minHeight: 80,
      className: "",
      objectName: "",
      attributes: [],
      lineCounts: [],
    });
  }

  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this.on("change:name", this.onChangeName);
    this.on("change:attribute", this.onChangeAttribute);
    this.on("change:size", this.onChangeSize);
  }

  onChangePrimaryColor(_element: KEAElement, _property: string, _options: any): void {
    this.attr("uml_object_name_rect/stroke", this.getPrimaryColor());
    this.attr("uml_object_attrs_rect/stroke", this.getPrimaryColor());
  }

  onChangeSecondaryColor(_element: KEAElement, _property: string, _options: any): void {
    this.attr("uml_object_name_rect/fill", this.getSecondaryColor());
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr("uml_object_name_text/fill", property);
  }

  onChangeName() {
    this.attr("uml_object_name_text/text", this.getObjectName() + " : " + this.getClassName());
    this.updateSize();
  }

  onChangeAttribute() {
    this._updateAttributeStyles();
    this.markup = this._calculateMarkup();
    this.updateSize();
    this.trigger("render-markup");
  }

  // Resize

  onChangeSize = () => {
    this._updateAttributeStyles();
  };

  compareStrings = (a: string, b: string) => {
    a = a.replace(/\s/g, "");
    b = b.replace(/\s/g, "");
    return a === b;
  };

  getWidthByText = (text: string) => {
    let retWidth = 0;
    const textElements = document.body.querySelectorAll("text");
    textElements.forEach((textElement) => {
      const tspan = textElement.querySelector("tspan");
      if (tspan && tspan.innerHTML) {
        const textContent = tspan.textContent;
        if (textContent) {
          if (this.compareStrings(text, textContent)) {
            const width = textElement.getBBox().width;
            const rounded = Math.round(width);
            if (retWidth < rounded) retWidth = rounded;
          }
        }
      }
    });
    return retWidth;
  };

  updateSize = () => {
    const className = this.getClassName();
    const objectName = this.getObjectName();
    const text = objectName + " : " + className;
    const newWidth = this.getWidthByText(text) + 20;
    const currentSize = this.get("size") || { width: 200, height: 160 };
    const minHeightCalc = 40 + this.getAttributes().length * 20 + 20;
    const minHeight = minHeightCalc < 80 ? 80 : minHeightCalc;
    this.set("size", {
      width: newWidth > currentSize.width ? newWidth : currentSize.width,
      height: minHeight > currentSize.height ? minHeight : currentSize.height,
    });
    this.set("minWidth", newWidth);
    this.set("minHeight", minHeight);
  };

  // Attributes

  _getAttributStyles = (attributes: ObjectAttribute[]) => {
    const attributeStyles = {};
    attributes.forEach((attribute, index) => {
      const objectIdentifier = "uml_object_attribute_text-" + index;
      defineObjectProperty(attributeStyles, objectIdentifier, this._getAttributeMarkup(index, attribute));
    });
    return attributeStyles;
  };

  _getAttributeString = (index: number, attribute: ObjectAttribute) => {
    const width = this.getBBox().width;
    const height = this._getAttributeHeight(index, attribute);
    const text = attribute.name + " = " + attribute.value;
    const breakText = util.breakText(text, { width: width, height: height });
    const indentedText = breakText.replace(/\n/g, "\n    ");
    const linesCount = indentedText.split("\n").length;
    const lineCounts = this.get("lineCounts");
    lineCounts[index] = linesCount;
    return indentedText;
  };

  _getLinesBefore = (index: number) => {
    const lineCounts = this.get("lineCounts");
    let linesBefore = 0;
    for (let i = 0; i < index; i++) {
      linesBefore += lineCounts[i];
    }
    return linesBefore;
  };

  _getAttributeHeight = (index: number, _attribute: ObjectAttribute) => {
    const linesBefore = this._getLinesBefore(index);
    const height = this.getBBox().height - linesBefore * 12 - index * 5 - 50;
    return height;
  };

  _getAttributeMarkup = (index: number, attribute: ObjectAttribute) => {
    const text = this._getAttributeString(index, attribute);

    return {
      ref: "uml_object_attrs_rect",
      text: text,
      refY: 20 + index * 5 + this._getLinesBefore(index) * 12,
      refX: 5,
      fill: this.getLabelColor(),
      textAnchor: "start",
      fontSize: 12,
    };
  };

  getAttributes(): ObjectAttribute[] {
    return this.get("attributes");
  }

  setAttributes(attributes: ObjectAttribute[]): KEAUMLObject {
    this.set("attributes", attributes);
    this.trigger("change:attribute", this);
    return this;
  }

  addAttribute(attribute: ObjectAttribute): KEAUMLObject {
    const attributes = this.getAttributes();
    attributes.push(attribute);
    this.setAttributes(attributes);
    this.trigger("change:attribute", this);
    return this;
  }

  updateAttribute = (attribute: ObjectAttribute, position: number): KEAUMLObject => {
    this.getAttributes()[position] = attribute;
    this.trigger("change:attribute", this);
    return this;
  };

  deleteAttribute = (position: number): KEAUMLObject => {
    this.getAttributes().splice(position, 1);
    this.trigger("change:attribute", this);
    return this;
  };

  _updateAttributeStyles = () => {
    this.attr(this._getAttributStyles(this.getAttributes()));
  };

  // Class name

  getClassName(): string {
    return this.get("className");
  }

  setClassName(className: string): KEAUMLObject {
    this.set("className", className);
    this.trigger("change:name", this);
    return this;
  }

  // Object name

  getObjectName(): string {
    return this.get("objectName");
  }

  setObjectName(objectName: string): KEAUMLObject {
    this.set("objectName", objectName);
    this.trigger("change:name", this);
    return this;
  }

  _calculateAttributesMarkup = (): Array<{
    tagName: string;
    selector: string;
  }> => {
    const attributes: Array<ObjectAttribute> = this.getAttributes();
    const attributeMarkup: { tagName: string; selector: string }[] = [];
    attributes.forEach((_attribute, index) =>
      attributeMarkup.push({
        tagName: "text",
        selector: "uml_object_attribute_text-" + index,
      }),
    );
    return attributeMarkup;
  };

  _calculateMarkup = (): { tagName: string; selector: string }[] => {
    const markup = [
      {
        tagName: "rect",
        selector: "uml_object_name_rect",
      },
      {
        tagName: "rect",
        selector: "uml_object_attrs_rect",
      },
      {
        tagName: "text",
        selector: "uml_object_name_text",
      },
      {
        tagName: "rect",
        selector: "body",
      },
    ].concat(this._calculateAttributesMarkup());

    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };
  markup = this._calculateMarkup();
}
