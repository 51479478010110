import { b64DecodeUnicode } from "@kea-mod/common";
import MDEditor from "@uiw/react-md-editor";
import classNames from "classnames";
import { useTaskContext } from "context/TaskContext";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ResizableDraggableCard } from "./ResizableDraggableCard";

type Props = {
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  setPositionX: (positionX: number) => void;
  setPositionY: (positionY: number) => void;
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
};

export const TaskComponent = (props: Props) => {
  const taskContext = useTaskContext();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);

  const getDecodedValue = useCallback((b64encoded: string): string => {
    const decodedValue = b64DecodeUnicode(b64encoded);
    if (!decodedValue) return "An error occured while loading this text..";
    return decodedValue;
  }, []);

  const decodedScenarioInput = useMemo(() => {
    return getDecodedValue(taskContext.markdown);
  }, [getDecodedValue, taskContext.markdown]);
  const decodedDescriptionInput = useMemo(() => {
    return getDecodedValue(taskContext.description);
  }, [getDecodedValue, taskContext.description]);

  const getScenario = () => {
    return (
      <MDEditor.Markdown
        style={{
          overflowY: "scroll",
          width: "100%",
          height: "calc(100% - 98px)",
        }}
        source={decodedScenarioInput}
      />
    );
  };

  const getDescription = () => {
    return (
      <MDEditor.Markdown
        style={{
          overflowY: "scroll",
          width: "100%",
          height: "calc(100% - 98px)",
        }}
        source={decodedDescriptionInput}
      />
    );
  };

  return (
    <ResizableDraggableCard
      positionX={props.positionX}
      positionY={props.positionY}
      width={props.width}
      height={props.height}
      minWidth={100}
      minHeight={100}
      visible={true}
      setPositionX={props.setPositionX}
      setPositionY={props.setPositionY}
      setWidth={props.setWidth}
      setHeight={props.setHeight}
      header={<p className="card-header-title is-centered">{t("TaskComponentTabDescriptionHeader")}</p>}
      body={
        <>
          {activeTab === 0 && getDescription()}
          {activeTab === 1 && getScenario()}
        </>
      }
      footer={
        <>
          <a
            href="#"
            onClick={() => setActiveTab(0)}
            className={classNames("card-footer-item", {
              "has-text-grey": activeTab === 1,
            })}
          >
            {t("TaskComponentTabDescription")}
          </a>
          <a
            href="#"
            onClick={() => setActiveTab(1)}
            className={classNames("card-footer-item", {
              "has-text-grey": activeTab === 0,
            })}
          >
            {t("TaskComponentTabScenario")}
          </a>
        </>
      }
    />
  );
};
