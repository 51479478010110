import { WithChildren } from "@kea-mod/common";
import { KEAUMLLifeLine } from "@kea-mod/jointjs";
import { EditLifeLineModal } from "modals/EditLifeLineModal";
import { Component } from "react";
import { EditUMLLifeLineContext } from "./EditUMLLifeLineContext";

interface State {
  node: KEAUMLLifeLine | undefined;
  changeLifeLineModalVisible: boolean;
}

interface Props extends WithChildren {}

export class EditUMLLifeLineContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      node: undefined,
      changeLifeLineModalVisible: false,
    };
  }

  isEditableByLifeLineModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.UMLLifeLine":
        return true;
      default:
        return false;
    }
  };

  toggleEditLifeLineModal = () => {
    this.setState((prevState) => ({
      changeLifeLineModalVisible: !prevState.changeLifeLineModalVisible,
      node: prevState.changeLifeLineModalVisible ? undefined : prevState.node,
    }));
  };

  setNode = (node: any) => {
    if (this.isEditableByLifeLineModal(node as any)) {
      this.setState(
        {
          node,
        },
        () => {
          this.setState((prevState) => ({
            changeLifeLineModalVisible: !prevState.changeLifeLineModalVisible,
          }));
        },
      );
    }
  };

  render() {
    return (
      <EditUMLLifeLineContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}
        <EditLifeLineModal
          isActive={this.state.changeLifeLineModalVisible}
          setisActive={this.toggleEditLifeLineModal}
          node={this.state.node}
        />
      </EditUMLLifeLineContext.Provider>
    );
  }
}
