import { useAPIContext } from "context/APIContext";
import { useEditBPMNContext } from "context/EditBPMNContext";
import { useEditKEALinkContext } from "context/EditKEALinkContext";
import { useEditLabelContext } from "context/EditLabelContext";
import { useEditPositionLabelContext } from "context/EditPositionLabelModalContext";
import { useEditUMLContext } from "context/EditUMLContext";
import { useEditUMLLifeLineContext } from "context/EditUMLLifeLineContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useModelingLanguageContext } from "context/ModelLanguageContext";
import { HTTPType, useNameModelModalContext } from "context/NameModelModalContext";
import { useUserSettingsContext } from "context/UserSettingsContext";
import { dimension, useWindowSizeContext, windowPadding } from "context/WindowSizeContext";
import { getModelingLanguage } from "helper/Helper";
import { ContentView } from "pages/model/update/content/ContentView";
import { useEffect, useState } from "react";
import { ContentContainer } from "shared/ContentContainer";
import { useCallbackRef } from "shared/misc/UseCallbackRef";
import { FooterContainer } from "./footer/FooterContainer";

interface Props {}

export const PutModelEditor = (_props: Props) => {
  const labelContext = useEditLabelContext();
  const positonLabelContext = useEditPositionLabelContext();
  const umlContext = useEditUMLContext();
  const kealinkContext = useEditKEALinkContext();
  const bpmnContext = useEditBPMNContext();
  const lifeLineContext = useEditUMLLifeLineContext();
  const windowSizeContext = useWindowSizeContext();
  const modelingLanguageContext = useModelingLanguageContext();
  const nameModelModalContext = useNameModelModalContext();
  const apiContext = useAPIContext();
  const keaGraphContext = useKEAGraphContext();
  const userSettingsContext = useUserSettingsContext();

  useEffect(() => {
    modelingLanguageContext.setModelingLanguage(getModelingLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    nameModelModalContext.setType(HTTPType.PUT);
  }, [nameModelModalContext]);

  const [footerHeight, setFooterRef] = useCallbackRef((node: any) => node?.clientHeight || 0);

  const [contentDimension, setContentDimension] = useState<dimension>({
    width: 1,
    height: 1,
  });

  const setNodeWrapper = (node: joint.dia.Element) => {
    umlContext.setNode(node);
    labelContext.setNode(node);
    kealinkContext.setNode(node);
    bpmnContext.setNode(node);
    lifeLineContext.setNode(node);
    positonLabelContext.setNode(node);
  };

  useEffect(() => {
    setContentDimension({
      width: windowSizeContext.dimension.width,
      height: windowSizeContext.dimension.height - (footerHeight as unknown as number) - windowPadding,
    });
  }, [footerHeight, windowSizeContext.dimension]);

  return (
    <>
      <ContentContainer width={windowSizeContext.dimension.width} height={contentDimension.height}>
        <ContentView
          width={windowSizeContext.dimension.width}
          height={contentDimension.height}
          setNode={setNodeWrapper}
          setName={nameModelModalContext.setName}
          modelLanguage={modelingLanguageContext.modelingLanguage}
          setModelLanguage={modelingLanguageContext.setModelingLanguage}
          userCanChangeModelingLanguage={modelingLanguageContext.userCanChangeModelingLanguage}
          apiManager={apiContext.apiManager}
          modelingGraph={keaGraphContext.modelingGraph}
          pushPresent={keaGraphContext.pushPresent}
          addUserInteraction={keaGraphContext.addUserInteraction}
          userSettingsContext={userSettingsContext}
          keaGraphContext={keaGraphContext}
        />
      </ContentContainer>
      <FooterContainer ref={setFooterRef} />
    </>
  );
};
