import { AppContext } from "context/AppContext";
import { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import { App } from "./App";
import "./i18n/i18n";
import "./index.sass";
import * as serviceWorker from "./serviceWorker";
import { Loader } from "./shared/Loader";

declare global {
  interface Window {
    _env_: any;
  }
}

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <AppContext>
      <App />
    </AppContext>
  </Suspense>,
  document.getElementById("root"),
);

// const container = document.getElementById("root");
// const root = createRoot(container!);
// root.render(
//   <Suspense fallback={<Loader />}>
//     <AppContext>
//       <App />
//     </AppContext>
//   </Suspense>
//);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
