import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("FeedbackModalCardContext");
};

export interface IFeedbackModalCardContext {
  setNode: (node: joint.dia.Element) => void;
}

const defaultState: IFeedbackModalCardContext = {
  setNode: () => printOutsideContext(),
};

export const FeedbackModalCardContext = createContext<IFeedbackModalCardContext>(defaultState);
export const useFeedbackModalCardContext = () => useContext(FeedbackModalCardContext);
