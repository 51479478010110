import { Interaction } from "context/KEAGraphContext";
import { AbsoluteFeedbackPosition, FeedbackMessage, ValuationType } from "tasks/sample_feedback";
import { KEAGraphExport } from "types/KEAGraphExport";
import { Endpoints } from "./APIConstants";
import { APIService, RequestBody } from "./APIService";
import {
  APIResponseSubmissionExerciseAssessment,
  ApiBodySendModel,
  ApiBodyUpdateModel,
  ApiRequestModelsPost,
  ApiResponseExercise,
  ApiResponseModelsPost,
  GraderFeedback,
  createDefaultHeader,
} from "./APITypes";

type exercisesresult = {
  id: number;
  startDate: Date;
  endDate: Date;
  userId: number;
  updatedAt: Date;
  createdAt: Date;
};
export class APIManager {
  private setSendModelHeader = (apiService: APIService): void => {
    apiService.setMethod("POST").setHeaders(createDefaultHeader());
  };

  private setUpdateModelHeader = (apiService: APIService): void => {
    apiService.setMethod("PUT").setHeaders(createDefaultHeader());
  };

  private createSendModelBody = (
    graph_data: KEAGraphExport,
    interactions: Interaction[],
    name: string,
    type: string,
    parentModel?: string,
    parentId?: number,
  ): RequestBody<ApiBodySendModel> => {
    return new RequestBody<ApiBodySendModel>({
      ...(parentModel && { parentModel }),
      ...(parentId && { parentId }),
      ...({} && {
        graphData: graph_data,
        interactionData: interactions,
        type,
        name,
      }),
    });
  };

  private createUpdateModelBody = (
    graph_data: KEAGraphExport,
    _interactions: Interaction[],
    name: string,
  ): RequestBody<ApiBodyUpdateModel> => {
    return new RequestBody<ApiBodyUpdateModel>({
      graphData: graph_data,
      interactionData: [],
      name,
    });
  };

  private getSendModelURL = (): string => {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.models_post
    );
  };

  private getUpdateModelURL(id: number): string {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.models_post +
      "/" +
      id +
      "?assessment=true"
    );
  }

  async performSendModel(
    url: string,
    apiService: APIService,
    body: RequestBody<ApiBodySendModel>,
  ): Promise<{ id: number; success: boolean }> {
    return fetch(url, {
      credentials: "include",
      ...apiService.request(body.requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === 201) {
          const response: ApiResponseModelsPost = data;
          this.sendModelSavePostMessage();
          return { id: response.data.id, success: true };
        }
        this.sendModelSavePostMessage();
        return { id: -1, success: false };
      })
      .catch(() => {
        this.sendModelSavePostMessage();
        return { id: -1, success: false };
      });
  }

  async getModel(id: number): Promise<{ success: boolean; data: any }> {
    const apiService = new APIService();
    this.setGetModelHeader(apiService);
    const url = this.getGetModelURL(id);

    return await this.performGetModel(url, apiService);
  }

  private sendModelSavePostMessage() {
    window.parent.postMessage("modelSaved", "*");
  }

  private async performGetModel(url: string, apiService: APIService): Promise<{ success: boolean; data: any }> {
    return fetch(url, { credentials: "include", ...apiService.request() })
      .then((res) => res.json())
      .then((data) => {
        const response: any = data;
        if (response.statusCode === 200) {
          return { success: true, data };
        }
        return { success: false, data: undefined };
      })
      .catch(() => {
        return { success: false, data: undefined };
      });
  }

  getGetModelURL = (id: number) => {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.models_post +
      "/" +
      id
    );
  };

  setGetModelHeader = (apiService: APIService) => {
    apiService.setMethod("GET").setHeaders(createDefaultHeader());
  };

  async upateModel(
    graph_data: KEAGraphExport,
    interactionData: Interaction[],
    name: string,
    id: number,
  ): Promise<boolean> {
    const apiService = new APIService();
    this.setUpdateModelHeader(apiService);
    const body = this.createUpdateModelBody(graph_data, interactionData, name);
    const url = this.getUpdateModelURL(id);

    return await this.performUpdateModel(url, apiService, body);
  }

  private async performUpdateModel(
    url: string,
    apiService: APIService,
    body: RequestBody<ApiBodyUpdateModel>,
  ): Promise<boolean> {
    return fetch(url, {
      credentials: "include",
      ...apiService.request(body.requestBody),
    })
      .then((res) => res.json())
      .then((_data) => {
        this.sendModelSavePostMessage();
        return true;
      })
      .catch(() => {
        this.sendModelSavePostMessage();
        return false;
      });
  }

  async getAssessmentsForSubmissionExercise(submissionExerciseId: string): Promise<Array<FeedbackMessage>> {
    const apiService = new APIService();
    this.setGetSubmissionExerciseAssessmentHeader(apiService);
    const url = this.getGetAssessmentsForSubmissionExerciseUrl(submissionExerciseId);
    return await this.performGetAssessmentsForSubmissionExercise(url, apiService);
  }

  getPositionValue = (feedback: GraderFeedback): string | AbsoluteFeedbackPosition | undefined => {
    if (feedback.positionId !== "") return feedback.positionId;
    if (feedback.positionX !== 0) return { x: feedback.positionX, y: feedback.positionY };
    return undefined;
  };

  convertToFeedbackMessage = (graderFeedback: GraderFeedback): FeedbackMessage | undefined => {
    let position = this.getPositionValue(graderFeedback);

    let feedback: FeedbackMessage = {
      category: graderFeedback.category,
      content: graderFeedback.content,
      valuation: ValuationType[graderFeedback.valuation as keyof typeof ValuationType],
      position: position,
    };
    return feedback;
  };

  async performGetAssessmentsForSubmissionExercise(
    url: string,
    apiService: APIService,
  ): Promise<Array<FeedbackMessage>> {
    return fetch(url, { credentials: "include", ...apiService.request() })
      .then((res) => res.json())
      .then((data) => {
        const response: APIResponseSubmissionExerciseAssessment = data;
        let feedbacks: Array<FeedbackMessage> = [];

        if (response.success) {
          if (response.data.gradings) {
            response.data.gradings.forEach((graderresults) => {
              graderresults.feedbacks.forEach((feedback) => {
                const convertedFeedback = this.convertToFeedbackMessage(feedback);
                if (convertedFeedback) feedbacks.push(convertedFeedback);
              });
            });
          }

          if (response.data.feedbacksmanual) {
            response.data.feedbacksmanual.forEach((graderFeedback) => {
              const convertedFeedback = this.convertToFeedbackMessage(graderFeedback);
              if (convertedFeedback) feedbacks.push(convertedFeedback);
            });
          }
        }
        return feedbacks;
      })
      .catch(() => {
        return [];
      });
  }

  private getGetAssessmentsForSubmissionExerciseUrl = (submissionExerciseId: string): string => {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.submissionexercises_get_assessments +
      submissionExerciseId +
      "/assessments"
    );
  };

  private setGetSubmissionExerciseAssessmentHeader = (apiService: APIService) => {
    apiService.setMethod("GET").setHeaders(createDefaultHeader());
  };

  async sendModel(
    graph_data: KEAGraphExport,
    interactionData: Interaction[],
    type: string,
    name: string = "",
    parentModel?: string,
    parentId?: number,
  ): Promise<{ id: number; success: boolean }> {
    const apiService = new APIService();
    this.setSendModelHeader(apiService);
    const body = this.createSendModelBody(graph_data, interactionData, name, type, parentModel, parentId);
    const url = this.getSendModelURL();
    return await this.performSendModel(url, apiService, body);
  }

  private setSendExerciseHeader = (apiService: APIService) => {
    apiService.setMethod("POST").setHeaders(createDefaultHeader());
  };

  private createSendExerciseBody = (
    exerciseId: number,
    _type: string,
    startDate: string,
    endDate: string,
    modelId: number,
  ): RequestBody<ApiRequestModelsPost> => {
    return new RequestBody<ApiRequestModelsPost>({
      exerciseId,
      type: "creation",
      startDate: startDate,
      endDate: endDate,
      modelId: modelId,
    });
  };

  private getSendExerciseURL = (assessmentId: number): string => {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.exercises_post +
      assessmentId +
      Endpoints.exercises_post_id
    );
  };

  private async performSendExerciseModel(
    url: string,
    apiService: APIService,
    body: RequestBody<ApiRequestModelsPost>,
  ): Promise<boolean> {
    return fetch(url, {
      credentials: "include",
      ...apiService.request(body.requestBody),
    })
      .then((res) => res.json())
      .then((data) => {
        const response: ApiResponseModelsPost = data;
        if (response.statusCode === 201) {
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  }

  private getCreateExerciseURL = () => {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.exercises_post
    );
  };

  private createCreateExerciseBody = () => {
    return {
      startDate: new Date(),
      endDate: new Date(),
      assessmentId: 1,
    };
  };

  async createExerciseObject(): Promise<number> {
    const apiService = new APIService();
    apiService.setMethod("POST").setHeaders(createDefaultHeader());

    return fetch(this.getCreateExerciseURL(), {
      credentials: "include",
      ...apiService.request(this.createCreateExerciseBody()),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const response = data;
        const payload: exercisesresult = response.data;
        return payload.id;
      })
      .catch(() => {
        return -1;
      });
  }

  async sendExercise(graph_data: KEAGraphExport, interactionData: Interaction[], exerciseId: number): Promise<boolean> {
    return await this.sendModel(graph_data, interactionData, "studentSolution", "", "exercise", exerciseId)
      .then(async (response: any) => {
        if (response.success) {
          const assessmentId = await this.createExerciseObject();
          const apiService = new APIService();
          this.setSendExerciseHeader(apiService);
          const body = this.createSendExerciseBody(
            exerciseId,
            "creation",
            "2021-04-13T15:06:34.361Z",
            "2021-04-13T15:06:34.361Z",
            response.id,
          );
          const url = this.getSendExerciseURL(assessmentId);
          return await this.performSendExerciseModel(url, apiService, body);
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  }

  private setFetchExerciseHeader = (apiService: APIService): void => {
    apiService.setMethod("GET").setHeaders(createDefaultHeader());
  };

  private getFetchExerciseURL(id: number): string {
    return (
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL +
      ":" +
      window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT +
      Endpoints.exercises_get_id +
      id
    );
  }

  async performFetchExercise(url: string, apiService: APIService): Promise<ApiResponseExercise> {
    return fetch(url, { credentials: "include", ...apiService.request() })
      .then((res) => res.json())
      .then((data) => {
        const response: any = data;
        return response;
      })
      .catch(() => {
        return {
          id: -1,
          name: "",
          type: "",
          description: "",
        };
      });
  }

  async fetchExercise(id: number): Promise<ApiResponseExercise> {
    const apiService = new APIService();
    this.setFetchExerciseHeader(apiService);
    const url = this.getFetchExerciseURL(id);

    return await this.performFetchExercise(url, apiService);
  }
}
