import { useCallback, useEffect, useState } from "react";

type Props = {
  width: number;
  setWidth: (width: number) => void;
  height: number;
  setHeight: (width: number) => void;
  x: number;
  y: number;
} & typeof defaultProps;

const defaultProps = {
  minWidth: 50,
  minHeight: 100,
};

const SPACING: number = 2;

export const ResizeBorder = ({ x, y, width, height, setWidth, setHeight }: Props) => {
  const [widthResizeEnabled, setWidthResizeEnabled] = useState<boolean>(false);
  const [heightResizeEnabled, setHeightResizeEnabled] = useState<boolean>(false);

  const handleHeightMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const height = event.pageY - y;
      setHeight(height);
    },
    [setHeight, y],
  );

  const handleWidthMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const width = event.pageX - x;
      setWidth(width);
    },
    [setWidth, x],
  );

  const handleHeightMouseUp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setHeightResizeEnabled(false);
    },
    [setHeightResizeEnabled],
  );

  const handleWidthMouseUp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setWidthResizeEnabled(false);
    },
    [setWidthResizeEnabled],
  );

  useEffect(() => {
    if (heightResizeEnabled) {
      document.addEventListener("mousemove", handleHeightMouseMove);
      document.addEventListener("mouseup", handleHeightMouseUp);
    }
    if (!heightResizeEnabled) {
      document.removeEventListener("mousemove", handleHeightMouseMove);
      document.removeEventListener("mouseup", handleHeightMouseUp);
    }
  }, [handleHeightMouseMove, handleHeightMouseUp, heightResizeEnabled]);

  useEffect(() => {
    if (widthResizeEnabled) {
      document.addEventListener("mousemove", handleWidthMouseMove);
      document.addEventListener("mouseup", handleWidthMouseUp);
    }
    if (!widthResizeEnabled) {
      document.removeEventListener("mousemove", handleWidthMouseMove);
      document.removeEventListener("mouseup", handleWidthMouseUp);
    }
  }, [handleWidthMouseMove, handleWidthMouseUp, widthResizeEnabled]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: 10,
          width: width + SPACING * 2,
          left: x - SPACING,
          top: y + height,
          cursor: "ns-resize",
        }}
        onMouseDown={() => setHeightResizeEnabled(true)}
      />
      <div
        style={{
          position: "absolute",
          height: height + SPACING * 2,
          width: 10,
          left: x + width,
          top: y - SPACING,
          cursor: "ew-resize",
        }}
        onMouseDown={() => setWidthResizeEnabled(true)}
      />
      <div
        style={{
          position: "absolute",
          height: 10,
          width: 10,
          left: x + width,
          top: y + height,
          cursor: "nwse-resize",
        }}
        onMouseDown={() => {
          setWidthResizeEnabled(true);
          setHeightResizeEnabled(true);
        }}
      />
    </>
  );
};
ResizeBorder.defaultProps = defaultProps;
