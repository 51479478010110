import { WithChildren } from "@kea-mod/common";
import { EditLabelModal } from "modals/EditLabelModal";
import { Component } from "react";
import { EditLabelContext } from "./EditLabelContext";

interface State {
  node: joint.dia.Element | undefined;
  changeLabelModalVisible: boolean;
}

interface Props extends WithChildren {}

export class EditLabelModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      node: undefined,
      changeLabelModalVisible: false,
    };
  }

  isEditableByLabelModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.EPKEvent":
      case "kea.EPKFunction":
      case "kea.EPKOrganizationalUnit":
      case "kea.EPKInformationObject":
      case "kea.BPMNEvent":
      case "kea.UMLSimpleState":
      case "kea.UMLActivityNode":
      case "kea.UMLSendSymbol":
      case "kea.UMLReceiveSymbol":
      case "kea.PNMarker":
      case "kea.UMLStateInvariant":
        return true;
      default:
        return false;
    }
  };

  toggleEditLabelModal = () => {
    this.setState((prevState) => ({
      changeLabelModalVisible: !prevState.changeLabelModalVisible,
    }));
  };

  setNode = (node: joint.dia.Element) => {
    if (this.isEditableByLabelModal(node)) {
      this.setState(
        {
          node,
        },
        () => {
          this.setState((prevState) => ({
            changeLabelModalVisible: !prevState.changeLabelModalVisible,
          }));
        },
      );
    }
  };

  render() {
    return (
      <EditLabelContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}
        <EditLabelModal
          isActive={this.state.changeLabelModalVisible}
          setisActive={this.toggleEditLabelModal}
          node={this.state.node}
        />
      </EditLabelContext.Provider>
    );
  }
}
