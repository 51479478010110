import { WithChildren, useStateWithCallback } from "@kea-mod/common";
import * as joint from "jointjs";
import { useCallback, useEffect, useState } from "react";
import { Interaction, KEAGraphContext } from "./KEAGraphContext";

import { InteractionType, KEAElement, KEAGraph, KEALink, KEAPaper, keaNamespace } from "@kea-mod/jointjs";
import { useUserSettingsContext } from "./UserSettingsContext";
import { LinkConfiguration } from "shared/LinkConfiguration";

interface Props extends WithChildren {}

export const KEAGraphContextProvider = (props: Props) => {
  const [modelingGraph, setModelingGraph] = useStateWithCallback<KEAGraph>(initializeGraph());
  const [modelingPaper, setModelingPaper] = useStateWithCallback<KEAPaper>(
    new KEAPaper({ cellViewNamespace: keaNamespace }),
  );
  const [templateGraph, setTemplateGraph] = useStateWithCallback<KEAGraph>(
    new KEAGraph({}, { cellNamespace: keaNamespace }),
  );
  const [templatePaper, setTemplatePaper] = useStateWithCallback<KEAPaper>(
    new KEAPaper({ cellViewNamespace: keaNamespace }),
  );
  const [modelLoaded, setModelLoaded] = useState<boolean>(true);
  const [past, setPast] = useStateWithCallback<KEAGraph[]>([]);
  const [present, setPresent] = useStateWithCallback<KEAGraph>(
    new KEAGraph({}, { cellNamespace: keaNamespace }).toJSON(),
  );
  const [future, setFuture] = useStateWithCallback<KEAGraph[]>([]);
  const [interactions, setInteraction] = useState<Interaction[]>([]);
  const [linkConfiguration, setLinkConfiguration] = useState<LinkConfiguration>({
    addLabelEnabled: false,
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
  });

  const userSettingsContext = useUserSettingsContext();

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  function handleKeydown(e: KeyboardEvent) {
    if (e.ctrlKey === true && e.key === "z") {
      if (canUndo()) {
        undo();
      }
    }
    if (e.ctrlKey === true && e.key === "y") {
      if (canRedo()) {
        redo();
      }
    }
  }

  const isCellInDom = (): boolean => {
    const element = document.querySelector('[model-id="myInvisibleCell"]');
    return element ? true : false;
  };

  const loadModel = (json: any, onFinished?: () => void) => {
    setModelLoaded(false);
    modelingGraph.fromJSON(json);
    modelingGraph.getElements().forEach((el) => {
      (el as KEAElement)
        .setPrimaryColor(userSettingsContext.primaryColor)
        .setSecondaryColor(userSettingsContext.secondaryColor)
        .setLabelColor(userSettingsContext.labelColor);
    });
    const interval = setInterval(() => {
      if (isCellInDom()) {
        modelingGraph.removeCells([modelingGraph.getCell("myInvisibleCell")]);
        setModelLoaded(true);
        clearInterval(interval);
        if (onFinished) onFinished();
      }
    }, 100);
  };

  function initializeGraph(): KEAGraph {
    const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
    graph.setHistoryCallback(() => {
      pushPresent();
    });

    graph.setInteractionCallback(
      (
        interactionType: InteractionType,
        interactionTime: number,
        payload?: KEAGraph | KEALink | KEAElement | undefined,
      ) => {
        addUserInteraction(interactionType, interactionTime, payload);
        pushPresent();
      },
    );

    graph.addHistoryEvents();
    graph.addUserInteractionEvents();
    return graph;
  }

  const setTemplateGraphWrapper = (graph: KEAGraph) => {
    setTemplateGraph(graph, () => {
      templatePaper.dumpViews();
    });
  };

  const addCell = (cell: KEAElement) => {
    modelingGraph.addCell(cell);
  };

  const setModelingGraphWrapper = (graph: KEAGraph) => {
    setModelingGraph(graph, () => {
      modelingPaper.dumpViews();
    });
  };

  const setModelingPaperWraper = (paper: KEAPaper) => {
    setModelingPaper(paper, () => {
      modelingPaper.dumpViews();
    });
  };

  const setTemplatePaperWrapper = (paper: KEAPaper) => {
    setTemplatePaper(paper, () => {
      templatePaper.dumpViews();
    });
  };

  const _setHistory = (past: KEAGraph[], present: KEAGraph, future: KEAGraph[], clb: () => void) => {
    setPast(past);
    setPresent(present, () => {
      clb();
    });
    setFuture(future);
  };

  const resetHistory = (graph: KEAGraph) => {
    setPast([]);
    setPresent(graph);
    setFuture([]);
  };

  const canUndo = (): boolean => {
    return past.length > 0 ? true : false;
  };

  const canRedo = (): boolean => {
    return future.length > 0 ? true : false;
  };

  const undo = (): void => {
    let newPast = [...past];
    let newPresent = newPast.pop();
    let newFuture: Array<KEAGraph> = [...future, present];
    _setHistory(newPast, newPresent!, newFuture, () => {
      modelingGraph.fromJSON(newPresent);
    });
  };

  const redo = (): void => {
    const newFuture = [...future];
    const newPast: Array<KEAGraph> = [...past, present];
    const newPresent = newFuture.pop();
    _setHistory(newPast, newPresent!, newFuture, () => {
      modelingGraph.fromJSON(newPresent);
    });
  };

  const pushPresent = useCallback(() => {
    let newPast: Array<KEAGraph> = [...past];
    newPast.push(present);
    setPast(newPast);
    setPresent(modelingGraph.toJSON());
    setFuture([]);
  }, [present, setPast, setPresent, setFuture]);

  const addUserInteraction = useCallback(
    (
      interactionType: InteractionType,
      interactionTime: number,
      payload?: KEAGraph | KEALink | KEAElement | joint.dia.MarkupJSON,
    ) => {
      const interaction: Interaction = {
        interactionType,
        interactionTime,
        payload: payload ? payload : undefined,
      };
      console.log("Added user interaction:", interaction);
      setInteraction((prevState) => [...prevState, interaction]);
    },
    [setInteraction],
  );

  const getUserInteractions = () => {
    return interactions;
  };

  const clearUserInteractions = () => {
    setInteraction([]);
  };

  return (
    <KEAGraphContext.Provider
      value={{
        modelingGraph,
        modelingPaper,
        templateGraph,
        templatePaper,
        linkConfiguration,
        past,
        present,
        future,
        canUndo,
        undo,
        canRedo: canRedo,
        redo,
        resetHistory,
        pushPresent,
        addCellToModelingGraph: addCell,
        setModelingGraph: setModelingGraphWrapper,
        setModelingPaper: setModelingPaperWraper,
        setTemplateGraph: setTemplateGraphWrapper,
        setTemplatePaper: setTemplatePaperWrapper,
        updateLinkConfiguration: setLinkConfiguration,
        addUserInteraction,
        getUserInteractions,
        clearUserInteractions,
        modelLoaded,
        setModelLoaded,
        loadModel,
      }}
    >
      {props.children}
    </KEAGraphContext.Provider>
  );
};
