import * as joint from "jointjs";

import { ButtonGroup, Horizontal, Vertical } from "@kea-mod/common";
import { KEACircle, KEAGraph, KEALink, KEAPaper } from "@kea-mod/jointjs";
import { FeedbackMessage, ValuationType, feedbackSamplegraph } from "tasks/sample_feedback";

import { IKEAGraphContext, InteractionType } from "context/KEAGraphContext";
import { getFeedbackHighlightColor } from "helper/Helper";
import { MessageType } from "modals/SuccessModal";
import { Component } from "react";
import { FeedbackCard } from "shared/FeedbackCard";
import { FeedbackGraph } from "shared/FeedbackGraph";
import { ResizableAndDraggableContainer } from "shared/ResizableAndDraggableContainer";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";

interface State {}

interface Props {
  width: number;
  height: number;
  modelingGraph: KEAGraph;
  modelingPaper: KEAPaper;
  feedback: { [id: string]: Array<FeedbackMessage> };
  positionFeedback: Array<FeedbackMessage>;
  unresolvedFeedbacks: Array<FeedbackMessage>;
  setNode: (node?: any) => void;
  toggleErrorModal: (errorMessageType?: MessageType) => void;
  updateFeedback: () => void;
  addUserInteraction: (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | joint.dia.Link | joint.dia.Cell,
  ) => void;
  keaGraphContext: IKEAGraphContext;
}

export class ContentView extends Component<Props, State> {
  componentDidMount = () => {
    this.loadModel();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.feedback !== prevProps.feedback ||
      this.props.positionFeedback !== prevProps.positionFeedback ||
      this.props.unresolvedFeedbacks !== prevProps.unresolvedFeedbacks
    ) {
      this.unhighlightAllElements();
      this.highlightAllElements();
    }
  };

  loadModel = () => {
    setTimeout(() => {
      this.props.keaGraphContext.loadModel(feedbackSamplegraph);
      this.addMouseEnterAndMouseLeaveEvents();
      this.props.updateFeedback();
    }, 2000);
  };

  addMouseEnterAndMouseLeaveEvents = () => {
    this.props.modelingPaper.on("cell:mouseenter", (cellView) => {
      this.props.setNode(cellView.model);
    });

    this.props.modelingPaper.on("cell:mouseleave", () => {
      this.props.setNode();
    });
  };

  getBadestValuationType = (feedbacks: Array<FeedbackMessage>): ValuationType => {
    if (feedbacks.find((e) => e.valuation === ValuationType.NEGATIVE)) return ValuationType.NEGATIVE;
    if (feedbacks.find((e) => e.valuation === ValuationType.NEUTRAL)) return ValuationType.NEUTRAL;
    if (feedbacks.find((e) => e.valuation === ValuationType.OTHER)) return ValuationType.OTHER;
    if (feedbacks.find((e) => e.valuation === ValuationType.POSITIVE)) return ValuationType.POSITIVE;
    return ValuationType.NEGATIVE;
  };

  getHighlightMarkup = (valuationTypes: Array<FeedbackMessage>) => {
    if (valuationTypes.find((e) => e.valuation === ValuationType.NEGATIVE)) {
      return {
        stroke: "#FF4365",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.NEUTRAL)) {
      return {
        stroke: "#9C9998",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.OTHER)) {
      return {
        stroke: "#9C9998",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.POSITIVE)) {
      return {
        stroke: "#3FCE41",
        "stroke-width": 4,
      };
    }

    return {
      stroke: "#9C9998",
      "stroke-width": 4,
    };
  };

  createFeedbackInfoShapeAt = (position: joint.dia.Point, type: ValuationType): KEACircle => {
    const shape = new KEACircle();
    shape.position(position.x, position.y);
    shape.size(25, 25);
    shape.setLabel("i");

    const feedbackColor = getFeedbackHighlightColor(type);
    shape.setSecondaryColor(feedbackColor);
    shape.setPrimaryColor(feedbackColor);
    shape.setFillOpacity(0.3);
    return shape;
  };

  highlightElement = (id: string, feedbacks: Array<FeedbackMessage>) => {
    const cells = this.props.modelingGraph.getCells();
    const cell = cells.find((element) => element.id === id);
    if (!cell) return;

    const cellView = this.props.modelingPaper.findViewByModel(cell);
    if (!cellView) return;

    if (cell.get("type") === "kea.Link") {
      (cell as KEALink).highlight(this.getHighlightMarkup(feedbacks).stroke);
    } else {
      joint.highlighters.mask.add(cellView as any, "body", "highlighter-selector", {
        attrs: this.getHighlightMarkup(feedbacks),
      });
    }
  };

  highlightAllElements = () => {
    for (const id in this.props.feedback) {
      this.highlightElement(id, this.props.feedback[id]);
    }
    this.props.positionFeedback.forEach((feedback) => {
      this.props.modelingGraph.addCell(
        this.createFeedbackInfoShapeAt(feedback.position as joint.dia.Point, feedback.valuation),
      );
    });
  };

  unhighlightAllElements = () => {
    const cells = this.props.modelingGraph.getCells();
    cells.forEach((cell) => {
      const cellView = this.props.modelingPaper.findViewByModel(cell);
      const elementView: joint.dia.ElementView = cellView as unknown as joint.dia.ElementView;
      joint.dia.HighlighterView.remove(elementView);
    });
  };

  render() {
    return (
      <>
        <FeedbackGraph setNode={this.props.setNode} width={this.props.width} height={this.props.height} />
        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.BOTTOM}
          verticalSpace="1rem"
          horizontalSpace="1rem"
        >
          <ZoomInButton />
          <ZoomOutButton />
          <FitModelButton />
        </ButtonGroup>
        <ResizableAndDraggableContainer
          initalX={20}
          initialY={20}
          initialWidth={200}
          initialHeight={400}
          visible={true}
          title="Feedback"
        >
          <FeedbackCard feedbacks={this.props.unresolvedFeedbacks} />
        </ResizableAndDraggableContainer>
      </>
    );
  }
}
