import { WithChildren } from "@kea-mod/common";
//import { NotSupportedSizeComponent } from "shared/NotSupportedSizeComponent";
import { EditKEALinkContextProvider } from "./EditKEALinkContextProvider";
import { EditPositionLabelModalContextProvider } from "./EditPositionLabelModalContextProvider";
import { ErrorModalContextProvider } from "./ErrorModalContextProvider";
import { KEAGraphContextProvider } from "./KEAGraphContextProvider";
import { ModelingLanguageContextProvider } from "./ModelLanguageContextProvider";
import { NameModelModalContextProvider } from "./NameModelModalContextProvider";
import { SuccessModalContextProvider } from "./SuccessModalContextProvider";
import { UserSettingsContextProvider } from "./UserSettingsContextProvider";
import { UserSettingsModalContextProvider } from "./UserSettingsModalContextProvider";
import { WindowSizeContextProvider } from "./WindowSizeContextProvider";

interface Props extends WithChildren {}

export const AppContext = (props: Props) => {
  return (
    <UserSettingsContextProvider>
      <KEAGraphContextProvider>
        <ModelingLanguageContextProvider>
          <SuccessModalContextProvider>
            <ErrorModalContextProvider>
              <NameModelModalContextProvider>
                <EditKEALinkContextProvider>
                  <EditPositionLabelModalContextProvider>
                    <WindowSizeContextProvider>
                      <UserSettingsModalContextProvider>
                          {props.children}
                      </UserSettingsModalContextProvider>
                    </WindowSizeContextProvider>
                  </EditPositionLabelModalContextProvider>
                </EditKEALinkContextProvider>
              </NameModelModalContextProvider>
            </ErrorModalContextProvider>
          </SuccessModalContextProvider>
        </ModelingLanguageContextProvider>
      </KEAGraphContextProvider>
    </UserSettingsContextProvider>
  );
};
