import { ButtonGroup, Horizontal, ModelingLanguage, Vertical } from "@kea-mod/common";
import { KEAGraph } from "@kea-mod/jointjs";
import { IKEAGraphContext, InteractionType } from "context/KEAGraphContext";
import { IUserSettingsContext } from "context/UserSettingsContext";
import { MessageType } from "modals/SuccessModal";
import { Component } from "react";
import { APIManager } from "service/APIManager";
import { ApiResponseExercise } from "service/APITypes";
import { IFramePrimaryGraph } from "shared/IFramePrimaryGraph";
import SecondaryGraph from "shared/SecondaryGraph";
import { TaskComponent } from "shared/TaskComponent";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ModelExportButton } from "shared/buttons/ModelExportButton";
import { ModelImportButton } from "shared/buttons/ModelImportButton";
import { RedoButton } from "shared/buttons/RedoButton";
import { UndoButton } from "shared/buttons/UndoButton";
import { UpdateModelButton } from "shared/buttons/UpdateModelButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";

interface State {}

interface Props {
  width: number;
  height: number;
  setNode: (node: any) => void;
  modelLanguage: ModelingLanguage;
  setModelLanguage: (language: ModelingLanguage) => void;
  userCanChangeModelingLanguage: boolean;
  setModelName: (name: string) => void;
  modelId: number | undefined;
  exerciseId: number | undefined;
  apiManager: APIManager;
  modelingGraph: KEAGraph;
  toggleErrorModal: (errorMessageType?: MessageType) => void;
  pushPresent: () => void;
  setShowTask(value: boolean): void;
  setMarkdown(value: string): void;
  setDescription(value: string): void;
  addUserInteraction: (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | joint.dia.Link | joint.dia.Element | joint.dia.Cell[],
  ) => void;
  userSettingsContext: IUserSettingsContext;
  keaGraphContext: IKEAGraphContext;
}

export class ContentView extends Component<Props, State> {
  componentDidMount() {
    this.props.keaGraphContext.setModelLoaded(false);
    setTimeout(() => {
      this.loadExercise();
    }, 1000);
  }

  loadModel = () => {
    if (this.props.modelId !== undefined) {
      this.props.apiManager.getModel(this.props.modelId!).then((result: any) => {
        if (result.success === true) {
          const graph = result.data.data.graphData.graph;
          const name = result.data.data.name;
          this.props.setModelName(name);
          this.props.keaGraphContext.loadModel(graph);
        } else {
          this.props.toggleErrorModal(MessageType.LOADING_ERROR);
        }
      });
    }
  };

  loadExerciseTaskData = () => {
    if (this.props.exerciseId !== undefined) {
      this.props.apiManager.fetchExercise(this.props.exerciseId!).then((task: ApiResponseExercise) => {
        if (task.success) {
          setTimeout(() => {
            this.setTaskData(task);
          }, 2000);
        } else {
          this.props.toggleErrorModal(MessageType.LOADING_ERROR);
        }
      });
    }
  };

  loadExercise = () => {
    this.loadModel();
    this.loadExerciseTaskData();
  };

  setTaskData = (task: ApiResponseExercise) => {
    this.props.setModelLanguage(task.data.language.name);
    this.props.setMarkdown(task.data.creation.scenario);
    this.props.setDescription(task.data.description);
    this.props.setShowTask(true);
  };

  render() {
    return (
      <>
        <IFramePrimaryGraph
          width={this.props.width}
          height={this.props.height}
          setNode={this.props.setNode}
          addUserInteraction={this.props.addUserInteraction}
          pushPresent={this.props.pushPresent}
        />

        <SecondaryGraph
          positionX={this.props.userSettingsContext.secondaryGraphPositionX}
          positionY={this.props.userSettingsContext.secondaryGraphPositionY}
          width={this.props.userSettingsContext.secondaryGraphWidth}
          height={this.props.userSettingsContext.secondaryGraphHeight}
          setPositionX={this.props.userSettingsContext.setSecondaryGraphPositionX}
          setPositionY={this.props.userSettingsContext.setSecondaryGraphPositionY}
          setWidth={this.props.userSettingsContext.setSecondaryGraphWidth}
          setHeight={this.props.userSettingsContext.setSecondaryGraphHeight}
          modelLanguage={this.props.modelLanguage}
          setModelLanguage={this.props.setModelLanguage}
          userCanChangeModelingLanguage={this.props.userCanChangeModelingLanguage}
        />

        <TaskComponent
          positionX={this.props.userSettingsContext.taskCardPositionX}
          positionY={this.props.userSettingsContext.taskCardPositionY}
          width={this.props.userSettingsContext.taskCardWidth}
          height={this.props.userSettingsContext.taskCardHeight}
          setPositionX={this.props.userSettingsContext.setTaskCardPositionX}
          setPositionY={this.props.userSettingsContext.setTaskCardPositionY}
          setWidth={this.props.userSettingsContext.setTaskCardWidth}
          setHeight={this.props.userSettingsContext.setTaskCardHeight}
        />

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.BOTTOM}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <ZoomInButton />
          <ZoomOutButton />
          <FitModelButton />
          <UpdateModelButton />
          <ModelImportButton />
          <ModelExportButton />
        </ButtonGroup>

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.TOP}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <span className="buttons has-addons">
            <UndoButton />
            <RedoButton />
          </span>
        </ButtonGroup>
      </>
    );
  }
}
