import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("AssessmentContext");
};
export interface IAssessmentContext {
  exerciseId: number | undefined;
  updateExerciseId: () => void;
  modelId: number | undefined;
  updateModelId: () => void;
}

const defaultState: IAssessmentContext = {
  exerciseId: -1,
  updateExerciseId: () => printOutsideContext(),
  modelId: -1,
  updateModelId: () => printOutsideContext(),
};

export const AssessmentContext = createContext<IAssessmentContext>(defaultState);
export const useAssessmentContext = () => useContext(AssessmentContext);
