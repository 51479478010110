import { KEAUMLClass, UMLAttribute, UMLOperation, UMLVisibility } from "@kea-mod/jointjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";
import { EditUMLClassModalAttributesForm } from "./EditUMLClassModalAttributesForm";
import { EditUMLClassModalIdentifierForm } from "./EditUMLClassModalIdentifierForm";
import { EditUMLClassModalOperationsForm } from "./EditUMLClassModalOperationsForm";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: joint.dia.Cell | undefined;
}

export const EditUMLClassModal = ({ setisActive, isActive, node }: Props) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [identifier, setIdentifier] = useState<string>("");
  const [attributes, setAttributes] = useState<UMLAttribute[]>([]);
  const [operations, setOperations] = useState<UMLOperation[]>([]);

  useEffect(() => {
    if (node?.get("type") === "kea.UMLClass") {
      setName((node as unknown as KEAUMLClass).getClassName());
      setIdentifier((node as unknown as KEAUMLClass).getClassIdentifier());
      setAttributes((node as unknown as KEAUMLClass).getAttributes());
      setOperations((node as unknown as KEAUMLClass).getOperations());
    }
  }, [node]);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  const setIsActiveWrapper = useCallback((): void => {
    setisActive(false);
  }, [setisActive]);

  const assignClassNode = useCallback((): void => {
    (node as unknown as KEAUMLClass).setClassName(name);
    (node as unknown as KEAUMLClass).setClassIdentifier(identifier);
    attributes.forEach((attribute, index) => {
      (node as unknown as KEAUMLClass).updateAttribute(attribute, index);
    });
    operations.forEach((operation, index) => {
      (node as unknown as KEAUMLClass).updateOperation(operation, index);
    });
    setisActive(true);
  }, [attributes, identifier, name, node, operations, setisActive]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignClassNode();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignClassNode, isActive, setIsActiveWrapper]);

  const addAttribute = (e: React.MouseEvent) => {
    e.preventDefault();
    const att: UMLAttribute = {
      visibility: UMLVisibility.public,
      attributeName: "",
      attributeType: "",
      multiplicity: "",
      defaultValue: "",
      isStatic: false,
      properties: {
        isComposite: false,
        isDerived: false,
        isDerivedUnion: false,
        isReadOnly: false,
        isStatic: false,
        isUnique: false,
      },
    };
    (node as unknown as KEAUMLClass).addAttribute(att);
    setAttributes((node as unknown as KEAUMLClass).getAttributes());
  };

  const deleteAttribute = (index: number) => {
    (node as unknown as KEAUMLClass).deleteAttribute(index);
    setAttributes((node as unknown as KEAUMLClass).getAttributes());
  };

  const addOperation = (e: React.MouseEvent) => {
    e.preventDefault();
    const operat = {
      visibility: UMLVisibility.public,
      operationName: "",
      operationParameters: [],
      returnType: "",
      hasProperties: false,
      isOrdered: false,
      isQuery: false,
      isUnique: false,
    };
    (node as unknown as KEAUMLClass).addOperation(operat);
    setOperations((node as unknown as KEAUMLClass).getOperations());
  };

  const deleteOperation = (index: number) => {
    (node as unknown as KEAUMLClass).deleteOperation(index);
    setOperations((node as unknown as KEAUMLClass).getOperations());
  };

  const addOperationParameter = (index: number) => {
    (node as unknown as KEAUMLClass).addOperationParameter(index);
    setOperations((node as unknown as KEAUMLClass).getOperations());
  };

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("uml_modal_header") as string}
      body={
        <>
          <div className="content">
            <EditUMLClassModalIdentifierForm
              isActive={isActive}
              name={name}
              setName={setName}
              identifier={identifier}
              setIdentifier={setIdentifier}
            />
          </div>

          <div className="divider is-left is-info">{t("uml_modal_attributes")}</div>
          <div className="content">
            <EditUMLClassModalAttributesForm
              attributes={attributes}
              setAttributes={setAttributes}
              deleteAttribute={deleteAttribute}
            />
          </div>

          <div className="divider is-left is-info">{t("uml_modal_operations")}</div>
          <EditUMLClassModalOperationsForm
            operations={operations}
            setOperations={setOperations}
            deleteOperation={deleteOperation}
            addOperationParameter={addOperationParameter}
          />
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button type="button" className="button is-rounded is-light is-success" onClick={addAttribute}>
            {t("uml_modal_attributes_add")}
          </button>
          <button type="button" className="button is-rounded is-light is-success" onClick={addOperation}>
            {t("uml_modal_operations_add")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignClassNode();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
