import { KEAElement } from "./KEAElement";
import { util } from "jointjs";

export enum BPMNEventMarkerType {
  NONE = "NONE",
  MESSAGE = "MESSAGE",
  TIMER = "TIMER",
  ERROR = "ERROR",
  ESCALATION = "ESCALATION",
  CANCEL = "CANCEL",
  COMPENSATION = "COMPENSATION",
  LINK = "LINK",
  SIGNAL = "SIGNAL",
  MULTIPLE = "MULTIPLE",
  PARALLEL_MULTIPLE = "PARALLEL_MULTIPLE",
  TERMINATE = "TERMINATE",
  CONDITIONAL = "CONDITIONAL",
}

export enum BPMNEventType {
  START = "START",
  INTERMEDIATE = "INTERMEDIATE",
  END = "END",
}

export enum BPMNEventCatchType {
  CATCHING = "CATCHING",
  THROWING = "THROWING",
}

export enum BPMNEventInterruptingType {
  INTERRUPTING = "INTERRUPTING",
  NON_INTERRUPTING = "NON_INTERRUPTING",
}

export class BPMNEvent extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.BPMNEvent",
      size: {
        width: 25,
        height: 25,
      },
      attrs: {
        outer: {
          stroke: "#020202",
          strokeWidth: 1,
          strokeDasharray: "1 0",
          refCx: "50%",
          refCy: "50%",
          refR: "50%",
          fill: "none",
        },
        middle: {
          stroke: "#020202",
          strokeWidth: 1,
          strokeDasharray: "1 0",
          refCx: "50%",
          refCy: "50%",
          refR: "40%",
          fill: "none",
          visibility: "hidden",
        },
        inner: {
          refCx: "50%",
          refCy: "50%",
          refR: "50%",
          fill: "#a2d96f",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        marker: {
          d: "",
          fill: "none",
          refX: "50%",
          refY: "50%",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "120%",
          fontSize: 14,
          fill: "#333333",
        },
      },
      eventInterruptingType: "INTERRUPTING",
      eventCatchType: "CATCHING",
      eventType: "START",
      eventMarker: "NONE",
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "circle",
        selector: "inner",
      },
      {
        tagName: "path",
        selector: "marker",
      },
      {
        tagName: "circle",
        selector: "middle",
      },
      {
        tagName: "circle",
        selector: "outer",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  setBPMNEventType = (type: BPMNEventType): BPMNEvent => {
    this.set("eventType", type);
    switch (type) {
      case BPMNEventType.START:
        this.attr({
          outer: { strokeWidth: 1 },
          middle: { visibility: "hidden" },
          inner: { fill: "#a2d96f" },
        });
        break;
      case BPMNEventType.INTERMEDIATE:
        this.attr({
          outer: { strokeWidth: 1 },
          middle: { visibility: "visible" },
          inner: { fill: "#ffb977" },
        });
        break;
      case BPMNEventType.END:
        this.attr({
          outer: { strokeWidth: 2 },
          middle: { visibility: "hidden" },
          inner: { fill: "#ff6870" },
        });
        break;
    }
    this.trigger("change:attribute", this);
    return this;
  };

  getBPMNEventTyoe = (): BPMNEventType => {
    return this.get("eventType");
  };

  setBPMNEventInterruptingType = (type: BPMNEventInterruptingType): BPMNEvent => {
    this.set("eventInterruptingType", type);
    switch (type) {
      case BPMNEventInterruptingType.INTERRUPTING:
        this.attr({
          outer: {
            strokeDasharray: "1 0",
          },
          middle: {
            strokeDasharray: "1 0",
          },
        });
        break;
      case BPMNEventInterruptingType.NON_INTERRUPTING:
        this.attr({
          outer: {
            strokeDasharray: "4 2",
          },
          middle: {
            strokeDasharray: "4 2",
          },
        });
        break;
    }
    this.trigger("change:attribute", this);
    return this;
  };

  getBPMNEventInterruptingType = (): BPMNEventInterruptingType => {
    return this.get("eventInterruptingType");
  };

  setBPMNEventCatchType = (type: BPMNEventCatchType): BPMNEvent => {
    this.set("eventCatchType", type);
    switch (type) {
      case BPMNEventCatchType.CATCHING:
        this.attr({
          marker: {
            fill: "none",
          },
        });
        break;
      case BPMNEventCatchType.THROWING:
        this.attr({
          marker: {
            fill: "#020202",
          },
        });
        break;
    }
    this.trigger("change:attribute", this);
    return this;
  };

  getBPMNEventCatchType = (): BPMNEventCatchType => {
    return this.get("eventCatchType");
  };

  setMarker = (marker: BPMNEventMarkerType): BPMNEvent => {
    this.set("eventMarker", marker);
    this.attr({
      marker: getMarkerMarkup(marker),
    });
    this.trigger("change:attribute", this);
    return this;
  };
}

function getMarkerMarkup(marker: BPMNEventMarkerType) {
  switch (marker) {
    case BPMNEventMarkerType.NONE:
    default:
      return {
        d: "",
      };
    case BPMNEventMarkerType.MESSAGE:
      return {
        d: "M 0 1 L 10 1 L 10 9 L 0 9 L 0 1 Z L 5 5 L 10 1",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.TIMER:
      return {
        d: "M 5 0 Q 10 0 10 5 Q 10 10 5 10 Q 0 10 0 5 Q 0 0 5 0 M 5 5 L 5 1 M 5 5 L 7 5",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.ERROR:
      return {
        d: "M 0 10 L 2 0 L 7 4 L 10 0 L 8 10 L 3 6 L 0 10",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.ESCALATION:
      return {
        d: "M 1 10 L 5 0 L 9 10 Q 5 4 1 10",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.CANCEL:
      return {
        d: "M 1 10 L 0 9 L 4 5 L 0 1 L 1 0 L 5 4 L 9 0 L 10 1 L 6 5 L 10 9 L 9 10 L 5 6 L 1 10",
        transform: "translate(-5 -5)",
        fill: "none",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.COMPENSATION:
      return {
        d: "M 0 5 L 5 0 L 5 10 L 0 5 M 5 5 L 10 0 L 10 10 L 5 5",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.LINK:
      return {
        d: "M 0 3 L 6 3 L 6 0 L 10 5 L 6 10 L 6 7 L 0 7 Z",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.SIGNAL:
      return {
        d: "M 0 10 L 5 0 L 10 10 L 0 10",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.MULTIPLE:
      return {
        d: "M 5 0 L 10 4 L 8 10 L 2 10 L 0 4 L 5 0",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.PARALLEL_MULTIPLE:
      return {
        d: "M 4 0 L 6 0 L 6 4 L 10 4 L 10 6 L 6 6 L 6 10 L 4 10 L 4 6 L 0 6 L 0 4 L 4 4 L 4 0",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.TERMINATE:
      return {
        d: "M 5 0 Q 10 0 10 5 Q 10 10 5 10 Q 0 10 0 5 Q 0 0 5 0",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
    case BPMNEventMarkerType.CONDITIONAL:
      return {
        d: "M 1 0 L 9 0 L 9 10 L 1 10 Z M 1 2 L 5 2 M 1 4 L 4 4 M 1 7 L 7 7 M 1 9 L 7 9",
        transform: "translate(-5 -5)",
        stroke: "#020202",
        strokeWidth: 1,
      };
  }
}
