import {
  KEAGraph,
  KEALink,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";
import { KEAUMLObject } from "../KEAUMLObject";

const object = new KEAUMLObject();
object.position(20, 10);

const link1 = new KEALink();
link1.source({ x: 20, y: 220 });
link1.target({ x: 200, y: 220 });
link1.setDasharray(LinkType.SOLID);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.NONE);

const link2 = new KEALink();
link2.source({ x: 20, y: 260 });
link2.target({ x: 200, y: 260 });
link2.setDasharray(LinkType.SOLID);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link1.clone();

export const getUMLObjectConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [object, link1, link2];
};

export const getUMLObjectMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLObjectConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.6,
    () => {
      return defaultLink;
    },
    conf,
  );
};
