import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLActivityNode extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLActivityNode",
      size: {
        width: 200,
        height: 100,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          strokeWidth: 2,
          rx: 10,
          ry: 10,
          stroke: "#004c93",
          fill: "#efe4bf",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "50%",
          fontSize: 14,
          fill: "#333333",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      minHeight: 50,
      minWidth: 100,
      resizeHeight: true,
      resizeWidth: true,
    });
  }

  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this.on("change:embeds", this.updatePinPosition);
    this.on("change:size", this.updatePinPosition);
  }

  updatePinPosition(): void {
    const ports = this.getEmbeddedCells().filter((cell) => cell.attributes.type === "kea.UMLPort");
    const width = this.get("size")!.width;
    const height = this.get("size")!.height;
    const position = this.get("position")!;
    const xLeft = position.x;
    const xRight = position.x + width;
    const yTop = position.y;
    const yBottom = position.y + height;

    ports.forEach((port) => {
      const portX = port.get("position")!.x;
      const portY = port.get("position")!.y;
      const distanceLeft = portX - xLeft;
      const distanceRight = xRight - portX;
      const distanceTop = portY - yTop;
      const distanceBottom = yBottom - portY;
      if (distanceLeft < distanceRight && distanceLeft < distanceTop && distanceLeft < distanceBottom) {
        port.set("position", { x: xLeft - 22, y: portY });
      } else if (distanceRight < distanceLeft && distanceRight < distanceTop && distanceRight < distanceBottom) {
        port.set("position", { x: xRight, y: portY });
      } else if (distanceTop < distanceLeft && distanceTop < distanceRight && distanceTop < distanceBottom) {
        port.set("position", { x: portX, y: yTop - 22 });
      } else if (distanceBottom < distanceLeft && distanceBottom < distanceRight && distanceBottom < distanceTop) {
        port.set("position", { x: portX, y: yBottom });
      }
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
