import * as joint from "jointjs";

export const bringToFrontTool = new joint.elementTools.Button({
  markup: [
    {
      tagName: "circle",
      selector: "button",
      attributes: {
        cx: 10,
        cy: 10,
        r: 10,
        fill: "#FFFFFF",
        cursor: "pointer",
      },
    },
    {
      tagName: "path",
      attributes: {
        fill: "none",
        stroke: "black",
        transform: "translate(10 10)",
        d: "M -5 0 L -5 -5 L 0 -5 M -3 -3 L 3 -3 L 3 3 L -3 3 Z M 5 0 L 5 5 L 0 5",
        cursor: "pointer",
      },
    },
  ],
  x: "50%",
  y: "100%",
  offset: { x: 40, y: 10 },
  action: function () {
    // @ts-ignore
    this.model.toFront();
  },
});
