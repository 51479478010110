import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLDestruction extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLDestruction",
      size: {
        width: 50,
        height: 50,
      },
      attrs: {
        body: {
          fill: "#333333",
          stroke: "#333333",
          strokeWidth: 5,
          d: "M 0, 0L calc(w), calc(h)M calc(w), 0 L 0, calc(h)",
        },
      },
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "path",
        selector: "body",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
