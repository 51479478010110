import {
  BPMNEvent,
  BPMNEventCatchType,
  BPMNEventInterruptingType,
  BPMNEventMarkerType,
  BPMNEventType,
  KEAElement,
  KEAGraph,
  KEALink,
  keaNamespace,
  LinkType,
  MarkerPosition,
  MarkerType,
} from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const event1 = new BPMNEvent().position(10, 10).setBPMNEventType(BPMNEventType.START);

const event2 = new BPMNEvent()
  .position(50, 10)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event3 = new BPMNEvent()
  .position(90, 10)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event4 = new BPMNEvent().position(130, 10).setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event5 = new BPMNEvent()
  .position(170, 10)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event6 = new BPMNEvent()
  .position(210, 10)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event7 = new BPMNEvent()
  .position(10, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventType(BPMNEventType.START);

const event8 = new BPMNEvent()
  .position(50, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event9 = new BPMNEvent()
  .position(90, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event10 = new BPMNEvent()
  .position(130, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event11 = new BPMNEvent()
  .position(170, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event12 = new BPMNEvent()
  .position(210, 50)
  .setMarker(BPMNEventMarkerType.MESSAGE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event13 = new BPMNEvent()
  .position(10, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventType(BPMNEventType.START);

const event14 = new BPMNEvent()
  .position(50, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event15 = new BPMNEvent()
  .position(90, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event16 = new BPMNEvent()
  .position(130, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event17 = new BPMNEvent()
  .position(170, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event18 = new BPMNEvent()
  .position(210, 90)
  .setMarker(BPMNEventMarkerType.TIMER)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event19 = new BPMNEvent()
  .position(10, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventType(BPMNEventType.START);

const event20 = new BPMNEvent()
  .position(50, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event21 = new BPMNEvent()
  .position(90, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event22 = new BPMNEvent()
  .position(130, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event23 = new BPMNEvent()
  .position(170, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event24 = new BPMNEvent()
  .position(210, 130)
  .setMarker(BPMNEventMarkerType.ERROR)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event25 = new BPMNEvent()
  .position(10, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventType(BPMNEventType.START);

const event26 = new BPMNEvent()
  .position(50, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event27 = new BPMNEvent()
  .position(90, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event28 = new BPMNEvent()
  .position(130, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event29 = new BPMNEvent()
  .position(170, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event30 = new BPMNEvent()
  .position(210, 170)
  .setMarker(BPMNEventMarkerType.ESCALATION)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event31 = new BPMNEvent()
  .position(10, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventType(BPMNEventType.START);

const event32 = new BPMNEvent()
  .position(50, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event33 = new BPMNEvent()
  .position(90, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event34 = new BPMNEvent()
  .position(130, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event35 = new BPMNEvent()
  .position(170, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event36 = new BPMNEvent()
  .position(210, 210)
  .setMarker(BPMNEventMarkerType.CANCEL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event37 = new BPMNEvent()
  .position(10, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventType(BPMNEventType.START);

const event38 = new BPMNEvent()
  .position(50, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event39 = new BPMNEvent()
  .position(90, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event40 = new BPMNEvent()
  .position(130, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event41 = new BPMNEvent()
  .position(170, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event42 = new BPMNEvent()
  .position(210, 250)
  .setMarker(BPMNEventMarkerType.COMPENSATION)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event43 = new BPMNEvent()
  .position(10, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventType(BPMNEventType.START);

const event44 = new BPMNEvent()
  .position(50, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event45 = new BPMNEvent()
  .position(90, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event46 = new BPMNEvent()
  .position(130, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event47 = new BPMNEvent()
  .position(170, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event48 = new BPMNEvent()
  .position(210, 290)
  .setMarker(BPMNEventMarkerType.LINK)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event49 = new BPMNEvent()
  .position(10, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventType(BPMNEventType.START);

const event50 = new BPMNEvent()
  .position(50, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event51 = new BPMNEvent()
  .position(90, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event52 = new BPMNEvent()
  .position(130, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event53 = new BPMNEvent()
  .position(170, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event54 = new BPMNEvent()
  .position(210, 330)
  .setMarker(BPMNEventMarkerType.SIGNAL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event55 = new BPMNEvent()
  .position(10, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventType(BPMNEventType.START);

const event56 = new BPMNEvent()
  .position(50, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event57 = new BPMNEvent()
  .position(90, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event58 = new BPMNEvent()
  .position(130, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event59 = new BPMNEvent()
  .position(170, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event60 = new BPMNEvent()
  .position(210, 370)
  .setMarker(BPMNEventMarkerType.TERMINATE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event61 = new BPMNEvent()
  .position(10, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventType(BPMNEventType.START);

const event62 = new BPMNEvent()
  .position(50, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event63 = new BPMNEvent()
  .position(90, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event64 = new BPMNEvent()
  .position(130, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event65 = new BPMNEvent()
  .position(170, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event66 = new BPMNEvent()
  .position(210, 410)
  .setMarker(BPMNEventMarkerType.MULTIPLE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event67 = new BPMNEvent()
  .position(10, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventType(BPMNEventType.START);

const event68 = new BPMNEvent()
  .position(50, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event69 = new BPMNEvent()
  .position(90, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event70 = new BPMNEvent()
  .position(130, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event71 = new BPMNEvent()
  .position(170, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event72 = new BPMNEvent()
  .position(210, 450)
  .setMarker(BPMNEventMarkerType.PARALLEL_MULTIPLE)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

const event73 = new BPMNEvent()
  .position(10, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventType(BPMNEventType.START);

const event74 = new BPMNEvent()
  .position(50, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.START);

const event75 = new BPMNEvent()
  .position(90, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventInterruptingType(BPMNEventInterruptingType.NON_INTERRUPTING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event76 = new BPMNEvent()
  .position(130, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event77 = new BPMNEvent()
  .position(170, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.INTERMEDIATE);

const event78 = new BPMNEvent()
  .position(210, 490)
  .setMarker(BPMNEventMarkerType.CONDITIONAL)
  .setBPMNEventCatchType(BPMNEventCatchType.THROWING)
  .setBPMNEventType(BPMNEventType.END);

let defaultLink = new KEALink();
defaultLink.setDasharray(LinkType.SOLID);
defaultLink.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
defaultLink.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

export const getEventConfiguration = (): Array<KEAElement> => {
  return [
    event1,
    event2,
    event3,
    event4,
    event5,
    event6,
    event7,
    event8,
    event9,
    event10,
    event11,
    event12,
    event13,
    event14,
    event15,
    event16,
    event17,
    event18,
    event19,
    event20,
    event21,
    event22,
    event23,
    event24,
    event25,
    event26,
    event27,
    event28,
    event29,
    event30,
    event31,
    event32,
    event33,
    event34,
    event35,
    event36,
    event37,
    event38,
    event39,
    event40,
    event41,
    event42,
    event43,
    event44,
    event45,
    event46,
    event47,
    event48,
    event49,
    event50,
    event51,
    event52,
    event53,
    event54,
    event55,
    event56,
    event57,
    event58,
    event59,
    event60,
    event61,
    event62,
    event63,
    event64,
    event65,
    event66,
    event67,
    event68,
    event69,
    event70,
    event71,
    event72,
    event73,
    event74,
    event75,
    event76,
    event77,
    event78,
  ];
};

export const getEventMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getEventConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
