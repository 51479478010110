import { createContext, useContext } from "react";

export const windowPadding = 0;

export type dimension = {
  width: number;
  height: number;
};

export interface IWindowSizeContext {
  dimension: dimension;
}

const defaultState: IWindowSizeContext = {
  dimension: { width: -1, height: -1 },
};

export const WindowSizeContext = createContext<IWindowSizeContext>(defaultState);
export const useWindowSizeContext = () => useContext(WindowSizeContext);
