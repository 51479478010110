import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const UndoButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    keaGraphContext.undo();
    keaGraphContext.addUserInteraction(InteractionType.UNDO, Date.now());
  };

  return (
    <KeaButton disabled={keaGraphContext.canUndo() ? false : true} onClick={handleClick}>
      <span className="icon is-small">
        <i className="fa fa-undo"></i>
      </span>
    </KeaButton>
  );
};
