import { ButtonGroup, Horizontal, Vertical } from "@kea-mod/common";
import { KEAGraph, KEAPaper } from "@kea-mod/jointjs";
import { IKEAGraphContext } from "context/KEAGraphContext";
import { getIsExportEnabled, getModelId, getShowButtons } from "helper/Helper";
import { MessageType } from "modals/SuccessModal";
import { Component } from "react";
import { APIManager } from "service/APIManager";
import { PreviewGraph } from "shared/PreviewGraph";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ModelExportButton } from "shared/buttons/ModelExportButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";

interface State {
  showButtons: boolean;
  isExportEnabled: boolean;
}

interface Props {
  width: number;
  height: number;
  modelingGraph: KEAGraph;
  modelingPaper: KEAPaper;
  apiManager: APIManager;
  toggleErrorModal: (errorMessageType?: MessageType) => void;
  keaGraphContext: IKEAGraphContext;
}

export class ContentView extends Component<Props, State> {
  state = {
    showButtons: false,
    isExportEnabled: false,
  };

  componentDidMount = () => {
    const showButtons = getShowButtons();
    this.setState({ showButtons });

    const isExportEnabled = getIsExportEnabled();
    this.setState({ isExportEnabled });

    const modelId = getModelId();
    if (modelId) {
      this.props.keaGraphContext.setModelLoaded(false);
      this.props.apiManager.getModel(modelId).then((result: any) => {
        if (result.success === true) {
          const graph = result.data.data.graphData.graph as KEAGraph;
          setTimeout(() => {
            this.props.keaGraphContext.loadModel(graph);
            this.props.modelingPaper.handleFit();
          }, 1000);
        } else {
          this.props.toggleErrorModal(MessageType.LOADING_ERROR);
        }
      });
    }
  };

  render() {
    return (
      <>
        <PreviewGraph width={this.props.width} height={this.props.height} />
        {this.state.showButtons && (
          <ButtonGroup
            alignHorizontal={Horizontal.RIGHT}
            alignVertical={Vertical.BOTTOM}
            verticalSpace="4rem"
            horizontalSpace="2rem"
          >
            <ZoomInButton />
            <ZoomOutButton />
            <FitModelButton />
            {this.state.isExportEnabled && <ModelExportButton />}
          </ButtonGroup>
        )}
      </>
    );
  }
}
