import {
  KEAGraph,
  KEALink,
  KEAPaper,
  KEAPaperBuilder,
  LinkType,
  MarkerPosition,
  MarkerType,
  PaperEventTypes,
  keaNamespace,
} from "@kea-mod/jointjs";
import { InteractionType, KEAGraphContext } from "context/KEAGraphContext";
import { Component, createRef } from "react";

const paperOptions: joint.dia.Paper.Options = {
  width: "100%",
  height: "100%",
  gridSize: 5,
  cellViewNamespace: keaNamespace,
  drawGrid: {
    name: "doubleMesh",
    args: [
      {
        color: "grey",
        thickness: 0.5,
      },
      {
        color: "#131313",
        scaleFactor: 16,
        thickness: 2,
      },
    ],
  },
  defaultConnectionPoint: { name: "boundary" },
  background: {
    color: "#EDF2FA",
  },
  linkPinning: true,
  validateConnection: function () {
    return true;
  },
  defaultAnchor: {
    name: "modelCenter",
    args: {
      rotate: true,
      padding: 20,
    },
  },
  interactive: { labelMove: false },
  defaultLink: () => {
    const link = new KEALink();
    link.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
    link.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.NONE);
    link.setDasharray(LinkType.SOLID);
    return link as joint.dia.Link;
  },
};

interface State {}

type Props = typeof IFramePrimaryGraph.defaultProps & {
  width: number;
  height: number;
  grid?: any;
  gridSize?: number;
  gridBackground?: string;
};

export class IFramePrimaryGraph extends Component<Props, State> {
  static defaultProps = {
    pushPresent: () => {},
    addUserInteraction: (
      _interactionType: InteractionType,
      _interactionTime: number,
      _payload?: KEAGraph | joint.dia.Link | joint.dia.Element | joint.dia.Cell[],
    ) => {},
    setNode: (_node: any) => {},
  };

  static contextType = KEAGraphContext;
  context!: React.ContextType<typeof KEAGraphContext>;

  private canvasContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.canvasContainerRef = createRef();
  }

  componentDidMount = () => {
    //this is a hacky, but works. Joint elements need to be in DOM to import new graphs. Otherwise, the scaling goes wrong. There is no way to guarantee, that the virtualDOM is indeed present in the actual DOM.
    //look at https://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(() => {
      this.context.setModelingPaper(this.initializePaper());
    }, 1000);
  };

  componentWillUnmount = () => {
    this.context.modelingPaper.removeDragPaperEvent();
    this.context.modelingPaper.removeCopyPasteMessageEvents();
    this.context.modelingPaper.removeDeleteEvent();
  };

  initializePaper = (): KEAPaper => {
    return new KEAPaperBuilder(
      this.canvasContainerRef,
      this.context.modelingGraph,
      paperOptions,
      this.props.grid,
      this.props.gridSize,
      this.props.gridBackground,
    )
      .historyClb(this.historyClb)
      .interactionClb(this.interactionClb)
      .nodeClb(this.nodeClb)
      .event(PaperEventTypes.SELECT)
      .event(PaperEventTypes.NODE_SELECT)
      .event(PaperEventTypes.PAPER_DRAG)
      .event(PaperEventTypes.PAPER_ZOOM)
      .event(PaperEventTypes.LINK_ELEMENT_TOOLS)
      .event(PaperEventTypes.NODE_ELEMENT_TOOLS)
      .event(PaperEventTypes.LINK_DRAG)
      .event(PaperEventTypes.NODE_EMBED)
      .event(PaperEventTypes.NODE_DELETE)
      .event(PaperEventTypes.MESSAGE_EVENT_NODE_COPY_PASTE)
      .event(PaperEventTypes.KEYBOARD_EVENT_NODE_COPY_PASTE)
      .build();
  };

  nodeClb = (node: joint.dia.Cell) => {
    this.props.setNode(node);
  };

  interactionClb = (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | joint.dia.Link | joint.dia.Element | joint.dia.Cell[],
  ) => {
    this.props.addUserInteraction(interactionType, interactionTime, payload);
  };

  historyClb = () => {
    this.props.pushPresent();
  };

  render() {
    return (
      <div style={{ width: this.props.width, height: this.props.height }} className="box m-0 p-0">
        <div id="canvas" ref={this.canvasContainerRef}></div>
      </div>
    );
  }
}
