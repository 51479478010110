import { util } from "jointjs";
import { getLabelMarkup, KEAElement, LabelPosition } from "./KEAElement";

export class KEAEPKOrganizationalUnit extends KEAElement {
  defaults() {
    return util.defaultsDeep(
      {
        ...super.defaults(),
        type: "kea.EPKOrganizationalUnit",
        size: {
          width: 200,
          height: 100,
        },
        attrs: {
          body: {
            refCx: "50%",
            refCy: "50%",
            refRx: "50%",
            refRy: "50%",
            strokeWidth: 2,
            stroke: "#333333",
            fill: "#fffd92",
          },
          stroke: {
            y1: "calc(0.2 * h)",
            x1: "calc(0.1 * w)",
            y2: "calc(0.8 * h)",
            x2: "calc(0.1 * w)",
            strokeWidth: 2,
            stroke: "#333333",
            fill: "#FFFFFF",
          },
          label: {
            textVerticalAnchor: "middle",
            textAnchor: "middle",
            refX: "50%",
            refY: "50%",
            text: "",
            fontSize: 14,
            fill: "#333333",
          },
          resize_border_right: {
            width: 10,
            refHeight: "100%",
            refX: "100%",
            refY: "0%",
            stroke: "none",
            fill: "none",
            cursor: "se-resize",
            pointerEvents: "visible",
            event: "keaelement:resize",
          },
          resize_border_bottom: {
            refWidth: "100%",
            height: 10,
            refX: "0%",
            refY: "100%",
            stroke: "none",
            fill: "none",
            cursor: "se-resize",
            pointerEvents: "visible",
            event: "keaelement:resize",
          },
        },
        minHeight: 100,
        minWidth: 200,
        resizeHeight: true,
        resizeWidth: true,
      },
      super.defaults,
    );
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangeLabelPosition(_element: KEAElement, property: string, _options: any): void {
    const labelPosition: LabelPosition = LabelPosition[property as keyof typeof LabelPosition];
    const markup = getLabelMarkup(labelPosition);
    this.attr({
      label: { ...markup },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "ellipse",
        selector: "body",
      },
      {
        tagName: "line",
        selector: "stroke",
      },

      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
