import { useLicenseModalContext } from "context/LicenseModalContext";
import { useTranslation } from "react-i18next";

interface Props {}

export const AboutLicensesFooter = (_props: Props) => {
  const licenseModalContext = useLicenseModalContext();
  const { t } = useTranslation();

  return (
    <div className="column has-text-centered">
      <a href="#/" onClick={licenseModalContext.toggleLicenseModal}>
        {t("footer_impressum")}
      </a>
    </div>
  );
};
