import { util } from "jointjs";
import { getLabelMarkup, KEAElement, LabelPosition } from "./KEAElement";

export class KEAUMLLifeLine extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLLifeLine",
      size: {
        width: 100,
        height: 100,
      },
      attrs: {
        header: {
          width: "calc(w)",
          height: 50,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#FFFFFF",
        },
        line: {
          stroke: "black",
          "stroke-dasharray": "5, 5",
          x1: "calc(0.5 * w)",
          y1: 0,
          x2: "calc(0.5 * w)",
          y2: "calc(h)",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          x: "calc(0.5 * w)",
          y: 25,
          fontSize: 14,
          fill: "#333333",
          text: ":",
        },
        resize_border_bottom: {
          refWidth: "20%",
          height: 10,
          x: "calc(0.4 * w)",
          y: "calc(h)",
          stroke: "none",
          fill: "none",
          cursor: "s-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      objectInstance: "",
      objectType: "",
      label: ":",
      labelPosition: "CENTER",
      secondBorder: "false",
      minHeight: 100,
      resizeHeight: true,
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      header: { stroke: property },
    });
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      header: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      header: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      header: { strokeWidth: property },
    });
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { fill: property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    if (!property.includes(":")) return;
    const [objectInstance, objectType] = property.split(" : ");
    this.attr({
      label: { text: property },
    });
    this.set("objectInstance", objectInstance);
    this.set("objectType", objectType);
    this.updateWidthByText();
  }

  onChangeLabelPosition(_element: KEAElement, property: string, _options: any): void {
    const labelPosition: LabelPosition = LabelPosition[property as keyof typeof LabelPosition];
    const markup = getLabelMarkup(labelPosition);
    this.attr({
      label: { ...markup },
    });
  }

  setObjectInstance = (objectInstance: string) => {
    this.set("objectInstance", objectInstance);
    this.set("label", `${objectInstance} : ${this.get("objectType")}`);
    this.updateWidthByText();
  };

  setObjectType = (objectType: string) => {
    this.set("objectType", objectType);
    this.set("label", `${this.get("objectInstance")} : ${objectType}`);
    this.updateWidthByText();
  };

  getObjectInstance = () => {
    return this.get("objectInstance");
  };

  getObjectType = () => {
    return this.get("objectType");
  };

  updateWidthByText = () => {
    const label = this.get("label");
    var labelWidth = label.length * 10;
    if (labelWidth < 100) labelWidth = 100;
    var height = this.get("size")?.height;
    if (!height) height = 50;
    this.resize(labelWidth, height);
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "g",
        selector: "body",
        children: [
          {
            tagName: "line",
            selector: "line",
          },
          {
            tagName: "rect",
            selector: "header",
          },
        ],
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
