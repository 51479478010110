import { UserSettingsButton } from "shared/buttons/UserSettingsButton";

interface Props {}

export const UserSettingsFooter = (_props: Props) => {
  return (
    <div className="column has-text-centered">
      <UserSettingsButton />
    </div>
  );
};
