import { debounce, WithChildren } from "@kea-mod/common";
import { dimension, WindowSizeContext } from "./WindowSizeContext";

import { Component } from "react";

interface State {
  dimension: dimension;
}

interface Props extends WithChildren {}

export class WindowSizeContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dimension: { width: -1, height: -1 },
    };
  }

  updateDimension = () => {
    this.setState({
      dimension: { width: window.innerWidth, height: window.innerHeight },
    });
  };

  componentDidMount = () => {
    this.updateDimension();
    window.addEventListener("resize", debounce(this.updateDimension, 200));
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", debounce(this.updateDimension, 200));
  };

  render() {
    return (
      <WindowSizeContext.Provider
        value={{
          dimension: this.state.dimension,
        }}
      >
        {this.props.children}
      </WindowSizeContext.Provider>
    );
  }
}
