import { WithChildren } from "@kea-mod/common";
import { UserSettingsModal } from "modals/UserSettingsModal";
import { Component } from "react";
import { UserSettingsModalContext } from "./UserSettingsModalContext";

interface State {
  settingsModalVisible: boolean;
}

interface Props extends WithChildren {}

export class UserSettingsModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      settingsModalVisible: false,
    };
  }

  setIsActiveSettingsModal = (isActive: boolean) => {
    this.setState({
      settingsModalVisible: isActive,
    });
  };

  render() {
    return (
      <UserSettingsModalContext.Provider
        value={{
          setIsActiveSettingsModal: this.setIsActiveSettingsModal,
        }}
      >
        {this.props.children}
        <UserSettingsModal isActive={this.state.settingsModalVisible} setIsActive={this.setIsActiveSettingsModal} />
      </UserSettingsModalContext.Provider>
    );
  }
}
