import { shapes } from "jointjs";
import { KEABPMNActivity } from "./KEABPMNActivity";
import { BPMNDatastore } from "./KEABPMNDatastore";
import { BPMNEvent } from "./KEABPMNEvent";
import { BPMNGateway } from "./KEABPMNGateway";
import { KEABPMNSwimlane } from "./KEABPMNSwimlane";
import { KEABPMNSwimlaneView } from "./KEABPMNSwimlaneView";
import { KEACircle } from "./KEACircle";
import { KEAEPKConnector } from "./KEAEPKConnector";
import { KEAEPKEvent } from "./KEAEPKEvent";
import { KEAEPKFunction } from "./KEAEPKFunction";
import { KEAEPKInformationObject } from "./KEAEPKInformationObject";
import { KEAEPKOrganizationalUnit } from "./KEAEPKOrganizationalUnit";
import { KEAEllipse } from "./KEAEllipse";
import { KEALink } from "./KEALink";
import { KEAPNMarker } from "./KEAPNMarker";
import { KEAPolygon } from "./KEAPolygon";
import { KEARectangle } from "./KEARectangle";
import { KEAUMLActivityBar } from "./KEAUMLActivityBar";
import { KEAUMLActivityNode } from "./KEAUMLActivityNode";
import { KEAUMLArea } from "./KEAUMLArea";
import { KEAUMLCircleState } from "./KEAUMLCircleState";
import { KEAUMLClass } from "./KEAUMLClass";
import { KEAUMLClassView } from "./KEAUMLClassView";
import { KEAUMLComplexState } from "./KEAUMLComplexState";
import { KEAUMLContinuation } from "./KEAUMLContinuation";
import { KEAUMLDestruction } from "./KEAUMLDestruction";
import { KEAUMLForkAndJoin } from "./KEAUMLForkAndJoin";
import { KEAUMLFrame } from "./KEAUMLFrame";
import { KEAUMLLifeLine } from "./KEAUMLLifeLine";
import { KEAUMLReceiveSymbol } from "./KEAUMLReceiveSymbol";
import { KEAUMLSendSymbol } from "./KEAUMLSendSymbol";
import { KEAUMLSimpleState } from "./KEAUMLSimpleState";
import { KEAUMLStateInvariant } from "./KEAUMLStateInvariant";
import { UMLSeperator } from "./UMLSeparator";
import { KEAUMLObject } from "./KEAUMLObject";
import { KEAUMLObjectView } from "./KEAUMLObjectView";
import { KEAUMLComponent } from "./uml_component/KEAUMLComponent";
import { KEAUMLPort } from "./uml_component/KEAUMLPort";

export const keaNamespace = {
  kea: {
    BPMNSwimlane: KEABPMNSwimlane,
    BPMNSwimlaneView: KEABPMNSwimlaneView,
    BPMNActivity: KEABPMNActivity,
    BPMNDatastore: BPMNDatastore,
    BPMNEvent: BPMNEvent,
    BPMNGateway: BPMNGateway,
    Link: KEALink,
    Circle: KEACircle,
    Polygon: KEAPolygon,
    Ellipse: KEAEllipse,
    Rectangle: KEARectangle,
    UMLClass: KEAUMLClass,
    UMLClassView: KEAUMLClassView,
    UMLObject: KEAUMLObject,
    UMLObjectView: KEAUMLObjectView,
    UMLComplexState: KEAUMLComplexState,
    UMLCircleState: KEAUMLCircleState,
    UMLSimpleState: KEAUMLSimpleState,
    UMLSendSymbol: KEAUMLSendSymbol,
    UMLReceiveSymbol: KEAUMLReceiveSymbol,
    EPKEvent: KEAEPKEvent,
    EPKFunction: KEAEPKFunction,
    EPKConnector: KEAEPKConnector,
    EPKOrganizationalUnit: KEAEPKOrganizationalUnit,
    EPKInformationObject: KEAEPKInformationObject,
    UMLActivityNode: KEAUMLActivityNode,
    UMLArea: KEAUMLArea,
    UMLForkAndJoin: KEAUMLForkAndJoin,
    PNMarker: KEAPNMarker,
    UMLActivityBar: KEAUMLActivityBar,
    UMLLifeLine: KEAUMLLifeLine,
    UMLDestruction: KEAUMLDestruction,
    UMLFrame: KEAUMLFrame,
    UMLStateInvariant: KEAUMLStateInvariant,
    UMLContinuation: KEAUMLContinuation,
    UMLComponent: KEAUMLComponent,
    UMLPort: KEAUMLPort,
  },
  standard: {
    Rectangle: shapes.standard.Rectangle,
    Circle: shapes.standard.Circle,
    Ellipse: shapes.standard.Ellipse,
    Polygon: shapes.standard.Polygon,
  },
  pn: {
    Place: shapes.pn.Place,
    Transition: shapes.pn.Transition,
  },
  uml: {
    UMLSeperator: UMLSeperator,
    StartState: shapes.uml.StartState,
    State: shapes.uml.State,
    EndState: shapes.uml.EndState,
  },
};
