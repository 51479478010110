import { KEAUMLClass, UMLAttribute, UMLVisibility } from "@kea-mod/jointjs";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  attributes: UMLAttribute[];
  setAttributes: (attributes: UMLAttribute[]) => void;
  deleteAttribute: (index: number) => void;
};

export const EditUMLClassModalAttributesForm = (props: Props) => {
  const { t } = useTranslation();

  const handleVisibilityChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].visibility = event.target.value as UMLVisibility;
    //(props.node as KEAUMLClass).updateAttribute(newState[index], index);
    props.setAttributes(newState);
  };

  const handleAttributNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].attributeName = event.target.value;
    //(props.node as KEAUMLClass).updateAttribute(newState[index], index);
    props.setAttributes(newState);
  };

  const handleAttributTypeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].attributeType = event.target.value;
    //(props.node as KEAUMLClass).updateAttribute(newState[index], index);
    props.setAttributes(newState);
  };

  const handleAttributMultiplicityChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].multiplicity = event.target.value;
    //(props.node as KEAUMLClass).updateAttribute(newState[index], index);
    props.setAttributes(newState);
  };

  const handleIsStaticChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].isStatic = !newState[index].isStatic;
    props.setAttributes(newState);
  };

  const handleAttributDefaultValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].defaultValue = event.target.value;
    //(props.node as KEAUMLClass).updateAttribute(newState[index], index);
    props.setAttributes(newState);
  };

  const handleIsCompositeChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].properties.isComposite = !newState[index].properties.isComposite;
    props.setAttributes(newState);
  };

  const handleIsDerivedChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].properties.isDerived = !newState[index].properties.isDerived;
    props.setAttributes(newState);
  };

  const handleIsDerivedUnionChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].properties.isDerivedUnion = !newState[index].properties.isDerivedUnion;
    props.setAttributes(newState);
  };

  const handleIsReadOnlyChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].properties.isReadOnly = !newState[index].properties.isReadOnly;
    props.setAttributes(newState);
  };

  const handleIsUniqueChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.attributes];
    newState[index].properties.isUnique = !newState[index].properties.isUnique;
    props.setAttributes(newState);
  };

  const renderAttribute = (attribute: UMLAttribute, index: number) => {
    return (
      <div key={index}>
        {index < props.attributes.length && (
          <div className="divider is-info is-light">{KEAUMLClass.getAttributeString(attribute)}</div>
        )}

        <div className="field is-grouped">
          <div className="control">
            <label className="label is-small">{t("uml_attributes_modal_visibility")}</label>
            <div className="select is-fullwidth">
              <select value={attribute.visibility} onChange={(event) => handleVisibilityChange(event, index)}>
                <option value={UMLVisibility.none}>&nbsp;</option>
                <option value={UMLVisibility.public}>+</option>
                <option value={UMLVisibility.package}>~</option>
                <option value={UMLVisibility.protected}>#</option>
                <option value={UMLVisibility.private}>-</option>
              </select>
            </div>
          </div>
          <div className="control is-expanded">
            <label className="label is-small">{t("uml_attributes_modal_attributename")}</label>
            <input
              className="input"
              type="text"
              placeholder={t("uml_attributes_modal_attributename") as string}
              value={attribute.attributeName}
              onChange={(event) => handleAttributNameChange(event, index)}
            />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <label className="label is-small">{t("uml_attributes_modal_multiplicity")}</label>
            <input
              className="input"
              type="text"
              placeholder={t("uml_attributes_modal_multiplicity") as string}
              value={attribute.multiplicity}
              onChange={(event) => handleAttributMultiplicityChange(event, index)}
            />
          </div>
          <div className="control is-expanded">
            <label className="label is-small">{t("uml_attributes_modal_type")}</label>
            <input
              className="input"
              type="text"
              placeholder={t("uml_attributes_modal_type") as string}
              value={attribute.attributeType}
              onChange={(event) => handleAttributTypeChange(event, index)}
            />
          </div>
        </div>

        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              type="text"
              value={attribute.defaultValue}
              onChange={(event) => handleAttributDefaultValueChange(event, index)}
              placeholder={t("uml_attributes_modal_value") as string}
            />
          </div>
          <div className="control">
            <button
              type="button"
              className="button is-danger"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                props.deleteAttribute(index);
              }}
            >
              {t("uml_attributes_modal_delete")}
            </button>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <label className="checkbox">
              <input checked={attribute.isStatic} onChange={(e) => handleIsStaticChange(e, index)} type="checkbox" />
              Is Static
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={attribute.properties.isComposite}
                onChange={(e) => handleIsCompositeChange(e, index)}
                type="checkbox"
              />
              Is Composite
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={attribute.properties.isDerived}
                onChange={(e) => handleIsDerivedChange(e, index)}
                type="checkbox"
              />
              Is Derived
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={attribute.properties.isDerivedUnion}
                onChange={(e) => handleIsDerivedUnionChange(e, index)}
                type="checkbox"
              />
              Is Derived Union
            </label>
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <label className="checkbox">
              <input
                checked={attribute.properties.isReadOnly}
                onChange={(e) => handleIsReadOnlyChange(e, index)}
                type="checkbox"
              />
              Is Readonly
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={attribute.properties.isUnique}
                onChange={(e) => handleIsUniqueChange(e, index)}
                type="checkbox"
              />
              Is Unique
            </label>
          </div>
        </div>
      </div>
    );
  };

  if (props.attributes.length === 0) {
    return <div className="block">{t("uml_attributes_modal_noattributes")}</div>;
  } else {
    return <>{props.attributes.map((attribute, index) => renderAttribute(attribute, index))}</>;
  }
};
