import { MessageType } from "modals/SuccessModal";
import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("ErrorMessageContext");
};

interface IErrorMessageContext {
  errorModalMessageType: MessageType;
  setErrorModalMessageType: (message: MessageType) => void;
  toggleErrorModal: (errorMessageType?: MessageType) => void;
}

const defaultState: IErrorMessageContext = {
  errorModalMessageType: MessageType.LOADING_ERROR,
  setErrorModalMessageType: () => printOutsideContext(),
  toggleErrorModal: () => printOutsideContext(),
};

export const ErrorMessageContext = createContext<IErrorMessageContext>(defaultState);
export const useErrorMessageContext = () => useContext(ErrorMessageContext);
