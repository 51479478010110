import { useAPIContext } from "context/APIContext";
import { useAssessmentContext } from "context/AssessmentContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useNameModelModalContext } from "context/NameModelModalContext";
import { useTranslation } from "react-i18next";
import { KeaButton } from "./KEAButton";

type Props = {};

export const UpdateModelButton = (_props: Props) => {
  const nameModelModalContext = useNameModelModalContext();
  const assessmentContext = useAssessmentContext();
  const keaGraphContext = useKEAGraphContext();
  const apiContext = useAPIContext();

  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (assessmentContext.modelId !== undefined) {
      nameModelModalContext.putModel(
        keaGraphContext.modelingGraph,
        nameModelModalContext.name,
        keaGraphContext.getUserInteractions(),
        apiContext.apiManager,
        assessmentContext.modelId,
      );
    }
  };

  return <KeaButton onClick={handleClick}>{t("save")}</KeaButton>;
};
