import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLArea extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLArea",
      size: {
        width: 200,
        height: 100,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          rx: 5,
          ry: 5,
          strokeWidth: 2,
          stroke: "#000000",
          strokeDasharray: "5",
          fill: "#FFFFFF",
          fillOpacity: 0,
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      minWidth: 200,
      minHeight: 100,
      resizeHeight: true,
      resizeWidth: true,
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
