import { util } from "jointjs";
import { getLabelMarkup, KEAElement, LabelPosition } from "./KEAElement";

export class KEAPolygon extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.Polygon",
      size: {
        width: 100,
        height: 100,
      },
      attrs: {
        body: {
          fill: "#FFFFFF",
          stroke: "#333333",
          strokeWidth: 2,
          d: "M calc(0.5 * w) 0 calc(w) calc(0.5 * h) calc(0.5 * w) calc(h) 0 calc(0.5 * h) Z",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          fontSize: 14,
          text: "",
          x: "calc(0.5 * w)",
          y: "calc(0.5 * h)",
          fill: "#333333",
        },
        border: {
          fill: "none",
          stroke: "#333333",
          strokeWidth: 1,
          d: "M calc(0.5 * w) 6 calc(w - 6) calc(0.5 * h) calc(0.5 * w) calc(h - 6) 6 calc(0.5 * h) Z",
          visibility: "hidden",
        },
        resize_border_right: {
          width: 10,
          height: "calc(h)",
          x: "calc(w)",
          y: "0",
          stroke: "none",
          fill: "none",
          cursor: "default",
          pointerEvents: "visible",
          event: undefined,
        },
        resize_border_bottom: {
          height: 10,
          width: "calc(w)",
          x: "0",
          y: "calc(h)",
          stroke: "none",
          fill: "none",
          cursor: "default",
          pointerEvents: "visible",
          event: undefined,
        },
      },
      secondBorder: false,
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
    if (this.get("secondBorder")) {
      this.attr({
        border: { stroke: property },
      });
    }
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
    });
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { fill: property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: property },
    });
  }

  onChangeLabelPosition(_element: KEAElement, property: string, _options: any): void {
    const labelPosition: LabelPosition = LabelPosition[property as keyof typeof LabelPosition];
    const markup = getLabelMarkup(labelPosition);
    this.attr({
      label: { ...markup },
    });
  }

  setHasSecondBorder = (value: boolean): KEAPolygon => {
    this.set("secondBorder", value);
    if (value === false) {
      this.attr({
        border: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        border: {
          visibility: "visible",
        },
      });
    }
    return this;
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "path",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.get("secondBorder")) {
      markup.push({
        tagName: "path",
        selector: "border",
      });
    }
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
