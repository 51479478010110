import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLForkAndJoin extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLForkAndJoin",
      size: {
        width: 10,
        height: 100,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#000000",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "s-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      rotatable: true,
      minHeight: 80,
      resizeHeight: true,
      resizeWidth: false,
    });
  }

  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this.on("keaelement:rotate", this.rotateListener, this);
  }

  rotateListener = () => {
    switch (this.angle()) {
      case 90:
        this.attr({
          resize_border_bottom: {
            height: 10,
            refWidth: "100%",
            refX: "0%",
            refY: "-10%",
            cursor: "e-resize",
          },
        });
        break;
      case 180:
        this.attr({
          resize_border_bottom: {
            height: 10,
            refWidth: "100%",
            refX: "0%",
            refY: "-10%",
            cursor: "s-resize",
          },
        });
        break;
      case 270:
        this.attr({
          resize_border_bottom: {
            height: 10,
            refWidth: "100%",
            refX: "0%",
            refY: "100%",
            cursor: "e-resize",
          },
        });
        break;
      default:
        this.attr({
          resize_border_bottom: {
            refWidth: "100%",
            height: 10,
            refX: "0%",
            refY: "100%",
            cursor: "s-resize",
          },
        });
        break;
    }
  };

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property, fill: property },
    });
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, _property: string, _options: any): void {}

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
