import { useKEAGraphContext } from "context/KEAGraphContext";

interface KeaButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick: (e: any) => void | undefined;
  disabled?: boolean;
}

export const KeaButton = (props: KeaButtonProps) => {
  const keaGraphContext = useKEAGraphContext();
  return (
    <button
      type="button"
      className={props.className || "button is-primary is-rounded"}
      onClick={props.onClick}
      disabled={!keaGraphContext.modelLoaded || props.disabled}
    >
      {props.children}
    </button>
  );
};
