import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export class KEAUMLActivityBar extends KEAElement {
  defaults() {
    return util.defaultsDeep(
      {
        ...super.defaults(),
        type: "kea.UMLActivityBar",
        size: {
          width: 25,
          height: 165,
        },
        attrs: {
          body: {
            refWidth: "100%",
            refHeight: "100%",
            strokeWidth: 2,
            stroke: "#000000",
            fill: "#FFFFFF",
          },
          border: {
            width: "calc(0.96 * w)",
            height: "calc(0.92 * h)",
            x: "calc(0.02 * w)",
            y: "calc(0.04 * h)",
            stroke: "#000000",
            strokeWidth: 2,
            fill: "none",
            visibility: "hidden",
          },
          resize_border_bottom: {
            refWidth: "100%",
            height: 10,
            refX: "0%",
            refY: "100%",
            stroke: "none",
            fill: "none",
            cursor: "se-resize",
            pointerEvents: "visible",
            event: "keaelement:resize",
          },
        },
        minHeight: 40,
        resizeHeight: true,
        resizeWidth: false,
      },
      super.defaults,
    );
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "rect",
        selector: "border",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
