import raw from "raw.macro";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(): void;
  isActive: boolean;
}

export const LicenseModal = (props: Props) => {
  const { t } = useTranslation();
  const licenses = raw("./licenses.txt");

  const setIsActiveWrapper = useCallback((): void => {
    props.setisActive();
  }, [props]);

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={props.isActive}
      header={t("footer_impressum") as string}
      body={
        <div className="content">
          <div className="columns">
            <div className="column">
              <h2>Angaben gemäß § 5 TMG</h2>
            </div>
          </div>
          <div className="columns">
            <div className="column">Anschrift...</div>
            <div className="column">Vertreten durch...</div>
          </div>
          <div className="columns">
            <div className="column">Kontakt</div>
          </div>
          <div className="columns">
            <div className="column">Anschrift</div>
          </div>
          <div className="columns">
            <div className="column">
              <small>{licenses}</small>
            </div>
          </div>
        </div>
      }
      footer={<></>}
    />
  );
};
