import { Navigate, Route, Routes } from "react-router-dom";

import { ExcersisesContext } from "context/routecontext/ExcercisesContext";
import { FeedbackContext } from "context/routecontext/FeedbackContext";
import { StandaloneContext } from "context/routecontext/StandaloneContext";
import { AppEditor } from "pages/app/AppEditor";
import { AssessmentEditor } from "pages/assessment/AssessmentEditor";
import { FeedbackEditor } from "pages/feedback/FeedbackEditor";
import { SampleFeedbackEditor } from "pages/feedback/sample/SampleFeedbackEditor";
import { ModelRoutes } from "./ModelRoutes";

interface Props {}

export function AppRoutes({}: Props) {
  return (
    <Routes>
      <Route path={"/models/*"} element={<ModelRoutes />} />

      <Route
        path={"/exercises"}
        element={
          <ExcersisesContext>
            <AssessmentEditor />
          </ExcersisesContext>
        }
      />

      <Route
        path={"/feedback"}
        element={
          <FeedbackContext>
            <FeedbackEditor />
          </FeedbackContext>
        }
      />

      <Route
        path={"/sample-feedback"}
        element={
          <FeedbackContext>
            <SampleFeedbackEditor />
          </FeedbackContext>
        }
      />

      <Route
        path="/app"
        element={
          <StandaloneContext>
            <AppEditor />
          </StandaloneContext>
        }
      />

      <Route path="/" element={<Navigate to="/app" replace />} />
    </Routes>
  );
}
