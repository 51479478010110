import { AppContext } from "context/AppContext";
import "jointjs/css/layout.css";
import "jointjs/css/themes/default.css";
import "jointjs/dist/joint.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "routes/AppRoutes";
import "./App.css";

export const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    function receiveMessage(event: any) {
      if (event.data) {
        if (event.data.type === "language") {
          event.data.language === "de" ? i18n.changeLanguage("de") : void 0;
          event.data.language === "en" ? i18n.changeLanguage("en") : void 0;
        }
      }
    }
    window.addEventListener("message", receiveMessage);
    return function cleanup() {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div id="editor-wrapper" className="App" data-color-mode="light">
        <AppContext>
          <AppRoutes />
        </AppContext>
      </div>
    </Router>
  );
};
