import {
  KEACircle,
  KEAElement,
  KEAEllipse,
  KEAGraph,
  KEALink,
  keaNamespace,
  KEAPNMarker,
  KEAPolygon,
  KEARectangle,
  LabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
} from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const rectangle1 = new KEARectangle();
rectangle1.size(75, 50);
rectangle1.position(25, 25);
rectangle1.setLabelPosition(LabelPosition.BELOW);

const rectangle2 = new KEARectangle();
rectangle2.size(15, 50);
rectangle2.position(125, 25);
rectangle2.setPrimaryColor("#333333");
rectangle2.setLabelPosition(LabelPosition.BELOW);

const circle1 = new KEACircle();
circle1.position(25, 100);
circle1.size(50, 50);
circle1.setLabelPosition(LabelPosition.BELOW);

const circle2 = new KEAPNMarker();
circle2.position(125, 195);
circle2.setLabelPosition(LabelPosition.BELOW);

const ellipse = new KEAEllipse();
ellipse.size(75, 50);
ellipse.position(25, 175);
ellipse.setLabelPosition(LabelPosition.BELOW);

const polygon = new KEAPolygon();
polygon.position(100, 100);
polygon.size(50, 50);
polygon.setLabelPosition(LabelPosition.BELOW);

const link1 = new KEALink();
link1.source({ x: 20, y: 300 });
link1.target({ x: 200, y: 300 });
link1.setDasharray(LinkType.SOLID);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.NONE);

const link2 = new KEALink();
link2.source({ x: 20, y: 350 });
link2.target({ x: 200, y: 350 });
link2.setDasharray(LinkType.SOLID);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

const link3 = new KEALink();
link3.source({ x: 20, y: 400 });
link3.target({ x: 200, y: 400 });
link3.setDasharray(LinkType.SOLID);
link3.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.ARROW);
link3.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link1.clone();

export const getPetriNetConfiguration = (): Array<KEAElement | KEALink> => {
  return [rectangle1, rectangle2, circle1, circle2, ellipse, polygon, link1, link2, link3];
};

export const getPetriNetMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getPetriNetConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
