import { getFeedbackHighlightColor } from "helper/Helper";
import { FeedbackMessage } from "tasks/sample_feedback";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  feedback: Array<FeedbackMessage>;
}

export const FeedbackModalCard = (props: Props) => {
  return (
    <div
      className="box"
      style={{
        position: "absolute",
        left: "20px",
        bottom: "20px",
        display: props.isActive ? "inline" : "none",
      }}
    >
      {props.feedback.map((element, id) => {
        return (
          <p className="mb-2" style={{ color: getFeedbackHighlightColor(element.valuation) }} key={id}>
            {element.category}
            <br />
            {element.content}
            <br />
          </p>
        );
      })}
    </div>
  );
};
