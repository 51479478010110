import { ReactNode, useCallback, useRef } from "react";
import { DragBorder } from "./DragBorder";
import { ResizeBorder } from "./ResizeBorder";

interface Props {
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  minHeight: number;
  minWidth: number;
  visible: boolean;
  setPositionX: (positionX: number) => void;
  setPositionY: (positionY: number) => void;
  setWidth: (width: number) => void;
  setHeight: (height: number) => void;
}

export const ResizableCard = ({
  header,
  body,
  footer,
  positionX,
  positionY,
  width,
  height,
  minHeight,
  minWidth,
  visible,
  setPositionX,
  setPositionY,
  setWidth,
  setHeight,
}: Props) => {
  const setPositionWithBoundary = useCallback(
    (new_position: joint.dia.Point) => {
      const footerHeight = document.querySelector("footer")?.offsetHeight ?? 0;
      const topLeftBorder = { x: 10, y: 10 };
      const bottomRightBorder = { x: window.innerWidth - 20, y: window.innerHeight - footerHeight - 10 };
      if (new_position.x <= topLeftBorder.x) {
        new_position.x = topLeftBorder.x;
      }
      if (new_position.y <= topLeftBorder.y) {
        new_position.y = topLeftBorder.y;
      }
      if (new_position.x + width >= bottomRightBorder.x) {
        new_position.x = bottomRightBorder.x - width;
      }
      if (new_position.y + height >= bottomRightBorder.y) {
        new_position.y = bottomRightBorder.y - height;
      }
      setPositionX(new_position.x);
      setPositionY(new_position.y);
    },
    [width, height, setPositionX, setPositionY],
  );

  const setWidthWidthBoundary = useCallback(
    (new_width: number) => {
      const footerHeight = document.querySelector("footer")?.offsetHeight ?? 0;
      const bottomRightBorder = { x: window.innerWidth - 20, y: window.innerHeight - footerHeight - 10 };
      if (new_width <= minWidth) {
        new_width = minWidth;
      }
      if (new_width + positionX >= bottomRightBorder.x) {
        new_width = bottomRightBorder.x - positionX;
      }

      setWidth(new_width);
    },
    [minWidth, positionX, setWidth],
  );

  const setHeightWithBoundary = useCallback(
    (new_height: number) => {
      const footerHeight = document.querySelector("footer")?.offsetHeight ?? 0;
      const bottomRightBorder = { x: window.innerWidth - 20, y: window.innerHeight - footerHeight - 10 };
      if (new_height <= minHeight) {
        new_height = minHeight;
      }
      if (new_height + positionY >= bottomRightBorder.y) {
        new_height = bottomRightBorder.y - positionY;
      }
      setHeight(new_height);
    },
    [minHeight, positionY, setHeight],
  );

  const ref = useRef(null);

  return (
    <div style={{ display: visible ? "inline" : "none" }}>
      <div
        className="card"
        ref={ref}
        style={{
          position: "absolute",
          left: positionX,
          top: positionY,
          width: width,
          height: height,
          overflowY: "hidden",
        }}
      >
        <header className="card-header">{header}</header>
        {body}
        {footer && <footer className="card-footer">{footer}</footer>}
      </div>
      <DragBorder x={positionX} y={positionY} height={height} setPosition={setPositionWithBoundary} />
      <ResizeBorder
        x={positionX}
        y={positionY}
        width={width}
        height={height}
        setWidth={setWidthWidthBoundary}
        setHeight={setHeightWithBoundary}
      />
    </div>
  );
};
