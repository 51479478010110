import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditUMLLifeLineContext");
};

interface IEditUMLLifeLineContext {
  setNode: (node: joint.dia.Cell) => void;
}

const defaultState: IEditUMLLifeLineContext = {
  setNode: () => printOutsideContext(),
};

export const EditUMLLifeLineContext = createContext<IEditUMLLifeLineContext>(defaultState);
export const useEditUMLLifeLineContext = () => useContext(EditUMLLifeLineContext);
