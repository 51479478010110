import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditPositionLabelContext");
};

interface IEditPositionLabelContext {
  setNode: (node: joint.dia.Element) => void;
}

const defaultState: IEditPositionLabelContext = {
  setNode: () => printOutsideContext(),
};

export const EditPositionLabelContext = createContext<IEditPositionLabelContext>(defaultState);
export const useEditPositionLabelContext = () => useContext(EditPositionLabelContext);
