import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditLabelContext");
};

interface IEditLabelContext {
  setNode: (node: joint.dia.Element) => void;
}

const defaultState: IEditLabelContext = {
  setNode: () => printOutsideContext(),
};

export const EditLabelContext = createContext<IEditLabelContext>(defaultState);
export const useEditLabelContext = () => useContext(EditLabelContext);
