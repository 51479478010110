import { ModelingLanguage } from "@kea-mod/common";
import { createContext, useContext } from "react";

interface IModelingLanguageContext {
  modelingLanguage: ModelingLanguage;
  setModelingLanguage: (language: ModelingLanguage) => void;
  userCanChangeModelingLanguage: boolean;
  setUserCanChangeModelingLanguage: (value: boolean) => void;
}

const defaultState: IModelingLanguageContext = {
  modelingLanguage: ModelingLanguage.NONE,
  setModelingLanguage: () => {
    console.warn("ModelingLanguageContext: not in scope.");
  },
  userCanChangeModelingLanguage: false,
  setUserCanChangeModelingLanguage: () => {
    console.warn("ModelingLanguageContext: not in scope.");
  },
};

export const ModelingLanguageContext = createContext<IModelingLanguageContext>(defaultState);
export const useModelingLanguageContext = () => useContext(ModelingLanguageContext);
