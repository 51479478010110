import { StandaloneContext } from "context/routecontext/StandaloneContext";
import { CreateModelEditor } from "pages/model/create/CreateModelEditor";
import { PreviewModelEditor } from "pages/model/preview/PreviewModelEditor";
import { PutModelEditor } from "pages/model/update/PutModelEditor";
import { Route, Routes } from "react-router-dom";

interface Props {}

export const ModelRoutes = (_props: Props) => {
  return (
    <Routes>
      <Route
        path="create"
        element={
          <StandaloneContext>
            <CreateModelEditor />
          </StandaloneContext>
        }
      />

      <Route
        path="update"
        element={
          <StandaloneContext>
            <PutModelEditor />
          </StandaloneContext>
        }
      />

      <Route path="preview" element={<PreviewModelEditor />} />
    </Routes>
  );
};
