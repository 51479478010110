import { InteractionType, KEAElement, KEAGraph, KEALink, KEAPaper, keaNamespace } from "@kea-mod/jointjs";
import * as joint from "jointjs";

export enum PaperEventTypes {
  SELECT = "SELECT",
  PAPER_DRAG = "PAPER_DRAG",
  PAPER_ZOOM = "PAPER_ZOOM",
  NODE_SELECT = "NODE_SELECT",
  NODE_EMBED = "NODE_EMBED",
  NODE_DRAG_AND_DROP = "NODE_DRAG_AND_DROP",
  NODE_DRAG_AND_DROP_AND_HOVER = "NODE_DRAG_AND_DROP_AND_HOVER",
  KEYBOARD_EVENT_NODE_COPY_PASTE = "KEYBOARD_EVENT_NODE_COPY_PASTE",
  MESSAGE_EVENT_NODE_COPY_PASTE = "MESSAGE_EVENT_NODE_COPY_PASTE",
  NODE_ELEMENT_TOOLS = "NODE_ELEMENT_TOOLS",
  NODE_DELETE = "NODE_DELETE",
  LINK_SELECT = "LINK_SELECT",
  LINK_DRAG = "LINK_DRAG",
  LINK_ELEMENT_TOOLS = "LINK_ELEMENT_TOOLS",
}

export class KEAPaperBuilder {
  private mainPaper: KEAPaper;
  private secondaryGraph: KEAGraph | undefined;
  private secondaryPaper: KEAPaper | undefined;
  private editorId: string | undefined;
  constructor(
    ref: React.RefObject<HTMLDivElement>,
    graph: KEAGraph,
    paperOptions: joint.dia.Paper.Options,
    grid?: any,
    gridSize?: number,
    gridBackground?: string,
    editorId?: string,
    inputDevice?: string,
  ) {
    if (editorId) {
      this.editorId = editorId;
    }
    if (grid && gridSize && gridBackground) {
      this.mainPaper = new KEAPaper({
        ...paperOptions,
        cellViewNamespace: keaNamespace,
        el: ref.current!,
        model: graph,
        defaultConnectionPoint: {
          name: "boundary",
          args: {
            selector: "body",
            stroke: true,
          },
        },
        drawGrid: grid,
        gridSize: gridSize,
        background: {
          color: gridBackground,
        },
      });
    } else {
      this.mainPaper = new KEAPaper({
        ...paperOptions,
        cellViewNamespace: keaNamespace,
        el: ref.current!,
        model: graph,
        defaultConnectionPoint: {
          name: "boundary",
          args: {
            selector: "body",
            stroke: true,
          },
        },
      });
    }
    if (inputDevice) {
      this.mainPaper.setinputDevice(inputDevice);
    }
  }

  nodeClb = (clb: (node: joint.dia.Cell) => void): KEAPaperBuilder => {
    this.mainPaper.setNodeCallback(clb);
    return this;
  };

  historyClb = (clb: () => void): KEAPaperBuilder => {
    this.mainPaper.setHistoryCallback(clb);
    return this;
  };

  dropGraph = (graph: KEAGraph): KEAPaperBuilder => {
    this.secondaryGraph = graph;
    return this;
  };

  dropPaper = (paper: KEAPaper): KEAPaperBuilder => {
    this.secondaryPaper = paper;
    return this;
  };

  interactionClb = (
    clb: (
      interactionType: InteractionType,
      interactionTime: number,
      payload?: KEAGraph | KEALink | KEAElement | joint.dia.Cell[],
    ) => void,
  ): KEAPaperBuilder => {
    this.mainPaper.setInterActionCallback(clb);
    return this;
  };

  event = (type: PaperEventTypes): KEAPaperBuilder => {
    switch (type) {
      case PaperEventTypes.SELECT:
        this.mainPaper.addSelectField();
        break;
      case PaperEventTypes.NODE_DELETE:
        this.mainPaper.addDeleteEvent();
        this.mainPaper.setCanDelete(true);
        break;
      case PaperEventTypes.LINK_DRAG:
        this.mainPaper.addLinkDragEvents();
        break;
      case PaperEventTypes.LINK_ELEMENT_TOOLS:
        this.mainPaper.addLinkToolEvents();
        break;
      case PaperEventTypes.KEYBOARD_EVENT_NODE_COPY_PASTE:
        this.mainPaper.addCopyPastekeydownEvents();
        break;
      case PaperEventTypes.MESSAGE_EVENT_NODE_COPY_PASTE:
        this.mainPaper.addCopyPasteMessageEvents();
        break;
      case PaperEventTypes.NODE_DRAG_AND_DROP:
        if (this.secondaryPaper && this.secondaryGraph) {
          this.mainPaper.addElementDragAndDropEvent(this.secondaryGraph, this.secondaryPaper, this.editorId);
          this.mainPaper.addLinkDragAndDropEvent(this.secondaryGraph, this.secondaryPaper, this.editorId);
        }
        break;
      case PaperEventTypes.NODE_DRAG_AND_DROP_AND_HOVER:
        if (this.secondaryPaper && this.secondaryGraph) {
          this.mainPaper.addElementDragAndDropAndZoomEvent(this.secondaryGraph, this.secondaryPaper, this.editorId);
          this.mainPaper.addLinkDragAndDropAndZoomEvent(this.secondaryGraph, this.secondaryPaper, this.editorId);
        }
        break;
      case PaperEventTypes.NODE_ELEMENT_TOOLS:
        this.mainPaper.addElementToolEvents();
        break;
      case PaperEventTypes.NODE_EMBED:
        this.mainPaper.addEmbeddEvents();
        break;
      case PaperEventTypes.NODE_SELECT:
        this.mainPaper.addSelectEvent();
        break;
      case PaperEventTypes.LINK_SELECT:
        this.mainPaper.addLinkSelectEvent();
        break;
      case PaperEventTypes.PAPER_DRAG:
        this.mainPaper.addPaperDragEvents();
        break;
      case PaperEventTypes.PAPER_ZOOM:
        this.mainPaper.addMouseWheelEvents();
        break;
    }
    return this;
  };

  build = (): KEAPaper => {
    return this.mainPaper;
  };
}
