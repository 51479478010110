import { WithChildren } from "@kea-mod/common";
import { LicenseModal } from "modals/LicenseModal";
import { Component } from "react";
import { LicenseModalContext } from "./LicenseModalContext";

interface State {
  open: boolean;
}

interface Props extends WithChildren {}

export class LicenseModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleLicenseModal = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    return (
      <LicenseModalContext.Provider
        value={{
          toggleLicenseModal: this.toggleLicenseModal,
        }}
      >
        {this.props.children}
        <LicenseModal isActive={this.state.open} setisActive={this.toggleLicenseModal} />
      </LicenseModalContext.Provider>
    );
  }
}
