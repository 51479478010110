export enum InteractionType {
  NEW_FROM_EMPTY = "New empty graph.",
  NEW_FROM_TEMPLATE = "New graph from template.",
  EDGE_CREATED = "New link created.",
  EDGE_EDITING_STARTED = "Started editing a link.",
  EDGE_EDITING_CANCEL = "Cancelled editing a link.",
  EDGE_EDITING_SAVE = "Saved the edited link.",
  EDGDE_DELETED = "Link deleted.",
  NODE_CREATED = "New node created.",
  NODE_UNEMBED = "NODE_UNEMBED",
  NODE_EMBED = "NODE_EMBED",
  NODE_EDITED = "NODE_EDITED",
  NODE_EDITING_STARTED = "Started editing a node.",
  NODE_EDITING_CANCEL = "Cancelled editing a node.",
  NODE_EDITING_SAVE = "Saved the edited node.",
  NODE_DELETED = "Node deleted.",
  NODE_DRAGGED = "Node was dragged",
  GRAPH_ZOOM_IN = "Zoomed into graph.",
  GRAPH_ZOOM_OUT = "Zoomed out graph.",
  GRAPH_FIT = "Fitted graph.",
  GRAPH_DRAG_START = "GRAPH_DRAG_START",
  GRAPH_DRAG_END = "GRAPH_DRAG_END",
  GRAPH_DOWNLOAD = "GRAPH_DOWNLOAD",
  UNDO = "undid last action",
  REDO = "redid last action",
  IMPORT_START = "IMPORT_START",
  IMPORT_ABORT = "IMPORT_ABORT",
  IMPORT_SUCCESS = "IMPORT_SUCCESS",
  IMPORT_ERROR = "IMPORT_ERROR",
  DRAG_AND_DROP_START = "DRAG_AND_DROP_START",
  DRAG_AND_DROP_SUCCESS = "DRAG_AND_DROP_SUCCESS",
  DRAG_AND_DROP_ABORT = "DRAG_AND_DROP_ABORT",
  COPY_STRG_C = "COPY_STRG_C",
  COPY_STRG_V = "COPY_STRG_V",
}
