import { util } from "jointjs";

import { KEAElement } from "./KEAElement";

export enum BPMNTaskType {
  NONE = "NONE",
  USER = "USER",
  SERVICE = "SERVICE",
  SEND = "SEND",
  SCRIPT = "SCRIPT",
  RECEIVE = "RECEIVE",
  MANUAL = "MANUAL",
  BUSINESS_RULE = "BUSINESS_RULE",
}

export class KEABPMNActivity extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.BPMNActivity",
      size: {
        width: 150,
        height: 75,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          rx: 15,
          ry: 15,
          stroke: "#000000",
          strokeWidth: 1,
          fill: "#ededed",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        border: {
          ref: "body",
          refWidth: "96%",
          refHeight: "92%",
          refX: "2%",
          refY: "4%",
          rx: 15,
          ry: 15,
          stroke: "#000000",
          strokeWidth: 1,
          fill: "none",
          visibility: "hidden",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          refX: "50%",
          refY: "50%",
          fontSize: 12,
          fill: "#333333",
          text: "",
        },
        taskMarker: {
          d: "",
          ref: "body",
          refX: "10%",
          refY: "10%",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        subprocessMarker: {
          d: "M 0 0 L 10 0 L 10 10 L 0 10 L 0 0 M 4 2 L 6 2 L 6 4 L 8 4 L 8 6 L 6 6 L 6 8 L 4 8 L 4 6 L 2 6 L 2 4 L 4 4 L 4 2",
          transform: "translate(-5 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        loopMarker: {
          d: "M 10 0 M 5 9 Q 9 9 9 5 Q 9 1 5 1 Q 1 1 1 5 Q 1 6 3 7 L 3 4 M 3 7 L 0 7",
          transform: "translate(-20 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        parallelMarker: {
          d: "M 1 1 L 1 9 L 3 9 L 3 1 Z M 4 1 L 6 1 L 6 9 L 4 9 Z M 7 1 L 9 1 L 9 9 L 7 9 Z",
          transform: "translate(-40 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        sequentialMarker: {
          d: "M 1 1 L 9 1 L 9 3 L 1 3 L 1 1 M 1 4 L 9 4 L 9 6 L 1 6 L 1 4 M 1 7 L 9 7 L 9 9 L 1 9 Z",
          transform: "translate(-30 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        adHocMarker: {
          d: "M 0 4 Q 1 3 2 3 L 6 4 Q 7 4 8 3 L 8 5 Q 7 6 6 6 L 2 5 Q 1 5 0 6 Z",
          transform: "translate(15 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
        compensationMarker: {
          d: "M 8 8 L 8 2 L 5 5 L 8 8 M 5 2 L 5 8 L 2 5 L 5 2",
          transform: "translate(25 -10)",
          ref: "body",
          refX: "50%",
          refY: "96%",
          visibility: "hidden",
          fill: "none",
          stroke: "#020202",
          strokeWidth: 1,
        },
      },
      isSubProcess: false,
      isEventSubprocess: false,
      isLoop: false,
      isParallel: false,
      isSequential: false,
      isCompensation: false,
      isAdHoc: false,
      isTransaction: false,
      isCallActivity: false,
      taskType: BPMNTaskType.NONE,
      strokeRadius: 0,
    });
  }

  onChangePrimaryColor(element: KEAElement, property: string, _options: any): void {
    element.attr({
      body: { stroke: property },
      taskMarker: {
        stroke: property,
        fill: this.attr("label/fill"),
      },
    });
  }

  onChangeSecondaryColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      body: { fill: _property },
      border: { fill: _property },
    });
  }

  onChangeLabelColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      label: { fill: _property },
      taskMarker: { stroke: _property, fill: "none" },
      subprocessMarker: { stroke: _property },
      loopMarker: { stroke: _property },
      parallelMarker: { stroke: _property },
      sequentialMarker: { stroke: _property },
      adHocMarker: { stroke: _property },
      compensationMarker: { stroke: _property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    const template = util.breakText(
      property,
      {
        width: this.attributes.size!.width,
        height: this.attributes.size!.height,
      },
      { "font-size": 12 },
    );

    this.attr({
      label: {
        text: template.split("\n", 1)[0],
      },
    });
  }

  setIsEventSubprocess = (value: boolean): KEABPMNActivity => {
    this.set("isEventSubprocess", value);

    const cssValue = value ? "10 5" : "1 0";

    this.attr({
      body: {
        strokeDasharray: cssValue,
      },
    });

    this.trigger("change:attribute");
    return this;
  };

  getIsEventSubprocess = (): boolean => {
    return this.get("isEventSubprocess");
  };

  setIsCallActivity = (value: boolean): KEABPMNActivity => {
    this.set("isCallActivity", value);

    if (value === false) {
      this.attr({
        body: {
          strokeWidth: 1,
        },
        border: {
          strokeWidth: 1,
        },
      });
    } else {
      this.attr({
        body: {
          strokeWidth: 2,
        },
        border: {
          strokeWidth: 2,
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsCallActivity = (): boolean => {
    return this.get("isCallActivity");
  };

  setTaskType = (value: BPMNTaskType): KEABPMNActivity => {
    this.set("taskType", value);

    this.attr({
      taskMarker: {
        d: KEABPMNActivity.getTaskTypePath(value),
      },
    });

    if (value === BPMNTaskType.SEND) {
      this.attr({
        taskMarker: {
          fill: this.attr("label/fill"),
          stroke: this.attr("body/fill"),
        },
      });
    } else {
      this.attr({
        taskMarker: {
          fill: "none",
          stroke: "#020202",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getTaskType = (): BPMNTaskType => {
    return this.get("taskType");
  };

  setIsTransaction = (value: boolean): KEABPMNActivity => {
    this.set("isTransaction", value);

    if (value === false) {
      this.attr({
        border: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        border: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsTransaction = (): boolean => {
    return this.get("isTransaction");
  };

  setIsSubProcess = (value: boolean): KEABPMNActivity => {
    this.set("isSubProcess", value);

    if (value === false) {
      this.attr({
        subprocessMarker: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        subprocessMarker: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsSubProcess = (): boolean => {
    return this.get("isSubProcess");
  };

  setIsCompensation = (value: boolean): KEABPMNActivity => {
    this.set("isCompensation", value);

    if (value === false) {
      this.attr({
        compensationMarker: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        compensationMarker: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsCompensation = (): boolean => {
    return this.get("isCompensation");
  };

  setIsAdHoc = (value: boolean): KEABPMNActivity => {
    this.set("isAdHoc", value);

    if (value === false) {
      this.attr({
        adHocMarker: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        adHocMarker: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsAdHoc = (): boolean => {
    return this.get("isAdHoc");
  };

  setIsLoop = (value: boolean): KEABPMNActivity => {
    this.set("isLoop", value);

    if (value === false) {
      this.attr({
        loopMarker: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        loopMarker: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsParallel = (): boolean => {
    return this.get("isParallel");
  };

  setIsParallel = (value: boolean): KEABPMNActivity => {
    this.set("isParallel", value);

    if (value === false) {
      this.attr({
        parallelMarker: {
          visibility: "hidden",
        },
      });
    } else {
      this.attr({
        parallelMarker: {
          visibility: "visible",
        },
      });
    }

    this.trigger("change:attribute");
    return this;
  };

  getIsLoop = (): boolean => {
    return this.get("isLoop");
  };

  setIsSequential = (value: boolean): KEABPMNActivity => {
    this.set("isSequential", value);

    const attrString = value ? "visible" : "hidden";
    this.attr({
      sequentialMarker: {
        visibility: attrString,
      },
    });

    this.trigger("change:attribute");
    return this;
  };

  getIsSequential = (): boolean => {
    return this.get("isSequential");
  };

  static getTaskTypePath = (type: BPMNTaskType): string => {
    switch (type) {
      case BPMNTaskType.NONE:
        return "";
      case BPMNTaskType.SERVICE:
        return "M 5 0 L 6 1 L 7 3 L 9 2 L 10 2 L 10 3 L 9 5 L 10 7 L 10 8 L 9 8 L 7 7 L 6 9 L 5 10 L 4 9 L 3 7 L 1 8 L 0 8 L 0 7 L 1 5 L 0 3 L 0 2 L 1 2 L 3 3 L 4 1 L 5 0 M 5 4 Q 6 4 6 5 Q 6 6 5 6 Q 4 6 4 5 Q 4 4 5 4";
      case BPMNTaskType.SEND:
        return "M 0 1 L 10 1 L 10 9 L 0 9 L 0 1 L 0.5 1 L 5 4.5 L 9.5 1 Z L 5 5 L 10 1";
      case BPMNTaskType.RECEIVE:
        return "M 0 1 L 10 1 L 10 9 L 0 9 L 0 1 Z L 5 5 L 10 1";
      case BPMNTaskType.USER:
        return "M 6 0 L 8 2 L 8 4 L 6 6 L 4 6 L 2 4 L 2 2 L 4 0 L 6 0 M 6 6 L 7 7 L 9 7 L 10 8 L 10 10 M 0 10 L 0 8 L 1 7 L 3 7 L 4 6";
      case BPMNTaskType.MANUAL:
        return "M 0 2 L 2 0 L 5 0 L 6 1 L 5 2 L 3 2 L 9 2 L 10 3 L 9 4 L 4 4 L 9 4 L 10 5 L 9 6 L 4 6 L 8 6 L 9 7 L 8 8 L 4 8 L 7 8 L 8 9 L 7 10 L 2 10 L 0 8";
      case BPMNTaskType.BUSINESS_RULE:
        return "M 0 2 L 10 2 L 10 8 L 0 8 L 0 2 M 2 2 L 2 8 M 0 3 L 10 3 M 1 5 L 10 5 M 1 7 L 10 7";
      case BPMNTaskType.SCRIPT:
        return "M 2 3 Q 2 1 4 0 L 10 0 Q 8 1 8 3 L 8 7 Q 8 9 6 10 L 0 10 Q 2 9 2 7 Z M 3 3 L 6 3 M 7 5 L 3 5 M 2 9 L 4 9";
    }
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "rect",
        selector: "border",
      },
      {
        tagName: "path",
        selector: "subprocessMarker",
      },
      {
        tagName: "path",
        selector: "loopMarker",
      },
      {
        tagName: "path",
        selector: "parallelMarker",
      },
      {
        tagName: "path",
        selector: "sequentialMarker",
      },
      {
        tagName: "path",
        selector: "adHocMarker",
      },
      {
        tagName: "path",
        selector: "compensationMarker",
      },
      {
        tagName: "path",
        selector: "taskMarker",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
