import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("LicenseModalContext");
};

interface ILicenseModalContext {
  toggleLicenseModal: () => void;
}

const defaultState: ILicenseModalContext = {
  toggleLicenseModal: () => printOutsideContext(),
};

export const LicenseModalContext = createContext<ILicenseModalContext>(defaultState);
export const useLicenseModalContext = () => useContext(LicenseModalContext);
