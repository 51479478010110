import { useTranslation } from "react-i18next";
import { CustomSimpleModal } from "./CustomSimpleModal";

interface Props {
  isActive: boolean;
  messageType: MessageType;
}

export enum MessageType {
  TASK_SUCCESS,
  MODEL_SUCCESS,
  LOADING_ERROR,
}

export const SuccessModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <CustomSimpleModal isActive={props.isActive}>
      <div className="box">
        {props.messageType === MessageType.TASK_SUCCESS && <p>{t("SuccessModal-Body")}</p>}
        {props.messageType === MessageType.MODEL_SUCCESS && <p>{t("CreateModelModalBodySuccess")}</p>}
      </div>
    </CustomSimpleModal>
  );
};
