import {
  KEABPMNSwimlane,
  KEAElement,
  KEAGraph,
  KEALink,
  KEAPolygon,
  KEAUMLActivityNode,
  KEAUMLArea,
  KEAUMLCircleState,
  KEAUMLReceiveSymbol,
  KEAUMLSendSymbol,
  LabelPosition,
  LinkConfiguration,
  LinkLabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
  StateType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { KEAUMLForkAndJoin } from "@kea-mod/jointjs/components/KEAUMLForkAndJoin";
import { TemplateConfiguration } from "./../TemplateConfiguration";

const activityNode = new KEAUMLActivityNode();
activityNode.position(20, 20);

const umlForkAndJoin = new KEAUMLForkAndJoin();
umlForkAndJoin.position(240, 20);

const send = new KEAUMLSendSymbol();
send.position(270, 20);

const receive = new KEAUMLReceiveSymbol();
receive.position(400, 20);

const area = new KEAUMLArea();
area.size(280, 100);
area.position(270, 85);

const umlChoice = new KEAPolygon();
umlChoice.position(20, 140);
umlChoice.setLabelPosition(LabelPosition.BELOW);
umlChoice.size(30, 30);

const initialUMLCircleActivity = new KEAUMLCircleState();
initialUMLCircleActivity.position(60, 140);
initialUMLCircleActivity.setStateType(StateType.INITIAL);

const exitUMLCircleActivity = new KEAUMLCircleState();
exitUMLCircleActivity.position(100, 140);
exitUMLCircleActivity.setStateType(StateType.EXIT);

const finalUMLCircleActivity = new KEAUMLCircleState();
finalUMLCircleActivity.position(140, 140);
finalUMLCircleActivity.setStateType(StateType.FINAL);

const swimlane = new KEABPMNSwimlane().position(570, 20).setNumberOfLanes(1).size(300, 125);

const link = new KEALink();
link.source({ x: 570, y: 170 });
link.target({ x: 870, y: 170 });
link.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
link.setDasharray(LinkType.SOLID);
link.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link.clone();

export const getUMLActivityConfiguration = (): Array<KEAElement | KEALink> => {
  return [
    initialUMLCircleActivity,
    exitUMLCircleActivity,
    finalUMLCircleActivity,
    umlChoice,
    umlForkAndJoin,
    send,
    receive,
    activityNode,
    area,
    link,
    swimlane,
  ];
};

export const getUMLActivityHorizontalMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: false,
    sourceLabelEnabled: false,
    midLabelEnabled: true,
    targetLabelEnabled: false,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLActivityConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
