import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const RedoButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    keaGraphContext.redo();
    keaGraphContext.addUserInteraction(InteractionType.REDO, Date.now());
  };

  return (
    <KeaButton disabled={keaGraphContext.canRedo() ? false : true} onClick={handleClick}>
      <span className="icon is-small">
        <i className="fa fa-repeat"></i>
      </span>
    </KeaButton>
  );
};
