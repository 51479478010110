import { KEAElement } from "../KEAElement";
import $ from "jquery";
import { KEAPaper } from "../KEAPaper";
import { find } from "lodash";
import * as joint from "jointjs";
export const getReactDiv = (editorId?: string) => {
  const divName = editorId ? `editor-wrapper-${editorId}` : "editor-wrapper";
  const reactDiv = document.getElementById(divName);
  if (!reactDiv) throw "React Div not found";
  return reactDiv;
};

export const createFlyDiv = (editorId?: string) => {
  const reactDiv = getReactDiv(editorId);
  const div = document.createElement("div");
  div.id = "flyPaper";
  div.className = "flyPaper";
  div.style.position = "fixed";
  div.style.top = "0";
  div.style.right = "0";
  reactDiv.appendChild(div);
};

export const offsetFlyDiv = (gridSize: number | undefined, evt: any, offset: { x: number; y: number }) => {
  if (gridSize !== undefined) {
    $("#flyPaper").offset({
      left: Math.ceil((evt.pageX - offset.x) / gridSize) * gridSize,
      top: Math.ceil((evt.pageY - offset.y) / gridSize) * gridSize,
    });
  }
};

export const getFlyShapePosition = (shape: joint.dia.Cell) => {
  let x = 0;
  let y = 0;
  if ((shape as KEAElement).getResizeHeight()) y = 10;
  if ((shape as KEAElement).getResizeWidth()) x = 10;
  return { x: x, y: y };
};

export const isInsidePaper = (x: number, y: number, paper: KEAPaper): boolean => {
  const target = paper.$el.offset();
  const width = paper.$el.width();
  const height = paper.$el.height();

  if (target && width && height) {
    if (x < target.left || x > target.left + width) {
      return false;
    }
    if (y < target.top || y > target.top + height) {
      return false;
    }
  }
  return true;
};

export const getClientPosition = (paper: KEAPaper, e: any, gridSize: number | undefined): joint.dia.Point => {
  const x = e.clientX;
  const y = e.clientY;
  const viewPoint = paper.clientToLocalPoint(x, y);

  let position = {
    x: viewPoint.x,
    y: viewPoint.y,
  };

  if (gridSize) {
    position.x = Math.round(position.x / gridSize) * gridSize;
    position.y = Math.round(position.y / gridSize) * gridSize;
  }
  return position;
};

export const dropElement = (e: any, dropPaper: KEAPaper, flyShape: joint.dia.Cell, gridSize: number | undefined) => {
  const s = flyShape.clone();

  const position = getClientPosition(dropPaper, e, gridSize);
  // @ts-ignore
  s.position(position.x, position.y);
  dropPaper.model.addCell(s);
  dropPaper.dumpViews();
  s.toFront();
  var cellViewsBelow = dropPaper.findViewsFromPoint(s.getBBox().center());
  if (cellViewsBelow.length) {
    if (s.attributes.type === "kea.UMLComponent" || s.attributes.type === "kea.UMLPort") {
      let cellViewTop = cellViewsBelow.find((c) => {
        const embedded = c.model.getEmbeddedCells().map((c) => c.id);
        if (embedded.length === 0) return true;
        const viewCells = cellViewsBelow.map((c) => c.model.id);
        return !embedded.some((e) => viewCells.includes(e));
      });
      if (cellViewTop && !areRelated(s, cellViewTop.model)) {
        cellViewTop.model.embed(s);
        return;
      }
    }
    var cellViewBelow = find(cellViewsBelow, function (c: joint.dia.CellView) {
      return c.model.id !== s.id;
    });
    //Should be changed when we want to embedd embedded elements
    if (cellViewBelow && !areRelated(s, cellViewBelow.model)) {
      cellViewBelow.model.embed(s);
    }
  }
};

export const areRelated = (cell1: joint.dia.Cell, cell2: joint.dia.Cell): boolean => {
  if (cell1 === cell2) return true;
  let parent = cell1.get("parent");
  while (parent) {
    if (parent === cell2.id) return true;
    parent = cell1.graph.getCell(parent)?.get("parent");
  }
  parent = cell2.get("parent");
  while (parent) {
    if (parent === cell1.id) return true;
    parent = cell2.graph.getCell(parent)?.get("parent");
  }
  return false;
};

export const getNearestCircle = (cells: joint.dia.Cell[], x: number, y: number): joint.dia.Element | undefined => {
  let nearest: joint.dia.Element | undefined = undefined;
  let minDistance = Number.MAX_VALUE;
  cells.forEach((cell) => {
    if (cell instanceof joint.shapes.standard.Circle) {
      const distance = Math.sqrt((cell.position().x - x) ** 2 + (cell.position().y - y) ** 2);
      if (distance < minDistance) {
        minDistance = distance;
        nearest = cell;
      }
    }
  });
  return nearest;
};
