import { WithChildren } from "@kea-mod/common";
import { KEALink } from "@kea-mod/jointjs";
import { EditKEALinkModal } from "modals/EditKEALinkModal";
import { Component } from "react";
import { EditKEALinkContext } from "./EditKEALinkContext";

interface State {
  node: KEALink | undefined;
  visible: boolean;
}

interface Props extends WithChildren {}

export class EditKEALinkContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      node: undefined,
      visible: false,
    };
  }

  isEditableByKEALinkModal = (cell: joint.dia.Cell): boolean => {
    switch (cell.get("type")) {
      case "kea.Link":
        return true;
      default:
        return false;
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  setNode = (node: any) => {
    if (this.isEditableByKEALinkModal(node)) {
      this.setState(
        {
          node: node,
        },
        () => {
          this.setState((prevState) => ({
            visible: !prevState.visible,
          }));
        },
      );
    }
  };

  render() {
    return (
      <EditKEALinkContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}
        <EditKEALinkModal isActive={this.state.visible} setisActive={this.toggleModal} node={this.state.node} />
      </EditKEALinkContext.Provider>
    );
  }
}
