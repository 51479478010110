import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const ZoomInButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    keaGraphContext.modelingPaper.zoomIn();
    keaGraphContext.addUserInteraction(InteractionType.GRAPH_ZOOM_IN, Date.now());
  };

  return (
    <KeaButton onClick={handleClick}>
      <span className="icon is-small">
        <i className="fa fa-search-plus"></i>
      </span>
    </KeaButton>
  );
};
