import { KEALink, KEALinkLabel, LinkLabelPosition, LinkType, MarkerPosition, MarkerType } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";
import { LinkLabelComponent } from "./LinkLabelComponent";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: KEALink | undefined;
}

export const EditKEALinkModal = ({ setisActive, isActive, node }: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const [linkLabels, setLinkLabels] = useState<Array<KEALinkLabel>>([]);
  const [sourceMarker, setSourceMarker] = useState<MarkerType>(MarkerType.NONE);
  const [targetMarker, setTargetMarker] = useState<MarkerType>(MarkerType.NONE);
  const [pathType, setPathType] = useState<LinkType>(LinkType.SOLID);

  useEffect(() => {
    if (node) {
      setSourceMarker(node.getMarkerType(MarkerPosition.SOURCE_MARKER));
      setTargetMarker(node.getMarkerType(MarkerPosition.TARGET_MARKER));
      setPathType(node.getDasharray());
      setLinkLabels(node.getLabels());
    }
  }, [node]);

  const setIsActiveWrapper = useCallback((): void => {
    setisActive(false);
  }, [setisActive]);

  const assignChanges = useCallback((): void => {
    if (node) {
      node.deleteKEALabel({ position: LinkLabelPosition.SOURCE, value: "" });
      node.deleteKEALabel({ position: LinkLabelPosition.MID, value: "" });
      node.deleteKEALabel({ position: LinkLabelPosition.TARGET, value: "" });
      linkLabels.forEach((label) => node.setKEALabel(label));
      node.setMarker(MarkerPosition.SOURCE_MARKER, sourceMarker);
      node.setMarker(MarkerPosition.TARGET_MARKER, targetMarker);
      node.setDasharray(pathType);
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setisActive(true);
  }, [node, keaGraphContext, setisActive, linkLabels, sourceMarker, targetMarker, pathType]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignChanges, isActive, setIsActiveWrapper]);

  const addLinkLabel = (pos: LinkLabelPosition) => {
    const labels = [...linkLabels];
    labels.push({ position: pos, value: "" });
    setLinkLabels(labels);
  };

  const removeLinkLabel = (pos: LinkLabelPosition) => {
    const labels = [...linkLabels];
    const index = labels.findIndex((label) => label.position === pos);
    if (index !== -1) {
      labels.splice(index, 1);
    }
    setLinkLabels(labels);
  };

  const handleLinkLabelChange = (pos: LinkLabelPosition, event: ChangeEvent<HTMLInputElement>) => {
    const links = [...linkLabels];
    const link = links.find((label) => label.position === pos);
    if (link) {
      link.value = event.target.value;
      setLinkLabels(links);
    }
  };

  const renderSourceLabel = () => {
    const label = linkLabels.find((label) => label.position === LinkLabelPosition.SOURCE);
    return (
      <LinkLabelComponent
        label={label}
        text={t("KEALinkModalAddSourceLabelButton")}
        position={LinkLabelPosition.SOURCE}
        handleLinkLabelChange={handleLinkLabelChange}
        addLinkLabel={addLinkLabel}
        removeLinkLabel={removeLinkLabel}
      />
    );
  };
  const renderMiddleLabel = () => {
    const label = linkLabels.find((label) => label.position === LinkLabelPosition.MID);
    return (
      <LinkLabelComponent
        label={label}
        text={t("KEALinkModalAddMiddleLabelButton")}
        position={LinkLabelPosition.MID}
        handleLinkLabelChange={handleLinkLabelChange}
        addLinkLabel={addLinkLabel}
        removeLinkLabel={removeLinkLabel}
      />
    );
  };
  const renderTargetLabel = () => {
    const label = linkLabels.find((label) => label.position === LinkLabelPosition.TARGET);
    return (
      <LinkLabelComponent
        label={label}
        text={t("KEALinkModalAddTargetLabelButton")}
        position={LinkLabelPosition.TARGET}
        handleLinkLabelChange={handleLinkLabelChange}
        addLinkLabel={addLinkLabel}
        removeLinkLabel={removeLinkLabel}
      />
    );
  };

  const handleSourceMarkerChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSourceMarker(event.target.value as MarkerType);
  };

  const handleTargetMarkerChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTargetMarker(event.target.value as MarkerType);
  };

  const handlePathTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPathType(event.target.value as LinkType);
  };

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={undefined}
      body={
        <>
          <div className="divider">{t("KEALinkModalMarkupDivider")}</div>
          <div className="columns">
            <div className="column">
              <div className="select">
                <select
                  disabled={!keaGraphContext.linkConfiguration.editSourceMarkerEnabled}
                  value={sourceMarker}
                  onChange={handleSourceMarkerChange}
                >
                  <option value={MarkerType.ARROW}>{t("MarkerTypeArrow")}</option>
                  <option value={MarkerType.ARROW_EMPTY}>{t("MarkerTypeArrowEmpty")}</option>
                  <option value={MarkerType.ARROW_FILLED}>{t("MarkerTypeArrowFilled")}</option>
                  <option value={MarkerType.CIRCLE_EMPTY}>{t("MarkerTypeCircleEmpty")}</option>
                  <option value={MarkerType.DIAMOND_EMPTY}>{t("MarkerTypeDiamondEmpty")}</option>
                  <option value={MarkerType.DIAMOND_FILLED}>{t("MarkerTypeDiamondFilled")}</option>
                  <option value={MarkerType.NONE}>{t("MarkerTypeNone")}</option>
                </select>
              </div>
            </div>
            <div className="column">
              <div className="select">
                <select
                  disabled={!keaGraphContext.linkConfiguration.editPathTypeEnabled}
                  value={pathType}
                  onChange={handlePathTypeChange}
                >
                  <option value={LinkType.DASHED}>{t("LinkTypeDashed")}</option>
                  <option value={LinkType.SOLID}>{t("LinkTypeSolid")}</option>
                </select>
              </div>
            </div>
            <div className="column">
              <div className="select">
                <select
                  disabled={!keaGraphContext.linkConfiguration.editTargetMarkerEnabled}
                  value={targetMarker}
                  onChange={handleTargetMarkerChange}
                >
                  <option value={MarkerType.ARROW}>{t("MarkerTypeArrow")}</option>
                  <option value={MarkerType.ARROW_EMPTY}>{t("MarkerTypeArrowEmpty")}</option>
                  <option value={MarkerType.ARROW_FILLED}>{t("MarkerTypeArrowFilled")}</option>
                  <option value={MarkerType.CIRCLE_EMPTY}>{t("MarkerTypeCircleEmpty")}</option>
                  <option value={MarkerType.DIAMOND_EMPTY}>{t("MarkerTypeDiamondEmpty")}</option>
                  <option value={MarkerType.DIAMOND_FILLED}>{t("MarkerTypeDiamondFilled")}</option>
                  <option value={MarkerType.NONE}>{t("MarkerTypeNone")}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="divider">{t("KEALinkModalLabelDivider")}</div>
          {renderSourceLabel()}
          {renderMiddleLabel()}
          {renderTargetLabel()}
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignChanges();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
