import { KEAGraph, KEALink, keaNamespace, KEAUMLFrame, LinkLabelPosition, LinkType } from "@kea-mod/jointjs";
import { KEAElement } from "@kea-mod/jointjs/components/KEAElement";
import { FrameType } from "@kea-mod/jointjs/components/KEAUMLFrame";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const frame = new KEAUMLFrame();
frame.position(20, 20);

const alt = new KEAUMLFrame();
alt.setFrameType(FrameType.ALT);
alt.position(20, 200);

const ref = new KEAUMLFrame();
ref.setFrameType(FrameType.REFERENCE);
ref.position(20, 380);

const defaultLink = new KEALink();
defaultLink.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
defaultLink.setDasharray(LinkType.DASHED);

export const getUMLSequenceFrameConfiguration = (): Array<KEAElement | KEALink> => {
  return [frame, alt, ref];
};

export const getUMLSequenceFrameMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: false,
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLSequenceFrameConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
