import { KEAUMLLifeLine } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: KEAUMLLifeLine | undefined;
}

export const EditLifeLineModal = ({ setisActive, isActive, node }: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const [type, setType] = useState<string>("");
  const [instance, setInstance] = useState<string>("");
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (node) {
      setType(node.getObjectType());
      setInstance(node.getObjectInstance());
    }
  }, [node]);

  const handleChangeType = (event: ChangeEvent<HTMLInputElement>): void => {
    setType(event.target.value);
  };

  const handleChangeInstance = (event: ChangeEvent<HTMLInputElement>): void => {
    setInstance(event.target.value);
  };

  const setIsActiveWrapper = useCallback((): void => {
    setInstance("");
    setType("");
    setisActive(false);
  }, [setisActive]);

  const assignLabel = useCallback((): void => {
    if (node) {
      node.setObjectInstance(instance);
      node.setObjectType(type);
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setInstance("");
    setType("");
    setisActive(true);
  }, [node, keaGraphContext, setisActive, instance, type]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignLabel();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignLabel, isActive, setIsActiveWrapper]);

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("edit_lifeline_header") as string}
      body={
        <div>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <label className="label is-small">{t("edit_lifeline_instance")}</label>
              <input
                placeholder={t("edit_lifeline_instance") as string}
                ref={inputElement}
                className="input"
                type="text"
                value={instance}
                onChange={handleChangeInstance}
              />
            </div>

            <div className="control pt-5">
              <p>:</p>
            </div>
            <div className="control is-expanded">
              <label className="label is-small">{t("edit_lifeline_type")}</label>
              <input
                placeholder={t("edit_lifeline_type") as string}
                ref={inputElement}
                className="input"
                type="text"
                value={type}
                onChange={handleChangeType}
              />
            </div>
          </div>
        </div>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignLabel();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
