import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { useTranslation } from "react-i18next";

type Props = {};

export const ModelExportButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const handleClick = () => {
    keaGraphContext.modelingGraph.download();
    keaGraphContext.addUserInteraction(InteractionType.GRAPH_DOWNLOAD, Date.now());
  };

  return (
    <button className="button is-primary is-rounded" onClick={handleClick}>
      {t("export")}
    </button>
  );
};
