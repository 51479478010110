import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export enum OutlineType {
  NONE = "NONE",
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE",
}

export enum GatewayType {
  NONE = "NONE",
  EXLUSIVE = "EXCLUSIVE",
  EVENT_BASED = "EVENT_BASED",
  PARALLEL = "PARALLEL",
  EVENT_PARALLEL = "EVENT_PARALLEL",
  INCLUSIVE = "INCLUSIVE",
  COMPLEX = "COMPLEX",
}

export class BPMNGateway extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.BPMNGateway",
      size: {
        width: 50,
        height: 50,
      },
      attrs: {
        body: {
          stroke: "#020202",
          fill: "#edce8a",
          strokeWidth: 1,
          refWidth: "100%",
          refHeight: "100%",
          transform: "rotate(45 25 25)",
        },
        outerCircle: {
          visibility: "hidden",
          stroke: "#020202",
          strokeWidth: 1,
          refCx: "50%",
          refCy: "50%",
          refR: "40%",
          fill: "none",
        },
        innerCircle: {
          visibility: "hidden",
          stroke: "#020202",
          strokeWidth: 1,
          refCx: "50%",
          refCy: "50%",
          refR: "35%",
          fill: "none",
        },
        marker: {
          d: "",
          stroke: "#020202",
          strokeWidth: 1,
          fill: "none",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      gatewayType: "EXCLUSIVE",
      outlineType: "NONE",
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "circle",
        selector: "outerCircle",
      },
      {
        tagName: "circle",
        selector: "innerCircle",
      },
      {
        tagName: "path",
        selector: "marker",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();

  setOutlineType = (type: OutlineType): BPMNGateway => {
    this.set("outlineType", type);
    this.changeOutlineMarkup(type);
    this.trigger("change:attribute", this);
    return this;
  };

  getOutlineType = (): OutlineType => {
    return this.get("outlineType");
  };

  setGatewayType = (type: GatewayType): BPMNGateway => {
    this.set("gatewayType", type);
    this.attr({ marker: getGateWayMarkup(type) });
    this.trigger("change:attribute", this);
    return this;
  };

  getGatewayType = (): GatewayType => {
    return this.get("gatewayType");
  };

  private changeOutlineMarkup = (type: OutlineType) => {
    switch (type) {
      case OutlineType.DOUBLE:
        this.setVisibilityForInnerCircle("visible");
        this.setVisibilityForOuterCircle("visible");
        break;
      case OutlineType.SINGLE:
        this.setVisibilityForInnerCircle("visible");
        this.setVisibilityForOuterCircle("hidden");
        break;
      case OutlineType.NONE:
        this.setVisibilityForInnerCircle("hidden");
        this.setVisibilityForOuterCircle("hidden");
        break;
    }
  };

  private setVisibilityForInnerCircle = (visibility: string) => {
    this.attr({
      innerCircle: {
        visibility: visibility,
      },
    });
  };

  private setVisibilityForOuterCircle = (visibility: string) => {
    this.attr({
      outerCircle: {
        visibility: visibility,
      },
    });
  };
}

const getGateWayMarkup = (type: GatewayType) => {
  switch (type) {
    case GatewayType.NONE:
      return {
        d: "",
        fill: "none",
      };
    case GatewayType.COMPLEX:
      return {
        d: "M -1 -1 L -1 -5 L 1 -5 L 1 -1 L 4 -4 L 5 -3 L 2 0 L 5 3 L 4 4 L 1 1 L 1 5 L -1 5 L -1 1 L -4 4 L -5 3 L -2 0 L -5 -3 L -4 -4 Z",
        transform: "translate(25 25) scale(2)",
        fill: "020202",
      };
    case GatewayType.EVENT_BASED:
      return {
        d: "M 0 5 L -3 5 L -5 -1 L 0 -5 L 5 -1 L 3 5 Z",
        transform: "translate(25 25) scale(2)",
        fill: "none",
      };

    case GatewayType.EVENT_PARALLEL:
      return {
        d: "M -1 -5 L 1 -5 L 1 -1 L 5 -1 L 5 1 L 1 1 L 1 5 L -1 5 L -1 1 L -5 1 L -5 -1 L -1 -1 L -1 -5",
        transform: "translate(25 25) scale(2)",
        fill: "none",
      };
    case GatewayType.EXLUSIVE:
      return {
        d: "M 0 -1 L 3 -5 L 5 -5 L 1 0 L 5 5 L 3 5 L 0 1 L -3 5 L -5 5 L -1 0 L -5 -5 L -3 -5 Z",
        transform: "translate(25 25) scale(2)",
        fill: "020202",
      };
    case GatewayType.INCLUSIVE:
      return {
        d: "M 0 -5 Q 5 -5 5 0 Q 5 5 0 5 Q -5 5 -5 0 Q -5 -5 0 -5 Z",
        transform: "translate(25 25) scale(2)",
        fill: "none",
      };
    case GatewayType.PARALLEL:
      return {
        d: "M -1 -5 L 1 -5 L 1 -1 L 5 -1 L 5 1 L 1 1 L 1 5 L -1 5 L -1 1 L -5 1 L -5 -1 L -1 -1 L -1 -5",
        transform: "translate(25 25) scale(2)",
        fill: "020202",
      };
  }
};
