import { Component, createRef } from "react";
import { KEAPaper, keaNamespace, KEAPaperBuilder, PaperEventTypes } from "@kea-mod/jointjs";

import { KEAGraphContext } from "context/KEAGraphContext";
import { getZoomDisabled } from "helper/Helper";

const paperOptions: joint.dia.Paper.Options = {
  width: "100%",
  height: "100%",
  gridSize: 5,
  cellViewNamespace: keaNamespace,
  drawGrid: {
    name: "doubleMesh",
    args: [
      {
        color: "grey",
        thickness: 0.5,
      },
      {
        color: "#131313",
        scaleFactor: 16,
        thickness: 2,
      },
    ],
  },
  defaultConnectionPoint: { name: "boundary" },
  linkPinning: true,
  validateConnection: function () {
    return true;
  },
  defaultAnchor: {
    name: "modelCenter",
    args: {
      rotate: true,
      padding: 20,
    },
  },
  background: {
    color: "rgba(65, 131, 215, 0.1)",
  },
  interactive: false,
};

interface State {}

type Props = typeof PreviewGraph.defaultProps & {
  width: number;
  height: number;
};

export class PreviewGraph extends Component<Props, State> {
  static defaultProps = {
    pushPresent: () => {},
  };

  static contextType = KEAGraphContext;
  context!: React.ContextType<typeof KEAGraphContext>;

  private canvasContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.canvasContainerRef = createRef();
  }

  componentDidMount = () => {
    //this is a hacky, but works. Joint elements need to be in DOM to import new graphs. Otherwise, the scaling goes wrong. There is no way to guarantee, that the virtualDOM is indeed present in the actual DOM.
    //look at https://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(() => {
      this.context.setModelingPaper(this.initializePaper());
    }, 1000);
  };

  componentWillUnmount = () => {
    this.context.modelingPaper.removeDragPaperEvent();
  };

  initializePaper = (): KEAPaper => {
    const paperBuilder = new KEAPaperBuilder(this.canvasContainerRef, this.context.modelingGraph, paperOptions).event(
      PaperEventTypes.PAPER_DRAG,
    );

    if (!getZoomDisabled()) {
      paperBuilder.event(PaperEventTypes.PAPER_ZOOM);
    }

    return paperBuilder.build();
  };

  historyClb = () => {
    this.props.pushPresent();
  };

  render() {
    return (
      <div style={{ width: this.props.width, height: this.props.height }} className="box m-0 p-0">
        <div id="canvas" ref={this.canvasContainerRef}></div>
      </div>
    );
  }
}
