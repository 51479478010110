import { KEAGraph, KEALink, LinkConfiguration, keaNamespace } from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

let defaultLink = new KEALink();

export const getEmptyConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [];
};

export const getEmptyMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getEmptyConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
