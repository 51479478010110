import { dia, shapes, util } from "jointjs";

export enum LinkType {
  SOLID = "solid",
  DASHED = "dashed",
}

export enum MarkerType {
  DIAMOND_FILLED = "diamond_filled",
  DIAMOND_EMPTY = "diamond_empty",
  ARROW = "arrow",
  ARROW_FILLED = "arrow_filled",
  ARROW_EMPTY = "arrow_empty",
  ARROW_HALFFILLED = "arrow_halffilled",
  CIRCLE_EMPTY = "circle_empty",
  CIRCLE_FILLED = "circle_filled",
  CIRCLE_FILLED_ARROW = "circle_filled_arrow",
  PROVIDED_INTERFACE = "provided_interface",
  REQUIRED_INTERFACE = "required_interface",
  NONE = "none",
}

export enum MarkerPosition {
  SOURCE_MARKER = "sourceMarker",
  TARGET_MARKER = "targetMarker",
}

export enum InterfaceType {
  PROVIDED = "provided",
  REQUIRED = "required",
  NONE = "none",
}

export interface KEALinkLabel {
  position: LinkLabelPosition;
  value: string;
}

export enum LinkLabelPosition {
  SOURCE = "source",
  MID = "middle",
  TARGET = "target",
}

export class KEALink extends dia.Link {
  defaults() {
    return util.defaultsDeep(
      {
        type: "kea.Link",
        attrs: {
          line: {
            connection: true,
            stroke: "#333333",
            strokeDasharray: "",
            strokeWidth: 2,
            strokeLinejoin: "round",
            sourceMarker: {
              type: "path",
              d: "M 0 0 L 0 0",
            },
            targetMarker: {
              type: "path",
              d: "M 0 0 L 0 0",
            },
          },
          wrapper: {
            connection: true,
            strokeWidth: 10,
            strokeLinejoin: "round",
          },
        },
        linkType: LinkType.SOLID,
        targetMarker: MarkerType.NONE,
        sourceMarker: MarkerType.NONE,
        keaLabels: [],
        interface: InterfaceType.NONE,
        interfaceElement: undefined,
      },
      super.defaults,
    );
  }

  constructor(attributes?: any, options?: any) {
    super(attributes, options);
    this.on("change:target", () => {
      if (!this.get("interface") || this.get("interface") === false) return;
      this.updateInterfaceElement();
      const source = this.getSourceCell();
      const target = this.getTargetCell();
      if (
        this.getMarkerType(MarkerPosition.TARGET_MARKER) === MarkerType.REQUIRED_INTERFACE &&
        target &&
        this.getInterfaceType() === InterfaceType.REQUIRED
      ) {
        this.prop("target", {
          id: target.id,
          connectionPoint: {
            name: "bbox",
            args: {
              offset: 10,
            },
          },
        });
      }
      if (source && target) {
        if (source.get("type") === "kea.UMLPort") {
          if (target.get("type") === "kea.Link") {
            const link = target as KEALink;
            this.set("target", link.get("target"));
          }
        }
      }
    });
  }

  getInterfaceType = (): InterfaceType => {
    return this.get("interface");
  };

  setInterfaceType = (interfaceType: InterfaceType) => {
    this.set("interface", interfaceType);
  };

  getKEALabel = (position: LinkLabelPosition) => {
    const keaLabels: Array<KEALinkLabel> = this.get("keaLabels");
    const index = keaLabels.findIndex((keaLabel) => keaLabel.position === position);
    if (index === -1) return undefined;
    return keaLabels[index];
  };

  setKEALabel = (label: KEALinkLabel) => {
    let keaLabels: Array<KEALinkLabel> = this.get("keaLabels");
    const index = keaLabels.findIndex((keaLabel) => keaLabel.position === label.position);

    if (index !== -1) {
      keaLabels[index] = label;
    } else {
      keaLabels.push(label);
    }

    this.set("keaLabels", keaLabels);

    this.updateKEALabels();
    this.trigger("change:attribute", this);
  };

  deleteKEALabel = (label: KEALinkLabel) => {
    let keaLabels: Array<KEALinkLabel> = this.get("keaLabels");
    const index = keaLabels.findIndex((keaLabel) => keaLabel.position === label.position);

    if (index !== -1) {
      keaLabels.splice(index, 1);
    }
    this.set("keaLabels", keaLabels);
    this.updateKEALabels();
    this.trigger("change:attribute", this);
  };

  setOrthogonalLines = (value: boolean) => {
    if (value) {
      this.set("router", {
        name: "orthogonal",
      });
    } else {
      this.set("router", {
        name: "normal",
      });
    }
  };

  updateKEALabels = () => {
    this.labels([]);
    const keaLabels: Array<KEALinkLabel> = this.get("keaLabels");
    const keaLabelMarkup = [];
    for (let keaLabel of keaLabels) {
      keaLabelMarkup.push(KEALink.getLabelMarkup(keaLabel));
    }
    this.labels(keaLabelMarkup);
  };

  setDasharray = (type: LinkType): KEALink => {
    this.set("linkType", type);
    this.updateLine();
    this.trigger("change:attribute", this);
    return this;
  };

  getDasharray = (): LinkType => {
    return this.get("linkType");
  };

  updateLine = () => {
    const linkType = this.get("linkType");
    switch (linkType) {
      case LinkType.SOLID:
        this.attr({
          line: { strokeDasharray: "" },
        });
        break;
      case LinkType.DASHED:
      default:
        this.attr({
          line: { strokeDasharray: "5" },
        });
        break;
    }
  };

  getLabels = (): Array<KEALinkLabel> => {
    return this.get("keaLabels");
  };

  getMarkerType = (position: MarkerPosition) => {
    if (position === MarkerPosition.SOURCE_MARKER) {
      return this.get("sourceMarker");
    }
    if (position === MarkerPosition.TARGET_MARKER) {
      return this.get("targetMarker");
    }
  };

  setMarker = (position: MarkerPosition, type: MarkerType) => {
    position === MarkerPosition.SOURCE_MARKER ? this.set("sourceMarker", type) : this.set("targetMarker", type);
    this.updateMarkers();
    this.trigger("change:attribute", this);
  };

  updateMarkers = () => {
    this.updateMarker(MarkerPosition.SOURCE_MARKER);
    this.updateMarker(MarkerPosition.TARGET_MARKER);
  };

  highlight = (color: string) => {
    this.attr({
      line: {
        stroke: color,
        sourceMarker: {
          stroke: color,
        },
        targetMarker: {
          stroke: color,
        },
      },
    });
  };

  unhighlight = () => {
    this.attr({
      line: {
        stroke: "#333333",
        sourceMarker: {
          stroke: "#333333",
        },
        targetMarker: {
          stroke: "#333333",
        },
      },
    });
  };

  rotateLabels = () => {
    let source = this.getKEALabel(LinkLabelPosition.SOURCE);
    let target = this.getKEALabel(LinkLabelPosition.TARGET);

    if (source) {
      source.position = LinkLabelPosition.TARGET;
    }
    if (target) {
      target.position = LinkLabelPosition.SOURCE;
    }
    this.updateKEALabels();
    this.trigger("change:attribute", this);
  };

  updateInterfaceElement = () => {
    if (
      this.getInterfaceType() === InterfaceType.PROVIDED &&
      this.getMarkerType(MarkerPosition.SOURCE_MARKER) === MarkerType.NONE &&
      this.getMarkerType(MarkerPosition.TARGET_MARKER) === MarkerType.PROVIDED_INTERFACE &&
      !this.getTargetCell()
    ) {
      const interfaceElement = this.get("interfaceElement");

      if (this.graph) {
        if (interfaceElement) {
          interfaceElement.position(this.get("target").x, this.get("target").y);
          this.target(interfaceElement);
        } else {
          const element = new shapes.standard.Circle();
          element.size(20, 20);
          element.attr({
            body: {
              fill: "white",
              stroke: "#333333",
              strokeWidth: 3,
            },
          });
          element.position(this.get("target").x, this.get("target").y);
          this.graph.addCell(element);
          this.set("interfaceElement", element);
          this.target(element);
          this.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.PROVIDED_INTERFACE);
        }
      }
    }
  };

  updateMarker = (position: MarkerPosition) => {
    const halfFilledPath = position === MarkerPosition.SOURCE_MARKER ? "M 20 -10 L 0 0 L 20 0" : "M 20 0 L 0 0 L 20 10";
    const markerType = this.get(position);

    switch (markerType) {
      case MarkerType.ARROW_EMPTY:
        this.attr({
          line: {
            [position]: {
              d: "M 20 0 L 20 -10 L 0 0 L 20 10 Z",
              type: "path",
              stroke: "#333333",
              fill: "white",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.ARROW_FILLED:
        this.attr({
          line: {
            [position]: {
              d: "M 20 0 L 20 -10 L 0 0 L 20 10 Z",
              type: "path",
              stroke: "#333333",
              fill: "#333333",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.ARROW:
        this.attr({
          line: {
            [position]: {
              d: "M 20 -10 L 0 0 L 20 10",
              type: "path",
              stroke: "#333333",
              fill: "none",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.ARROW_HALFFILLED:
        this.attr({
          line: {
            [position]: {
              d: halfFilledPath,
              type: "path",
              stroke: "#333333",
              fill: "#333333",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.DIAMOND_EMPTY:
        this.attr({
          line: {
            [position]: {
              d: "M 20 0 L 10 -8 L 0 0 L 10 8 Z",
              type: "path",
              stroke: "#333333",
              fill: "white",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.DIAMOND_FILLED:
        this.attr({
          line: {
            [position]: {
              d: "M 20 0 L 10 -8 L 0 0 L 10 8 Z",
              type: "path",
              stroke: "#333333",
              fill: "#333333",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.CIRCLE_EMPTY:
        this.attr({
          line: {
            [position]: {
              d: "M 10 0 m -10, 0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
              type: "path",
              stroke: "#333333",
              fill: "white",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.CIRCLE_FILLED:
        this.attr({
          line: {
            [position]: {
              d: "M 10 0 m -10, 0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
              type: "path",
              stroke: "#333333",
              fill: "#333333",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.CIRCLE_FILLED_ARROW:
        this.attr({
          path: {
            [position]: {
              d: "M 20 -10 L 0 0 L 20 10",
              type: "path",
              stroke: "#333333",
              fill: "none",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
          line: {
            [position]: {
              d: "M -3 -10 a 10 10 0 1 0 0 20 a 10 10 0 1 0 0 -20",
              type: "path",
              stroke: "#333333",
              fill: "#333333",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.REQUIRED_INTERFACE:
        this.attr({
          line: {
            [position]: {
              d: "M -15 15 A 5 5 0 0 0 -15 -15",
              type: "path",
              stroke: "#333333",
              fill: "none",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.PROVIDED_INTERFACE:
        this.attr({
          line: {
            [position]: {
              d: this.get("interfaceElement")
                ? "M 0 0 L 0 0"
                : "M -10 0 m -10 0 a 10 10 0 1 0 20 0 a 10 10 0 1 0 -20 0",
              type: "path",
              stroke: "#333333",
              fill: "none",
              "stroke-dasharray": "100%",
              "stroke-width": 2,
            },
          },
        });
        break;
      case MarkerType.NONE:
        this.attr({
          line: { [position]: { type: "path", d: "M 0 0 L 0 0" } },
        });
        break;
    }
  };

  markup = [
    {
      tagName: "path",
      selector: "wrapper",
      attributes: {
        fill: "none",
        cursor: "pointer",
        stroke: "transparent",
        "stroke-linecap": "round",
      },
    },
    {
      tagName: "path",
      selector: "line",
      attributes: {
        fill: "none",
        "pointer-events": "none",
      },
    },
  ];

  static getLabelMarkup = (label: KEALinkLabel) => {
    const attrs = {
      text: {
        text: label.value,
      },
    };
    let labelPosition = { distance: 0, offset: 25 };

    switch (label.position) {
      case LinkLabelPosition.SOURCE:
        labelPosition["distance"] = 0.1;
        break;
      case LinkLabelPosition.MID:
        labelPosition["distance"] = 0.5;
        break;
      case LinkLabelPosition.TARGET:
        labelPosition["distance"] = 0.9;
        break;
    }

    return {
      attrs,
      position: labelPosition,
    };
  };
}
