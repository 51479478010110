import { WithChildren } from "@kea-mod/common";
import { FeedbackModalCard } from "modals/FeedbackModalCard";
import { Component } from "react";
import { FeedbackMessage } from "tasks/sample_feedback";
import { FeedbackContext } from "./FeedbackContext";
import { FeedbackModalCardContext } from "./FeedbackModalCardContext";

interface State {
  feedback: Array<FeedbackMessage>;
  visible: boolean;
}

interface Props extends WithChildren {}
export class FeedbackModalCardContextProvider extends Component<Props, State> {
  static contextType = FeedbackContext;
  context!: React.ContextType<typeof FeedbackContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      feedback: [],
      visible: false,
    };
  }

  toggleModal = (visible: boolean) => {
    this.setState(() => ({
      visible,
    }));
  };

  setNode = (node?: joint.dia.Element) => {
    if (!node) {
      this.toggleModal(false);
      return;
    }
    if (!node.id) {
      this.toggleModal(false);
      return;
    }

    const feedback = this.context.getFeedbackForNode(node);
    if (feedback.length === 0) return;
    this.setState({ feedback: feedback }, () => {
      this.toggleModal(true);
    });
  };

  render() {
    return (
      <FeedbackModalCardContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}

        <FeedbackModalCard
          setisActive={this.toggleModal}
          isActive={this.state.visible}
          feedback={this.state.feedback}
        />
      </FeedbackModalCardContext.Provider>
    );
  }
}
