import { useErrorMessageContext } from "context/ErrorModalContext";
import { useFeedbackContext } from "context/FeedbackContext";
import { useFeedbackModalCardContext } from "context/FeedbackModalCardContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { dimension, useWindowSizeContext, windowPadding } from "context/WindowSizeContext";
import { ContentView } from "pages/feedback/sample/content/ContentView";
import { useEffect, useState } from "react";
import { ContentContainer } from "shared/ContentContainer";
import { FeedbackMessage, sampleFeedbackMessages } from "tasks/sample_feedback";

interface Props {}

export const SampleFeedbackEditor = (_props: Props) => {
  const windowSizeContext = useWindowSizeContext();
  const keaGraphContext = useKEAGraphContext();
  const errorModalContext = useErrorMessageContext();
  const feedbackContext = useFeedbackContext();
  const feedbackModalContext = useFeedbackModalCardContext();

  const [contentDimension, setContentDimension] = useState<dimension>({
    width: 1,
    height: 1,
  });

  useEffect(() => {
    setContentDimension({
      width: 1,
      height: windowSizeContext.dimension.height - windowPadding,
    });
  }, [windowSizeContext.dimension.height]);

  const updateFeedback = async () => {
    const apiFeedbacks = sampleFeedbackMessages;
    let feedbacks: { [id: string]: Array<FeedbackMessage> } = {};
    let unresolvedFeedbacks: Array<FeedbackMessage> = [];
    let positionFeedback: Array<FeedbackMessage> = [];

    apiFeedbacks.forEach((item: FeedbackMessage) => {
      if (typeof item.position === "string") {
        if (!feedbacks[item.position]) {
          feedbacks[item.position] = [];
        }
        const newFeedack = feedbacks[item.position];
        newFeedack.push(item);
        feedbacks[item.position] = newFeedack;
      }
      if (typeof item.position === "object") {
        if (item.position.x && item.position.y) {
          positionFeedback.push(item);
        }
      }
      if (typeof item.position === "undefined") {
        unresolvedFeedbacks.push(item);
      }
    });
    feedbackContext.setFeedback(feedbacks);
    feedbackContext.setUnresolvedFeedback(unresolvedFeedbacks);
    feedbackContext.setPositionFeedback(positionFeedback);
  };

  return (
    <>
      <ContentContainer width={windowSizeContext.dimension.width} height={contentDimension.height}>
        <ContentView
          updateFeedback={updateFeedback}
          setNode={feedbackModalContext.setNode}
          width={windowSizeContext.dimension.width}
          height={contentDimension.height}
          modelingGraph={keaGraphContext.modelingGraph}
          modelingPaper={keaGraphContext.modelingPaper}
          feedback={feedbackContext.feedback}
          positionFeedback={feedbackContext.positionFeedback}
          unresolvedFeedbacks={feedbackContext.unresolvedFeedback}
          toggleErrorModal={errorModalContext.toggleErrorModal}
          addUserInteraction={keaGraphContext.addUserInteraction}
          keaGraphContext={keaGraphContext}
        />
      </ContentContainer>
    </>
  );
};
