import { WithChildren } from "@kea-mod/common";
import { Component } from "react";
import { ErrorModal } from "../modals/ErrorModal";
import { MessageType } from "../modals/SuccessModal";
import { ErrorMessageContext } from "./ErrorModalContext";

interface State {
  errorModalVisible: boolean;
  errorModalMessageType: MessageType;
}

interface Props extends WithChildren {}

export class ErrorModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorModalVisible: false,
      errorModalMessageType: MessageType.LOADING_ERROR,
    };
  }

  setErrorModalMessageType = (type: MessageType): void => {
    this.setState({
      errorModalMessageType: type,
    });
  };

  toggleErrorModal = (type?: MessageType) => {
    if (type) {
      this.setState(
        {
          errorModalMessageType: type,
        },
        () => {
          this.setState({
            errorModalVisible: !this.state.errorModalVisible,
          });
        },
      );
    } else {
      this.setState({
        errorModalVisible: !this.state.errorModalVisible,
      });
    }
  };

  render() {
    return (
      <ErrorMessageContext.Provider
        value={{
          errorModalMessageType: this.state.errorModalMessageType,
          setErrorModalMessageType: this.setErrorModalMessageType,
          toggleErrorModal: this.toggleErrorModal,
        }}
      >
        {this.props.children}

        <ErrorModal messageType={this.state.errorModalMessageType} isActive={this.state.errorModalVisible} />
      </ErrorMessageContext.Provider>
    );
  }
}
