import { ModelingLanguage } from "@kea-mod/common";
import { KEAGraph } from "@kea-mod/jointjs";
import { createContext, useContext } from "react";
import { APIManager } from "service/APIManager";
import { printOutsideContextProviderMessage } from "./ContextHelper";
import { Interaction } from "./KEAGraphContext";

export enum HTTPType {
  POST,
  PUT,
}

const printOutsideContext = () => {
  printOutsideContextProviderMessage("NameModelModalContext");
};

export interface INameModelModalContext {
  name: string;
  setName: (name: string) => void;
  type: HTTPType;
  setType: (type: HTTPType) => void;
  toggleNameModelModal: (value: boolean) => void;
  postModel: (
    model: KEAGraph,
    name: string,
    interaction: Interaction[],
    apiManager: APIManager,
    selectedModelingLanguage: ModelingLanguage,
  ) => void;
  putModel: (
    model: KEAGraph,
    name: string,
    interaction: Interaction[],
    apiManager: APIManager,
    id: number | null,
  ) => void;
}

const defaultState: INameModelModalContext = {
  name: "",
  setName: () => printOutsideContext(),
  type: HTTPType.POST,
  setType: () => printOutsideContext(),
  toggleNameModelModal: () => printOutsideContext(),
  postModel: () => printOutsideContext(),
  putModel: () => printOutsideContext(),
};

export const NameModelModalContext = createContext<INameModelModalContext>(defaultState);
export const useNameModelModalContext = () => useContext(NameModelModalContext);
