import * as joint from "jointjs";
import $ from "jquery";
import { find, uniqBy } from "lodash";
import { InteractionType } from "./InteractionType";
import { KEAElement } from "./KEAElement";
import { KEAGraph } from "./KEAGraph";
import { InterfaceType, KEALink, MarkerPosition, MarkerType } from "./KEALink";
import { keaNamespace } from "./KEANamespace";
import { boundaryTool } from "./element-tools/BoundaryTool";
import { bringToBackTool } from "./element-tools/BringToBackTool";
import { bringToFrontTool } from "./element-tools/BringToFrontTool";
import { removeTool } from "./element-tools/RemoveTool";
import { rotateTool } from "./element-tools/RotateTool";
import { startNewLinkTool } from "./element-tools/StartNewLinkTool";
import { SourceArrowhead, TargetArrowhead } from "./link-tools/ArrowheadTool";
import { editLinkTool } from "./link-tools/EditLinkTool";
import { switchMarkerEndsTool } from "./link-tools/SwitchMarkerEndsTool";
import {
  areRelated,
  createFlyDiv,
  dropElement,
  getClientPosition,
  getNearestCircle,
  getReactDiv,
  isInsidePaper,
  offsetFlyDiv,
} from "./misc/DragAndDrop";

const COPY_SPACING: number = 40;
const MIN_ZOOM: number = 0.5;
const MAX_ZOOM: number = 2;

export class KEAPaper extends joint.dia.Paper {
  historyClb?: () => void | undefined;
  interactionClb?: (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | KEALink | KEAElement | joint.dia.Cell[],
  ) => void | undefined;
  nodeClb?: (node: joint.dia.Cell) => void | undefined;
  inputDevice: string = "mouse";

  setinputDevice = (device: string) => {
    this.inputDevice = device;
  };

  setHistoryCallback = (historyClb: () => void) => {
    this.historyClb = historyClb;
  };

  setNodeCallback = (clb: (node: joint.dia.Cell) => void) => {
    this.nodeClb = clb;
  };

  setInterActionCallback = (
    interactionClb: (
      interactionType: InteractionType,
      interactionTime: number,
      payload?: KEAGraph | KEALink | KEAElement | joint.dia.Cell[],
    ) => void,
  ) => {
    this.interactionClb = interactionClb;
  };

  addSelectEvent = () => {
    this.on("element:pointerdblclick", (cellView: joint.dia.CellView) => {
      this.nodeClb?.(cellView.model);
    });
  };

  addLinkSelectEvent = () => {
    this.on("link:pointerdblclick", (cellView: joint.dia.CellView) => {
      this.nodeClb?.(cellView.model);
    });
  };

  addMouseWheelEvents = () => {
    this.on("paper:pinch", (evt: any, _x: any, _y: any, scale: any) => {
      evt.preventDefault();
      this.handlePinch(scale, evt.clientX || 0, evt.clientY || 0);
    });

    this.on("paper:pan", (evt: any, deltaX: any, deltaY: any) => {
      evt.preventDefault();
      if (this.inputDevice === "mouse") {
        this.handlePan(deltaY, evt.clientX || 0, evt.clientY || 0);
      } else {
        const origin = this.options.origin;
        if (!origin) return;
        const newX = origin.x + deltaX * -1;
        const newY = origin.y + deltaY * -1;
        this.translate(newX, newY);
      }
    });
  };

  private handlePinch = (scale: number, clientX: number, clientY: number) => {
    this.handleZoom(scale, clientX, clientY);
  };

  private handlePan = (delta: number, clientX: number, clientY: number) => {
    const scale = delta > 0 ? 0.95 : 1.05;
    this.handleZoom(scale, clientX, clientY);
  };

  private handleZoom = (scale: number, clientX: number, clientY: number) => {
    const currentScale = this.scale();
    const currentTranslation = this.translate();
    const newScaleX = parseFloat((currentScale.sx * scale).toFixed(2));
    const newScale = Math.min(MAX_ZOOM, Math.max(MIN_ZOOM, newScaleX));
    const origin = this.clientToLocalPoint({
      x: clientX,
      y: clientY,
    }).round();
    const newTx = currentTranslation.tx - origin.x * (newScale - currentScale.sx);
    const newTy = currentTranslation.ty - origin.y * (newScale - currentScale.sx);
    const ctm = joint.V.createSVGMatrix();
    ctm.e = newTx;
    ctm.f = newTy;
    ctm.a = newScale;
    ctm.d = newScale;
    this.matrix(ctm);
    this.update();
    scale < 1
      ? this.interactionClb?.(InteractionType.GRAPH_ZOOM_OUT, Date.now())
      : this.interactionClb?.(InteractionType.GRAPH_ZOOM_IN, Date.now());
  };

  _getCenterCoordinates() {
    return this.clientToLocalPoint({
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    });
  }

  zoomIn = () => {
    const newScale = this.scale().sx * 1.05;
    if (newScale > MAX_ZOOM) return;
    this.handleZoom(1.05, this._getCenterCoordinates().x, this._getCenterCoordinates().y);
    this.interactionClb?.(InteractionType.GRAPH_ZOOM_IN, Date.now());
  };

  zoomOut = () => {
    const newScale = this.scale().sx * 0.95;
    if (newScale < MIN_ZOOM) return;
    this.handleZoom(0.95, this._getCenterCoordinates().x, this._getCenterCoordinates().y);
    this.interactionClb?.(InteractionType.GRAPH_ZOOM_OUT, Date.now());
  };

  addResizeEvent = () => {
    this.on(
      "keaelement:resize",
      (
        view: any,
        evt: {
          stopPropagation: () => void;
        },
      ) => {
        evt.stopPropagation();
        //view.model.toFront();
        view.delegateDocumentEvents(
          {
            mousemove: function (evt: any) {
              const { paper, view } = evt.data;
              const { model } = view;
              const point = paper.clientToLocalPoint(evt.clientX, evt.clientY);
              const sourcepos = model.get("position");

              const keaelement = model as KEAElement;

              const minWidth = keaelement.getMinWidth();
              const minHeight = keaelement.getMinHeight();

              const resizeHeight = model.get("resizeHeight");
              const resizeWidth = model.get("resizeWidth");

              const element: KEAElement = model as KEAElement;

              if (resizeHeight && resizeWidth && minHeight && minWidth) {
                const angle: number = element.angle();
                var dx: number = getResizeX(point, sourcepos, angle);
                var dy: number = getResizeY(point, sourcepos, angle);
                if (dx < minWidth || !element.attr("resize_border_right")) dx = minWidth;
                if (dy < minHeight || !element.attr("resize_border_bottom")) dy = minHeight;

                element.resize(dx, dy, {
                  direction: getResizeDirection(angle),
                });
              } else if (minHeight && resizeHeight) {
                const angle: number = element.angle();

                var dy: number = getResizeY(point, sourcepos, angle);

                if (dy < minHeight || !element.attr("resize_border_bottom")) dy = minHeight;

                element.resize(element.size().width, dy, {
                  direction: getResizeDirection(angle),
                });
              } else if (minWidth && resizeWidth) {
                const angle: number = element.angle();

                var dx: number = getResizeX(point, sourcepos, angle);

                if (dx < minWidth || !element.attr("resize_border_right")) dx = minWidth;

                element.resize(dx, element.size().height, {
                  direction: getResizeDirection(angle),
                });
              }
            },
            mouseup: function (evt: any) {
              const { view } = evt.data;
              view.undelegateDocumentEvents();
            },
          },
          {
            center: view.model.getBBox().center(),
            paper: this,
            view,
          },
        );
      },
    );

    this.on(
      "keaelement:link",
      (
        view: any,
        evt: {
          stopPropagation: () => void;
          preventDefault: () => void;
          clientX: any;
          clientY: any;
        },
      ) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (view.model) {
          const link = view.paper.options.defaultLink().clone();
          link.source(view.model);
          const point = view.paper.clientToLocalPoint(evt.clientX, evt.clientY);
          link.target(point);
          link.addTo(view.paper.model);

          $("body").on("mousemove.fly", (e) => {
            const point = view.paper.clientToLocalPoint(e.clientX, e.clientY);
            link.target(point);
            const cells = view.paper.model.getCells();
            highlightHoveredElements(cells, link, view.paper, e);
          });

          $("body").on("mousedown.fly", (e) => {
            const x = e.pageX;
            const y = e.pageY;

            const target = this.$el.offset();

            if (x && y && target && isInsidePaper(x, y, view.paper)) {
              const viewPoint = view.paper.clientToLocalPoint(x, y);

              let position = {
                x: viewPoint.x,
                y: viewPoint.y,
              };
              if (this.options.gridSize) {
                position.x = Math.ceil(position.x / this.options.gridSize) * this.options.gridSize;
                position.y = Math.ceil(position.y / this.options.gridSize) * this.options.gridSize;
              }
              setLinkPosition(link, view.paper, position, LinkState.SELECTTARGET);
              unhighlightAllElements(view.paper);
              link.toFront();

              $("body").off("mousemove.fly").off("mousedown.fly");
            } else {
              $("body").off("mousemove.fly").off("mousedown.fly");
            }
          });
        }
      },
    );
  };

  addEmbeddEvents = () => {
    this.addResizeEvent();
    (this.model as KEAGraph).on("model:BringToBack", (model) => {
      const parentModel = model.getParentCell();
      if (parentModel) {
        parentModel.unembed(model);
      }
      var cellViewsAbove = this.findViewsFromPoint(model.getBBox().center());
      var filteredCellViewsAbove = cellViewsAbove.filter((c: joint.dia.CellView) => c.model.id !== model.id);
      if (filteredCellViewsAbove.length) {
        filteredCellViewsAbove.forEach((cellViewAbove: joint.dia.CellView) => {
          if (cellViewAbove.model.get("parent") !== model.id) {
            model.embed(cellViewAbove.model);
            this.interactionClb?.(InteractionType.NODE_EMBED, Date.now(), cellViewAbove.model as KEAElement);
            this.historyClb?.();
          }
        });
      }
    });

    this.on("cell:pointerdown", (cellView, _evt, _x, _y) => {
      var cell = cellView.model as KEAElement;

      if (!cell.get("embeds") || cell.get("embeds").length === 0) {
        cell.toFront();
      }

      if (cell.get("parent")) {
        this.model.getCell(cell.get("parent")).unembed(cell);
        this.interactionClb?.(InteractionType.NODE_UNEMBED, Date.now(), cell);
      }
    });

    this.on("cell:pointerup", (cellView, _evt, _x, _y) => {
      var cell = cellView.model as KEAElement;
      var cellViewsBelow = this.findViewsFromPoint(cell.getBBox().center());

      if (cellViewsBelow.length) {
        if (cell.attributes.type === "kea.UMLComponent" || cell.attributes.type === "kea.UMLPort") {
          let cellViewTop = cellViewsBelow.find((c) => {
            const embedded = c.model.getEmbeddedCells().map((c) => c.id);
            if (embedded.length === 0) return true;
            const viewCells = cellViewsBelow.map((c) => c.model.id);
            return !embedded.some((e) => viewCells.includes(e));
          });

          if (cellViewTop && !areRelated(cellViewTop.model, cell)) {
            cellViewTop.model.embed(cell);
            return;
          }
        }
        var cellViewBelow = find(cellViewsBelow, function (c: joint.dia.CellView) {
          return c.model.id !== cell.id;
        });

        //Should be changed when we want to embedd embedded elements
        if (cellViewBelow && !areRelated(cellViewBelow.model, cell)) {
          cellViewBelow.model.embed(cell);
          this.interactionClb?.(InteractionType.NODE_EMBED, Date.now(), cell);
          this.historyClb?.();
        }
      }
    });
  };

  setGridOptions = (drawGrid: any, gridSize: number, gridBackground: string) => {
    this.options.gridSize = gridSize;
    this.setGridSize(gridSize);
    this.drawBackground({
      color: gridBackground,
    });
    this.setGrid(drawGrid).drawGrid();
  };

  autoAnchor = (link: KEALink) => {
    const offset = 0.1;

    const source = link.get("source");
    const target = link.get("target");
    if (!source.id && !target.id) return;
    if (source.id === target.id) {
      if (link.get("vertices")) return;
      const element = link.getSourceElement();
      if (!element) return;
      const bbox = element.getBBox();
      const width = bbox.width;
      const height = bbox.height;
      if (width > height) {
        const offsetSourceX = offset * width;
        const offsetTargetX = (1 - offset) * width;
        link.source(element, {
          anchor: {
            name: "topLeft",
            args: {
              dx: offsetSourceX,
              dy: "50%",
              rotate: true,
            },
          },
        });
        link.target(element, {
          anchor: {
            name: "topLeft",
            args: {
              dx: offsetTargetX,
              dy: "50%",
              rotate: true,
            },
          },
        });
        const newVertices = [
          {
            x: bbox.x + offsetSourceX,
            y: bbox.y - 50,
          },
          {
            x: bbox.x + offsetTargetX,
            y: bbox.y - 50,
          },
        ];
        link.vertices(newVertices);
      } else {
        const offsetSourceY = offset * height;
        const offsetTargetY = (1 - offset) * height;
        link.source(element, {
          anchor: {
            name: "topLeft",
            args: {
              dx: "50%",
              dy: offsetSourceY,
              rotate: true,
            },
          },
        });
        link.target(element, {
          anchor: {
            name: "topLeft",
            args: {
              dx: "50%",
              dy: offsetTargetY,
              rotate: true,
            },
          },
        });
        const newVertices = [
          {
            x: bbox.x + 75,
            y: bbox.y + offsetSourceY,
          },
          {
            x: bbox.x + 75,
            y: bbox.y + offsetTargetY,
          },
        ];
        link.vertices(newVertices);
      }
    }
  };

  addElementDragAndDropEvent = (flyGraph: KEAGraph, dropPaper: KEAPaper, editorId?: string) => {
    this.on("element:pointerdown", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
      createFlyDiv(editorId);
      const flyPaper = this.createFlyPaper(flyGraph, this.historyClb);
      const flyShape = cellView.model.clone();
      if (flyGraph.getCells().length > 0) {
        for (let i = 0; i < flyGraph.getCells().length; i++) {
          flyGraph.getCells()[i].remove();
        }
      }
      let x = 0;
      let y = 0;
      if ((flyShape as KEAElement).getResizeHeight()) y = 10;
      if ((flyShape as KEAElement).getResizeWidth()) x = 10;
      // @ts-ignore
      flyShape.position(x, y);
      flyGraph.addCell(flyShape);
      flyPaper.fitToContent();
      this.startElementDragAndDrop(cellView, evt, flyPaper, flyGraph, dropPaper, editorId);
    });
  };
  addElementDragAndDropAndZoomEvent = (flyGraph: KEAGraph, dropPaper: KEAPaper, editorId?: string) => {
    let zoomPaper: KEAPaper | undefined;
    let zoomShape: joint.dia.Cell | undefined;
    let currentCellView: joint.dia.CellView | undefined;
    const offset = {
      x: -10,
      y: -10,
    };

    this.on("element:mouseenter", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
      if (!zoomShape || !zoomPaper || !currentCellView) {
        createFlyDiv(editorId);
        zoomPaper = this.createFlyPaper(flyGraph, this.historyClb);
        zoomShape = cellView.model.clone();
        currentCellView = cellView;
      } else {
        if (currentCellView.id !== cellView.id) {
          createFlyDiv(editorId);
          zoomPaper = this.createFlyPaper(flyGraph, this.historyClb);
          zoomShape = cellView.model.clone();
          currentCellView = cellView;
        } else {
          return;
        }
      }

      if (flyGraph.getCells().length > 0) {
        for (let i = 0; i < flyGraph.getCells().length; i++) {
          flyGraph.getCells()[i].remove();
        }
      }

      let x = 0;
      let y = 0;
      if ((zoomShape as KEAElement).getResizeHeight()) y = 10;
      if ((zoomShape as KEAElement).getResizeWidth()) x = 10;
      // @ts-ignore
      zoomShape.position(x, y);
      flyGraph.addCell(zoomShape);
      zoomPaper.fitToContent();

      offsetFlyDiv(this.options.gridSize, evt, offset);
      this.on("element:pointerdown", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
        if (!zoomShape || !zoomPaper || !currentCellView) return;
        zoomShape.remove();
        zoomPaper.dumpViews();
        dropPaper.dumpViews();
        zoomShape = undefined;
        currentCellView = undefined;
        this.startElementDragAndDrop(cellView, evt, zoomPaper, flyGraph, dropPaper, editorId);
        zoomPaper = undefined;
      });
      this.on("element:mouseleave", (cellView: joint.dia.CellView, _e: joint.dia.Event) => {
        if (!zoomShape || !zoomPaper || !currentCellView) return;
        if (currentCellView?.id !== cellView.id) return;
        $("body").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
        $("canvas").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
        zoomShape?.remove();
        $("#flyPaper").remove();
        unhighlightAllElements(dropPaper);
        dropPaper.dumpViews();
        zoomPaper = undefined;
        zoomShape = undefined;
        currentCellView = undefined;
      });
    });
  };
  addLinkDragAndDropEvent = (flyGraph: KEAGraph, dropPaper: KEAPaper, editorId?: string) => {
    this.on("link:pointerdown", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
      createFlyDiv(editorId);
      const flyPaper = this.createFlyPaper(flyGraph, this.historyClb);
      const flyShape = cellView.model.clone();
      const invisibleLink = flyShape.clone() as KEALink;
      if (flyGraph.getCells().length > 0) {
        for (let i = 0; i < flyGraph.getCells().length; i++) {
          flyGraph.getCells()[i].remove();
        }
      }
      // @ts-ignore
      flyShape.source({
        x: 5,
        y: 20,
      });
      // @ts-ignore
      flyShape.target({
        x: 205,
        y: 20,
      });

      invisibleLink.source({
        x: 5,
        y: 40,
      });

      invisibleLink.target({
        x: 210,
        y: 40,
      });
      invisibleLink.attr("line/sourceMarker", "none");
      invisibleLink.attr("line/targetMarker", "none");
      invisibleLink.attr("line", "none");
      flyGraph.addCell(invisibleLink);
      flyGraph.addCell(flyShape);
      flyPaper.fitToContent();
      this.startLinkDragAndDrop(cellView, evt, flyPaper, flyGraph, dropPaper, editorId);
    });
  };
  addLinkDragAndDropAndZoomEvent = (flyGraph: KEAGraph, dropPaper: KEAPaper, editorId?: string) => {
    let zoomPaper: KEAPaper | undefined;
    let zoomShape: joint.dia.Cell | undefined;
    let currentCellView: joint.dia.CellView | undefined;
    const offset = {
      x: -10,
      y: -10,
    };

    this.on("link:mouseenter", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
      if (!zoomShape || !zoomPaper || !currentCellView) {
        createFlyDiv(editorId);
        zoomPaper = this.createFlyPaper(flyGraph, this.historyClb);
        zoomShape = cellView.model.clone();
        currentCellView = cellView;
      } else {
        if (currentCellView.id !== cellView.id) {
          createFlyDiv(editorId);
          zoomPaper = this.createFlyPaper(flyGraph, this.historyClb);
          zoomShape = cellView.model.clone();
          currentCellView = cellView;
        } else {
          return;
        }
      }

      const invisibleLink = zoomShape.clone() as KEALink;
      // @ts-ignore
      zoomShape.source({
        x: 5,
        y: 20,
      });
      // @ts-ignore
      zoomShape.target({
        x: 205,
        y: 20,
      });

      invisibleLink.source({
        x: 5,
        y: 40,
      });

      invisibleLink.target({
        x: 210,
        y: 40,
      });
      invisibleLink.attr("line/sourceMarker", "none");
      invisibleLink.attr("line/targetMarker", "none");
      invisibleLink.attr("line", "none");
      flyGraph.addCell(invisibleLink);
      flyGraph.addCell(zoomShape);
      zoomPaper.fitToContent();

      offsetFlyDiv(this.options.gridSize, evt, offset);
      this.on("link:pointerdown", (cellView: joint.dia.CellView, evt: joint.dia.Event) => {
        if (!zoomShape || !zoomPaper || !currentCellView) return;
        zoomShape.remove();
        zoomPaper.dumpViews();
        dropPaper.dumpViews();
        zoomShape = undefined;
        currentCellView = undefined;
        this.startLinkDragAndDrop(cellView, evt, zoomPaper, flyGraph, dropPaper, editorId);
        zoomPaper = undefined;
      });
      this.on("link:mouseleave", (cellView: joint.dia.CellView, _e: joint.dia.Event) => {
        if (!zoomShape || !zoomPaper || !currentCellView) return;
        if (currentCellView?.id !== cellView.id) return;
        $("body").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
        $("canvas").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
        zoomShape?.remove();
        flyGraph.getCells().forEach((cell) => {
          cell.remove();
        });
        $("#flyPaper").remove();
        dropPaper.dumpViews();
        zoomPaper = undefined;
        zoomShape = undefined;
        currentCellView = undefined;
      });
    });
  };

  private startElementDragAndDrop = (
    cellView: joint.dia.CellView,
    e: joint.dia.Event,
    flyPaper: KEAPaper,
    flyGraph: KEAGraph,
    dropPaper: KEAPaper,
    editorId?: string,
  ) => {
    this.interactionClb?.(InteractionType.DRAG_AND_DROP_START, Date.now(), cellView.model as KEAElement);
    const flyShape = cellView.model.clone();
    let x = 0;
    let y = 0;
    if ((flyShape as KEAElement).getResizeHeight()) y = 10;
    if ((flyShape as KEAElement).getResizeWidth()) x = 10;
    // @ts-ignore
    flyShape.position(x, y);
    const offset = {
      x: 5,
      y: 5,
    };
    flyGraph.addCell(flyShape);
    flyPaper.fitToContent();

    if (this.options.gridSize) {
      $("#flyPaper").offset({
        left: Math.ceil(((e as any).pageX - offset.x) / this.options.gridSize) * this.options.gridSize,
        top: Math.ceil(((e as any).pageY - offset.y) / this.options.gridSize) * this.options.gridSize,
      });
    }
    const reactDiv = getReactDiv(editorId);
    const handleMouseLeave = () => {
      $("body").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
      $("canvas").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
      flyShape.remove();
      $("#flyPaper").remove();
      reactDiv.removeEventListener("mouseleave", handleMouseLeave);
      unhighlightAllElements(dropPaper);
      dropPaper.dumpViews();
      this.interactionClb?.(InteractionType.DRAG_AND_DROP_ABORT, Date.now(), flyShape as KEAElement);
    };
    reactDiv.addEventListener("mouseleave", handleMouseLeave);
    $("body").on("mousemove.fly", (e) => {
      offsetFlyDiv(this.options.gridSize, e, offset);
    });

    $("body").on("mouseup.fly", (_e) => {
      $("body").off("mousemove.fly").off("mouseup.fly");
      flyShape.remove();
      $("#flyPaper").remove();
    });

    $("body").on("mouseup.fly", (e) => {
      const x = e.pageX;
      const y = e.pageY;
      const target = this.$el.offset();
      if (x && y && target && !isInsidePaper(x, y, this) && isInsidePaper(x, y, dropPaper)) {
        dropElement(e, dropPaper, flyShape, this.options.gridSize);
        this.interactionClb?.(InteractionType.DRAG_AND_DROP_SUCCESS, Date.now(), flyShape as KEAElement);
        this.historyClb?.();
      } else {
        this.interactionClb?.(InteractionType.DRAG_AND_DROP_ABORT, Date.now());
      }
      $("#canvas").off("mousemove.fly").off("mouseup.fly");
      flyShape.remove();
      $("#flyPaper").remove();
      flyGraph.getCells().forEach((cell) => {
        cell.remove();
      });
      reactDiv.removeEventListener("mouseleave", handleMouseLeave);
    });
  };

  private startLinkDragAndDrop = (
    cellView: joint.dia.CellView,
    e: joint.dia.Event,
    flyPaper: KEAPaper,
    flyGraph: KEAGraph,
    dropPaper: KEAPaper,
    editorId?: string,
  ) => {
    this.interactionClb?.(InteractionType.DRAG_AND_DROP_START, Date.now(), cellView.model as KEAElement);
    const flyShape = cellView.model.clone();
    const invisibleLink = flyShape.clone() as KEALink;
    // @ts-ignore
    flyShape.source({
      x: 5,
      y: 20,
    });
    // @ts-ignore
    flyShape.target({
      x: 205,
      y: 20,
    });

    invisibleLink.source({
      x: 5,
      y: 40,
    });

    invisibleLink.target({
      x: 210,
      y: 40,
    });
    invisibleLink.attr("line/sourceMarker", "none");
    invisibleLink.attr("line/targetMarker", "none");
    invisibleLink.attr("line", "none");

    const offset = {
      x: 5,
      y: 5,
    };
    flyGraph.addCell(flyShape);
    flyGraph.addCell(invisibleLink);
    flyPaper.fitToContent();

    offsetFlyDiv(this.options.gridSize, e, offset);
    const reactApp = getReactDiv(editorId);
    const handleMouseLeave = () => {
      $("body").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
      $("canvas").off("mousemove.fly").off("mousedown.fly").off("mouseup.fly");
      flyShape.remove();
      $("#flyPaper").remove();
      reactApp.removeEventListener("mouseleave", handleMouseLeave);
      unhighlightAllElements(dropPaper);
      dropPaper.dumpViews();
      this.interactionClb?.(InteractionType.DRAG_AND_DROP_ABORT, Date.now(), flyShape as KEAElement);
    };
    reactApp.addEventListener("mouseleave", handleMouseLeave);
    let state = LinkState.SELECTSOURCE;
    const s: KEALink = flyShape.clone() as KEALink;
    const flyLink = flyShape as KEALink;
    $("body").on("mousemove.fly", (e) => {
      offsetFlyDiv(this.options.gridSize, e, offset);
      if (state === LinkState.SELECTTARGET) {
        const position = getClientPosition(dropPaper, e, this.options.gridSize);
        s.target(position);
      }

      const cells = dropPaper.model.getCells();

      if (state === LinkState.SELECTTARGET) {
        highlightHoveredElements(cells, s, dropPaper, e);
      } else {
        highlightHoveredElements(cells, flyLink, dropPaper, e);
      }
    });
    let counter = 0;
    $("body").on("mousedown.fly", (e) => {
      counter++;
      if (counter > 1) {
        const x = e.pageX;
        const y = e.pageY;

        const target = this.$el.offset();

        if (x && y && target && !isInsidePaper(x, y, this) && isInsidePaper(x, y, dropPaper)) {
          const position = getClientPosition(dropPaper, e, this.options.gridSize);
          if (state === LinkState.SELECTSOURCE) {
            setLinkPosition(s as KEALink, dropPaper, position, state);
            dropPaper.model.addCell(s);
            flyShape.remove();
            $("#flyPaper").remove();
            state = LinkState.SELECTTARGET;
          } else {
            setLinkPosition(s as KEALink, dropPaper, position, state);
            unhighlightAllElements(dropPaper);
            s.unhighlight();
            s.toFront();
            flyGraph.getCells().forEach((cell) => {
              cell.remove();
            });
            this.interactionClb?.(InteractionType.DRAG_AND_DROP_SUCCESS, Date.now(), s);
            this.historyClb?.();
            $("body").off("mousemove.fly").off("mousedown.fly");
          }
          this.autoAnchor(s);
        } else {
          this.interactionClb?.(InteractionType.DRAG_AND_DROP_ABORT, Date.now(), flyShape as KEAElement);
          unhighlightAllElements(dropPaper);
          $("body").off("mousemove.fly").off("mousedown.fly");
          flyShape.remove();
          flyGraph.getCells().forEach((cell) => {
            cell.remove();
          });
          $("#flyPaper").remove();
        }
        reactApp.removeEventListener("mouseleave", handleMouseLeave);
      }
    });
  };

  addDeleteEvent = () => {
    window.addEventListener("keydown", this.handleKeyDownDelete);
  };

  removeDeleteEvent = () => {
    window.removeEventListener("keydown", this.handleKeyDownDelete);
  };

  private onSelectCellClick = (cellView: joint.dia.CellView, evt: any) => {
    (this.model as KEAGraph).setSelected(cellView.model);
    if (evt.metaKey || evt.ctrlKey) {
      if (cellView.model instanceof KEAElement) {
        this.updateHighlightCopyPaste(() => {
          if (this.currentSelectedElements.indexOf(cellView.model as KEAElement) === -1) {
            this.currentSelectedElements.push(cellView.model as KEAElement);
          } else {
            this.currentSelectedElements = this.currentSelectedElements.filter((el) => el.id !== cellView.model.id);
          }
        });
      }
      if (cellView.model instanceof KEALink) {
        evt.preventDefault();
        const link = cellView.model as KEALink;
        this.updateHighlightCopyPaste(() => {
          if (this.currentSelectedLinks.indexOf(link) === -1) {
            this.currentSelectedLinks.push(link);
            if (
              link.getMarkerType(MarkerPosition.TARGET_MARKER) === MarkerType.PROVIDED_INTERFACE &&
              link.getTargetCell() !== undefined &&
              link.getTargetCell()?.attributes.type === "standard.Circle"
            ) {
              this.currentSelectedElements.push(link.getTargetCell() as KEAElement);
            }
          } else {
            this.currentSelectedLinks = this.currentSelectedLinks.filter((link2) => link2.id !== link.id);
            if (
              link.getMarkerType(MarkerPosition.TARGET_MARKER) === MarkerType.PROVIDED_INTERFACE &&
              link.getTargetCell() !== undefined &&
              link.getTargetCell()?.attributes.type === "standard.Circle"
            ) {
              this.currentSelectedElements = this.currentSelectedElements.filter(
                (el) => el.id !== link.getTargetCell()?.id,
              );
            }
          }
        });
      }
    }
  };

  addSelectField = () => {
    this.on("cell:pointerclick", (cellView: joint.dia.CellView, evt, _x, _y) => this.onSelectCellClick(cellView, evt));
  };

  removeSelectField = () => {
    this.off("cell:pointerclick", (cellView: joint.dia.CellView, evt, _x, _y) => this.onSelectCellClick(cellView, evt));
  };

  addCopyPasteMessageEvents = () => {
    window.addEventListener("message", this.handleMessageCopyPaste);
  };

  removeCopyPasteMessageEvents = () => {
    window.removeEventListener("message", this.handleMessageCopyPaste);
  };

  addCopyPastekeydownEvents = () => {
    window.addEventListener("keydown", this.handleKeydownCopyPaste);
    window.addEventListener("keyup", this.handleKeyupCopyPaste);
  };

  removeCopyPasteKeydownEvents = () => {
    window.removeEventListener("keydown", this.handleKeydownCopyPaste);
    window.removeEventListener("keyup", this.handleKeyupCopyPaste);
  };

  handleKeyDownDelete = (e: KeyboardEvent) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      this.doDelete();
    }
  };
  canDelete = false;

  setCanDelete = (canDelete: boolean) => {
    this.canDelete = canDelete;
  };
  doDelete = () => {
    if (!this.canDelete) return;
    const node = this.model.get("selected");
    if (node !== undefined) {
      this.interactionClb?.(InteractionType.NODE_DELETED, Date.now(), node);
      this.model.set("selected", undefined);
      node.remove();
    }
  };

  handleMessageCopyPaste = (e: MessageEvent) => {
    if (e.data) {
      if (e.data.type === "keydown") {
        this.checkCopyPaste(e.data.ctrlKey, e.data.metaKey, e.data.key);
      }
    }
  };

  handleKeydownCopyPaste = (e: KeyboardEvent) => {
    if (!this.canDelete) return; //Wenn das Löschen nicht erlaubt ist, dann soll auch nichts kopiert werden (wenn das Modal offen ist)
    this.checkCopyPaste(e.ctrlKey, e.metaKey, e.key);
    if (e.key === "Control" || e.key === "Meta") {
      const node = (this.model as KEAGraph).getSelected();
      if (node !== undefined) {
        if (!this.findViewByModel(node).hasTools()) return;
        if (node instanceof KEAElement) {
          this.updateHighlightCopyPaste(() => {
            this.currentSelectedElements.push(node as KEAElement);
          });
        }
        if (node instanceof KEALink) {
          this.updateHighlightCopyPaste(() => {
            this.currentSelectedLinks.push(node as KEALink);
          });
        }
      }
    }
  };
  currentSelectedElements: KEAElement[] = [];
  currentSelectedLinks: KEALink[] = [];
  currentCopiedElements: KEAElement[] = [];
  currentCopiedLinks: KEALink[] = [];

  handleKeyupCopyPaste = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      this.updateHighlightCopyPaste(() => {
        this.currentSelectedElements = [];
        this.currentSelectedLinks = [];
      });
    }
  };

  updateHighlightCopyPaste = (updateFunction?: () => void) => {
    this.currentSelectedElements.forEach((el) => {
      unhighlightElement(el, this);
    });
    this.currentSelectedLinks.forEach((link) => {
      link.unhighlight();
    });
    if (updateFunction) updateFunction();
    this.currentSelectedElements.forEach((el) => {
      highlightElement(el, this, "#0096FF");
    });
    this.currentSelectedLinks.forEach((link) => {
      link.highlight("#0096FF");
    });
  };
  checkCopyPaste = (ctrlKey: boolean, metaKey: boolean, key: string) => {
    if ((ctrlKey === true || metaKey === true) && key === "c") {
      this.doCopy();
    }
    if ((ctrlKey === true || metaKey === true) && key === "v") {
      this.doPaste();
    }
  };

  private removeDuplicates = (array: any[]) => {
    return uniqBy(array, (e) => e.id);
  };

  doCopy = () => {
    if (this.currentSelectedElements.length > 0 || this.currentSelectedLinks.length > 0) {
      this.currentSelectedElements = this.removeDuplicates(this.currentSelectedElements) as KEAElement[];
      this.currentSelectedLinks = this.removeDuplicates(this.currentSelectedLinks) as KEALink[];
      const cells = (this.currentSelectedElements as joint.dia.Cell[]).concat(this.currentSelectedLinks);
      this.currentCopiedElements = [...this.currentSelectedElements];
      this.currentCopiedLinks = [...this.currentSelectedLinks];
      this.interactionClb?.(InteractionType.COPY_STRG_C, Date.now(), cells);
    }
  };

  lastPastedCells: joint.dia.Cell[] = [];

  _compareCellArrays = (a: joint.dia.Cell[], b: joint.dia.Cell[]) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i].id !== b[i].id) return false;
    }
    return true;
  };

  doPaste = () => {
    if (this.currentCopiedElements.length == 0 && this.currentCopiedLinks.length == 0) return;
    const newPastedCells: joint.dia.Cell[] = (this.currentCopiedElements as joint.dia.Cell[]).concat(
      this.currentCopiedLinks,
    );
    if (!this._compareCellArrays(this.lastPastedCells, newPastedCells)) (this.model as KEAGraph).setTimesCopied(1);
    this.lastPastedCells = newPastedCells;
    const copiedCells: joint.dia.Cell[] = [];
    const oldToNewIdMap: Map<string, string> = new Map();
    if (this.currentCopiedElements.length > 0) {
      this.currentCopiedElements.forEach((el) => {
        unhighlightElement(el, this);
        const copiedNode = el.clone();
        const position: joint.dia.Point = {
          x: el.get("position")!.x + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
          y: el.get("position")!.y + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
        };
        copiedNode.set("position", position);
        this.model.addCell(copiedNode);
        copiedCells.push(copiedNode);
        oldToNewIdMap.set(el.id as string, copiedNode.id as string);
      });
    }
    if (this.currentCopiedLinks.length > 0) {
      this.currentCopiedLinks.forEach((el) => {
        el.unhighlight();
        const copiedLink = el.clone();
        const source = copiedLink.get("source");
        const target = copiedLink.get("target");
        if (source.id && oldToNewIdMap.has(source.id)) {
          copiedLink.set("source", { id: oldToNewIdMap.get(source.id) });
        }
        if (target.id && oldToNewIdMap.has(target.id)) {
          copiedLink.set("target", { id: oldToNewIdMap.get(target.id) });
        }
        if (!source.id && source.x !== undefined && source.y !== undefined) {
          copiedLink.set("source", {
            x: source.x + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
            y: source.y + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
          });
        }

        // Prüfen, ob das Ziel eine Position ist
        if (!target.id && target.x !== undefined && target.y !== undefined) {
          copiedLink.set("target", {
            x: target.x + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
            y: target.y + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
          });
        }

        if (copiedLink.get("vertices")) {
          const vertices = copiedLink.get("vertices") as joint.dia.Point[];
          const newVertices = vertices.map((vertex) => {
            return {
              x: vertex.x + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
              y: vertex.y + (this.model as KEAGraph).getTimesCopied() * COPY_SPACING,
            };
          });
          copiedLink.set("vertices", newVertices);
        }

        this.model.addCell(copiedLink);
        copiedCells.push(copiedLink);
      });
    }
    (this.model as KEAGraph).setTimesCopied((this.model as KEAGraph).getTimesCopied() + 1);
    this.updateHighlightCopyPaste();
    this.historyClb?.();
    this.interactionClb?.(InteractionType.COPY_STRG_V, Date.now(), copiedCells);
  };

  addPaperDragEvents = () => {
    this.on("blank:pointerdown", (_event, x, y) => {
      (this.model as KEAGraph).setDragStartPosition({
        x: x * this.scale().sx,
        y: y * this.scale().sy,
      });
      this.interactionClb?.(InteractionType.GRAPH_DRAG_START, Date.now());
    });
    this.on("cell:pointerup blank:pointerup", (_cellView: any, _x: any, _y: any) => {
      if ((this.model as KEAGraph).getDragStartPosition().x !== undefined) {
        (this.model as KEAGraph).setDragStartPosition({
          x: undefined,
          y: undefined,
        });
        this.interactionClb?.(InteractionType.GRAPH_DRAG_END, Date.now());
      }
    });
    $(this.$document).on("mousemove", (event: any) => {
      this.handleDragEvent(event);
    });
  };

  addElementToolEvents = () => {
    this.on("element:pointerclick", (elementView) => {
      this.removeAllElementTools();
      this.addElementTools(elementView);
    });

    this.on("blank:pointerclick", (_evt, _x, _y) => {
      this.removeAllElementTools();
    });
  };

  addLinkDragEvents = () => {
    this.on("blank:pointermove, element:pointermove, cell:pointermove", (_cellView: any, _evt: any, x: any, y: any) => {
      if ((this.model as KEAGraph).getActiveLink()) {
        (this.model as KEAGraph).getActiveLink()?.target({ x: x, y: y });
      }
    });

    this.on("element:pointerup", (_elementView, _evt, x, y) => {
      if ((this.model as KEAGraph).getActiveLink()) {
        var coordinates = new joint.g.Point(x, y);

        (this.model as KEAGraph).getActiveLink()?.target(coordinates);

        (this.model as KEAGraph).set("activeLink", undefined);
      }
      this.setInteractivity({
        elementMove: true,
      });
    });

    this.on("all", () => {
      if ((this.model as KEAGraph).getActiveLink() !== undefined) {
        this.setInteractivity({
          elementMove: false,
        });
      }
    });
  };

  addLinkToolEvents = () => {
    this.on("link:pointerclick", function (linkView) {
      const verticesTool = new joint.linkTools.Vertices();
      const segmentsTool = new joint.linkTools.Segments();
      const deleteTool = new joint.linkTools.Remove({
        offset: 10,
        distance: 80,
      });
      const boundaryTool = new joint.linkTools.Boundary();

      const targetAnchorTool = new joint.linkTools.TargetAnchor();
      const sourceAnchorTool = new joint.linkTools.SourceAnchor();

      const sourceArrowheadTool = new SourceArrowhead({
        focusOpacity: 0.5,
        offset: 15,
      });

      const targetArrowheadTool = new TargetArrowhead({
        focusOpacity: 0.5,
        offset: -15,
      });

      const tools = [
        sourceAnchorTool,
        verticesTool,
        boundaryTool,
        segmentsTool,
        deleteTool,
        sourceArrowheadTool,
        editLinkTool,
      ];

      const kealink = linkView.model as KEALink;

      if (
        kealink.getMarkerType(MarkerPosition.TARGET_MARKER) !== MarkerType.PROVIDED_INTERFACE &&
        kealink.getMarkerType(MarkerPosition.TARGET_MARKER) !== MarkerType.REQUIRED_INTERFACE
      ) {
        tools.push(switchMarkerEndsTool);
      }

      if (kealink.getMarkerType(MarkerPosition.TARGET_MARKER) !== MarkerType.PROVIDED_INTERFACE) {
        tools.push(targetAnchorTool);
        tools.push(targetArrowheadTool);
      }

      const toolsView = new joint.dia.ToolsView({
        tools: tools,
      });

      linkView.addTools(toolsView);
    });
  };

  removeDragPaperEvent = () => {
    $(this.$document).off("mousemove");
  };

  private createFlyPaper = (graph: KEAGraph, historyClb?: () => void): KEAPaper => {
    const flyPaper = new KEAPaper({
      el: $("#flyPaper"),
      width: 200,
      height: 200,
      cellViewNamespace: keaNamespace,
      background: {
        color: "rgba(65, 131, 215, 0.1)",
        opacity: 0,
      },
      model: graph,
      interactive: false,
    });
    if (historyClb) {
      flyPaper.setHistoryCallback(historyClb);
    }
    return flyPaper;
  };

  private handleDragEvent = (event: any) => {
    const dragStartPosition = (this.model as KEAGraph).getDragStartPosition();
    if (dragStartPosition.x !== undefined && dragStartPosition.y !== undefined) {
      this.translate(event.offsetX - dragStartPosition.x, event.offsetY - dragStartPosition.y);
    }
  };

  removeAllElementTools = () => {
    this.model.getCells().forEach((cell: joint.dia.Cell) => {
      const viewModel = this.findViewByModel(cell);
      if (viewModel) {
        viewModel.hasTools() ? viewModel.removeTools() : void 0;
      }
    });
  };

  private addElementTools(elementView: joint.dia.ElementView) {
    if (!(elementView.model instanceof KEAElement)) {
      return;
    }
    const element: KEAElement = elementView.model as unknown as KEAElement;

    if (element.attributes.type === "kea.UMLComponent") {
      elementView.addTools(
        new joint.dia.ToolsView({
          tools: [removeTool, boundaryTool, bringToFrontTool, bringToBackTool],
        }),
      );
      return;
    }

    if (!element.getRotateable()) {
      elementView.addTools(
        new joint.dia.ToolsView({
          tools: [removeTool, boundaryTool, bringToFrontTool, bringToBackTool, startNewLinkTool],
        }),
      );
    } else {
      elementView.addTools(
        new joint.dia.ToolsView({
          tools: [removeTool, boundaryTool, bringToFrontTool, bringToBackTool, startNewLinkTool, rotateTool],
        }),
      );
    }
  }

  handleFit = () => {
    this.scaleContentToFit({
      padding: 10,
      minScale: 0.2,
      maxScale: 1.5,
    });
  };
}

const getResizeDirection = (angle: number): joint.dia.Direction => {
  switch (angle) {
    case 90:
      return "top-right";
    case 180:
      return "top-left";
    case 270:
      return "bottom-left";
    default:
      return "bottom-right";
  }
};

const getResizeX = (point: joint.g.Point, sourcepos: joint.g.Point, angle: number): number => {
  switch (angle) {
    case 90:
    case 270:
      return point.y - sourcepos.y;
    default:
      return point.x - sourcepos.x;
  }
};
const getResizeY = (point: joint.g.Point, sourcepos: joint.g.Point, angle: number): number => {
  switch (angle) {
    case 90:
    case 270:
      return point.x - sourcepos.x;
    default:
      return point.y - sourcepos.y;
  }
};

const highlightElement = (cell: joint.dia.Cell, dropPaper: KEAPaper, color?: string) => {
  const cellView = dropPaper.findViewByModel(cell);
  if (!color) color = "#43a148";
  if (cellView) {
    joint.highlighters.mask.add(cellView, "body", "highlighter-selector", {
      attrs: {
        stroke: color,
        "stroke-width": 4,
      },
    });
  }
};

const unhighlightElement = (cell: joint.dia.Cell, dropPaper: KEAPaper) => {
  const cellView = dropPaper.findViewByModel(cell);
  if (cellView) {
    joint.dia.HighlighterView.remove(cellView);
  }
};

const highlightHoveredElements = (cells: joint.dia.Cell[], link: KEALink, paper: KEAPaper, event: any) => {
  const position = getClientPosition(paper, event, paper.options.gridSize);
  cells.forEach((cell: any) => {
    if (cell.id !== link.source().id && cell.id !== link.target().id) {
      const x1 = cell.getBBox().x;
      const y1 = cell.getBBox().y;
      const x2 = cell.getBBox().x + cell.getBBox().width;
      const y2 = cell.getBBox().y + cell.getBBox().height;

      // Überprüfen, ob das aktuelle Element das oberste in der Z-Index-Reihenfolge innerhalb seines Papier-Views ist
      const views = paper.findViewsFromPoint(position);
      const isTopmost = views.length > 0 && views[views.length - 1].model.id === cell.id;

      if (isTopmost && position.x >= x1 && position.x <= x2 && position.y >= y1 && position.y <= y2) {
        highlightElement(cell, paper);
      } else {
        unhighlightElement(cell, paper);
      }
    }
  });
};

const unhighlightAllElements = (dropPaper: KEAPaper) => {
  const cells = dropPaper.model.getCells();
  cells.forEach((cell) => {
    unhighlightElement(cell, dropPaper);
  });
};

const setLinkPosition = (link: joint.dia.Link, dropPaper: KEAPaper, position: any, state: LinkState) => {
  const cells = dropPaper.model.getCells();
  let connected = false;
  const kealink = link as KEALink;
  cells.forEach((cell) => {
    if (!connected) {
      const x1 = cell.getBBox().x;
      const y1 = cell.getBBox().y;
      const x2 = cell.getBBox().x + cell.getBBox().width;
      const y2 = cell.getBBox().y + cell.getBBox().height;
      if (!(cell as KEAElement).setSecondaryColor && (!link.get("interface") || link.get("interface") === false))
        return;
      // Überprüfen, ob das aktuelle Element das oberste in der Z-Index-Reihenfolge innerhalb seines Papier-Views ist
      const views = dropPaper.findViewsFromPoint({
        x: position.x,
        y: position.y,
      });
      const isTopmost = views.length > 0 && views[views.length - 1].model.id === cell.id;
      if (isTopmost && position.x > x1 && position.x < x2 && position.y > y1 && position.y < y2) {
        connected = true;
        switch (state) {
          case LinkState.SELECTSOURCE:
            if (cell.attributes.type === "kea.UMLLifeLine") {
              const dx = ((position.x - cell.getBBox().x) / cell.getBBox().width) * 100 + "%";
              const dy = ((position.y - cell.getBBox().y) / cell.getBBox().height) * 100 + "%";
              link.set("source", {
                id: cell.id,
                selector: "body",
                connectionPoint: {
                  name: "boundary",
                },
                anchor: {
                  name: "topLeft",
                  args: {
                    dx: dx,
                    dy: dy,
                  },
                },
              });
            } else {
              link.set("source", {
                id: cell.id,
                selector: "boundary",
              });
            }
            link.target({
              x: position.x,
              y: position.y,
            });
            break;
          case LinkState.SELECTTARGET:
            if (cell.attributes.type === "kea.UMLLifeLine") {
              const dx = ((position.x - cell.getBBox().x) / cell.getBBox().width) * 100 + "%";
              const dy = ((position.y - cell.getBBox().y) / cell.getBBox().height) * 100 + "%";
              link.set("target", {
                id: cell.id,
                selector: "body",
                connectionPoint: {
                  name: "boundary",
                },
                anchor: {
                  name: "topLeft",
                  args: {
                    dx: dx,
                    dy: dy,
                  },
                },
              });
            } else {
              if (kealink.getInterfaceType() !== InterfaceType.NONE) {
                link.set("target", {
                  id: cell.id,
                  selector: "body",
                });
              } else {
                link.set("target", {
                  id: cell.id,
                  selector: "boundary",
                });
              }
            }
            break;
        }
      }
    }
  });
  if (connected) return;
  if (state === LinkState.SELECTSOURCE) {
    link.source({
      x: position.x,
      y: position.y,
    });
  }
  if (
    state === LinkState.SELECTTARGET &&
    kealink.getMarkerType(MarkerPosition.TARGET_MARKER) === MarkerType.REQUIRED_INTERFACE
  ) {
    const nearestCircle = getNearestCircle(cells, position.x, position.y);
    if (nearestCircle) {
      link.set("target", {
        id: nearestCircle.id,
        selector: "body",
      });
    } else {
      link.target({
        x: position.x,
        y: position.y,
      });
    }
  } else {
    link.target({
      x: position.x,
      y: position.y,
    });
  }
};

enum LinkState {
  SELECTSOURCE,
  SELECTTARGET,
}
