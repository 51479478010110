import { useAPIContext } from "context/APIContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useNameModelModalContext } from "context/NameModelModalContext";
import { getModeltype, getParentId, getParentModel } from "helper/Helper";
import { useEffect, useState } from "react";
import { KeaButton } from "./KEAButton";

type Props = {};

export const SaveModelButton = (_props: Props) => {
  const apiContext = useAPIContext();
  const keaGraphContext = useKEAGraphContext();
  const nameModelModalContext = useNameModelModalContext();

  const [parentModel, setParentModel] = useState<string | null>(null);
  const [parentId, setParentId] = useState<number | null>(null);
  const [type, setType] = useState<string | null>(null);

  useEffect(() => {
    setParentModel(getParentModel());
    setParentId(getParentId());
    setType(getModeltype());
  }, []);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (type === "studentSolution") {
      apiContext.apiManager.sendModel(
        {
          graph: keaGraphContext.modelingGraph.toJSON(),
        },
        keaGraphContext.getUserInteractions(),
        type || "",
        undefined,
        parentModel || undefined,
        parentId || undefined,
      );
      return;
    }

    nameModelModalContext.toggleNameModelModal(true);
    return;
  };

  return <KeaButton onClick={handleClick}>speichern</KeaButton>;
};
