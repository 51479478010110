import { WithChildren } from "@kea-mod/common";
import { Component } from "react";
import { FeedbackMessage } from "tasks/sample_feedback";
import { FeedbackContext } from "./FeedbackContext";

interface State {
  feedback: { [id: string]: Array<FeedbackMessage> };
  unresolvedFeedback: Array<FeedbackMessage>;
  positionFeedback: Array<FeedbackMessage>;
}

interface Props extends WithChildren {}

export class FeedbackContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      feedback: {},
      unresolvedFeedback: [],
      positionFeedback: [],
    };
  }

  setFeedback = (feedback: { [id: string]: Array<FeedbackMessage> }) => {
    this.setState({
      feedback,
    });
  };

  setUnresolvedFeedback = (feedback: Array<FeedbackMessage>) => {
    this.setState({
      unresolvedFeedback: feedback,
    });
  };

  setPositionFeedback = (feedback: Array<FeedbackMessage>) => {
    this.setState({
      positionFeedback: feedback,
    });
  };

  getFeedbackForNode = (node: joint.dia.Cell): Array<FeedbackMessage> => {
    const allIds = Object.keys(this.state.feedback);
    if (allIds.includes(node.get("id"))) {
      return this.state.feedback[node.get("id")];
    } else {
      const positionFeedbacks: Array<FeedbackMessage> = [];

      this.state.positionFeedback.forEach((positionFeedback) => {
        const nodePosition = node.get("position") as joint.dia.Point;

        if (nodePosition) {
          if (typeof positionFeedback.position === "object") {
            if (positionFeedback.position.x === nodePosition.x && positionFeedback.position.y === nodePosition.y) {
              positionFeedbacks.push(positionFeedback);
            }
          }
        }
      });
      if (positionFeedbacks.length > 0) {
        return positionFeedbacks;
      }

      return [];
    }
  };

  render() {
    return (
      <FeedbackContext.Provider
        value={{
          feedback: this.state.feedback,
          unresolvedFeedback: this.state.unresolvedFeedback,
          positionFeedback: this.state.positionFeedback,
          getFeedbackForNode: this.getFeedbackForNode,
          setFeedback: this.setFeedback,
          setUnresolvedFeedback: this.setUnresolvedFeedback,
          setPositionFeedback: this.setPositionFeedback,
        }}
      >
        {this.props.children}
      </FeedbackContext.Provider>
    );
  }
}
