import { ModelSetOptions } from "backbone";
import { dia } from "jointjs";
export enum LabelPosition {
  ABOVE = "ABOVE",
  BELOW = "BELOW",
  CENTER = "CENTER",
}

export const getLabelMarkup = (position: LabelPosition): any => {
  switch (position) {
    case LabelPosition.ABOVE:
      return { refX: "50%", refY: "-25%" };
    case LabelPosition.BELOW:
      return { refX: "50%", refY: "125%" };
    case LabelPosition.CENTER:
      return { refX: "50%", refY: "50%" };
  }
};

export class KEAElement extends dia.Element<dia.Element.Attributes, ModelSetOptions> {
  defaults(): any {
    return {
      ...super.defaults,
      primaryColor: "#333333",
      secondaryColor: "#FFFFFF",
      strokeWidth: 2,
      labelColor: "#333333",
      fillOpacity: 1.0,
      label: "",
      labelPosition: LabelPosition.CENTER,
      resizeHeight: false,
      resizeWidth: false,
      rotatable: false,
      minWidth: 100,
      minHeight: 100,
    };
  }

  constructor(attributes?: dia.Element.Attributes, options?: ModelSetOptions) {
    super(attributes, options);
    this.onChangePrimaryColor(this, localStorage.getItem("primaryColor") || "#333333", {});
    this.onChangeSecondaryColor(this, localStorage.getItem("secondaryColor") || "#FFFFFF", {});
    this.onChangeLabelColor(this, this.getLabelColor(), {});
  }

  onChangeResizeHeight(_element: KEAElement, _property: boolean, _options: any): void {}

  onChangeResizeWidth(_element: KEAElement, _property: boolean, _options: any): void {}

  onChangePrimaryColor(_element: KEAElement, _property: string, _options: any): void {}

  onChangeFillOpacity(_element: KEAElement, _property: string, _options: any): void {}

  onChangeSecondaryColor(_element: KEAElement, _property: string, _options: any): void {}

  onChangeStrokeWidth(_element: KEAElement, _property: string, _options: any): void {}

  onChangeLabelColor(_element: KEAElement, _property: string, _options: any): void {}

  onChangeLabel(_element: KEAElement, _property: string, _options: any): void {}

  onChangeLabelPosition(_element: KEAElement, _property: string, _options: any): void {}

  setRotateable(rotateable: boolean): KEAElement {
    this.set("rotatable", rotateable);
    return this;
  }

  getRotateable(): boolean {
    return this.get("rotatable");
  }

  setResizeHeight(resizeHeight: boolean): KEAElement {
    this.set("resizeHeight", resizeHeight);
    return this;
  }

  getResizeHeight(): boolean {
    return this.get("resizeHeight");
  }

  setResizeWidth(resizeWidth: boolean): KEAElement {
    this.set("resizeWidth", resizeWidth);
    return this;
  }

  getResizeWidth(): boolean {
    return this.get("resizeWidth");
  }

  getMinWidth(): number {
    return this.get("minWidth");
  }

  setMinWidth(minWidth: number): KEAElement {
    this.set("minWidth", minWidth);
    return this;
  }

  getMinHeight(): number {
    return this.get("minHeight");
  }

  setMinHeight(minHeight: number): KEAElement {
    this.set("minHeight", minHeight);
    return this;
  }

  setPrimaryColor = (color: string): KEAElement => {
    this.prop("primaryColor", color);
    return this;
  };

  getPrimaryColor = (): string => {
    return this.get("primaryColor");
  };

  setFillOpacity = (opacity: number): KEAElement => {
    this.prop("fillOpacity", opacity);
    return this;
  };

  getFillOpacity = (): number => {
    return this.get("fillOpacity");
  };

  setStrokeWidth = (width: number): KEAElement => {
    this.prop("strokeWidth", width);
    return this;
  };

  getStrokeWidth = (): number => {
    return this.get("strokeWidth");
  };

  setSecondaryColor = (color: string): KEAElement => {
    this.prop("secondaryColor", color);
    return this;
  };

  getSecondaryColor = (): string => {
    return this.get("secondaryColor");
  };

  setLabelColor = (color: string): KEAElement => {
    this.prop("labelColor", color);
    return this;
  };

  getLabelColor = (): string => {
    return this.get("labelColor");
  };

  setLabel = (text: string): KEAElement => {
    this.prop("label", text);
    return this;
  };

  getLabel = (): string => {
    return this.get("label");
  };

  setLabelPosition(position: LabelPosition): KEAElement {
    this.set("labelPosition", position);
    return this;
  }

  getLabelPosition(): LabelPosition {
    return this.get("labelPosition");
  }

  initialize(): void {
    dia.Element.prototype.initialize.apply(this, arguments as any);
    this.on("change:primaryColor", this.onChangePrimaryColor);
    this.on("change:secondaryColor", this.onChangeSecondaryColor);
    this.on("change:strokeWidth", this.onChangeStrokeWidth);
    this.on("change:labelColor", this.onChangeLabelColor);
    this.on("change:fillOpacity", this.onChangeFillOpacity);
    this.on("change:label", this.onChangeLabel);
    this.on("change:labelPosition", this.onChangeLabelPosition);
    this.on("change:resizeHeight", this.onChangeResizeHeight);
    this.on("change:resizeWidth", this.onChangeResizeWidth);
    this.onChangeResizeHeight(this, this.getResizeHeight(), {});
    this.onChangeResizeWidth(this, this.getResizeWidth(), {});
  }
}
