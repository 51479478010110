import {
  KEABPMNSwimlane,
  KEAGraph,
  KEALink,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const swimlane1 = new KEABPMNSwimlane().position(10, 10).setNumberOfLanes(1);

let link1 = new KEALink();
link1.source({ x: 10, y: 200 });
link1.target({ x: 200, y: 200 });
link1.setDasharray(LinkType.SOLID);
link1.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link1.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

let link2 = new KEALink();
link2.source({ x: 10, y: 225 });
link2.target({ x: 200, y: 225 });
link2.setDasharray(LinkType.DASHED);
link2.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.CIRCLE_EMPTY);
link2.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_EMPTY);

let link3 = new KEALink();
link3.source({ x: 10, y: 250 });
link3.target({ x: 200, y: 250 });
link3.setDasharray(LinkType.DASHED);

let link4 = new KEALink();
link4.source({ x: 10, y: 275 });
link4.target({ x: 200, y: 275 });
link4.setDasharray(LinkType.DASHED);
link4.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link4.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link1.clone();

export const getGeneralConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [swimlane1, link1, link2, link3, link4];
};

export const getBPMNGeneralMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getGeneralConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.8,
    () => {
      return defaultLink;
    },
    conf,
  );
};
