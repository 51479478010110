import { useTranslation } from "react-i18next";
import { CustomSimpleModal } from "./CustomSimpleModal";
import { MessageType } from "./SuccessModal";

interface Props {
  isActive: boolean;
  messageType: MessageType;
}

export const ErrorModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <CustomSimpleModal isActive={props.isActive}>
      <div className="box">
        {props.messageType === MessageType.TASK_SUCCESS && <p>{t("ErrorModal-Body")}</p>}
        {props.messageType === MessageType.LOADING_ERROR && <p>{t("ErrorModal-Body-Loading-Error")}</p>}
        {props.messageType === MessageType.MODEL_SUCCESS && <p>{t("CreateModelModalBodyFailure")}</p>}
      </div>
    </CustomSimpleModal>
  );
};
