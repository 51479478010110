import { KEAUMLComplexState } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: KEAUMLComplexState | undefined;
}

export const EditUMLComplexStateModal: FunctionComponent<Props> = ({ setisActive, isActive, node }) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const [label, setLabel] = useState<string>("");
  const [events, setEvents] = useState<string>("");

  useEffect(() => {
    if (node) {
      setLabel(node.getLabel());
      setEvents(node.getEvents());
    }
  }, [node]);

  const setIsActiveWrapper = useCallback((): void => {
    setisActive(false);
  }, [setisActive]);

  const assignChanges = useCallback((): void => {
    if (node) {
      node.setEvents(events).setLabel(label);
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setisActive(true);
  }, [node, keaGraphContext, setisActive, label, events]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignChanges, isActive, setIsActiveWrapper]);

  const handleLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const handleEventsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEvents(event.target.value);
  };

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("uml_state_modal") as string}
      body={
        <>
          <div className="field">
            <label className="label">{t("uml_state_modal_name")}</label>
            <div className="control">
              <input
                placeholder={t("uml_state_modal_name") as string}
                className="input"
                type="text"
                value={label}
                onChange={handleLabelChange}
              />
            </div>
          </div>

          <div className="field">
            <label className="label">{t("uml_state_modal_name")}</label>
            <div className="control">
              <textarea className="textarea" value={events} onChange={handleEventsChange} />
            </div>
          </div>
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignChanges();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
