import { util } from "jointjs";
import { KEAElement } from "./../KEAElement";

export type ObjectAttribute = {
  name: string;
  value: any;
};

export class KEAUMLComponent extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLComponent",
      size: {
        width: 200,
        height: 160,
      },
      attrs: {
        body: {
          width: "calc(w)",
          height: "calc(h)",
          x: 0,
          y: 0,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "white",
        },
        uml_component_name: {
          x: "calc(w/2)",
          y: 30,
          textAnchor: "middle",
          fontWeight: "bold",
          fontFamily: "Arial, sans-serif",
          fill: "black",
          fontSize: 12,
          text: "",
        },
        uml_component_symbole: {
          fill: "none",
          stroke: "black",
          strokeWidth: 2,
          d: "M calc(w-10) 22.5 L calc(w-10) 22.5 L calc(w-10) 7.5 L calc(w-10) 37.5 L calc(w - 28) 37.5 L calc(w - 28) 31.5 L calc(w - 20.5) 31.5 L calc(w - 20.5) 25.5 L calc(w - 35.5) 25.5 L calc(w - 35.5) 31.5 L calc(w - 20.5) 31.5 L calc(w - 20.5) 25.5 L calc(w - 28) 25.5 L calc(w - 28) 19.5 L calc(w - 20.5) 19.5 L calc(w - 20.5) 13.5 L calc(w - 35.5) 13.5 L calc(w - 35.5) 19.5 L calc(w - 28) 19.5 L calc(w - 20.5) 19.5 L calc(w - 20.5) 13.5 L calc(w - 28) 13.5 L calc(w - 28) 7.5 L calc(w-10) 7.5",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      resizeHeight: true,
      resizeWidth: true,
      minWidth: 140,
      minHeight: 60,
    });
  }

  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this.on("change:embeds", this.updatePortPosition);
    this.on("change:size", this.updatePortPosition);
  }

  updatePortPosition(): void {
    const ports = this.getEmbeddedCells().filter((cell) => cell.attributes.type === "kea.UMLPort");
    const width = this.get("size")!.width;
    const height = this.get("size")!.height;
    const position = this.get("position")!;
    const xLeft = position.x;
    const xRight = position.x + width;
    const yTop = position.y;
    const yBottom = position.y + height;

    ports.forEach((port) => {
      const portX = port.get("position")!.x;
      const portY = port.get("position")!.y;
      const distanceLeft = portX - xLeft;
      const distanceRight = xRight - portX;
      const distanceTop = portY - yTop;
      const distanceBottom = yBottom - portY;
      if (distanceLeft < distanceRight && distanceLeft < distanceTop && distanceLeft < distanceBottom) {
        port.set("position", { x: xLeft - 11, y: portY });
      } else if (distanceRight < distanceLeft && distanceRight < distanceTop && distanceRight < distanceBottom) {
        port.set("position", { x: xRight - 11, y: portY });
      } else if (distanceTop < distanceLeft && distanceTop < distanceRight && distanceTop < distanceBottom) {
        port.set("position", { x: portX, y: yTop - 11 });
      } else if (distanceBottom < distanceLeft && distanceBottom < distanceRight && distanceBottom < distanceTop) {
        port.set("position", { x: portX, y: yBottom - 11 });
      }
    });
  }

  onChangePrimaryColor(_element: KEAElement, _property: string, _options: any): void {
    this.attr("body/stroke", this.getPrimaryColor());
    this.attr("uml_component_symbole/stroke", this.getPrimaryColor());
  }

  onChangeSecondaryColor(_element: KEAElement, _property: string, _options: any): void {}

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr("uml_component_name/fill", property);
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      uml_component_name: { text: property },
    });
  }

  _calculateMarkup = (): { tagName: string; selector: string }[] => {
    const markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "text",
        selector: "uml_component_name",
      },
      {
        tagName: "path",
        selector: "uml_component_symbole",
      },
    ];

    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };
  markup = this._calculateMarkup();
}
