import { ModelingLanguage } from "@kea-mod/common";
import { ValuationType } from "tasks/sample_feedback";

export const getIsExportEnabled = (): boolean => {
  const isExportEnabled = getURLParam("exportEnabled");
  if (!isExportEnabled) return false;
  return convertToBoolean(isExportEnabled);
};

const convertToBoolean = (s: string): boolean => {
  switch (s.toLowerCase().trim()) {
    case "true":
      return true;
    case "false":
    case null:
    default:
      return false;
  }
};

export const getZoomDisabled = (): boolean => {
  const zoomDisabled = getURLParam("zoomDisabled");
  if (!zoomDisabled) return false;
  return convertToBoolean(zoomDisabled);
};

export const getModelId = (): number | null => {
  const modelId = getURLParam("modelId");
  if (!modelId) return null;
  return parseInt(modelId);
};

export const getExerciseId = (): number | null => {
  const exerciseId = getURLParam("exerciseId");
  if (!exerciseId) return null;
  return parseInt(exerciseId);
};

export const getSubmissionId = (): number | null => {
  const submissionId = getURLParam("submissionId");
  if (!submissionId) return null;
  return parseInt(submissionId);
};

export const getParentModel = (): string | null => {
  return getURLParam("parentModel");
};

export const getShowButtons = (): boolean => {
  const showButtons = getURLParam("showButtons");
  if (!showButtons) return false;
  return convertToBoolean(showButtons);
};

export const getParentId = (): number | null => {
  const parentId = getURLParam("parentId");
  if (!parentId) return null;
  return parseInt(parentId);
};

export const getModeltype = (): string | null => {
  return getURLParam("type");
};

export const getSubmissionExerciseId = (): string | null => {
  return getURLParam("submissionExerciseId");
};

export const getModelingLanguage = (): ModelingLanguage => {
  const urlParam = getURLParam("modelingLanguage");
  if (!urlParam) return ModelingLanguage.NONE;
  const modelingLanguage: ModelingLanguage = ModelingLanguage[urlParam as keyof typeof ModelingLanguage];
  return modelingLanguage;
};

const getURLParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getFeedbackHighlightColor = (type: ValuationType): string => {
  switch (type) {
    case ValuationType.NEGATIVE:
      return "#FF4365";
    case ValuationType.NEUTRAL:
      return "#9C9998";
    case ValuationType.POSITIVE:
      return "#3FCE41";
    case ValuationType.OTHER:
    default:
      return "#9C9998";
  }
};

export const defineObjectProperty = (obj: Object, key: string, value: any) => {
  var config = {
    value: value,
    writable: true,
    enumerable: true,
    configurable: true,
  };
  Object.defineProperty(obj, key, config);
};
