import { WithChildren } from "@kea-mod/common";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { useEffect } from "react";

interface Props extends WithChildren {
  isActive: boolean;
}

export const CustomSimpleModal = (props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  useEffect(() => {
    if (props.isActive) keaGraphContext.modelingPaper.removeDeleteEvent();
    if (!props.isActive) keaGraphContext.modelingPaper.addDeleteEvent();
  }, [props.isActive, keaGraphContext.modelingPaper]);

  return (
    <div className={props.isActive ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-content">{props.children}</div>
    </div>
  );
};
