import { MessageType, SuccessModal } from "../modals/SuccessModal";

import { WithChildren } from "@kea-mod/common";
import { Component } from "react";
import { SuccessModalContext } from "./SuccessModalContext";

interface State {
  successModalVisible: boolean;
}

interface Props extends WithChildren {}

export class SuccessModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      successModalVisible: false,
    };
  }

  toggleSuccessModal = () => {
    this.setState({
      successModalVisible: !this.state.successModalVisible,
    });
  };

  render() {
    return (
      <SuccessModalContext.Provider
        value={{
          toggleSuccessModal: this.toggleSuccessModal,
        }}
      >
        {this.props.children}
        <SuccessModal messageType={MessageType.MODEL_SUCCESS} isActive={this.state.successModalVisible} />
      </SuccessModalContext.Provider>
    );
  }
}
