import { AboutLicensesFooter } from "shared/footer/AboutLicensesFooter";
import { AboutProjectFooter } from "shared/footer/AboutProjectFooter";
import { ChangeLanguageFooter } from "shared/footer/ChangeLanguageFooter";
import { UserSettingsFooter } from "shared/footer/UserSettingsFooter";

interface Props {
  footerRef: React.Ref<HTMLDivElement>;
}

export const FooterView = (props: Props) => {
  return (
    <footer ref={props.footerRef} className="footer p-1">
      <div className="columns">
        <AboutProjectFooter />
        <AboutLicensesFooter />
        <UserSettingsFooter />
        <ChangeLanguageFooter />
      </div>
    </footer>
  );
};
