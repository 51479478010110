import * as joint from "jointjs";

import { ButtonGroup, Horizontal, ModelingLanguage, Vertical } from "@kea-mod/common";

import { IUserSettingsContext } from "context/UserSettingsContext";
import { Component } from "react";
import { IFramePrimaryGraph } from "shared/IFramePrimaryGraph";
import SecondaryGraph from "shared/SecondaryGraph";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ModelExportButton } from "shared/buttons/ModelExportButton";
import { ModelImportButton } from "shared/buttons/ModelImportButton";
import { RedoButton } from "shared/buttons/RedoButton";
import { SaveModelButton } from "shared/buttons/SaveModelButton";
import { UndoButton } from "shared/buttons/UndoButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";

interface State {}

interface Props {
  width: number;
  height: number;
  modelLanguage: ModelingLanguage;
  setModelLanguage: (language: ModelingLanguage) => void;
  userCanChangeModelingLanguage: boolean;
  setNode: (node: any) => void;
  pushPresent: () => void;
  addUserInteraction: any;
  userSettingsContext: IUserSettingsContext;
}

export class ContentView extends Component<Props, State> {
  storePositionChange = (position: joint.dia.Point) => {
    this.props.userSettingsContext.setSecondaryGraphPositionX(position.x);
    this.props.userSettingsContext.setSecondaryGraphPositionY(position.y);
  };

  storeWidthChange = (width: number) => {
    this.props.userSettingsContext.setSecondaryGraphWidth(width);
  };

  storeHeightChange = (height: number) => {
    this.props.userSettingsContext.setSecondaryGraphHeight(height);
  };

  render() {
    return (
      <>
        <IFramePrimaryGraph
          width={this.props.width}
          height={this.props.height}
          setNode={this.props.setNode}
          addUserInteraction={this.props.addUserInteraction}
          pushPresent={this.props.pushPresent}
        />

        <SecondaryGraph
          positionX={this.props.userSettingsContext.secondaryGraphPositionX}
          positionY={this.props.userSettingsContext.secondaryGraphPositionY}
          width={this.props.userSettingsContext.secondaryGraphWidth}
          height={this.props.userSettingsContext.secondaryGraphHeight}
          setPositionX={this.props.userSettingsContext.setSecondaryGraphPositionX}
          setPositionY={this.props.userSettingsContext.setSecondaryGraphPositionY}
          setWidth={this.props.userSettingsContext.setSecondaryGraphWidth}
          setHeight={this.props.userSettingsContext.setSecondaryGraphHeight}
          modelLanguage={this.props.modelLanguage}
          setModelLanguage={this.props.setModelLanguage}
          userCanChangeModelingLanguage={this.props.userCanChangeModelingLanguage}
        />

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.BOTTOM}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <ZoomInButton />
          <ZoomOutButton />
          <FitModelButton />
          <SaveModelButton />
          <ModelImportButton />
          <ModelExportButton />
        </ButtonGroup>

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.TOP}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <span className="buttons has-addons">
            <UndoButton />
            <RedoButton />
          </span>
        </ButtonGroup>
      </>
    );
  }
}
