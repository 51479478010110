import { KEAGraph, KEALink, keaNamespace } from "@kea-mod/jointjs";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

let defaultLink = new KEALink();

export const getEmptyConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [];
};

export const getEmptyMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getEmptyConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
