import {
  KEABPMNSwimlane,
  KEAElement,
  KEAGraph,
  KEALink,
  keaNamespace,
  KEAPolygon,
  KEAUMLActivityNode,
  KEAUMLArea,
  KEAUMLCircleState,
  KEAUMLReceiveSymbol,
  KEAUMLSendSymbol,
  LabelPosition,
  LinkLabelPosition,
  LinkType,
  MarkerPosition,
  MarkerType,
  StateType,
} from "@kea-mod/jointjs";
import { KEAUMLForkAndJoin } from "@kea-mod/jointjs/components/KEAUMLForkAndJoin";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { TemplateConfiguration } from "./TemplateConfiguration";

const activityNode = new KEAUMLActivityNode();
activityNode.position(10, 10);

const umlForkAndJoin = new KEAUMLForkAndJoin();
umlForkAndJoin.position(230, 10);

const send = new KEAUMLSendSymbol();
send.position(10, 120);

const receive = new KEAUMLReceiveSymbol();
receive.position(140, 120);

const area = new KEAUMLArea();
area.size(280, 100);
area.position(10, 180);

const umlChoice = new KEAPolygon();
umlChoice.position(10, 290);
umlChoice.setLabelPosition(LabelPosition.BELOW);
umlChoice.size(30, 30);

const initialUMLCircleActivity = new KEAUMLCircleState();
initialUMLCircleActivity.position(60, 290);
initialUMLCircleActivity.setStateType(StateType.INITIAL);

const exitUMLCircleActivity = new KEAUMLCircleState();
exitUMLCircleActivity.position(110, 290);
exitUMLCircleActivity.setStateType(StateType.EXIT);

const finalUMLCircleActivity = new KEAUMLCircleState();
finalUMLCircleActivity.position(160, 290);
finalUMLCircleActivity.setStateType(StateType.FINAL);

const swimlane = new KEABPMNSwimlane().position(10, 350).setNumberOfLanes(2);

const link = new KEALink();
link.source({ x: 10, y: 340 });
link.target({ x: 290, y: 340 });
link.setKEALabel({ position: LinkLabelPosition.MID, value: "" });
link.setDasharray(LinkType.SOLID);
link.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);

let defaultLink = link.clone();

export const getUMLActivityConfiguration = (): Array<KEAElement | KEALink> => {
  return [
    initialUMLCircleActivity,
    exitUMLCircleActivity,
    finalUMLCircleActivity,
    umlChoice,
    umlForkAndJoin,
    send,
    receive,
    activityNode,
    area,
    link,
    swimlane,
  ];
};

export const getUMLActivityMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    addLabelEnabled: true,
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: false,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLActivityConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
