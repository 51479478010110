import { WithChildren } from "@kea-mod/common";
import { FeedbackContextProvider } from "context/FeedbackContextProvider";
import { FeedbackModalCardContextProvider } from "context/FeedbackModalCardContextProvider";

interface Props extends WithChildren {}

export const FeedbackContext = (props: Props) => {
  return (
    <FeedbackContextProvider>
      <FeedbackModalCardContextProvider>{props.children}</FeedbackModalCardContextProvider>
    </FeedbackContextProvider>
  );
};
