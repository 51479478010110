import { WithChildren } from "@kea-mod/common";
import { useCallback, useEffect, useState } from "react";

interface Props extends WithChildren {
  setPosition: (point: joint.dia.Point) => void;
}

export const Draggable = ({ children, setPosition }: Props) => {
  const [dragEnabled, setDragEnabled] = useState<boolean>(false);
  const [dragOffset, setDragOffset] = useState<joint.dia.Point>({ x: 0, y: 0 });

  const enableDragEvent = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      setDragOffset({
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
      });
      setDragEnabled(true);
    },
    [setDragOffset],
  );

  const handleDragMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setPosition({
        x: event.pageX - dragOffset.x,
        y: event.pageY - dragOffset.y,
      });
    },
    [setPosition, dragOffset],
  );

  const handleDragMouseUp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setDragEnabled(false);
      setDragOffset({ x: 0, y: 0 });
    },
    [setDragEnabled, setDragOffset],
  );

  useEffect(() => {
    if (dragEnabled) {
      document.addEventListener("mousemove", handleDragMouseMove);
      document.addEventListener("mouseup", handleDragMouseUp);
    }
    if (!dragEnabled) {
      document.removeEventListener("mousemove", handleDragMouseMove);
      document.removeEventListener("mouseup", handleDragMouseUp);
    }
  }, [dragEnabled, handleDragMouseMove, handleDragMouseUp]);

  return (
    <div
      style={{
        cursor: "grab",
      }}
      onMouseDown={(e) => enableDragEvent(e)}
    >
      {children}
    </div>
  );
};
