import { useCallback, useState } from "react";

export function useCallbackRef(ref: any) {
  const [node, setNode] = useState(null);
  const setRef = useCallback(
    (newNode: any) => {
      setNode(ref(newNode));
    },
    [ref],
  );
  return [node, setRef];
}
