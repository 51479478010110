import { KEAUMLClass, UMLOperation, UMLOperationParameter, UMLVisibility } from "@kea-mod/jointjs";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  operations: UMLOperation[];
  setOperations: (value: UMLOperation[]) => void;
  deleteOperation: (index: number) => void;
  addOperationParameter: (position: number) => void;
};

export const EditUMLClassModalOperationsForm = (props: Props) => {
  const { t } = useTranslation();

  const handleOperationVisibilityChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].visibility = event.target.value as UMLVisibility;
    props.setOperations(newState);
  };

  const handleOperationNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].operationName = event.target.value;
    props.setOperations(newState);
  };

  const handleOperationReturnTypeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].returnType = event.target.value;
    //(props.node as KEAUMLClass).updateOperation(newState[index], index);
    props.setOperations(newState);
  };

  const deleteOperationParam = (index: number, paramIndex: number) => {
    const newState = [...props.operations];
    newState[index].operationParameters.splice(paramIndex, 1);
    //(props.node as KEAUMLClass).deleteOperationParameter(index, paramIndex);
    props.setOperations(newState);
  };

  const handleParamNameChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, paramindex: number) => {
    const newState = [...props.operations];
    newState[index].operationParameters[paramindex].parameterName = event.target.value;
    //(props.node as KEAUMLClass).updateOperation(newState[index], index);
    props.setOperations(newState);
  };

  const handleParamTypeChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, paramindex: number) => {
    const newState = [...props.operations];
    newState[index].operationParameters[paramindex].type = event.target.value;
    //(props.node as KEAUMLClass).updateOperation(newState[index], index);
    props.setOperations(newState);
  };

  const handleHasPropertiesChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].hasProperties = !newState[index].hasProperties;
    props.setOperations(newState);
  };

  const handleIsOrderedChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].isOrdered = !newState[index].isOrdered;
    props.setOperations(newState);
  };

  const handleIsQueryChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].isQuery = !newState[index].isQuery;
    props.setOperations(newState);
  };

  const handleIsUniqueChange = (_event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newState = [...props.operations];
    newState[index].isUnique = !newState[index].isUnique;
    props.setOperations(newState);
  };

  const renderOperation = (operation: UMLOperation, index: number) => {
    return (
      <div key={index}>
        {index < props.operations.length && (
          <div className="divider is-info is-light">{KEAUMLClass.getOperationString(operation)}</div>
        )}

        <div className="field is-grouped">
          <div className="control">
            <label className="label is-small">{t("uml_operations_modal_visibility")}</label>
            <div className="select is-fullwidth">
              <select value={operation.visibility} onChange={(event) => handleOperationVisibilityChange(event, index)}>
                <option value={UMLVisibility.none}>&nbsp;</option>
                <option value={UMLVisibility.public}>+</option>
                <option value={UMLVisibility.package}>~</option>
                <option value={UMLVisibility.protected}>#</option>
                <option value={UMLVisibility.private}>-</option>
              </select>
            </div>
          </div>
          <div className="control is-expanded">
            <label className="label is-small">{t("uml_operations_modal_operationname")}</label>
            <input
              className="input"
              type="text"
              placeholder={t("uml_operations_modal_operationname") as string}
              value={operation.operationName}
              onChange={(event) => handleOperationNameChange(event, index)}
            />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <label className="checkbox">
              <input
                checked={operation.hasProperties}
                onChange={(e) => handleHasPropertiesChange(e, index)}
                type="checkbox"
              />
              Has Properties
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={operation.isOrdered}
                disabled={operation.hasProperties ? false : true}
                onChange={(e) => handleIsOrderedChange(e, index)}
                type="checkbox"
              />
              Is Ordered
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={operation.isQuery}
                disabled={operation.hasProperties ? false : true}
                onChange={(e) => handleIsQueryChange(e, index)}
                type="checkbox"
              />
              Is Query
            </label>
          </div>
          <div className="control">
            <label className="checkbox">
              <input
                checked={operation.isUnique}
                disabled={operation.hasProperties ? false : true}
                onChange={(e) => handleIsUniqueChange(e, index)}
                type="checkbox"
              />
              Is Unique
            </label>
          </div>
        </div>

        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              type="text"
              value={operation.returnType}
              onChange={(event) => handleOperationReturnTypeChange(event, index)}
              placeholder={t("uml_operations_modal_returntype") as string}
            />
          </div>
          <div className="control">
            <button
              type="button"
              className="button is-danger"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                props.deleteOperation(index);
              }}
            >
              {t("uml_operations_modal_delete")}
            </button>
          </div>
        </div>

        {renderOperationParameters(operation.operationParameters, index)}

        <div className="field">
          <p className="control ">
            <button
              type="button"
              className="button is-fullwidth is-small is-success"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                props.addOperationParameter(index);
              }}
            >
              {t("uml_operations_modal_addparameter")}
            </button>
          </p>
        </div>
      </div>
    );
  };

  const renderOperationParameters = (parameters: UMLOperationParameter[], index: number) => {
    return (
      <>
        {parameters.map((parameter, parameterIndex) => {
          return renderOperationParameter(parameter, index, parameterIndex);
        })}
      </>
    );
  };

  const renderOperationParameter = (parameter: UMLOperationParameter, index: number, parameterIndex: number) => {
    const key = index.toString().concat(parameterIndex.toString());
    return (
      <div className="pl-4 mb-2" key={key}>
        <div className="field is-grouped">
          <div className="control is-expanded">
            <input
              placeholder={t("uml_operations_modal_parameter_name") as string}
              className="input"
              type="text"
              value={parameter.parameterName}
              onChange={(event) => handleParamNameChange(event, index, parameterIndex)}
            />
          </div>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder={t("uml_operations_modal_parameter_type") as string}
              value={parameter.type}
              onChange={(event) => handleParamTypeChange(event, index, parameterIndex)}
            />
          </div>
          <div className="control">
            <button
              className="button is-danger"
              type="button"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                deleteOperationParam(index, parameterIndex);
              }}
            >
              {t("uml_operations_modal_parameter_delete") as string}
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (props.operations.length === 0) {
    return <div className="block">{t("uml_operations_modal_noparameters")}</div>;
  } else {
    return <>{props.operations.map((operation, index) => renderOperation(operation, index))}</>;
  }
};
