import { useUserSettingsModalContext } from "context/UserSettingsModalContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const UserSettingsButton = (_props: Props) => {
  const userSettingsModalContext = useUserSettingsModalContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    userSettingsModalContext.setIsActiveSettingsModal(true);
  };
  return (
    <KeaButton onClick={handleClick} className="button is-outlined">
      <i className="fa fa-cog"></i>
    </KeaButton>
  );
};
