import { KEAGraph, KEALink, LinkConfiguration, MarkerPosition, MarkerType, keaNamespace } from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";
import { KEAUMLComponent } from "../uml_component/KEAUMLComponent";
import { KEAUMLPort } from "../uml_component/KEAUMLPort";
import { InterfaceType } from "../KEALink";

const component = new KEAUMLComponent();
component.position(20, 10);

const port = new KEAUMLPort();
port.position(240, 10);

const link = new KEALink();
link.source({ x: 20, y: 200 });
link.target({ x: 215, y: 200 });
link.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
link.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW);
link.setOrthogonalLines(true);

let defaultLink = link.clone();

const required_interface = new KEALink();
required_interface.source({ x: 20, y: 240 });
required_interface.target({ x: 200, y: 240 });
required_interface.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
required_interface.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.REQUIRED_INTERFACE);
required_interface.setInterfaceType(InterfaceType.REQUIRED);
required_interface.setOrthogonalLines(true);

const provided_interface = new KEALink();
provided_interface.source({ x: 20, y: 280 });
provided_interface.target({ x: 200, y: 280 });
provided_interface.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
provided_interface.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.PROVIDED_INTERFACE);
provided_interface.setInterfaceType(InterfaceType.PROVIDED);
provided_interface.setOrthogonalLines(true);

export const getUMLComponentConfiguration = (): Array<joint.dia.Element | joint.dia.Link> => {
  return [component, port, link, required_interface, provided_interface];
};

export const getUMLComponentMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
    sourceLabelEnabled: true,
    midLabelEnabled: false,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getUMLComponentConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    0.6,
    () => {
      return defaultLink;
    },
    conf,
  );
};
