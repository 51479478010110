import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const ZoomOutButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    keaGraphContext.modelingPaper.zoomOut();
    keaGraphContext.addUserInteraction(InteractionType.GRAPH_ZOOM_OUT, Date.now());
  };

  return (
    <KeaButton onClick={handleClick}>
      <span className="icon is-small">
        <i className="fa fa-search-minus"></i>
      </span>{" "}
    </KeaButton>
  );
};
