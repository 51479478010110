import { KEACircle } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: joint.dia.Element | undefined;
}

export const EditLabelModal = ({ setisActive, isActive, node }: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const [label, setLabel] = useState<string>("");
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (node) {
      setLabel((node as unknown as KEACircle).getLabel());
    }
  }, [node]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLabel(event.target.value);
  };

  const setIsActiveWrapper = useCallback((): void => {
    setLabel("");
    setisActive(false);
  }, [setisActive]);

  const assignLabel = useCallback((): void => {
    if (node) {
      (node as unknown as KEACircle).setLabel(label);
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setLabel("");
    setisActive(true);
  }, [node, keaGraphContext, setisActive, label]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignLabel();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignLabel, isActive, setIsActiveWrapper]);

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("edit_label_header") as string}
      body={
        <div className="field">
          <label className="label">{t("edit_label_name")}</label>
          <div className="control">
            <input
              placeholder={t("edit_label_name") as string}
              ref={inputElement}
              className="input"
              type="text"
              value={label}
              onChange={handleChange}
            />
          </div>
        </div>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignLabel();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
