import { getFeedbackHighlightColor } from "helper/Helper";
import { ReactNode } from "react";
import { FeedbackMessage } from "tasks/sample_feedback";

interface Props {
  feedbacks: Array<FeedbackMessage>;
}

export const FeedbackCard = (props: Props) => {
  const renderFeedback = (feedback: FeedbackMessage, index: number): ReactNode => {
    return (
      <div className="mb-2" style={{ color: getFeedbackHighlightColor(feedback.valuation) }} key={index}>
        {feedback.category}
        <br></br>
        {feedback.content}
        <br></br>
      </div>
    );
  };

  return (
    <>
      {props.feedbacks.map((feedback, index) => {
        return renderFeedback(feedback, index);
      })}
    </>
  );
};
