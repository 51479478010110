import { Draggable } from "./Draggable";

type Props = {
  setPosition: (point: joint.dia.Point) => void;
  height: number;
  x: number;
  y: number;
} & typeof defaultProps;

const defaultProps = {};

export const DragBorder = ({ setPosition, height, x, y }: Props) => {
  return (
    <Draggable setPosition={setPosition}>
      <div
        style={{
          position: "absolute",
          height: height,
          width: 10,
          left: x - 5,
          top: y,
        }}
      />
    </Draggable>
  );
};
DragBorder.defaultProps = defaultProps;
