import { ButtonGroup, Horizontal, Vertical } from "@kea-mod/common";
import { KEACircle, KEAGraph, KEALink, KEAPaper } from "@kea-mod/jointjs";
import { IKEAGraphContext, InteractionType } from "context/KEAGraphContext";
import { getFeedbackHighlightColor, getModelId, getSubmissionExerciseId } from "helper/Helper";
import * as joint from "jointjs";
import { MessageType } from "modals/SuccessModal";
import { Component } from "react";
import { APIManager } from "service/APIManager";
import { FeedbackGraph } from "shared/FeedbackGraph";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";
import { ModelExportButton } from "shared/buttons/ModelExportButton";
import { FeedbackMessage, ValuationType } from "tasks/sample_feedback";

interface State {}

interface Props {
  width: number;
  height: number;
  modelingGraph: KEAGraph;
  modelingPaper: KEAPaper;
  feedback: { [id: string]: Array<FeedbackMessage> };
  unresolvedFeedback: Array<FeedbackMessage>;
  setNode: (node?: any) => void;
  toggleErrorModal: (errorMessageType?: MessageType) => void;
  updateFeedback: (id: string) => void;
  apiManager: APIManager;
  positionFeedback: Array<FeedbackMessage>;
  addUserInteraction: (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | joint.dia.Link | joint.dia.Cell,
  ) => void;
  setFeedback: (feedback: { [id: string]: Array<FeedbackMessage> }) => void;
  setUnresolvedFeedback: (feedback: Array<FeedbackMessage>) => void;
  setPositionFeedback: (feedback: Array<FeedbackMessage>) => void;
  keaGraphContext: IKEAGraphContext;
}

export class ContentView extends Component<Props, State> {
  componentDidMount = async () => {
    const submissionExerciseId = getSubmissionExerciseId();
    const modelId = getModelId();

    if (modelId) {
      this.props.keaGraphContext.setModelLoaded(false);
      this.props.apiManager.getModel(modelId).then((result: any) => {
        if (result.success === true) {
          const graph = result.data.data.graphData.graph as KEAGraph;
          this.props.keaGraphContext.loadModel(graph, () => {
            this.addMouseEnterAndMouseLeaveEvents();
            Object.keys(this.props.feedback).forEach((id) => {
              this.props.updateFeedback(id);
            });
          });
        }
        //else handle error
      });
    }

    if (submissionExerciseId) {
      this.props.apiManager.getAssessmentsForSubmissionExercise(submissionExerciseId).then((result: any) => {
        setTimeout(() => {
          let feedbacks: { [id: string]: Array<FeedbackMessage> } = {};
          let unresolvedFeedbacks: Array<FeedbackMessage> = [];
          let positionFeedback: Array<FeedbackMessage> = [];

          result.forEach((item: FeedbackMessage) => {
            if (typeof item.position === "string") {
              if (!feedbacks[item.position]) {
                feedbacks[item.position] = [];
              }
              const newFeedack = feedbacks[item.position];
              newFeedack.push(item);
              feedbacks[item.position] = newFeedack;
            }
            if (typeof item.position === "object") {
              if (item.position.x && item.position.y) {
                positionFeedback.push(item);
              }
            }
            if (typeof item.position === "undefined") {
              unresolvedFeedbacks.push(item);
            }
          });
          this.props.setFeedback(feedbacks);
          this.props.setUnresolvedFeedback(unresolvedFeedbacks);
          this.props.setPositionFeedback(positionFeedback);
        }, 2000);
      });
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.feedback !== prevProps.feedback ||
      this.props.positionFeedback !== prevProps.positionFeedback ||
      this.props.unresolvedFeedback !== prevProps.unresolvedFeedback
    ) {
      this.unhighlightAllElements();
      this.highlightAllElements();
    }
  };

  getHighlightMarkup = (valuationTypes: Array<FeedbackMessage>) => {
    if (valuationTypes.find((e) => e.valuation === ValuationType.NEGATIVE)) {
      return {
        stroke: "#FF4365",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.NEUTRAL)) {
      return {
        stroke: "#9C9998",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.OTHER)) {
      return {
        stroke: "#9C9998",
        "stroke-width": 4,
      };
    }
    if (valuationTypes.find((e) => e.valuation === ValuationType.POSITIVE)) {
      return {
        stroke: "#3FCE41",
        "stroke-width": 4,
      };
    }

    return {
      stroke: "#9C9998",
      "stroke-width": 4,
    };
  };

  createFeedbackInfoShapeAt = (position: joint.dia.Point, type: ValuationType): KEACircle => {
    const shape = new KEACircle();
    shape.position(position.x, position.y);
    shape.size(25, 25);
    shape.setLabel("i");

    const feedbackColor = getFeedbackHighlightColor(type);
    shape.setSecondaryColor(feedbackColor);
    shape.setPrimaryColor(feedbackColor);
    shape.setFillOpacity(0.3);
    return shape;
  };

  highlightElement = (id: string, feedbacks: Array<FeedbackMessage>) => {
    const cells = this.props.modelingGraph.getCells();
    const cell = cells.find((element) => element.id === id);
    if (!cell) return;

    const cellView = this.props.modelingPaper.findViewByModel(cell);
    if (!cellView) return;

    if (cell.get("type") === "kea.Link") {
      (cell as KEALink).highlight(this.getHighlightMarkup(feedbacks).stroke);
    } else {
      joint.highlighters.mask.add(cellView as any, "body", "highlighter-selector", {
        attrs: this.getHighlightMarkup(feedbacks),
      });
    }
  };

  highlightAllElements = () => {
    for (const id in this.props.feedback) {
      this.highlightElement(id, this.props.feedback[id]);
    }
    this.props.positionFeedback.forEach((feedback) => {
      this.props.modelingGraph.addCell(
        this.createFeedbackInfoShapeAt(feedback.position as joint.dia.Point, feedback.valuation),
      );
    });
  };

  addMouseEnterAndMouseLeaveEvents = () => {
    this.props.modelingPaper.on("cell:mouseenter", (cellView) => {
      this.props.setNode(cellView.model);
    });

    this.props.modelingPaper.on("cell:mouseleave", () => {
      this.props.setNode();
    });
  };

  unhighlightAllElements = () => {
    const cells = this.props.modelingGraph.getCells();
    cells.forEach((cell) => {
      if (cell.get("type") === "kea.Link") {
        (cell as KEALink).unhighlight();
      } else {
        const cellView = this.props.modelingPaper.findViewByModel(cell);
        const elementView: joint.dia.ElementView = cellView as unknown as joint.dia.ElementView;
        joint.dia.HighlighterView.remove(elementView);
      }
    });
  };

  render() {
    return (
      <>
        <FeedbackGraph setNode={this.props.setNode} width={this.props.width} height={this.props.height} />
        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.BOTTOM}
          verticalSpace="1rem"
          horizontalSpace="1rem"
        >
          <ZoomInButton />
          <ZoomOutButton />
          <FitModelButton />
          <ModelExportButton />
        </ButtonGroup>
      </>
    );
  }
}
