import * as joint from "jointjs";

import { ModelingLanguage, exportFile } from "@kea-mod/common";

import { InteractionType, KEARectangle } from "@kea-mod/jointjs";
import { KEAGraphExport } from "@kea-mod/modellingtool/src/types/KEAGraphExport";
import { debounce } from "lodash";
import { KEAElement } from "./KEAElement";
import { KEALink } from "./KEALink";
import { XMIConverter } from "./XMIConverter";

export interface PaperPoint {
  x: number | undefined;
  y: number | undefined;
}

export class KEAGraph extends joint.dia.Graph {
  historyCallback?: () => void | undefined;
  interactionClb?: (
    interactionType: InteractionType,
    interactionTime: number,
    payload?: KEAGraph | KEALink | KEAElement,
  ) => void | undefined;

  constructor(attributes?: any, options?: any) {
    super(attributes, options);
    this.set("activeLink", undefined);
    this.set("selected", undefined);
    this.set("copyField", undefined);
    this.set("timesCopied", 1);
    this.set("modalNode", undefined);
    this.set("dragStartPosition", { x: undefined, y: undefined });
    this.on("remove", (cell: joint.dia.Cell) => {
      if (cell.get("type") === "kea.Link") {
        const link = cell as KEALink;
        const interfaceElement = link.get("interfaceElement");
        if (interfaceElement) {
          const interfaceCell = this.getCell(interfaceElement);
          interfaceCell?.remove();
        }
      }
    });
  }

  fromJSONWithoutInvisibleCell(json: any, opt?: joint.dia.ModelSetOptions | undefined): this {
    super.fromJSON(json, opt);
    return this;
  }

  fromJSON(json: any, opt?: joint.dia.ModelSetOptions | undefined): this {
    const invisibleCell: joint.dia.Cell = new KEARectangle({
      id: "myInvisibleCell",
      attrs: { body: { fill: "none", stroke: "none" } },
      position: { x: -1000, y: -1000 },
      size: { width: 0, height: 0 },
    });
    json.cells.push(invisibleCell);
    return this.fromJSONWithoutInvisibleCell(json, opt);
  }

  setHistoryCallback = (callback: () => void) => {
    this.historyCallback = callback;
  };

  setInteractionCallback = (
    callback: (
      interactionType: InteractionType,
      interactionTime: number,
      payload?: KEAGraph | KEALink | KEAElement,
    ) => void,
  ) => {
    this.interactionClb = callback;
  };

  addHistoryEvents = () => {
    this.on("add", () => {
      this.historyCallback?.();
    });

    this.on("remove", () => {
      this.historyCallback?.();
    });

    this.on(
      "change:position",
      debounce(() => {
        this.historyCallback?.();
      }, 50),
    );
  };

  addUserInteractionEvents = () => {
    this.on("change:attribute", (node) => {
      this.interactionClb?.(InteractionType.NODE_EDITED, Date.now(), node);
    });
  };

  setTimesCopied(value: number): void {
    this.set("timesCopied", value);
  }

  getTimesCopied(): number {
    return this.get("timesCopied");
  }

  setSelected(node: joint.dia.Cell | undefined): void {
    this.set("selected", node);
  }
  getSelected(): joint.dia.Cell | undefined {
    return this.get("selected");
  }

  setCopyField(node: joint.dia.Cell | undefined): void {
    this.set("copyField", node);
  }
  getCopyField(): joint.dia.Cell | undefined {
    return this.get("copyField");
  }

  setActiveLink(link: joint.dia.Link | undefined): void {
    this.set("activeLink", link);
  }
  getActiveLink(): joint.dia.Link | undefined {
    return this.get("activeLink");
  }

  setModalNode(node: joint.dia.Element | undefined): void {
    this.set("modalNode", node);
  }
  getModalNode(): joint.dia.Element | undefined {
    return this.get("modalNode");
  }

  setDragStartPosition(position: PaperPoint) {
    this.set("dragStartPosition", position);
  }

  getDragStartPosition(): PaperPoint {
    return this.get("dragStartPosition");
  }

  download(name: string = "model.kea") {
    this.set("activeLink", undefined);
    this.set("selected", undefined);
    this.set("copyField", undefined);
    this.set("timesCopied", 0);
    this.set("modalNode", undefined);
    this.set("dragStartPosition", { x: undefined, y: undefined });

    const file: KEAGraphExport = {
      graph: this.toJSON(),
    };
    exportFile(JSON.stringify(file), name);
  }

  export(language: ModelingLanguage) {
    const converter = new XMIConverter();
    const data = converter.parseModel(this.toJSON(), language);
    exportFile(data, "model.xmi", "application/xml");
  }
}
