import { useNameModelModalContext } from "context/NameModelModalContext";
import { useTranslation } from "react-i18next";
import { KeaButton } from "./KEAButton";

type Props = {};

export const SaveButton = (_props: Props) => {
  const { t } = useTranslation();
  const nameModelModalContext = useNameModelModalContext();
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    nameModelModalContext.toggleNameModelModal(true);
  };
  return (
    <>
      <KeaButton onClick={handleClick}>{t("save")}</KeaButton>
    </>
  );
};
