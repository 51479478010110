import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditKEALinkContext");
};

interface IEditKEALinkContext {
  setNode: (node: joint.dia.Cell) => void;
}

const defaultState: IEditKEALinkContext = {
  setNode: () => printOutsideContext(),
};

export const EditKEALinkContext = createContext<IEditKEALinkContext>(defaultState);
export const useEditKEALinkContext = () => useContext(EditKEALinkContext);
