import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { KeaButton } from "./KEAButton";

type Props = {};

export const FitModelButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    keaGraphContext.modelingPaper.handleFit();
    keaGraphContext.addUserInteraction(InteractionType.GRAPH_FIT, Date.now());
  };

  return (
    <KeaButton onClick={handleClick}>
      {" "}
      <span className="icon is-small">
        <i className="fa fa-compress"></i>
      </span>
    </KeaButton>
  );
};
