import { useAPIContext } from "context/APIContext";
import { useErrorMessageContext } from "context/ErrorModalContext";
import { useKEAGraphContext } from "context/KEAGraphContext";
import { dimension, useWindowSizeContext, windowPadding } from "context/WindowSizeContext";
import { ContentView } from "pages/model/preview/content/ContentView";
import { useEffect, useState } from "react";
import { ContentContainer } from "shared/ContentContainer";

interface Props {}

export const PreviewModelEditor = (_props: Props) => {
  const windowSizeContext = useWindowSizeContext();
  const keaGraphContext = useKEAGraphContext();
  const apiContext = useAPIContext();
  const errorModalContext = useErrorMessageContext();

  const [contentDimension, setContentDimension] = useState<dimension>({
    width: 1,
    height: 1,
  });

  useEffect(() => {
    setContentDimension({
      width: windowSizeContext.dimension.width,
      height: windowSizeContext.dimension.height - windowPadding,
    });
  }, [windowSizeContext.dimension.height, windowSizeContext.dimension.width]);

  return (
    <>
      <ContentContainer width={contentDimension.width} height={contentDimension.height}>
        <ContentView
          width={contentDimension.width}
          height={contentDimension.height}
          modelingGraph={keaGraphContext.modelingGraph}
          modelingPaper={keaGraphContext.modelingPaper}
          apiManager={apiContext.apiManager}
          toggleErrorModal={errorModalContext.toggleErrorModal}
          keaGraphContext={keaGraphContext}
        />
      </ContentContainer>
    </>
  );
};
