import { WithChildren } from "@kea-mod/common";
import { EditPositionLabelModal } from "modals/EditPositionLabelModal";
import { Component } from "react";
import { EditPositionLabelContext } from "./EditPositionLabelModalContext";

interface State {
  node: joint.dia.Element | undefined;
  changeLabelModalVisible: boolean;
}

interface Props extends WithChildren {}

export class EditPositionLabelModalContextProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      node: undefined,
      changeLabelModalVisible: false,
    };
  }

  isEditableByLabelModal = (cell: joint.dia.Element): boolean => {
    switch (cell.get("type")) {
      case "kea.Circle":
      case "kea.Ellipse":
      case "kea.Polygon":
      case "kea.Rectangle":
        return true;
      default:
        return false;
    }
  };

  toggleEditLabelModal = () => {
    this.setState((prevState) => ({
      changeLabelModalVisible: !prevState.changeLabelModalVisible,
    }));
  };

  setNode = (node: joint.dia.Element) => {
    if (this.isEditableByLabelModal(node)) {
      this.setState(
        {
          node,
        },
        () => {
          this.setState((prevState) => ({
            changeLabelModalVisible: !prevState.changeLabelModalVisible,
          }));
        },
      );
    }
  };

  render() {
    return (
      <EditPositionLabelContext.Provider
        value={{
          setNode: this.setNode,
        }}
      >
        {this.props.children}
        <EditPositionLabelModal
          isActive={this.state.changeLabelModalVisible}
          setisActive={this.toggleEditLabelModal}
          node={this.state.node}
        />
      </EditPositionLabelContext.Provider>
    );
  }
}
