import { ButtonGroup, Horizontal, ModelingLanguage, Vertical, getModelId } from "@kea-mod/common";
import { KEAGraph } from "@kea-mod/jointjs";
import { IKEAGraphContext } from "context/KEAGraphContext";
import { IUserSettingsContext } from "context/UserSettingsContext";
import * as joint from "jointjs";
import { Component } from "react";
import { APIManager } from "service/APIManager";
import { IFramePrimaryGraph } from "shared/IFramePrimaryGraph";
import SecondaryGraph from "shared/SecondaryGraph";
import { FitModelButton } from "shared/buttons/FitModelButton";
import { ModelExportButton } from "shared/buttons/ModelExportButton";
import { ModelImportButton } from "shared/buttons/ModelImportButton";
import { RedoButton } from "shared/buttons/RedoButton";
import { SaveButton } from "shared/buttons/SaveButton";
import { UndoButton } from "shared/buttons/UndoButton";
import { ZoomInButton } from "shared/buttons/ZoomInButton";
import { ZoomOutButton } from "shared/buttons/ZoomOutButton";

interface State {}

interface Props {
  width: number;
  height: number;
  modelLanguage: ModelingLanguage;
  setModelLanguage: (language: ModelingLanguage) => void;
  apiManager: APIManager;
  modelingGraph: KEAGraph;
  userCanChangeModelingLanguage: boolean;
  setNode: (node: any) => void;
  setName: (name: string) => void;
  pushPresent: () => void;
  addUserInteraction: any;
  userSettingsContext: IUserSettingsContext;
  keaGraphContext: IKEAGraphContext;
}

export class ContentView extends Component<Props, State> {
  componentDidMount = () => {
    const modelId = getModelId();
    this.props.keaGraphContext.setModelLoaded(false);
    if (modelId) {
      this.props.apiManager.getModel(modelId as any).then((result: any) => {
        if (result.success === true) {
          const graph = result.data.data.graphData.graph as KEAGraph;
          setTimeout(() => {
            this.props.keaGraphContext.loadModel(graph);
          }, 1000);

          const name = result.data.data.name;
          setTimeout(() => {
            this.props.setName(name);
          }, 1000);
        }
        //else handle error
      });
    }
  };

  storePositionChange = (position: joint.dia.Point) => {
    this.props.userSettingsContext.setSecondaryGraphPositionX(position.x);
    this.props.userSettingsContext.setSecondaryGraphPositionY(position.y);
  };

  storeWidthChange = (width: number) => {
    this.props.userSettingsContext.setSecondaryGraphWidth(width);
  };

  storeHeightChange = (height: number) => {
    this.props.userSettingsContext.setSecondaryGraphHeight(height);
  };

  render() {
    return (
      <>
        <IFramePrimaryGraph
          width={this.props.width}
          height={this.props.height}
          setNode={this.props.setNode}
          addUserInteraction={this.props.addUserInteraction}
          pushPresent={this.props.pushPresent}
        />

        <SecondaryGraph
          positionX={this.props.userSettingsContext.secondaryGraphPositionX}
          positionY={this.props.userSettingsContext.secondaryGraphPositionY}
          width={this.props.userSettingsContext.secondaryGraphWidth}
          height={this.props.userSettingsContext.secondaryGraphHeight}
          setPositionX={this.props.userSettingsContext.setSecondaryGraphPositionX}
          setPositionY={this.props.userSettingsContext.setSecondaryGraphPositionY}
          setWidth={this.props.userSettingsContext.setSecondaryGraphWidth}
          setHeight={this.props.userSettingsContext.setSecondaryGraphHeight}
          modelLanguage={this.props.modelLanguage}
          setModelLanguage={this.props.setModelLanguage}
          userCanChangeModelingLanguage={this.props.userCanChangeModelingLanguage}
        />

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.BOTTOM}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <ZoomInButton />
          <ZoomOutButton />
          <FitModelButton />
          <SaveButton />
          <ModelImportButton />
          <ModelExportButton />
        </ButtonGroup>

        <ButtonGroup
          alignHorizontal={Horizontal.RIGHT}
          alignVertical={Vertical.TOP}
          verticalSpace="4rem"
          horizontalSpace="2rem"
        >
          <span className="buttons has-addons">
            <UndoButton />
            <RedoButton />
          </span>
        </ButtonGroup>
      </>
    );
  }
}
