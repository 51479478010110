import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditBPMNContext");
};

interface IEditBPMNContext {
  setNode: (node: joint.dia.Cell) => void;
}

const defaultState: IEditBPMNContext = {
  setNode: () => printOutsideContext(),
};

export const EditBPMNContext = createContext<IEditBPMNContext>(defaultState);
export const useEditBPMNContext = () => useContext(EditBPMNContext);
