import { ChangeEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isActive: boolean;
  name: string;
  setName: (value: string) => void;
  identifier: string;
  setIdentifier: (value: string) => void;
};

export const EditUMLClassModalIdentifierForm = (props: Props) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [props.isActive]);

  const handleIdentifierChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    props.setIdentifier(event.target.value);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    props.setName(event.target.value);
  };

  return (
    <div className="field is-grouped">
      <div className="control">
        <label className="label is-small">{t("uml_modal_identifier")}</label>
        <div className="select">
          <select value={props.identifier} onChange={handleIdentifierChange}>
            <option value="">None</option>
            <option value="Class">Class</option>
            <option value="Interface">Interface</option>
            <option value="Enumeration">Enumeration</option>
          </select>
        </div>
      </div>
      <div className="control is-expanded">
        <label className="label is-small">{t("uml_modal_name")}</label>
        <input
          ref={inputElement}
          className="input"
          type="text"
          placeholder={t("uml_modal_name") as string}
          value={props.name}
          onChange={handleNameChange}
        />
      </div>
    </div>
  );
};
