import * as joint from "jointjs";
import { KEAElement } from "../KEAElement";

export const rotateTool = new joint.elementTools.Button({
  markup: [
    {
      tagName: "circle",
      selector: "button",
      attributes: {
        cx: 10,
        cy: 10,
        r: 10,
        fill: "#FFFFFF",
        cursor: "pointer",
      },
    },
    {
      tagName: "path",
      attributes: {
        fill: "none",
        stroke: "black",
        transform: "translate(10 10)",
        d: "M -0.9874 -5.0624 L -4.111 -1.9674 L -0.9874 1.1202 V -0.8042 C -0.9116 -0.8142 -0.8444 -0.8474 -0.7648 -0.8474 C 0.6352 -0.8474 1.77 0.2948 1.77 1.6946 C 1.77 2.3574 1.5042 2.952 1.0878 3.4036 L 2.7466 5.0624 C 3.5868 4.1864 4.111 3.0042 4.111 1.6946 C 4.111 -0.9982 1.928 -3.1882 -0.7648 -3.1882 C -0.8406 -3.1882 -0.9126 -3.171 -0.9874 -3.1666 V -5.0623 Z",
        cursor: "pointer",
      },
    },
  ],
  x: "100%",
  y: "0%",
  offset: {
    x: -10,
    y: -20,
  },
  action: function () {
    const element: KEAElement = this.model as unknown as KEAElement;
    element.rotate(element.angle() + 90, true);
    element.trigger("keaelement:rotate");
  },
});
