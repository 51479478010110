import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("SuccessModalContext");
};

interface ISuccessModalContext {
  toggleSuccessModal: () => void;
}

const defaultState: ISuccessModalContext = {
  toggleSuccessModal: () => printOutsideContext(),
};

export const SuccessModalContext = createContext<ISuccessModalContext>(defaultState);
export const useSuccessModalContext = () => useContext(SuccessModalContext);
