import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { KEAGraphExport } from "types/KEAGraphExport";
import { KeaButton } from "./KEAButton";

type Props = {};

export const ModelImportButton = (_props: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);

  const clickInput = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (inputEl) {
      inputEl.current?.click();
      keaGraphContext.addUserInteraction(InteractionType.IMPORT_START, Date.now());
    }
  };

  const resetInput = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  const importFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const file = (event.target as HTMLInputElement).files![0];
    // @ts-ignore
    reader.fileName = file.name;

    reader.onload = (upload: any) => {
      const contents: KEAGraphExport = JSON.parse(upload.target.result);
      if (contents) {
        try {
          keaGraphContext.loadModel(contents.graph, () => {
            keaGraphContext.addUserInteraction(InteractionType.IMPORT_SUCCESS, Date.now());
          });
        } catch (error) {
          keaGraphContext.addUserInteraction(InteractionType.IMPORT_ERROR, Date.now(), error);
        }
      } else {
        keaGraphContext.addUserInteraction(InteractionType.IMPORT_ERROR, Date.now());
      }
    };

    if (file !== undefined) {
      reader.readAsText(file);
    } else {
      keaGraphContext.addUserInteraction(InteractionType.IMPORT_ABORT, Date.now());
    }
  };

  return (
    <>
      <input type="file" hidden ref={inputEl} onChange={importFile} onClick={resetInput} />
      <KeaButton onClick={clickInput}>{t("open")}</KeaButton>
    </>
  );
};
