import { defineObjectProperty } from "@kea-mod/common";
import { util } from "jointjs";
import { KEAElement } from "./KEAElement";

export type UMLAttribute = {
  visibility: UMLVisibility;
  attributeName: string;
  attributeType: string;
  multiplicity: string;
  defaultValue: string;
  properties: UMLAttributeProperties;
  isStatic: boolean;
};

export type UMLAttributeProperties = {
  isComposite: boolean;
  isDerived: boolean;
  isDerivedUnion: boolean;
  isReadOnly: boolean;
  isStatic: boolean;
  isUnique: boolean;
};

export type UMLOperation = {
  visibility: UMLVisibility;
  operationName: string;
  operationParameters: UMLOperationParameter[];
  returnType: string;
  hasProperties: boolean;
  isOrdered: boolean;
  isQuery: boolean;
  isUnique: boolean;
};

export type UMLOperationProperties = {
  ordered: boolean;
  readOnly: boolean;
  in: boolean;
  out: boolean;
  inout: boolean;
  redefines: boolean;
};

export type UMLOperationParameter = {
  handoverDirection: HandoverDirection;
  parameterName: string;
  type: string;
  multiplicity: string;
  defaultValue: string;
  properties: OperationProperties;
};

export enum UMLVisibility {
  none = "none",
  public = "public",
  private = "private",
  protected = "protected",
  package = "package",
}

export enum HandoverDirection {
  IN = "in",
  OUT = "out",
  INOUT = "inout",
  RETURN = "return",
}

export interface OperationProperties {
  ordered: boolean;
  readOnly: boolean;
  in: boolean;
  out: boolean;
  inout: boolean;
  redefines: boolean;
}

export class KEAUMLClass extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLClass",
      size: {
        width: 200,
        height: 160,
      },
      attrs: {
        uml_class_name_rect: {
          refWidth: "100%",
          height: 40,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#ededed",
        },
        uml_class_attrs_rect: {
          refWidth: "100%",
          refY: 40,
          height: 60,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "white",
        },
        uml_class_operations_rect: {
          refWidth: "100%",
          refY: 100,
          height: 60,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "white",
        },
        uml_class_name_text: {
          ref: "uml_class_name_rect",
          refY: 0.5,
          refX: 0.5,
          textAnchor: "middle",
          yAlignment: "middle",
          fontWeight: "bold",
          fill: "black",

          fontSize: 12,
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        body: {
          refWidth: "100%",
          refHeight: "100%",
          refX: "0%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          pointerEvents: "invisible",
        },
      },
      identifier: "",
      name: "",
      attributes: [],
      operations: [],
      minWidth: 200,
      minHeight: 160,
      resizeHeight: true,
      resizeWidth: true,
      minHeaderRectHeight: 40,
      minAttributeRectHeight: 60,
      minOperationRectHeight: 60,
      headerRectHeight: 40,
      attributesRectHeight: 60,
      operationsReactHeight: 60,
    });
  }

  constructor(attributes?: any, opt?: any) {
    super(attributes, opt);
    this._calculateAndSetRectHeights();
    this._resizeAndPositionAttributesRect();
    this._resizeAndPositionOperationsRect();
    this.on(
      "change:name change:methods",
      () => {
        this._calculateAndSetRectHeights();
        this._resizeAndPositionAttributesRect();
        this._resizeAndPositionOperationsRect();
        this.trigger("render-markup");
      },
      this,
    );
    this.on("change:identifier", this._changeIdentifierListener, this);
    this.on("change:attribute", this._changeAttributeListener, this);
    this.on("change:operation", this._changeOperationListener, this);
    this.on("change:size", this.changeSizeListener, this);
  }

  onChangePrimaryColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      uml_class_name_rect: { stroke: _property },
      uml_class_attrs_rect: { stroke: _property },
      uml_class_operations_rect: { stroke: _property },
    });
  }

  onChangeSecondaryColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      uml_class_name_rect: { fill: _property },
    });
  }

  onChangeLabelColor(element: KEAElement, _property: string, _options: any): void {
    element.attr({
      uml_class_name_text: { fill: _property },
    });
  }

  /**
   * Identifier related function
   */

  getClassIdentifier = (): string => {
    return this.get("identifier");
  };

  setClassIdentifier = (value: string): KEAUMLClass => {
    this.set("identifier", value);
    this.trigger("change:identifier");
    return this;
  };

  getClassName = (): string => {
    return this.get("name");
  };

  setClassName = (value: string): KEAUMLClass => {
    this.set("name", value);
    this.trigger("change:identifier");
    return this;
  };

  _updateIdentiferStyles = () => {
    const name = this.getClassName();
    const identifer = this.getClassIdentifier();

    const truncatedIdentifierText = util.breakText(
      "<< " + identifer + " >>",
      {
        width: this.size().width,
        height: this.size().height,
      },
      { "font-size": 12 },
    );

    const truncatedNameText = util.breakText(
      name,
      {
        width: this.size().width,
        height: this.size().height,
      },
      { "font-size": 12 },
    );

    this.attr(
      "uml_class_name_text/text",
      truncatedIdentifierText.split("\n", 1)[0] + "\n" + truncatedNameText.split("\n", 1)[0],
    );
  };

  _changeIdentifierListener = () => {
    this._updateIdentiferStyles();
  };

  /**
   * Attribute related functions
   */

  getAttributes = (): Array<UMLAttribute> => {
    return this.get("attributes");
  };

  addAttribute = (attribute: UMLAttribute): KEAUMLClass => {
    this.getAttributes().push(attribute);
    this.trigger("change:attribute", this);
    return this;
  };

  updateAttribute = (attribute: UMLAttribute, position: number): KEAUMLClass => {
    this.getAttributes()[position] = attribute;
    this.trigger("change:attribute", this);
    return this;
  };

  deleteAttribute = (position: number): KEAUMLClass => {
    this.getAttributes().splice(position, 1);
    this.trigger("change:attribute", this);
    return this;
  };

  _updateAttributeStyles = () => {
    this.attr(KEAUMLClass._getAttributStyles(this.getAttributes()));
  };

  _changeAttributeListener = () => {
    this._calculateAndSetRectHeights();
    this._resizeAndPositionAttributesRect();
    this._resizeAndPositionOperationsRect();

    this._updateAttributeStyles();
    this.markup = this._calculateMarkup();
    this.trigger("render-markup");
  };

  /**
   * Operation related functions
   */

  getOperations = (): Array<UMLOperation> => {
    return this.get("operations");
  };

  addOperation = (operation: UMLOperation): KEAUMLClass => {
    this.getOperations().push(operation);
    this.trigger("change:operation", this);
    return this;
  };

  deleteOperation = (position: number): KEAUMLClass => {
    this.getOperations().splice(position, 1);
    this.trigger("change:operation", this);
    return this;
  };

  addOperationParameter = (position: number): KEAUMLClass => {
    const param = {
      handoverDirection: HandoverDirection.IN,
      parameterName: "",
      type: "",
      multiplicity: "",
      defaultValue: "",
      properties: {
        ordered: false,
        readOnly: false,
        in: false,
        out: false,
        inout: false,
        redefines: false,
      },
    };
    this.getOperations()[position].operationParameters.push(param);
    this.trigger("change:operation", this);
    return this;
  };

  deleteOperationParameter = (position: number, index: number): KEAUMLClass => {
    this.getOperations()[position].operationParameters.splice(index, 1);
    this.trigger("change:operation", this);
    return this;
  };

  updateOperation = (operation: UMLOperation, position: number): KEAUMLClass => {
    this.getOperations()[position] = operation;
    this.trigger("change:operation", this);
    return this;
  };

  _updateOperationStyles = () => {
    this.attr(KEAUMLClass._getOperationStyles(this.getOperations()));
  };

  _changeOperationListener = () => {
    this._calculateAndSetRectHeights();
    this._resizeAndPositionAttributesRect();
    this._resizeAndPositionOperationsRect();

    this._updateOperationStyles();
    this.markup = this._calculateMarkup();
    this.trigger("render-markup");
  };

  /**
   * Helper functions
   */

  _resizeAndPositionAttributesRect = () => {
    const headerRectHeight = this.get("headerRectHeight");
    const attributesRectHeight = this.get("attributesRectHeight");
    this.attr("uml_class_operations_rect/refY", headerRectHeight);
    this.attr("uml_class_attrs_rect/height", attributesRectHeight);
  };

  _resizeAndPositionOperationsRect = () => {
    const headerRectHeight = this.get("headerRectHeight");
    const attributesRectHeight = this.get("attributesRectHeight");
    const operationsReactHeight = this.get("operationsReactHeight");

    this.attr("uml_class_operations_rect/refY", headerRectHeight + attributesRectHeight);
    this.attr("uml_class_operations_rect/height", operationsReactHeight);
  };

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  _calculateMarkup = (): { tagName: string; selector: string }[] => {
    const markup = [
      {
        tagName: "rect",
        selector: "uml_class_name_rect",
      },
      {
        tagName: "rect",
        selector: "uml_class_attrs_rect",
      },
      {
        tagName: "rect",
        selector: "uml_class_operations_rect",
      },
      {
        tagName: "text",
        selector: "uml_class_name_text",
      },
      {
        tagName: "rect",
        selector: "body",
      },
    ]
      .concat(this._calculateAttributesMarkup())
      .concat(this._calculateOperationsMarkup());

    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  _calculateAttributesMarkup = (): Array<{
    tagName: string;
    selector: string;
  }> => {
    const attributes: Array<UMLAttribute> = this.getAttributes();
    const attributeMarkup: { tagName: string; selector: string }[] = [];
    attributes.forEach((_attribute, index) =>
      attributeMarkup.push({
        tagName: "text",
        selector: "uml_class_attribute_text-" + index,
      }),
    );
    return attributeMarkup;
  };

  _calculateOperationsMarkup = (): Array<{
    tagName: string;
    selector: string;
  }> => {
    const operations = this.getOperations();
    const operationMarkup: { tagName: string; selector: string }[] = [];
    operations.forEach((_operation, index) =>
      operationMarkup.push({
        tagName: "text",
        selector: "uml_class_operation_text-" + index,
      }),
    );
    return operationMarkup;
  };

  _calculateAndSetRectHeights = () => {
    this._calculateAndSetAttributeRectHeight();
    this._calculateAndSetOperationRectHeight();
    const minHeight = this._calculateAndSetMinHeight();
    this.size(this.size().width, minHeight);
  };

  _calculateAndSetMinHeight = (): number => {
    const headerRectHeight = this.get("headerRectHeight");
    const operationsReactHeight = this.get("operationsReactHeight");
    const attributesRectHeight = this.get("attributesRectHeight");
    const minHeight = headerRectHeight + operationsReactHeight + attributesRectHeight;
    this.prop("minHeight", minHeight);
    return minHeight;
  };

  _calculateAndSetAttributeRectHeight = (): number => {
    const attributes = this.getAttributes();
    const minAttributeRectHeight = this.get("minAttributeRectHeight");
    const calculatedAttributesRectHeight = attributes.length * 20;
    const attributesRectHeight =
      calculatedAttributesRectHeight > minAttributeRectHeight ? calculatedAttributesRectHeight : minAttributeRectHeight;
    this.prop("attributesRectHeight", attributesRectHeight);
    return attributesRectHeight;
  };

  _calculateAndSetOperationRectHeight = (): number => {
    const methods = this.getOperations();
    const minOperationRectHeight = this.get("minOperationRectHeight");
    const calculatedOperationsReactHeight = methods.length * 20;
    const operationsReactHeight =
      calculatedOperationsReactHeight > minOperationRectHeight
        ? calculatedOperationsReactHeight
        : minOperationRectHeight;
    this.prop("operationsReactHeight", operationsReactHeight);
    return operationsReactHeight;
  };

  changeSizeListener = () => {
    const height = this.size().height;
    const headerRectHeight = this.get("headerRectHeight");
    const attributesRectHeight = this.get("attributesRectHeight");

    if (height) {
      const operationsReactHeight = height - headerRectHeight - attributesRectHeight;
      this.attr("uml_class_operations_rect/height", operationsReactHeight);
    }
  };

  markup = this._calculateMarkup();

  static getOperationString = (operation: UMLOperation) => {
    let attributeString = "";
    attributeString = attributeString.concat(KEAUMLClass.getVisibilityMarkup(operation.visibility));
    attributeString =
      operation.operationName !== "" ? attributeString.concat(" ").concat(operation.operationName) : attributeString;
    attributeString = attributeString.concat("(");

    KEAUMLClass.getOperationParametersMarkup(operation.operationParameters).forEach((e) => attributeString.concat(e));

    attributeString = attributeString.concat(" )");
    attributeString =
      operation.returnType !== "" ? attributeString.concat(" : ").concat(operation.returnType) : attributeString;
    attributeString = attributeString
      .concat(" {")
      .concat(KEAUMLClass.getUMLOperationPropertyMarkup(operation.isOrdered, operation.isQuery, operation.isUnique))
      .concat(" }");
    return attributeString;
  };

  static getOperationParametersMarkup = (operationParameters: UMLOperationParameter[]): string[] => {
    const params = operationParameters.map((parameter) => KEAUMLClass.getOperationParameterMarkup(parameter));
    return params;
  };

  static getOperationParameterMarkup = (operationParameter: UMLOperationParameter) => {
    let attributeString = " ";
    attributeString =
      operationParameter.handoverDirection !== HandoverDirection.IN
        ? attributeString.concat("[", operationParameter.handoverDirection, "]")
        : attributeString;
    attributeString =
      operationParameter.parameterName !== ""
        ? attributeString.concat(" ", operationParameter.parameterName)
        : attributeString;
    attributeString =
      operationParameter.type !== "" ? attributeString.concat(": ", operationParameter.type) : attributeString;
    attributeString =
      operationParameter.multiplicity !== ""
        ? attributeString.concat(" [", operationParameter.multiplicity, "]")
        : attributeString;
    return attributeString;
  };

  static getAttributeString = (attribute: UMLAttribute) => {
    let attributeString = "";

    attributeString = attributeString.concat(KEAUMLClass.getVisibilityMarkup(attribute.visibility));
    attributeString =
      attribute.attributeName !== "" ? attributeString.concat(" ").concat(attribute.attributeName) : attributeString;
    attributeString =
      attribute.attributeType !== "" ? attributeString.concat(" : ").concat(attribute.attributeType) : attributeString;
    attributeString =
      attribute.multiplicity !== ""
        ? attributeString.concat(" [").concat(attribute.multiplicity).concat("]")
        : attributeString;
    attributeString =
      attribute.defaultValue !== "" ? attributeString.concat(" = ").concat(attribute.defaultValue) : attributeString;
    attributeString = attribute.properties
      ? attributeString
          .concat(" { ")
          .concat(KEAUMLClass.getUMLAttributePropertyMarkup(attribute.properties))
          .concat(" }")
      : attributeString;
    return attributeString;
  };

  static getVisibilityMarkup = (visibility: UMLVisibility) => {
    switch (visibility) {
      case UMLVisibility.public:
        return "+";
      case UMLVisibility.private:
        return "-";
      case UMLVisibility.package:
        return "~";
      case UMLVisibility.protected:
        return "#";
      default:
        return "";
    }
  };

  static getUMLAttributePropertyMarkup = (properties: UMLAttributeProperties): string => {
    let propertyString = "";
    const propertyArray = [];
    if (properties.isComposite) {
      propertyArray.push("isComposite");
    }
    if (properties.isDerived) {
      propertyArray.push("isDerived");
    }
    if (properties.isDerivedUnion) {
      propertyArray.push("isDerivedUnion");
    }
    if (properties.isReadOnly) {
      propertyArray.push("isReadOnly");
    }
    if (properties.isStatic) {
      propertyArray.push("isStatic");
    }
    if (properties.isUnique) {
      propertyArray.push("isUnique");
    }

    for (let i: number = 0; i < propertyArray.length; i++) {
      switch (i) {
        case propertyArray.length - 1:
          propertyString = propertyString.concat(propertyArray[i]);
          break;
        default:
          propertyString = propertyString.concat(propertyArray[i]).concat(", ");
          break;
      }
    }
    return propertyString;
  };

  static getUMLOperationPropertyMarkup = (isOrdered: boolean, isQuery: boolean, isUnique: boolean): string => {
    let propertyString = " ";
    const propertyArray = [];
    if (isOrdered) {
      propertyArray.push("ordered");
    }
    if (isQuery) {
      propertyArray.push("query");
    }
    if (isUnique) {
      propertyArray.push("unique");
    }

    for (let i: number = 0; i < propertyArray.length; i++) {
      switch (i) {
        case propertyArray.length - 1:
          propertyString = propertyString.concat(propertyArray[i]);
          break;
        default:
          propertyString = propertyString.concat(propertyArray[i]).concat(", ");
          break;
      }
    }
    return propertyString;
  };

  static _getAttributStyles = (attributes: UMLAttribute[]) => {
    const attributeStyles = {};

    attributes.forEach((attribute, index) => {
      const objectIdentifier = "uml_class_attribute_text-" + index;

      defineObjectProperty(attributeStyles, objectIdentifier, KEAUMLClass._getAttributMarkup(index, attribute));
    });
    return attributeStyles;
  };

  static _getAttributMarkup = (index: number, attribute: UMLAttribute) => {
    return {
      ref: "uml_class_attrs_rect",
      refY: 5 + index * 20,
      refX: 5,
      fill: "black",
      textAnchor: "start",
      textDecoration: attribute.isStatic ? "underline" : "none",
      text: KEAUMLClass.getAttributeString(attribute),
      fontSize: 12,
    };
  };

  static _getOperationStyles = (operations: UMLOperation[]) => {
    const operationStyles = {};

    operations.forEach((operation, index) => {
      const objectIdentifier = "uml_class_operation_text-" + index;
      defineObjectProperty(operationStyles, objectIdentifier, KEAUMLClass._getOperationMarkup(index, operation));
    });
    return operationStyles;
  };

  static _getOperationMarkup = (index: number, operation: UMLOperation) => {
    return {
      ref: "uml_class_operations_rect",
      refY: 5 + index * 20,
      refX: 5,
      fill: "black",
      textAnchor: "start",
      text: KEAUMLClass.getOperationString(operation),
      fontSize: 12,
    };
  };
}
