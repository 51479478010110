import { createContext, useContext } from "react";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("EditUMLContext");
};

interface IEditUMLContext {
  setNode: (node: joint.dia.Cell) => void;
}

const defaultState: IEditUMLContext = {
  setNode: () => printOutsideContext(),
};

export const EditUMLContext = createContext<IEditUMLContext>(defaultState);
export const useEditUMLContext = () => useContext(EditUMLContext);
