import { util } from "jointjs";
import { getLabelMarkup, KEAElement, LabelPosition } from "./KEAElement";

export enum FrameType {
  FRAME = "FRAME",
  REFERENCE = "REFERENCE",
  ALT = "ALT",
}

export class KEAUMLFrame extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLFrame",
      size: {
        width: 200,
        height: 150,
      },
      attrs: {
        body: {
          width: "calc(w)",
          height: "calc(h)",
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#FFFFFF",
        },
        header: {
          width: 60,
          height: 20,
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#FFFFFF",
          d: "M0,0 L 60,0 L 60,10 L 52,20 L 0, 20 Z",
        },
        border: {
          width: "calc(0.96 * w)",
          height: "calc(0.92 * h)",
          x: "calc(0.02 * w)",
          y: "calc(0.04 * h)",
          stroke: "#000000",
          strokeWidth: 2,
          fill: "none",
          visibility: "hidden",
        },
        line: {
          stroke: "black",
          "stroke-dasharray": "5, 5",
          x1: 0,
          y1: "calc(0.5*h)",
          x2: "calc(w)",
          y2: "calc(0.5*h)",
          visibility: "hidden",
        },
        label: {
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          x: 15,
          y: 12,
          text: "sd",
          fontSize: 14,
          fill: "#333333",
        },
        resize_border_right: {
          width: 10,
          refHeight: "100%",
          refX: "100%",
          refY: "0%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
        resize_border_bottom: {
          refWidth: "100%",
          height: 10,
          refX: "0%",
          refY: "100%",
          stroke: "none",
          fill: "none",
          cursor: "se-resize",
          pointerEvents: "visible",
          event: "keaelement:resize",
        },
      },
      label: "",
      labelPosition: "CENTER",
      minWidth: 100,
      minHeight: 50,
      frameType: FrameType.FRAME,
      resizeHeight: true,
      resizeWidth: true,
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
      header: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { fill: property },
    });
  }

  onChangeLabel(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { text: this.getPrefix() + " " + property },
    });
  }

  getPrefix(): string {
    switch (this.get("frameType")) {
      case FrameType.REFERENCE:
        return "ref";
      case FrameType.ALT:
        return "alt";
      default:
        return "sd";
    }
  }

  getFrameType(): FrameType {
    return this.get("frameType");
  }

  setFrameType(frameType: FrameType): void {
    this.set("frameType", frameType);
    this.attr({
      label: { text: this.getPrefix() + " " + this.get("label") },
      line: { visibility: frameType === FrameType.ALT ? "visible" : "hidden" },
    });
  }

  onChangeLabelPosition(_element: KEAElement, property: string, _options: any): void {
    const labelPosition: LabelPosition = LabelPosition[property as keyof typeof LabelPosition];
    const markup = getLabelMarkup(labelPosition);
    this.attr({
      label: { ...markup },
    });
  }

  onChangeResizeHeight(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_bottom: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_bottom: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  onChangeResizeWidth(_element: KEAElement, property: boolean, _options: any): void {
    if (property) {
      this.attr({
        resize_border_right: {
          cursor: "se-resize",
          event: "keaelement:resize",
        },
      });
    } else {
      this.attr({
        resize_border_right: {
          cursor: "default",
          event: undefined,
        },
      });
    }
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
      {
        tagName: "path",
        selector: "header",
      },
      {
        tagName: "line",
        selector: "line",
      },
      {
        tagName: "rect",
        selector: "border",
      },
      {
        tagName: "text",
        selector: "label",
      },
    ];
    if (this.getResizeWidth()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_right",
      });
    }
    if (this.getResizeHeight()) {
      markup.push({
        tagName: "rect",
        selector: "resize_border_bottom",
      });
    }
    return markup;
  };

  markup = this.getBodyMarkup();
}
