import { util } from "jointjs";
import { KEAElement } from "../KEAElement";

export class KEAUMLPort extends KEAElement {
  defaults() {
    return util.defaultsDeep({
      ...super.defaults(),
      type: "kea.UMLPort",
      size: {
        width: 22,
        height: 22,
      },
      attrs: {
        body: {
          refWidth: "100%",
          refHeight: "100%",
          strokeWidth: 2,
          stroke: "#000000",
          fill: "#FFFFFF",
        },
      },
    });
  }

  onChangePrimaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { stroke: property },
    });
    if (this.get("secondBorder")) {
      this.attr({
        border: { stroke: property },
      });
    }
  }

  onChangeFillOpacity(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fillOpacity: property },
    });
  }

  onChangeSecondaryColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { fill: property },
    });
  }

  onChangeStrokeWidth(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      body: { strokeWidth: property },
    });
  }

  onChangeLabelColor(_element: KEAElement, property: string, _options: any): void {
    this.attr({
      label: { fill: property },
    });
  }

  private getBodyMarkup = () => {
    let markup = [
      {
        tagName: "rect",
        selector: "body",
      },
    ];
    return markup;
  };

  markup = this.getBodyMarkup();
}
