import {
  BPMNGateway,
  GatewayType,
  KEAGraph,
  KEALink,
  LinkConfiguration,
  LinkType,
  MarkerPosition,
  MarkerType,
  OutlineType,
  keaNamespace,
} from "@kea-mod/jointjs";

import { TemplateConfiguration } from "./TemplateConfiguration";

const gateway1 = new BPMNGateway().position(10, 10).setGatewayType(GatewayType.INCLUSIVE);

const gateway2 = new BPMNGateway().position(90, 10).setGatewayType(GatewayType.EXLUSIVE);

const gateway3 = new BPMNGateway().position(170, 10).setGatewayType(GatewayType.PARALLEL);

const gateway4 = new BPMNGateway()
  .position(10, 90)
  .setOutlineType(OutlineType.SINGLE)
  .setGatewayType(GatewayType.EVENT_BASED);

const gateway5 = new BPMNGateway()
  .position(90, 90)
  .setOutlineType(OutlineType.DOUBLE)
  .setGatewayType(GatewayType.EVENT_BASED);

const gateway6 = new BPMNGateway().position(170, 90).setGatewayType(GatewayType.COMPLEX);

const gateway7 = new BPMNGateway()
  .position(10, 170)
  .setOutlineType(OutlineType.SINGLE)
  .setGatewayType(GatewayType.EVENT_PARALLEL);

const gateway8 = new BPMNGateway()
  .position(90, 170)
  .setOutlineType(OutlineType.DOUBLE)
  .setGatewayType(GatewayType.EVENT_PARALLEL);

const gateway9 = new BPMNGateway().position(170, 170).setGatewayType(GatewayType.NONE);

let defaultLink = new KEALink();
defaultLink.setDasharray(LinkType.SOLID);
defaultLink.setMarker(MarkerPosition.SOURCE_MARKER, MarkerType.NONE);
defaultLink.setMarker(MarkerPosition.TARGET_MARKER, MarkerType.ARROW_FILLED);

export const getGatewayConfiguration = (): Array<any> => {
  return [gateway1, gateway2, gateway3, gateway4, gateway5, gateway6, gateway7, gateway8, gateway9];
};

export const getGatewayMarkup = (): TemplateConfiguration => {
  const conf: LinkConfiguration = {
    editPathTypeEnabled: true,
    editSourceMarkerEnabled: true,
    editTargetMarkerEnabled: true,
    sourceLabelEnabled: true,
    midLabelEnabled: true,
    targetLabelEnabled: true,
  };
  const graph = new KEAGraph({}, { cellNamespace: keaNamespace });
  graph.addCells(getGatewayConfiguration());
  return new TemplateConfiguration(
    graph.toJSON(),
    1,
    () => {
      return defaultLink;
    },
    conf,
  );
};
