import { WithChildren } from "@kea-mod/common";
import { AssessmentContextProvider } from "context/AssessmentContextProvider";
import { TaskContextProvider } from "context/TaskContextProvider";
import { StandaloneContext } from "./StandaloneContext";

interface Props extends WithChildren {}

export const ExcersisesContext = (props: Props) => {
  return (
    <StandaloneContext>
      <AssessmentContextProvider>
        <TaskContextProvider>{props.children}</TaskContextProvider>
      </AssessmentContextProvider>
    </StandaloneContext>
  );
};
