import { KEAElement, KEAGraph, KEAPaper, keaNamespace } from "@kea-mod/jointjs";
import { createContext, useContext } from "react";
import { LinkConfiguration } from "shared/LinkConfiguration";
import { printOutsideContextProviderMessage } from "./ContextHelper";

const printOutsideContext = () => {
  printOutsideContextProviderMessage("KEAGraphContext");
};

export interface Interaction {
  interactionType: InteractionType;
  interactionTime: number;
  payload?: any;
  //graph: KEAGraph;
}

export enum InteractionType {
  NEW_FROM_EMPTY = "New empty graph.",
  NEW_FROM_TEMPLATE = "New graph from template.",
  EDGE_CREATED = "New link created.",
  EDGE_EDITING_STARTED = "Started editing a link.",
  EDGE_EDITING_CANCEL = "Cancelled editing a link.",
  EDGE_EDITING_SAVE = "Saved the edited link.",
  EDGDE_DELETED = "Link deleted.",
  NODE_CREATED = "New node created.",
  NODE_UNEMBED = "NODE_UNEMBED",
  NODE_EMBED = "NODE_EMBED",
  NODE_EDITED = "NODE_EDITED",
  NODE_EDITING_STARTED = "Started editing a node.",
  NODE_EDITING_CANCEL = "Cancelled editing a node.",
  NODE_EDITING_SAVE = "Saved the edited node.",
  NODE_DELETED = "Node deleted.",
  NODE_DRAGGED = "Node was dragged",
  GRAPH_ZOOM_IN = "Zoomed into graph.",
  GRAPH_ZOOM_OUT = "Zoomed out graph.",
  GRAPH_FIT = "Fitted graph.",
  GRAPH_DRAG_START = "GRAPH_DRAG_START",
  GRAPH_DRAG_END = "GRAPH_DRAG_END",
  GRAPH_DOWNLOAD = "GRAPH_DOWNLOAD",
  UNDO = "undid last action",
  REDO = "redid last action",
  IMPORT_START = "IMPORT_START",
  IMPORT_ABORT = "IMPORT_ABORT",
  IMPORT_SUCCESS = "IMPORT_SUCCESS",
  IMPORT_ERROR = "IMPORT_ERROR",
  DRAG_AND_DROP_START = "DRAG_AND_DROP_START",
  DRAG_AND_DROP_SUCCESS = "DRAG_AND_DROP_SUCCESS",
  DRAG_AND_DROP_ABORT = "DRAG_AND_DROP_ABORT",
  COPY_STRG_C = "COPY_STRG_C",
  COPY_STRG_V = "COPY_STRG_V",
}

export interface IKEAGraphContext {
  modelingGraph: KEAGraph;
  modelingPaper: KEAPaper;
  templateGraph: KEAGraph;
  templatePaper: KEAPaper;
  linkConfiguration: LinkConfiguration;
  past: KEAGraph[];
  present: KEAGraph | undefined;
  future: KEAGraph[];
  undo: () => void;
  redo: () => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
  pushPresent: () => void;
  resetHistory: (present: KEAGraph) => void;
  setModelingGraph: (graph: KEAGraph) => void;
  setModelingPaper: (graph: KEAPaper) => void;
  setTemplateGraph: (graph: KEAGraph) => void;
  setTemplatePaper: (graph: KEAPaper) => void;
  updateLinkConfiguration: (conf: LinkConfiguration) => void;
  addCellToModelingGraph: (cell: KEAElement) => void;
  addUserInteraction: (interactionType: InteractionType, interactionTime: number, payload?: any) => void;
  getUserInteractions: () => Interaction[];
  clearUserInteractions: () => void;
  modelLoaded: boolean;
  setModelLoaded: (loaded: boolean) => void;
  loadModel: (json: any, onFinished?: () => void) => void;
}

const defaultState: IKEAGraphContext = {
  modelingGraph: new KEAGraph({}, { cellNamespace: keaNamespace }),
  modelingPaper: new KEAPaper({ cellViewNamespace: keaNamespace }),
  templateGraph: new KEAGraph({}, { cellNamespace: keaNamespace }),
  templatePaper: new KEAPaper({ cellViewNamespace: keaNamespace }),
  linkConfiguration: {
    addLabelEnabled: false,
    editPathTypeEnabled: false,
    editSourceMarkerEnabled: false,
    editTargetMarkerEnabled: false,
  },
  past: [],
  present: undefined,
  future: [],
  modelLoaded: false,
  setModelLoaded: () => printOutsideContext(),
  undo: () => printOutsideContext(),
  redo: () => printOutsideContext(),
  canUndo: () => {
    printOutsideContext();
    return false;
  },
  canRedo: () => {
    printOutsideContext();
    return false;
  },
  pushPresent: () => printOutsideContext(),
  resetHistory: () => printOutsideContext(),
  addCellToModelingGraph: () => printOutsideContext(),
  setModelingGraph: () => printOutsideContext(),
  setModelingPaper: () => printOutsideContext(),
  setTemplateGraph: () => printOutsideContext(),
  setTemplatePaper: () => printOutsideContext(),
  updateLinkConfiguration: () => printOutsideContext(),
  addUserInteraction: () => printOutsideContext(),
  getUserInteractions: () => {
    printOutsideContext();
    return [];
  },
  clearUserInteractions: () => printOutsideContext(),
  loadModel: (_onFinished?: () => void) => printOutsideContext(),
};

export const KEAGraphContext = createContext<IKEAGraphContext>(defaultState);
export const useKEAGraphContext = () => useContext(KEAGraphContext);
