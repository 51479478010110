import { BPMNTaskType, KEABPMNActivity } from "@kea-mod/jointjs";
import { InteractionType, useKEAGraphContext } from "context/KEAGraphContext";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: KEABPMNActivity;
}

export const EditBPMNActivityModal = ({ setisActive, isActive, node }: Props) => {
  const keaGraphContext = useKEAGraphContext();
  const { t } = useTranslation();

  const [label, setLabel] = useState<string>("");
  const [isSubProcess, setIsSubProcess] = useState<boolean>(false);
  const [isTransaction, setIsTransaction] = useState<boolean>(false);
  const [isLoop, setIsLoop] = useState<boolean>(false);
  const [isEventSubProcess, setisEventSubProcess] = useState<boolean>(false);
  const [isParallel, setIsParallel] = useState<boolean>(false);
  const [isSequential, setIsSequential] = useState<boolean>(false);
  const [isCompensation, setIsCompensation] = useState<boolean>(false);
  const [isAdHoc, setIsAdHoc] = useState<boolean>(false);
  const [isCallActivity, setIsCallActivity] = useState<boolean>(false);
  const [taskType, setTaskType] = useState<BPMNTaskType>(BPMNTaskType.NONE);

  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (node) {
      setLabel(node.getLabel());
      setIsSubProcess(node.getIsSubProcess());
      setIsLoop(node.getIsLoop());
      setIsTransaction(node.getIsTransaction());
      setIsParallel(node.getIsParallel());
      setIsSequential(node.getIsSequential());
      setIsCompensation(node.getIsCompensation());
      setIsAdHoc(node.getIsAdHoc());
      setIsCallActivity(node.getIsCallActivity());
      setTaskType(node.getTaskType());
      setisEventSubProcess(node.getIsEventSubprocess());
    }
  }, [node]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLabel(event.target.value);
  };

  const setIsActiveWrapper = useCallback((): void => {
    setisActive(false);
  }, [setisActive]);

  const assignChanges = useCallback((): void => {
    if (node) {
      node
        .setIsSubProcess(isSubProcess)
        .setIsLoop(isLoop)
        .setIsTransaction(isTransaction)
        .setIsParallel(isParallel)
        .setIsSequential(isSequential)
        .setIsCompensation(isCompensation)
        .setIsAdHoc(isAdHoc)
        .setIsCallActivity(isCallActivity)
        .setTaskType(taskType)
        .setIsEventSubprocess(isEventSubProcess)
        .setLabel(label);
    }
    keaGraphContext.addUserInteraction(InteractionType.NODE_EDITING_SAVE, Date.now(), node?.toJSON());
    setisActive(true);
  }, [
    node,
    keaGraphContext,
    setisActive,
    label,
    isSubProcess,
    isLoop,
    isTransaction,
    isParallel,
    isSequential,
    isCompensation,
    isAdHoc,
    isCallActivity,
    taskType,
    isEventSubProcess,
  ]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignChanges();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignChanges, isActive, setIsActiveWrapper]);

  const handleSubProcessChange = () => {
    setIsSubProcess(!isSubProcess);
  };

  const handleLoopChange = () => {
    setIsLoop(!isLoop);
  };
  const handleIsParallelChange = () => {
    setIsParallel(!isParallel);
  };
  const handleIsSequentialChange = () => {
    setIsSequential(!isSequential);
  };
  const handleIsCompensationChange = () => {
    setIsCompensation(!isCompensation);
  };
  const handleIsAdHocChange = () => {
    setIsAdHoc(!isAdHoc);
  };
  const handleIsEventProcessChange = () => {
    setisEventSubProcess(!isEventSubProcess);
  };
  const handleIsCallActivityChange = () => {
    setIsCallActivity(!isCallActivity);
  };
  const handleIsTransactionChange = () => {
    setIsTransaction(!isTransaction);
  };
  const handleTaskTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTaskType(event.target.value as BPMNTaskType);
  };

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("bpmn_modal_activity_header") as string}
      body={
        <>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">{t("bpmn_modal_activity_name")}</label>
                <div className="control">
                  <input
                    placeholder={t("bpmn_modal_activity_name") as string}
                    ref={inputElement}
                    className="input"
                    type="text"
                    value={label}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">{t("bpmn_modal_activity_tasktype")}</label>
                <div className="select">
                  <select value={taskType} onChange={handleTaskTypeChange}>
                    <option value={BPMNTaskType.NONE}>None</option>
                    <option value={BPMNTaskType.USER}>User</option>
                    <option value={BPMNTaskType.SERVICE}>Service</option>
                    <option value={BPMNTaskType.SEND}>Send</option>
                    <option value={BPMNTaskType.SCRIPT}>Script</option>
                    <option value={BPMNTaskType.RECEIVE}>Receive</option>
                    <option value={BPMNTaskType.MANUAL}>Manual</option>
                    <option value={BPMNTaskType.BUSINESS_RULE}>Business Rule</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="divider">{t("bpmn_modal_activity_activitytype")}</div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isSubProcess} onChange={handleSubProcessChange} />
              is Subprocess
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isTransaction} onChange={handleIsTransactionChange} />
              is Transaction
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isCallActivity} onChange={handleIsCallActivityChange} />
              is Call Activity
            </label>
          </div>

          <div className="divider">{t("bpmn_modal_activity_markertype")}</div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isEventSubProcess} onChange={handleIsEventProcessChange} />
              is Eventsubprocess
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isLoop} onChange={handleLoopChange} />
              is Loop
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isParallel} onChange={handleIsParallelChange} />
              is Parallel
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isSequential} onChange={handleIsSequentialChange} />
              is Sequential
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isCompensation} onChange={handleIsCompensationChange} />
              is Compensation
            </label>
          </div>

          <div className="field">
            <label className="checkbox">
              <input type="checkbox" checked={isAdHoc} onChange={handleIsAdHocChange} />
              is Ad Hoc
            </label>
          </div>
        </>
      }
      footer={
        <p className="buttons">
          <button
            className="button is-rounded is-light"
            onClick={() => {
              setisActive(true);
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="button is-rounded is-primary"
            onClick={() => {
              assignChanges();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
