import { useEffect, useMemo, useState } from "react";
import {
  LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT_DEFAULT,
  LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT,
  LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y_DEFAULT,
  LOCAL_STORAGE_FEEDBACK_CARD_WIDTH_DEFAULT,
  LOCAL_STORAGE_LABEL_COLOR_DEFAULT,
  LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT,
  LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT,
  LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT,
  LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_DEFAULT,
  LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT,
  UserLocalStorage,
} from "shared/UserLocalStorage";

import { WithChildren } from "@kea-mod/common";
import { UserSettingsContext } from "./UserSettingsContext";

interface Props extends WithChildren {}

export const UserSettingsContextProvider = ({ children }: Props) => {
  const userLocalStorage: UserLocalStorage = useMemo(() => new UserLocalStorage(), []);
  const [gridSize, setGridSize] = useState(LOCAL_STORAGE_PAPER_GRID_SIZE_DEFAULT);
  const [grid, setGrid] = useState(LOCAL_STORAGE_PAPER_GRID_DEFAULT);
  const [gridBackground, setGridBackground] = useState(LOCAL_STORAGE_PAPER_GRID_BACKGROUND_DEFAULT);

  const [secondaryGraphPositionX, setSecondaryGraphPositionX] = useState(
    LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT,
  );
  const [secondaryGraphPositionY, setSecondaryGraphPositionY] = useState(
    LOCAL_STORAGE_SECONDARY_GRAPH_POSITION_Y_DEFAULT,
  );
  const [secondaryGraphWidth, setSecondaryGraphWidth] = useState(LOCAL_STORAGE_SECONDARY_GRAPH_WIDTH_DEFAULT);
  const [secondaryGraphHeight, setSecondaryGraphHeight] = useState(LOCAL_STORAGE_SECONDARY_GRAPH_HEIGHT_DEFAULT);

  const [taskCardPositionX, setTaskCardPositionX] = useState(LOCAL_STORAGE_TASK_CARD_POSITION_X_DEFAULT);
  const [taskCardPositionY, setTaskCardPositionY] = useState(LOCAL_STORAGE_TASK_CARD_POSITION_Y_DEFAULT);
  const [taskCardWidth, setTaskCardWidth] = useState(LOCAL_STORAGE_TASK_CARD_WIDTH_DEFAULT);
  const [taskCardHeight, setTaskCardHeight] = useState(LOCAL_STORAGE_TASK_CARD_HEIGHT_DEFAULT);

  const [feedbackCardPositionX, setFeedbackCardPositionX] = useState(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_X_DEFAULT);
  const [feedbackCardPositionY, setFeedbackCardPositionY] = useState(LOCAL_STORAGE_FEEDBACK_CARD_POSITION_Y_DEFAULT);
  const [feedbackCardWidth, setFeedbackCardWidth] = useState(LOCAL_STORAGE_FEEDBACK_CARD_WIDTH_DEFAULT);
  const [feedbackCardHeight, setFeedbackCardHeight] = useState(LOCAL_STORAGE_FEEDBACK_CARD_HEIGHT_DEFAULT);

  const [primaryColor, setPrimaryColor] = useState(LOCAL_STORAGE_PRIMARY_COLOR_DEFAULT);
  const [secondaryColor, setSecondaryColor] = useState(LOCAL_STORAGE_SECONDARY_COLOR_DEFAULT);
  const [labelColor, setLabelColor] = useState(LOCAL_STORAGE_LABEL_COLOR_DEFAULT);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setSecondaryGraphPositionX(userLocalStorage.getSecondaryGraphPositionX());
    setSecondaryGraphPositionY(userLocalStorage.getSecondaryGraphPositionY());
    setSecondaryGraphWidth(userLocalStorage.getSecondaryGraphWidth());
    setSecondaryGraphHeight(userLocalStorage.getSecondaryGraphHeight());

    setTaskCardPositionX(userLocalStorage.getTaskCardPositionX());
    setTaskCardPositionY(userLocalStorage.getTaskCardPositionY());
    setTaskCardWidth(userLocalStorage.getTaskCardWidth());
    setTaskCardHeight(userLocalStorage.getTaskCardHeight());

    setFeedbackCardPositionX(userLocalStorage.getFeedbackCardPositionX());
    setFeedbackCardPositionY(userLocalStorage.getFeedbackCardPositionY());
    setFeedbackCardWidth(userLocalStorage.getFeedbackCardWidth());
    setFeedbackCardHeight(userLocalStorage.getFeedbackCardHeight());

    setPrimaryColor(userLocalStorage.getPrimaryColor());
    setSecondaryColor(userLocalStorage.getSecondaryColor());
    setLabelColor(userLocalStorage.getLabelColor());

    setGridSize(userLocalStorage.getGridSize());
    setGrid(userLocalStorage.getGrid());
    setGridBackground(userLocalStorage.getGridBackground());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    userLocalStorage.setGridSize(gridSize);
  }, [gridSize, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setGrid(grid);
  }, [grid, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setGridBackground(gridBackground);
  }, [gridBackground, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setSecondaryGraphPositionX(secondaryGraphPositionX);
  }, [secondaryGraphPositionX, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setSecondaryGraphPositionY(secondaryGraphPositionY);
  }, [secondaryGraphPositionY, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setSecondaryGraphWidth(secondaryGraphWidth);
  }, [secondaryGraphWidth, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setSecondaryGraphHeight(secondaryGraphHeight);
  }, [secondaryGraphHeight, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setTaskCardPositionX(taskCardPositionX);
  }, [taskCardPositionX, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setTaskCardPositionY(taskCardPositionY);
  }, [taskCardPositionY, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setTaskCardWidth(taskCardWidth);
  }, [taskCardWidth, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setTaskCardhHeight(taskCardHeight);
  }, [taskCardHeight, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setFeedbackCardPositionX(feedbackCardPositionX);
  }, [feedbackCardPositionX, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setFeedbackCardPositionY(feedbackCardPositionY);
  }, [feedbackCardPositionY, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setFeedbackCardWidth(feedbackCardWidth);
  }, [feedbackCardWidth, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setFeedbackCardhHeight(feedbackCardHeight);
  }, [feedbackCardHeight, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setPrimaryColor(primaryColor);
  }, [primaryColor, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setSecondaryColor(secondaryColor);
  }, [secondaryColor, userLocalStorage]);

  useEffect(() => {
    userLocalStorage.setLabelColor(labelColor);
  }, [labelColor, userLocalStorage]);

  return (
    <UserSettingsContext.Provider
      value={{
        gridSize,
        setGridSize,
        grid,
        setGrid,
        gridBackground,
        setGridBackground,
        secondaryGraphPositionX,
        setSecondaryGraphPositionX,
        secondaryGraphPositionY,
        setSecondaryGraphPositionY,
        secondaryGraphWidth,
        setSecondaryGraphWidth,
        secondaryGraphHeight,
        setSecondaryGraphHeight,
        taskCardPositionX,
        setTaskCardPositionX,
        taskCardPositionY,
        setTaskCardPositionY,
        taskCardWidth,
        setTaskCardWidth,
        taskCardHeight,
        setTaskCardHeight,
        feedbackCardPositionX,
        setFeedbackCardPositionX,
        feedbackCardPositionY,
        setFeedbackCardPositionY,
        feedbackCardWidth,
        setFeedbackCardWidth,
        feedbackCardHeight,
        setFeedbackCardHeight,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        labelColor,
        setLabelColor,
        isActive,
        setIsActive,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};
